import React from 'react'
import HeaderMenu from '../Reusable/HeaderMenu'
import ReactDOM from 'react-dom';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { Container, Input, Button } from 'semantic-ui-react'
import ChangePassword from '../../MainProject/WithoutLogin/ChangePassword';

const axios = require('axios');

const ResetPassword = () => {
    const [loading, set_loading] = React.useState(false)
    const [CPWd, set_CPWd] = React.useState('')

    function submit() {
        if(CPWd != '' && CPWd != null){
        set_loading(true)
        let d = localStorage.getItem("emp_data");
        axios.get('Nishant/FGTAPI/VerifyPWD?id=' + JSON.parse(d).email_id + '&PWD=' + CPWd).then(function (response) {
            set_loading(false)
            if (!response.data.Ok) NotificationManager.error('Wrong Password')
            else {
                ReactDOM.render(<ChangePassword Email={JSON.parse(d).email_id}></ChangePassword>, document.getElementById('Content'));
            }
        }).catch(function (error) {
                set_loading(false)
                alert(error);
            })
        }
        else NotificationManager.error('Please Enter Current Password');
    }

    return (
        <div>
        <HeaderMenu HeaderText="Change Password" ShowChangePassword={false}></HeaderMenu>
        <Container style={{ width: 600, backgroundColor: '#fff' }} className="m-2">
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                justifyContent: 'center',
                alignItems: 'center',
                width: 600,
                paddingLeft: 130, paddingRight: 130,
                height: '80vh'
            }}>
                <div style={{display: "grid"}} id="Content">
                    <Input
                        onChange={(e) => {
                            set_CPWd(e.target.value)
                        }}
                        size='large' type="password" placeholder='Enter Current Password' />

                    <br></br>
                    <div style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                        <Button
                            style={{ width: 340, fontSize: 16 }}
                            onClick={() => {
                                submit()
                            }} positive>Verify Password</Button>
                    </div>
                </div>
                <div style={{ alignSelf: 'center', display: loading ? 'flex' : 'none' }}>
                    <Button
                        style={{ width: 340, fontSize: 16 }}
                        loading positive>
                        Loading
            </Button>
                </div>
            </div>
            <NotificationContainer></NotificationContainer>
        </Container>
    
            
        </div>
    )
}

export default ResetPassword
