import { useEffect, useState } from "react";
import React, { Component } from 'react'
import { Label, Container, Image } from 'semantic-ui-react'
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import NotificationManager from 'react-notifications/lib/NotificationManager';
const axios = require('axios');
const queryString = require("query-string");

const TrainerFeedBack = (props) => {
    const queryURL = queryString.parseUrl(window.location.href.replace("%20",""));
    console.log(window.location.href.replace("%20",""));
    const [trainerId, trainerId1] = React.useState(queryURL.query.trainerId1);
    const [arate1, rate1] = React.useState(queryURL.query.rate1);
    const [brate2, rate2] = React.useState(queryURL.query.rate2);
    const [gtrainerid, trainerId2] = React.useState(queryURL.query.trainerId2);
    const [loading, set_loading] = React.useState(false)




    useEffect(() => {
        set_loading(true);
        get_data();
    }, []);

    async function get_data() {
        set_loading(true);
        console.log(queryURL.query.trainerId1)
        console.log(queryURL.query.rate1)
        console.log(queryURL.query.rate2)
        console.log(queryURL.query.trainerId2)

        axios.get('Avishek/Save_Trainer_Feedback?Type=4&trainerId1=' + queryURL.query.trainerId1 + '&trainerId2=' + queryURL.query.trainerId2+'&rate1='+queryURL.query.rate1+'&rate2='+queryURL.query.rate2).then(function (response) {
            console.log("API CALLED");
            
            set_loading(false)
            console.log(response.data);

            if (!response.data.Ok) {
                NotificationManager.success('Feedback submitted Successfully')
                setTimeout(() => {
                    window.location.href = window.location.origin + '/login';
                }, 3000);
            }
            else {
                NotificationManager.error('Something Went Wrong Try Again!!!!');
                setTimeout(() => {
                    window.location.href = window.location.origin + '/login';
                }, 3000);
            }
        }).catch(function (error) {
            console.log("Error :")
            set_loading(false)
            alert(error);
        })

    }

    return (
        <Container style={{ width: 600, backgroundColor: '#fff' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                width: 600,
                paddingLeft: 130, paddingRight: 130,
                height: '100vh'
            }}>
                <br></br>
                <br></br>

                <Image
                    //size='tiny'
                    style={{ alignSelf: 'center', height: 100, width: 100 }}
                    src={require('../../reusable/images/ko.png')}
                //src={require('../../../reusable/images/ko.png')}
                />
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <br></br>
                <div style={{ display: "grid" }} id="Content">

                    <label>We Are processing Please Wait...</label>

                    <br></br>                  


                </div>
            </div>
            <NotificationContainer></NotificationContainer>
        </Container>


    )
}

export default TrainerFeedBack