import React, { useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
const axios = require('axios');


const CityDropDown = (props) => {
    const [city, set_city] = React.useState([])

    useEffect(() => {
        get_city();
    }, []);

    function get_city() {
        axios.get('yusuf/get_city')
            .then(function (response) {
                set_city(response.data)

            })
            .catch(function (error) {
                alert(error);
            })
    }
    function send_to_parent(value){
        //alert(value)
        props.passChildData(value); 
      
      }

    return (
            <Dropdown
                placeholder='All City'
                openOnFocus={false}
                search
                fluid
                onChange={(e, { value }) => {
                    send_to_parent(value)
                    
                }}
                selection
                options={city}
                clearable
            />

    )

}

export default CityDropDown