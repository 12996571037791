import React, { useRef, useEffect } from "react";

import InvoicePage from "./Compontents/elements/InvoicePage.js";

const InvoiceThirdStep = () => {
  return (
    <>
      <InvoicePage />
    </>
  );
};

export default InvoiceThirdStep;
