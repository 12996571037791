import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const IndustryDropDown = (props) => {
  const [industry, set_industrylist] = React.useState([]);

  useEffect(() => {
    get_industry();
  }, []);

  function get_industry() {
    axios
      .get("rajkumar/GetIndustryList")
      .then(function(response) {
        set_industrylist(response.data);
      })
      .catch(function(error) {
        alert(error);
      });
  }

  function send_to_parent(value) {
    //alert(value)
    props.passChildData(value);
  }

  return (
    <Dropdown
      placeholder="Select Industry"
      onChange={(e, { value }) => {
        send_to_parent(value);
      }}
      selection
      fluid
      search
      options={industry}
      clearable
    />
  );
};

export default IndustryDropDown;
