import React, { useState, useEffect } from 'react'
import HeaderMenu from '../Reusable/HeaderMenu'
import SVGLoader from '../../MainProject/Reuseble/SVGLoader';
import CSDataTable from '../Reusable/CSDataTable';

const axios = require("axios");

const Opportunity = () => {
    const [Rows, setRows] = useState([]);
    const [loading, set_loading] = React.useState(true)
    const Coulumn = [
        { text: 'Opportunity Id', key: 'Id', sortable: true },
        {
            text: 'Course Name',
            key: 'SUGGESTED',
            sortable: true,
            cell: record => {
                return (
                    <>
                        <b>{record.SUGGESTED}</b>
                    </>
                );
            }
        },
        { text: 'Closure Month', key: 'ClosureMonthForNewsLetter', sortable: false },
        {
            text: 'Apply Now',
            sortable: false,
            cell: record => {
                return (
                    <>
                        <a target="_blank" href={"https://www.koenig-solutions.com/freelancer-trainers?jobId=" + record.Id}>Apply Now</a>
                    </>
                );
            }
        },
    ];

    useEffect(() => {
        Getdata();
    }, [])

    function Getdata() {
        set_loading(true)
        axios.get("Nishant/GetOpportunity").then(function (response) {
            setRows(response.data);
            set_loading(false);
        }).catch(function (error) {
            alert(error);
        });
    }

    return (
        <div>
            <HeaderMenu HeaderText="Current Trainer Opportunities"></HeaderMenu>
            <div className="row">
                <div className="col-md-12">
                    <div className="m-3">
                        <label>If you are intrested in any course. Please mail to "fm@koenig-solutions.com" </label>
                        <hr></hr>
                        <div className="overflow-auto p-3" style={{ height: '40em' }}>
                            <SVGLoader loading={loading} SVGType="Table" SVGWidth="1200" SVGHeight="560"></SVGLoader>
                            <CSDataTable Rows={Rows} Columns={Coulumn} ShowPagignation={false} Length_menu={[1000]} PageSize={1000}></CSDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Opportunity