import React,{useEffect} from 'react'
import { Dropdown } from 'semantic-ui-react'
const axios = require('axios');


const LeadType = (props) => {
  const [lsftype, set_lsf_type] = React.useState([])

  useEffect(() => {
    get_ltype();
}, []);

  function get_ltype(){
    axios.get('yusuf/get_LeadTypes')
    .then(function (response) {
        set_lsf_type(response.data)
  
    })
    .catch(function (error) {
        alert(error);
    })

  }

function send_to_parent(value){
  //alert(value)
  props.passChildData(value); 

}
  
  return (
    <Dropdown

      placeholder='Select Lead Type'
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(value)
        // alert(value)
        //alert(e)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={lsftype}
      clearable
    />
  )


}

export default LeadType