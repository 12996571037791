import React, { useEffect, useState } from "react"

const KoeAddressDetails = (props) => {


   const callForAddtionalDetails = () => {
      if ((!props.invoiceNumber.includes("KSPL")) && ((props.BillingLocation == "1") || (props.BillingLocation == "2") || (props.BillingLocation == "3") || (props.BillingLocation == "4") || (props.BillingLocation == "6") || (props.BillingLocation == "7") || (props.BillingLocation == "74"))) {
         return (
            <>
               <tr><td>Company's GST # : <strong><span>{props.GST}</span></strong></td> </tr>
               <tr><td>Company's PAN # : <strong><span>{props.PAN}</span></strong></td> </tr>
               <tr><td><strong><span>Registered under MSME Act,2006.UAM No. DL11F0027009</span></strong></td> </tr>
            </>
         )
      }
      else if (props.BillingLocation == "507") {
         return (
            <>
               <tr><td>Company's GST # : <strong><span>{props.CompanyNumber}</span></strong></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "508") {
         return (
            <>
               <tr><td>NZBN # : <strong><span> 9429047975385</span></strong></td> </tr>
               <tr><td>Company's GST # : <strong><span>{props.CompanyNumber}</span></strong></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "12") {
         return (
            <>
               <tr><td> GST Registration Number :   <strong><span> 07ABAFR0030J1ZM </span> </strong> </td></tr>
               <tr><td> Pan Number :   <strong> <span> ABAFR0030J </span> </strong> </td> </tr>

               {props.isSez == true && (props.invoiceNumber.includes("RSEXP") || props.invoiceNumber.includes("RTEXP")) == true ? <tr><td> LUT No. : </td>  <strong><span>  AD0703210285874 </span> </strong> </tr> : ""}
            </>
         )
      }
      else if (props.invoiceNumber.includes("KSPL")) {
         return (
            <>
               <tr><td>Company's GST # : <strong><span>{props.GST}</span></strong></td> </tr>
               <tr><td>Company's PAN # : <strong><span>{props.PAN}</span></strong></td> </tr>
            </>
         )
      }
      else if (props.BillingLocation == "11") {
         return (
            <>
               <tr><td><strong><span>ABN:</span></strong><span> {props.ABN}</span></td> </tr>
               <tr><td><strong><span>GST No. :</span></strong><span> {props.GSTNo}</span></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "798") {
         return (
            <>
               <tr><td><strong><span>Commercial Registration:</span></strong><span> 1010903998</span></td> </tr>
               <tr><td><strong><span>VAT Registration No:</span></strong><span> 311759143500003</span></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "882") {
         return (
            <>
               <tr><td><strong><span>Company Number:</span></strong><span> HRB 289113</span></td> </tr>
               <tr><td><strong><span>VAT ID No:</span></strong><span> DE365852696</span></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "5") {
         return (
            <tr>
               <td>TRN #: <strong><span>{props.TRN}</span></strong></td>
            </tr>
         )
      }
      else if (props.BillingLocation == "853") {
         return (
            <>
               <tr><td><strong><span>Licence No.:</span></strong><span> DMCC193986</span></td> </tr>
               <tr><td><strong><span>TRN:</span></strong><span> 104178437000003</span></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "35") {
         return (
            <tr>
               <td>Business Number : <strong><span>{props.BusinessNumber}</span></strong></td>
            </tr>
         )
      }
      else if (props.BillingLocation == "210") {
         return (
            <>
               <tr><td>Company Number : <strong><span>{props.CompanyNumber}</span></strong></td></tr>
               <tr><td>VAT No : <strong><span>{props.VATNo}</span></strong></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "526") {
         return (
            <>
               <tr><td>Company Registration Number : <strong><span>{props.CompanyNumber}</span></strong></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "211") {
         return (
            <>
               <tr><td>Employer Identification Number: <strong><span> {props.EmployerIdentificationNumber}</span></strong></td></tr>
            </>
         )
      }
      else if (props.BillingLocation == "509") {
         return (
            <>
               <tr><td>VAT No : <strong><span>{props.VATNo}</span></strong></td></tr>
            </>
         )
      }
   }



   return (
      <table width="100%" border="0" cellSpacing="5" cellPadding="5">
         <tbody style={{ float: "Left" }}>
            <tr>
               <td><strong>{props.KoeName}</strong></td>
            </tr>
            <tr>
               <td dangerouslySetInnerHTML={{ __html: props.KoeAddress }}></td>
            </tr>
            {/* <tr>
               <td> Company Number: 12189878 </td>
            </tr>
            <tr>
               <td> VAT No. 361091616 </td>
            </tr> */}
            {/* {callForAddtionalDetails()}                                                         */}

            {callForAddtionalDetails()}

            {
               props.LUTNo != null ? (<span style={{ fontWeight: "bold" }} dangerouslySetInnerHTML={{ __html: props.LUTNo + "<br>" }}></span>) : ""
            }
            {/* <div dangerouslySetInnerHTML={{ __html:props.BillingDetails    }} ></div> */}

            {/* <tr dangerouslySetInnerHTML={{ __html: props.BillingDetails    }}></tr>                                           */}
         </tbody>
      </table>

   )
}

export default KoeAddressDetails