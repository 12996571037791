import React, { useEffect } from "react";
///import CountrtDropDown from './component/reusable/CountryDropDown'
import VendonDropDown from "../../reusable/VendonDropDown";
import CityDropDown from "../../reusable/CityDropDown";
import ContinentDropDown from "../../reusable/ContinentDropDown";
import { StickyTable, Row, Cell } from "react-sticky-table";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

import CountrtDropDown from "../../reusable/CountryDropDown";

///import CCEList from '../reusable/CCEList'

import {
  Loader,
  Radio,
  Button,
  Icon,
  Checkbox,
  Modal,
  Table,
  Dropdown,
} from "semantic-ui-react";

import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import "./Conversion.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "bootstrap/dist/css/bootstrap.css";
import LA from "./LA";
import LeadType from "../../reusable/LeadType";

const axios = require("axios");

export default function Conversion() {
  const [loading, set_loading] = React.useState(true);
  const [data_list, set_data_list] = React.useState([]);
  const [header, set_header] = React.useState([]);
  const [sd, set_sd] = React.useState(null);
  const [ed, set_ed] = React.useState(null);
  const [start_time, set_start_time] = React.useState("");
  const [end_time, set_end_time] = React.useState("");
  const [weekends, set_weekends] = React.useState("");
  const [holidays, set_holidays] = React.useState("");
  const [day, set_day] = React.useState("");
  const [four_eye, set_four_eye] = React.useState(false);
  // const [domestic, set_domestic] = React.useState(false);
  // const [international, set_international] = React.useState(false);
  // const [intl, set_intl] = React.useState(false);

  const [country_name, set_country_name] = React.useState("all");
  const [city_id, set_city_id] = React.useState(0);
  const [vendor_id, set_vendor_id] = React.useState(0);
  const [lsf_type, set_lsf_type] = React.useState(0);
  const [type, set_type] = React.useState("cce");
  const [type1, set_type1] = React.useState("yes");
  const [type2, set_type2] = React.useState("yes");
  const [type3, set_type3] = React.useState("yes");
  const [dispdiv, disp_div] = React.useState(false);
  const [country_type, set_country_type] = React.useState("");
  const [lead_type, set_lead_type] = React.useState("");
  const [fl_type, set_fl_type] = React.useState("");
  const [type_holiday, set_type_holiday] = React.useState("");
  const [cell_data, cell_cell_data] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [month, set_month] = React.useState("");
  const [user, set_user] = React.useState("");
  const [selected_geo, set_geo] = React.useState("all");
  const [key_, set_key_] = React.useState(1);

  let emp_data = localStorage.getItem("emp_data");

  const geo = [
    { key: 1, text: "Africa", value: "Africa" },
    { key: 2, text: "Africa, Europe", value: "Africa, Europe" },
    { key: 3, text: "Asia", value: "Asia" },
    { key: 4, text: "Asia, Europe", value: "Asia, Europe" },
    { key: 5, text: "East Asia", value: "East Asia" },
    { key: 6, text: "Europe", value: "Europe" },
    { key: 7, text: "Middle East", value: "Middle East" },
    { key: 8, text: "Middle East, Asia", value: "Middle East, Asia" },
    { key: 9, text: "North America", value: "North America" },
    { key: 10, text: "Oceania", value: "Oceania" },
    { key: 11, text: "South America", value: "South America" },
  ];

  const days = [
    {
      key: "0",
      text: "Sunday",
      value: "Sunday",
    },
    {
      key: "1",
      text: "Monday",
      value: "Monday",
    },
    {
      key: "2",
      text: "Tuesday",
      value: "Tuesday",
    },
    {
      key: "3",
      text: "Wednesday",
      value: "Wednesday",
    },
    {
      key: "4",
      text: "Thursday",
      value: "Thursday",
    },
    {
      key: "5",
      text: "Friday",
      value: "Friday",
    },
    {
      key: "6",
      text: "Saturday",
      value: "Saturday",
    },
  ];

  const hours = [
    {
      key: "0",
      text: "12:00 AM",
      value: "12:00 AM",
      // value: '0',
    },
    {
      key: "1",
      text: "1:00 AM",
      value: "1:00 AM",
      // value: '1',
    },
    {
      key: "2",
      text: "2:00 AM",
      value: "2:00 AM",
      // value: '2',
    },
    {
      key: "3",
      text: "3:00 AM",
      value: "3:00 AM",
      // value: '3',
    },
    {
      key: "4",
      text: "4:00 AM",
      value: "4:00 AM",
      // value: '4',
    },
    {
      key: "5",
      text: "5:00 AM",
      value: "5:00 AM",
      // value: '5',
    },
    {
      key: "6",
      text: "6:00 AM",
      value: "6:00 AM",
      // value: '6',
    },
    {
      key: "7",
      text: "7:00 AM",
      value: "7:00 AM",
      // value: '7',
    },
    {
      key: "8",
      text: "8:00 AM",
      value: "8:00 AM",
      // value: '8',
    },
    {
      key: "9",
      text: "9:00 AM",
      value: "9:00 AM",
      // value: '9',
    },
    {
      key: "10",
      text: "10:00 AM",
      value: "10:00 AM",
      // value: '10',
    },
    {
      key: "11",
      text: "11:00 AM",
      value: "11:00 AM",
      // value: '11',
    },
    {
      key: "12",
      text: "12:00 PM",
      value: "12:00 PM",
      // value: '12',
    },
    {
      key: "13",
      text: "1:00 PM",
      value: "1:00 PM",
      // value: '13',
    },
    {
      key: "14",
      text: "2:00 PM",
      value: "2:00 PM",
      // value: '14',
    },
    {
      key: "15",
      text: "3:00 PM",
      value: "3:00 PM",
      // value: '15',
    },
    {
      key: "16",
      text: "4:00 PM",
      value: "4:00 PM",
      // value: '16',
    },
    {
      key: "17",
      text: "5:00 PM",
      value: "5:00 PM",
      // value: '17',
    },
    {
      key: "18",
      text: "6:00 PM",
      value: "6:00 PM",
      // value: '18',
    },
    {
      key: "19",
      text: "7:00 PM",
      value: "7:00 PM",
      // value: '19',
    },
    {
      key: "20",
      text: "8:00 PM",
      value: "8:00 PM",
      // value: '20',
    },
    {
      key: "21",
      text: "9:00 PM",
      value: "9:00 PM",
      // value: '21',
    },
    {
      key: "22",
      text: "10:00 PM",
      value: "10:00 PM",
      // value: '22',
    },
    {
      key: "23",
      text: "11:00 PM",
      value: "11:00 PM",
      // value: '23',
    },
  ];

  useEffect(() => {
    set_loading(true);
    // Search_click();
    get_data();
  }, []);

  let separator = "-";
  let sdnewDate = new Date();
  let sddate = sdnewDate.getDate();
  let sdmonth = sdnewDate.getMonth() + 1;
  let sdyear = sdnewDate.getFullYear();

  let fsdate = `${sdyear}${separator}${sdmonth < 10 ? `0${sdmonth}` : `${sdmonth}`
    }${separator}${sddate < 10 ? `0${sddate}` : `${sddate}`}`;

  function get_data() {
    axios
      .get("yusuf/cce_conversion")
      .then(function (response) {
        //alert(JSON.stringify(response.data))
        //alert( JSON.stringify(response.data[0]))
        set_header(Object.keys(response.data[0]));
        set_loading(false);
        //console.log(response.data)
        set_data_list(response.data);
        //alert(JSON.stringify(response.data))
        // set_city(response.data)
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function getCurrentDate(separator = "") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`
      }${separator}${year}`;
  }

  async function Search_click() {
    set_data_list([]);
    set_loading(true);

    let js_start = new Date(sd);
    let js_end = new Date(ed);
    let sd_ =
      js_start.getMonth() +
      1 +
      "/" +
      js_start.getDate() +
      "/" +
      js_start.getFullYear();
    let ed_ =
      js_end.getMonth() +
      1 +
      "/" +
      js_end.getDate() +
      "/" +
      js_end.getFullYear();

    if (sd == null) {
      sd_ = "04/01/2020";
    }
    if (ed == null) {
      ed_ = getCurrentDate("/");
    }

    // let four_eye_ = (four_eye ? 'yes' : 'no')
    // let domestic_ = (domestic ? 'yes' : 'no')
    // let international_ = (international ? 'yes' : 'no')
    // let intl_ = (intl ? 'yes' : 'no')
    debugger;
    let { data } = await axios.get(
      "yusuf/cce_conversion1?sd=" +
      sd_ +
      "&ed=" +
      ed_ +
      "&ctryname=" +
      country_name +
      "&city_id=" +
      city_id +
      "&vendor_id=" +
      vendor_id +
      "&type=" +
      type +
      "&region=" +
      country_type +
      "&geo=" +
      selected_geo +
      "&google_lead=" +
      lead_type +
      "&course_type=" +
      fl_type +
      "&lead_type=" +
      lsf_type +
      "&dayofweek=" +
      day +
      "&start_time=" +
      start_time +
      "&end_time=" +
      end_time +
      "&weekends=" +
      weekends +
      "&holidays=" +
      holidays +
      ""
    );

    if (data.length > 0) {
      set_header(Object.keys(data[0]));
      set_data_list(data);
    } else {
      alert("NO DATA");
    }
    set_loading(false);
  }

  function cell_onclick(u, m) {
    debugger;
    if (m == "total") {
      return;
    }
    set_month(m);
    set_user(u);
    set_loading(true);
    //http://koshish.koenig-solutions.com/api/yusuf/cce_conversion_rate_OnClick?sd=01/01/2020&ed=08/08/2020&user=ally&monyear=FEB-2020
    let js_start = new Date(sd);
    let js_end = new Date(ed);
    let sd_ =
      js_start.getMonth() +
      1 +
      "/" +
      js_start.getDate() +
      "/" +
      js_start.getFullYear();
    let ed_ =
      js_end.getMonth() +
      1 +
      "/" +
      js_end.getDate() +
      "/" +
      js_end.getFullYear();

    if (sd == null) {
      sd_ = "04/01/2020";
    }
    if (ed == null) {
      ed_ = getCurrentDate("/");
    }

    axios
      .get(
        "yusuf/cce_conversionOnClick?sd=" +
        sd_ +
        "&ed=" +
        ed_ +
        "&user=" +
        u +
        "&monyear=" +
        m +
        "&country=" +
        country_name +
        "&city=" +
        city_id +
        "&vendor=" +
        vendor_id +
        "&optype=" +
        type +
        "&region=" +
        country_type +
        "&geo=" +
        selected_geo +
        "&google_lead=" +
        lead_type +
        "&course_type=" +
        fl_type +
        "&lead_type=" +
        lsf_type +
        "&dayofweek=" +
        day +
        "&start_time=" +
        start_time +
        "&end_time=" +
        end_time +
        "&weekends=" +
        weekends +
        "&holidays=" +
        holidays +
        ""
      )
      .then(function (response) {
        set_loading(false);
        if (response.data.length > 0) {
          // alert(JSON.stringify(response.data))
          //set_header(Object.keys(response.data[0]))
          cell_cell_data(response.data);
          setOpen(true);
          set_loading(false);
        } else {
          alert("NO DATA");
        }
        set_loading(false);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "100vh",
      }}
      className="mainDivCV"
    >
      {/* FILTER SECTION */}

      <div
        style={{
          padding: 20,
          height: "100vh",
          backgroundColor: "rgb(73, 82, 133)",
          width: "19%",
          overflowY: "auto",
        }}
      >
        <div style={{ flexDirection: "row" }}>
          <span
            style={{
              fontSize: 16,
              color: "#fff",
              opacity: 0.8,
              textAlign: "right",
              marginLeft: 10,
            }}
          >
            {" "}
            Filters <Icon name="filter" />{" "}
          </span>
        </div>

        <div>&nbsp;</div>

        <SemanticDatepicker
          placeholder="2020-04-01"
          onChange={(e, v) => {
            //alert(JSON.stringify(v))
            set_sd(v.value);
          }}
        />
        <div style={{ maxHeight: "2%" }}>&nbsp;</div>
        <SemanticDatepicker
          placeholder={fsdate}
          onChange={(e, v) => {
            //alert(JSON.stringify(v))
            set_ed(v.value);
          }}
        />
        <div style={{ maxHeight: "2%" }}>&nbsp;</div>
        <div key={four_eye} style={{ width: "90%" }}>
          <ContinentDropDown passChildData={set_geo} />
        </div>
        <div style={{ maxHeight: "2%" }}>&nbsp;</div>
        {/* <Dropdown
                    onChange={(e, { value }) => {
                        set_geo(value)
                        //alert(value)
                    }}
                    placeholder='All Continent' clearable options={geo} selection /> */}

        <div key={key_ + 1} style={{ width: "90%" }}>
          <CountrtDropDown passChildData={set_country_name} />
        </div>
        <div style={{ maxHeight: "2%" }}>&nbsp;</div>
        <div key={key_ + 2} style={{ width: "90%" }}>
          <CityDropDown passChildData={set_city_id} />
        </div>
        <div style={{ maxHeight: "2%" }}>&nbsp;</div>
        <div style={{ width: "90%" }}>
          <VendonDropDown passChildData={set_vendor_id} />
        </div>
        {/* <br></br> */}
        <div style={{ maxHeight: "2%" }}>&nbsp;</div>

        <div style={{ width: "90%" }}>
          <LeadType passChildData={set_lsf_type} />
        </div>

        <div style={{ maxHeight: "2%" }}>&nbsp;</div>

        <div style={{ width: "90%" }}>
          <Dropdown
            className="dropdown"
            placeholder="All Days"
            fluid
            selection
            options={days}
            clearable
            onChange={(e, v) => {
              //alert(JSON.stringify(v))
              set_day(v.value);
            }}
            style={{ width: "94%" }}
          ></Dropdown>
        </div>
        {/* <br></br> */}
        <div style={{ maxHeight: "2%" }}>&nbsp;</div>

        <Dropdown
          className="dropdown"
          placeholder="Start Time"
          fluid
          selection
          options={hours}
          clearable
          onChange={(e, v) => {
            //alert(JSON.stringify(v))
            set_start_time(v.value);
          }}
          style={{ width: "8vw", float: "left", width: "48%" }}
        ></Dropdown>

        {/* <div style={{ maxHeight: '2%' }}>&nbsp;</div> */}

        <Dropdown
          placeholder="End Time"
          fluid
          selection
          options={hours}
          clearable
          onChange={(e, v) => {
            //alert(JSON.stringify(v))
            set_end_time(v.value);
          }}
          style={{
            width: "8vw",
            float: "left",
            marginLeft: "4%",
            width: "48%",
          }}
        ></Dropdown>

        <br></br>
        <br></br>
        <hr style={{ backgroundColor: "white" }}></hr>
        <div>
          <Radio
            checked={country_type == "4 eyes" ? true : false}
            onChange={() => {
              set_country_type("4 eyes");
            }}
            label="4 eyes"
          />
          <br></br>
          <Radio
            checked={country_type == "Domestic" ? true : false}
            onChange={() => {
              set_country_type("Domestic");
            }}
            label="Domestic"
          />
          <br></br>
          <Radio
            checked={country_type == "Inter" ? true : false}
            onChange={() => {
              set_country_type("Inter");
            }}
            label="International"
          />
          <br></br>
          <Radio
            checked={country_type == "Intl" ? true : false}
            onChange={() => {
              set_country_type("Intl");
            }}
            label="Intl.(without 4 eyes)"
          />

          {/* <Radio checked={(country_type == '4 eyes') ? true : false} onChange={() => {
                        set_country_type('4 eyes')
                        set_country_name('all')
                        set_geo('all')
                        set_city_id(0)
                        set_key_(key_ + 1)
                        set_four_eye(!four_eye)
                    }} label='4 eyes' />
                    <br></br>
                    <Radio checked={(country_type == 'Domestic') ? true : false} onChange={() => {
                        set_country_type('Domestic')
                        set_country_name('all')
                        set_geo('all')
                        set_city_id(0)
                        set_key_(key_ + 1)
                        set_domestic(!domestic)
                    }} label='Domestic' />
                    <br></br>
                    <Radio checked={(country_type == 'Inter') ? true : false} onChange={() => {
                        set_country_type('Inter')
                        set_country_name('all')
                        set_geo('all')
                        set_city_id(0)
                        set_key_(key_ + 1)
                        set_international(!international)
                    }} label='Inter' />
                    <br></br>
                    <Radio checked={(country_type == 'Intl.') ? true : false} onChange={() => {
                        set_country_type('Intl.')
                        set_country_name('all')
                        set_geo('all')
                        set_city_id(0)
                        set_key_(key_ + 1)
                        set_intl(!intl)
                    }} label='Intl.(without 4 eyes)' /> */}

          {/* <Checkbox onChange={() => {
                        set_country_name('all')
                        set_geo('all')
                        set_city_id(0)
                        set_key_(key_ + 1)
                        set_four_eye(!four_eye)
                    }} label='4 eyes' /> */}
        </div>
        <hr style={{ backgroundColor: "white" }}></hr>
        <Checkbox
          onChange={() => {
            set_lead_type(lead_type == "google" ? "" : "google");
          }}
          label="Google Leads"
        />
        <br></br>
        <Checkbox
          onChange={() => {
            set_fl_type(fl_type == "freelancer" ? "" : "freelancer");
          }}
          label="Only FL Courses"
        />
        <br></br>
        <Checkbox
          onChange={() => {
            set_weekends(weekends == "weekend" ? "" : "weekend");
          }}
          label="Exclude Weekends"
        />
        <br></br>
        <Checkbox
          onChange={() => {
            set_holidays(holidays == "holiday" ? "" : "holiday");
          }}
          label="Exclude Holidays"
        />
        {/* <br></br> */}
        {/* <Checkbox onChange={() => { set_type((type == 'dhours') ? '' : 'dhours') }} label='Day/Hour wise' /> */}
        {/* <Radio clearable checked={(fl_type == 'freelancer') ? true : false} onChange={() => { set_fl_type('freelancer') }} label='Only FL Courses' /> */}
        {/* <Radio clearable checked={(lead_type == 'google') ? true : false} onChange={() => { set_lead_type('google') }} label='Google Leads' /> */}
        <hr style={{ backgroundColor: "white" }}></hr>
        <div>
          <Radio
            checked={type == "cce" ? true : false}
            onChange={() => {
              set_type("cce");
            }}
            label="CCE"
          />
          <br></br>
          <Radio
            checked={type == "mentor" ? true : false}
            onChange={() => {
              set_type("mentor");
            }}
            label="Mentor"
          />
          <br></br>
          <Radio
            checked={type == "manager" ? true : false}
            onChange={() => {
              set_type("manager");
            }}
            label="Manager"
          />
          <br></br>
          <Radio
            checked={type == "vendor" ? true : false}
            onChange={() => {
              set_type("vendor");
            }}
            label="All Vendor"
          />
          <br></br>
          <Radio
            checked={type == "country" ? true : false}
            onChange={() => {
              set_type("country");
            }}
            label="All Country"
          />
          <br></br>
          <Radio
            checked={type == "continent" ? true : false}
            onChange={() => {
              set_type("continent");
            }}
            label="All Continent"
          />
          <br></br>
          {/* <Radio checked={(type == 'technology') ? true : false} onChange={() => { set_type('technology') }} label='Technology' /> */}
          <Radio
            checked={type == "dhours" ? true : false}
            onChange={() => {
              set_type("dhours");
            }}
            label="Day / Hour Wise"
          />
        </div>

        <div style={{ maxHeight: "2%" }}>&nbsp;</div>
        <div>
          <Button
            onClick={() => {
              if ((day != "" && country_name == "all") || country_name == "") {
                alert("Please select a country!!");
              } else if (
                (start_time != "" && country_name == "all") ||
                country_name == ""
              ) {
                alert("Please select a country!!");
              } else if (
                weekends != "" &&
                (day == "Saturday" || day == "Sunday")
              ) {
                alert(
                  "Exclude Weekends filter does not work with Saturday & Sunday!!"
                );
              } else {
                Search_click();
              }
            }}
            color="teal"
          >
            Display
          </Button>
        </div>
      </div>

      {/* DATA SECTION */}
      <div
        style={{
          width: "90vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            padding: "1.5%",
            height: "8vh",
            backgroundColor: "#fafafb",
            width: "",
            WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
            MozBoxShadow: "0 8px 6px -6px #d5d4d6",
            boxShadow: "0 8px 6px -6px #d5d4d6",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "100%" }}>
              <span
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                CCE Conversion
              </span>
            </div>
            <div style={{ width: "20%", marginTop: "-0.5%" }}>
              <Popup
                trigger={<button className="trigger"> Excluded Leads </button>}
              >
                <div>
                  <b>Type of leads excluded from panel:-</b>
                  <br></br>
                  <br></br>* Duplicate
                  <br></br>* Online booking
                  <br></br>* Referral
                  <br></br>* Spam
                  <br></br>* Exam only
                  <br></br>* Regular corporate
                  <br></br>* Job Posting for EEDS
                  <br></br>
                </div>
              </Popup>
            </div>
            {/* <div style={{ width: "20%", marginTop: "-0.5%" }}>
              <button
                className="btn btn-danger"
                onClick={(event) =>
                  (window.location.href =
                    "https://rms.koenig-solutions.com/Cce/frm_CceReport.aspx?Parameter=Old")
                }
              >
                Old Cce Conversion
              </button>
            </div> */}
            <div style={{ width: "20%", marginTop: "-0.5%" }}>
              <ReactHTMLTableToExcel
                className="btn btn-info"
                table="conversion"
                filename="Conversion"
                sheet="Sheet"
                buttonText="Export to Excel"
                style={{
                  padding: "5px",
                  borderRadius: "5px",
                  fontSize: "14px",
                }}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            display: loading ? "none" : "flex",
            padding: "1%",
            overflow: "scroll",
          }}
        >
          {/* <DynamicDataTable style={{border:2}} rows={data_list} /> */}

          <span style={{ width: "99.5%", overflowY: "scroll" }}>
            <table
              id="conversion"
              className="table cvtable"
              style={{ width: "100%" }}
            >
              <thead>
                <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                  {header.map((key, index) => {
                    if (type === "cce" && index == "1") {
                      return (
                        <th className="text-center" key={index}>
                          CCE
                        </th>
                      );
                    } else if (type === "mentor" && index == "1") {
                      return (
                        <th className="text-center" key={index}>
                          MENTOR
                        </th>
                      );
                    } else if (type === "manager" && index == "1") {
                      return (
                        <th className="text-center" key={index}>
                          MANAGER
                        </th>
                      );
                    } else if (type === "vendor" && index == "1") {
                      return (
                        <th className="text-center" key={index}>
                          VENDOR
                        </th>
                      );
                    } else if (type === "country" && index == "1") {
                      return (
                        <th className="text-center" key={index}>
                          COUNTRY
                        </th>
                      );
                    } else if (type === "continent" && index == "1") {
                      return (
                        <th className="text-center" key={index}>
                          CONTINENT
                        </th>
                      );
                    } else {
                      return (
                        <th className="text-center" key={index}>
                          {key.toUpperCase()}
                        </th>
                      );
                    }
                  })}
                </tr>
              </thead>

              <tbody>
                {data_list.map((row, ii) => (
                  <tr key={ii}>
                    {Object.values(row).map((rowValue, i) => {
                      if (i == 0) {
                        return (
                          <td key={i} className="text-center">
                            <span>
                              <label
                                className="firstR"
                                style={{ fontSize: "110%" }}
                              >
                                {rowValue}
                              </label>
                            </span>
                          </td>
                        );
                      } else if (i == 1) {
                        if (
                          JSON.parse(emp_data).user_id == "3031" ||
                          JSON.parse(emp_data).user_id == "5472" ||
                          JSON.parse(emp_data).user_id == "94" ||
                          JSON.parse(emp_data).user_id == "4565"
                        ) {
                          return (
                            <td key={i} className="text-center">
                              <span>
                                <label style={{ fontSize: "110%" }}>
                                  {rowValue}
                                </label>
                              </span>
                              <br></br>
                              <LA cce={rowValue}></LA>
                            </td>
                          );
                        } else {
                          return (
                            <td key={i} className="text-center">
                              <span>
                                <label style={{ fontSize: "110%" }}>
                                  {rowValue}
                                </label>
                              </span>
                            </td>
                          );
                        }
                      } else if (rowValue && (i == 2 || i == 3)) {
                        return (
                          <td className="text-center" key={i}>
                            {console.log(" rowValue :", rowValue)}
                            E:{rowValue.split("/")[0]}
                            <br></br>
                            R:{rowValue.split("/")[1]}
                            <br></br>
                            <span
                              style={{
                                color:
                                  rowValue.split("/")[1] == 0 &&
                                    rowValue.split("/")[0] > 1
                                    ? "#f21111"
                                    : "",
                                fontWeight:
                                  rowValue.split("/")[1] == 0 &&
                                    rowValue.split("/")[0] > 1
                                    ? "bolder"
                                    : "normal",
                              }}
                            >
                              C:
                              {(
                                (rowValue.split("/")[1] /
                                  rowValue.split("/")[0]) *
                                100
                              ).toFixed(2)}
                            </span>
                          </td>
                        );
                      } else if (rowValue && data_list[ii].Rank == "0") {
                        return (
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                          >
                            E:{rowValue.split("/")[0]}
                            <br></br>
                            R:{rowValue.split("/")[1]}
                            <br></br>
                            <span
                              style={{
                                color:
                                  rowValue.split("/")[1] == 0 &&
                                    rowValue.split("/")[0] > 1
                                    ? "#f21111"
                                    : "",
                                fontWeight:
                                  rowValue.split("/")[1] == 0 &&
                                    rowValue.split("/")[0] > 1
                                    ? "bolder"
                                    : "normal",
                              }}
                            >
                              C:
                              {(
                                (rowValue.split("/")[1] /
                                  rowValue.split("/")[0]) *
                                100
                              ).toFixed(2)}
                            </span>
                          </td>
                        );
                      } else if (rowValue && data_list[ii].Rank != "0") {
                        return (
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              cell_onclick(data_list[ii].cce, header[i]);
                              //alert(data_list[ii].cce + '--'+header[i])
                              //alert(header[i])
                            }}
                            key={i}
                          >
                            E:{rowValue.split("/")[0]}
                            <br></br>
                            R:{rowValue.split("/")[1]}
                            <br></br>
                            <span
                              style={{
                                color:
                                  rowValue.split("/")[1] == 0 &&
                                    rowValue.split("/")[0] > 1
                                    ? "#f21111"
                                    : "",
                                fontWeight:
                                  rowValue.split("/")[1] == 0 &&
                                    rowValue.split("/")[0] > 1
                                    ? "bolder"
                                    : "normal",
                              }}
                            >
                              C:
                              {(
                                (rowValue.split("/")[1] /
                                  rowValue.split("/")[0]) *
                                100
                              ).toFixed(2)}
                            </span>
                          </td>
                        );
                      } else {
                        return (
                          <td key={i} className="text-center">
                            E:0
                            <br></br>
                            R:0
                            <br></br>
                            C:0
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>

              {/* {data_list.map((d) => {
                            return (
                                <Row>
                                    <Cell>
                                        E:{d.total.split('/')[0]}
                                        <br></br>
                                        R:{d.total.split('/')[1]}
                                        <br></br>
                                        C:{((d.total.split('/')[1]) / (d.total.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>{d.UserName}</Cell>
                                    <Cell>
                                        E:{d.jan.split('/')[0]}
                                        <br></br>
                                        R:{d.jan.split('/')[1]}
                                        <br></br>
                                        C:{((d.jan.split('/')[1]) / (d.jan.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.feb.split('/')[0]}
                                        <br></br>
                                        R:{d.feb.split('/')[1]}
                                        <br></br>
                                        C:{((d.feb.split('/')[1]) / (d.feb.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.mar.split('/')[0]}
                                        <br></br>
                                        R:{d.mar.split('/')[1]}
                                        <br></br>
                                        C:{((d.mar.split('/')[1]) / (d.mar.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.apr.split('/')[0]}
                                        <br></br>
                                        R:{d.apr.split('/')[1]}
                                        <br></br>
                                        C:{((d.apr.split('/')[1]) / (d.apr.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.may.split('/')[0]}
                                        <br></br>
                                        R:{d.may.split('/')[1]}
                                        <br></br>
                                        C:{((d.may.split('/')[1]) / (d.may.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.jun.split('/')[0]}
                                        <br></br>
                                        R:{d.jun.split('/')[1]}
                                        <br></br>
                                        C:{((d.jun.split('/')[1]) / (d.jun.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>
                                    <Cell>
                                        E:{d.jul.split('/')[0]}
                                        <br></br>
                                        R:{d.jul.split('/')[1]}
                                        <br></br>
                                        C:{((d.jul.split('/')[1]) / (d.jul.split('/')[0]) * 100).toFixed(2)}
                                    </Cell>


                                </Row>
                            )
                        })} */}
            </table>
          </span>
        </div>

        {/* LOADER */}
        <div
          style={{
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            width: "80vw",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Loader active inline size="massive">
            getting data...
          </Loader>
        </div>

        <div>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{ marginTop: "0px", marginLeft: "10%", width: "80%" }}
          >
            <Modal.Header>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div>
                  <div>{user}</div>
                  <div>{month}</div>
                </div>
                <div style={{ position: "absolute", right: 10 }}>
                  <Button onClick={() => setOpen(false)} primary>
                    Close <Icon style={{ marginLeft: 10 }} name="close" />
                  </Button>
                </div>
              </div>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <Table className="conversion" celled fixed singleLine>
                  <Table.Header>
                    <Table.Row>
                      {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                      <Table.HeaderCell>S.No</Table.HeaderCell>
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Course</Table.HeaderCell>
                      <Table.HeaderCell>Registered</Table.HeaderCell>
                      <Table.HeaderCell>SCID</Table.HeaderCell>
                      <Table.HeaderCell>Country</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {cell_data.map((d) => {
                      return (
                        <Table.Row>
                          {/* <Table.Cell>{d.Name}</Table.Cell> */}
                          <Table.Cell>{d.S_No}</Table.Cell>
                          <Table.Cell>{d.Enquiry}</Table.Cell>
                          <Table.Cell>{d.Course}</Table.Cell>
                          <Table.Cell>{d.Registered}</Table.Cell>
                          <Table.Cell>{d.SCID}</Table.Cell>
                          <Table.Cell>{d.countryid}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>
      </div>
    </div>
  );
}

// {d.jan.split('/')[1]}
