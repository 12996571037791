import React, { useState, useEffect } from "react";
import TopHeading from "./Compontents/elements/TopHeading.js";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Input } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Button } from "semantic-ui-react";
import "./AllInvoiceList.css"
import { Icon } from 'semantic-ui-react'
///import ReactHTMLTableToExcel from "react-html-table-to-excel";
///import ReactExport from "react-export-excel";
//import exportFromJSON from 'export-from-json'
import ReactExport from "react-export-excel";

const AllInvoicesList = () => {
  const InvoiceAllData = [];
  const [InvoiceNo, set_InvoiceNo] = useState("");
  const [StartDate, set_StartDate] = useState("");
  const [EndDate, set_EndDate] = useState("");
  const [Data, set_Data] = useState([])
  const [Data_Excel, set_Data_Excel] = useState([])
  const axios = require("axios");

  let d = localStorage.getItem("emp_data");// 15 mar 2022 
  const userid = JSON.parse(d).user_id// 15 mar 2022

  async function updateStatus(invid, status) {
    let jsonDT = {
      invid: invid, CurrIds: status
    }
    let { data } = await axios.get(
      "/Invoice/proc_NewInvoiceCreate1?type=32" +
      "&jsonDT=" +
      JSON.stringify(jsonDT) +
      ""
    );
    btnSearch();
  }




  async function searchData1() {
    let js_start = new Date(StartDate);
    let js_end = new Date(EndDate);

    set_Data([]);
    const sqlParam = {
      InvoiceNo: InvoiceNo == "" ? "0" : InvoiceNo,
      startdate:
        StartDate == ""
          ? "01/01/2013"
          : js_start.getMonth() +
          1 +
          "/" +
          js_start.getDate() +
          "/" +
          js_start.getFullYear(),
      enddate:
        EndDate == ""
          ? "01/01/2030"
          : js_end.getMonth() +
          1 +
          "/" +
          js_end.getDate() +
          "/" +
          js_end.getFullYear(),
      userId: userid
    };
    let { data } = await axios.get(
      "/Invoice/proc_NewInvoiceCreate?type=22" +
      "&SDate=" +
      sqlParam.startdate +
      "&EDate=" +
      sqlParam.enddate +
      "&InvoiceNo=" +
      sqlParam.InvoiceNo
      +
      "&UserId=" +
      sqlParam.userId
    );
    if (data.length > 0) {
      set_Data(data);

    }


  }

  async function searchData2(jsonDT) {
    let js_start = new Date(StartDate);
    let js_end = new Date(EndDate);
    set_Data_Excel([])
    const sqlParam = {
      InvoiceNo: InvoiceNo == "" ? "0" : InvoiceNo,
      startdate:
        StartDate == ""
          ? "01/01/2013"
          : js_start.getMonth() +
          1 +
          "/" +
          js_start.getDate() +
          "/" +
          js_start.getFullYear(),
      enddate:
        EndDate == ""
          ? "01/01/2030"
          : js_end.getMonth() +
          1 +
          "/" +
          js_end.getDate() +
          "/" +
          js_end.getFullYear(),
      userId: userid
    };

    console.log("/Invoice/proc_NewInvoiceCreate?type=37"
      +
      "&SDate=" +
      sqlParam.startdate +
      "&EDate=" +
      sqlParam.enddate)

    let { data } = await axios.get(
      "/Invoice/proc_NewInvoiceCreate?type=37"
      +
      "&SDate=" +
      sqlParam.startdate +
      "&EDate=" +
      sqlParam.enddate
      +
      "&UserId=" +
      sqlParam.userId
    );
    if (data.length > 0) {
      set_Data_Excel(data);
      console.log(" excel ", data)
    }
  }


  async function btnSearch() {
    searchData1();
    searchData2();
  }

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;





  return (
    <Container fluid>
      <Row>
        <TopHeading HeadingName={"View All Invoice's"} />


        <div style={{ position: 'absolute', right: 10, top: 10 }}>
          {Data_Excel.length > 0 ?
            <ExcelFile>
              <ExcelSheet data={Data_Excel} name="InvoiceData">
                <ExcelColumn label="Status" value="Status" />
                <ExcelColumn label="Voucher No" value="Voucher No" />
                <ExcelColumn label="Reference No" value="Reference No" />
                <ExcelColumn label="Date" value="Date" />
                <ExcelColumn label="Order No" value="Order No" />
                <ExcelColumn label="Order Date" value="Order Date" />
                <ExcelColumn label="Voucher Type Name" value="Voucher Type Name" />
                <ExcelColumn label="Party Name" value="Party Name" />
                <ExcelColumn label="GST NO" value="GST NO" />
                <ExcelColumn label="State" value="State" />
                <ExcelColumn label="Sales Income LEDGER" value="Sales Income LEDGER" />
                <ExcelColumn label="Sales Amount" value="Sales Amount" />
                <ExcelColumn label="IGST Output Ledger" value="IGST Output Ledger" />
                <ExcelColumn label="IGST Output Amt" value="IGST Output Amt" />
                <ExcelColumn label="CGST Output Ledger" value="CGST Output Ledger" />
                <ExcelColumn label="CGST Output Amt" value="CGST Output Amt" />
                <ExcelColumn label="SGST Output Ledger" value="SGST Output Ledger" />
                <ExcelColumn label="SGST Output Amt" value="SGST Output Amt" />
                <ExcelColumn label="Other Ledger 1" value="Other Ledger 1" />
                <ExcelColumn label="Dr/Cr 1" value="Dr/Cr 1" />
                <ExcelColumn label="Other Ledger 2" value="Other Ledger 2" />
                <ExcelColumn label="Other Ledger Ant 2" value="Other Ledger Ant 2" />
                <ExcelColumn label="Other Ledger 3" value="Other Ledger 3" />
                <ExcelColumn label="Other Ledger Amt 3" value="Other Ledger Amt 3" />
                <ExcelColumn label="Dr/Cr 3" value="Dr/Cr 3" />
                <ExcelColumn label="Payty Amt" value="Payty Amt" />
                <ExcelColumn label="Narration" value="Narration" />
                <ExcelColumn label="Created By" value="Created By" />

              </ExcelSheet>
            </ExcelFile>
            : null}

        </div>

      </Row>
      <Container fluid>
        <Row className="mt-5">
          <Col sm={2}>
            <Input
              placeholder="Search Invoice"
              value={InvoiceNo}
              onChange={(e) => set_InvoiceNo(e.target.value)}
            />
          </Col>
          <Col sm={2}>
            <SemanticDatepicker
              placeholder="From Date"
              onChange={(e, v) => {
                set_StartDate(v.value);
              }}
            />
          </Col>
          <Col sm={2}>
            <SemanticDatepicker
              placeholder="To Date"
              onChange={(e, v) => {
                set_EndDate(v.value);
              }}
            />
          </Col>
          <Col sm={2}>
            <Button
              color="teal"
              icon="search"
              onClick={() => {
                btnSearch();
              }}
            />
          </Col>
        </Row>
        <Row>
          <div className="box-Scroll">
            <table id="conversion" className="InvoiceTable" style={{ overflow: "scroll" }}>
              <tr>
                <th>Invoice#</th>
                <th> InvoiceDate</th>
                <th>Corporate/Client</th>
                <th>Payment Terms</th>
                <th>Billing Location</th>
                <th>Currency</th>
                <th>InvoiceAmt</th>
                <th>SGSTAmt</th>
                <th>CGSTAmt</th>
                <th>ICGSTAmt</th>
                <th>VAT</th>
                <th>WHT</th>
                <th>GST</th>
                <th>Edit</th>
                <th>Print</th>
                <th>Created By</th>
                <th>Invoice Status</th>
                <th>Action</th>
              </tr>
              {Data ? (
                Data.map((i, index) => (
                  <tr key={index}>
                    <td>{i.InvoiceNo}</td>
                    <td>{i.InvoiceDate}</td>
                    <td>{i.CorporateName}</td>
                    <td>{i.PayTerms}</td>
                    <td>{i.LocationName}</td>
                    <td>{i.Currency}</td>
                    <td>{i.InvAmount}</td>
                    <td>{i.InvSGSTAmt}</td>
                    <td>{i.InvCGSTAmt}</td>
                    <td>{i.InvICGSTAmt}</td>
                    <td>{i.InvVatAmnt}</td>
                    <td>{i.WHT}</td>
                    <td>{i.InvGSTAmt}</td>
                    <td>

                      <Icon name='pencil alternate' onClick={() => { window.open("/InvoiceSecondStep?invid=" + i.INVId + "&Edit=1", "_blank") }} /> </td>
                    <td> <Icon name='paste' onClick={() => window.open("/InvoiceThirdStep?invid=" + i.INVId, "_blank")} /></td>
                    <td>{i.createdby}</td>
                    <td>{i.InvoiceStatus}</td>

                    <td> <button onClick={() => updateStatus(i.INVId, i.InvoiceStatus)} > {i.InvoiceStatus} </button> </td>

                  </tr>
                ))
              ) : ""}

            </table>
          </div>
        </Row>

      </Container>
    </Container>
  )
}

export default AllInvoicesList

