import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const Menus = (props) => {
    const [Role, set_Role] = React.useState([]);
  
    useEffect(() => {
        get_Role();
    }, []);
  
    function get_Role() {
      axios
      .get("jai/Sp_Add_and_Remove_Menus?Type=1&RoleId=0&MenuId=0&Id=0&FromDate='28-apr-2021'&ToDate='28-apr-2021'&Feedback='test'")
        .then(function (response) {
            set_Role(response.data);
        })
        .catch(function (error) {
          alert(error);
        });
    }
  
    function send_to_parent(value) {
      //alert(value)
      props.passChildData(value);
    }
  
    return (
      <Dropdown
        // style={{ width: 180 }}
        placeholder="Roles"
        fluid
        search
        onChange={(e, { value }) => {
          send_to_parent(value);
          // alert(value)
          //alert(e)
        }}
        // onChange={() => { alert('Country') }}
        selection
        options={Role}
        clearable
      />
    );
  };
  
  export default Menus;
  