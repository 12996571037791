import React, { useState, useEffect } from 'react';
import SVGLoader from '../Reuseble/SVGLoader';
import ReactDOM from 'react-dom';
import 'font-awesome/css/font-awesome.min.css';
import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Icon, Modal, Popup, Input } from 'semantic-ui-react';
import ReactTooltip from 'react-tooltip';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { NotificationContainer, NotificationManager } from "react-notifications";
import SweetAlert from 'react-bootstrap-sweetalert';
import { Spinner } from 'react-bootstrap';
import "./CustomCss.css"
import Popup1 from '../Componant/Popup';
import CSDataTable from '../../Freelancer/Reusable/CSDataTable';
import StudentDetailWithAssignment from '../Reuseble/Componant/StudentDetailWithAssignment';

import UploadAttendanceForm from '../Reuseble/Componant/UploadAttendance';
import InterimFB from '../Reuseble/Componant/InterimFB';

const axios = require("axios");
var moment = require('moment'); // require

const IlomManager = () => {
    const [DelMode, setDelMode] = useState([]);
    const [DelModeValue, setDelModeValue] = useState(2);
    const [MngrValue, setMngrValue] = useState(-1);
    const [ILOM, setILOM] = useState([]);
    const [AssignmentStatus, setAssignmentStatus] = useState(0);
    const [BatchTypeStatus, setBatchTypeStatus] = useState(0);
    const [SCType, setSCType] = useState(0);
    const [FilterDate, setFilterDate] = useState('');
    const [loading, set_loading] = React.useState(true)
    const [CDay, setCDay] = React.useState(moment())
    const [CWeek, setCWeek] = React.useState([])

    const [stuEMail, setStuEmail] = React.useState('');
    const [StuFName, setStuFName] = React.useState('');
    const [StuLName, setStuLName] = React.useState('');
    const [stuAssignmentId, setStuAssignmentId] = React.useState('');
    const [stuScidId, setScidId] = React.useState('');
    const [buttonPopup, setButtonPopup] = useState(false);
    const [Rows, setRows] = useState([]);
    const Coulumn = [
        {
            text: 'SlNo',
            key: 'SLNo',
            sortable: true,
            cell: d => {
                return (
                    <>
                        {d.SLNo}
                        <br></br>
                        {d.RevisionClass != 0 ? <Icon color='red' link name='square' /> : ""}
                        {d.TOTDates != null ? d.TOTDates.split(',').length == 1 ? <> <Icon color='green' link name='square' /></>
                            : "" : ""}
                        <br></br>
                        <div className="text-break">
                            {d.RevisionClass != 0 ? <span style={{ "color": "red" }}>Revision Class</span> : ""}
                            {
                                d.TOTDates != null ? d.TOTDates.split(',').length == 1 ? <> {d.RevisionClass != 0 ? "/" : ""} <span style={{ "color": "green" }}>One day batch</span></>
                                    : "" : ""
                            }
                        </div>
                    </>
                )
            }
        },
        {
            text: 'Assignment ID',
            key: 'AssignmentId',
            sortable: true,
            cell: d => {
                return (
                    <>
                        {d.AssignmentId == null ? "N/A" : d.AssignmentId} {d.IsRedFlagBatch == 1 ? <i className='fa fa-flag text-danger'></i> : ""}
                        {d.SCType != 0 ? <span className="badge btn alert-success ml-2">{GetSCType(d.SCType)}</span> : ""}
                        <div>{d.TrainerName == null ? <span>N/A</span> : d.TrainerName}</div>
                        {d.BatchType == 2 ? <>
                            <div><span className="badge btn alert-success">NON-TP</span></div> <br />
                            <div><a className="badge btn btn-outline-primary" onClick={() => ViewFeedBack(d.AssignmentId)}>View FeedBack</a></div><br />
                            <div><span className="badge btn btn-outline-primary" onClick={() => SendFeedBack(d.AssignmentId)}>Send FeedBack</span></div><br />
                        </> : <> <div><span className="badge btn alert-primary">TP</span></div><br /></>
                        }
                        <div>
                            <a className="badge btn btn-outline-primary" onClick={() => ViewStudentForm(d.AssignmentId, d.ScId.split('-')[0] = null ? 'NA' : d.ScId.split('-')[0])}>Get Cert Link</a>
                        </div>
                    </>
                );
            }
        },
        {
            text: 'SCIDs and c/w type',
            key: 'CName',
            sortable: true,
            cell: d => {
                return (
                    <>
                        {
                            d.ScId == null ? "N/A" :
                                d.ScId.split(',').map((data, i) =>
                                    <>
                                        <a target="_blank" data-tip={data.split('*')[1] == 1
                                            ? "Recording Mandetory" : data.split('*')[1] == 2
                                                ? "Recording Prohibited" : "Recording Type not available"}
                                            className={data.split('*')[1] == 1 ?
                                                "badge btn btn-outline-success mb-0" :
                                                data.split('*')[1] == 2 ?
                                                    "badge btn btn-outline-danger mb-0" : "badge btn btn-outline-primary mb-0"}
                                            href={"https://rms.koenig-solutions.com/Forms/CRM/student-card-prev.aspx?scid=" + data.split('-')[0] + "&PDFTran=pdfwerw12312123xfcg"}>
                                            {data.split('*')[0]}
                                        </a>
                                        <span className="ml-2">
                                            {data.split('*')[1] == 1 ?
                                                <Icon color='green' data-tip="Recording Mandetory" link name='video' /> :
                                                data.split('*')[1] == 2 ?
                                                    <>
                                                        <FontAwesomeIcon data-tip="Recording Prohibited" icon={faVideoSlash} className="text-danger" />
                                                    </> : ""
                                            }
                                        </span>
                                    </>
                                )
                        }
                        <div>{d.CoursewareType == null || d.CoursewareType == '' ? "" : ' / ' + d.CoursewareType}</div>
                        <br />
                        <div>
                            <p data-tip={d.CName}>{d.CName == null ? "N/A" : d.CName}</p>
                            <ReactTooltip place="top" type="dark" effect="float" />
                        </div>
                        <div># of Pax(As per Attendence / Qutation): {d.NoOfParticipants == null ? "N/A"
                            : <a className="btn-sm" onClick={() => StudentDetail(d.AssignmentId)}>{d.NoOfParticipants} / {d.NoOfParticipants1}</a>}</div>
                    </>
                );
            }
        },
        {
            text: 'TOT Dates / Meeting link',
            key: 'StartDate',
            sortable: true,
            cell: d => {
                return (
                    <>
                        {d.TimeZone != null && d.TimeZone != "India Standard Time" ? <><div className="text-nowrap badge badge-success">Time Showing in {d.TimeZone}</div><br></br> </> : ""}
                        {
                            d.TOTDates != null ? d.TOTDates.split(',').map((data, i) =>
                                <div className={i == 0 ? "text-nowrap btn badge btn-outline-success" :
                                    i == d.TOTDates.split(',').length - 1 ? "text-nowrap btn badge btn-outline-danger" : "text-nowrap btn badge btn-outline-warning"}>{data}</div>)
                                : "Date or Time not available."
                        }
                        <br></br>
                        <br></br>
                        {d.MeetingLink == null ? <a className="badge btn btn-outline-primary" onClick={() => AddLink(d.AssignmentId)}>Add Link</a> :
                            <>
                                <a href={d.MeetingLink} data-tip={d.MeetingLink} target="_blank">{d.MeetingLink.slice(0, 50)}</a>
                                <br></br>
                                <span data-tip="Copy Link" className="badge btn btn-outline-primary mb-2" onClick={(e) => CopydivContent(d.MeetingLink)}>Copy link</span>
                                <span className="badge btn btn-outline-primary mb-2 ml-2" onClick={() => SendMeetingLink(d.MeetingLink, d.AssignmentId, d.TrainerId, d.ILOManagerID)}>Send Meeting Link</span>
                                {d.IsZoomClasses != null ? <span className="badge btn btn-outline-primary mb-2 ml-2" onClick={() => DisplayCredentials(d.IsZoomClasses, d.MeetingLink)}>View Credendials</span> : ""}
                            </>
                        }
                        <br></br>
                        <div>{d.ILOManager == null || d.ILOManager == "" ? "" : <span><span id={"spanILOMnger_" + d.AssignmentId}>{d.ILOManager}</span><a className="badge btn btn-outline-success ml-2" onClick={() => ChangeILOManager(d.AssignmentId)}>Transfer</a></span>} </div>
                        {d.DCType == null ? "" : <span className="mt-2"><b>{d.DCType}</b></span>}
                        <div className="mt-2">
                            <a target="_blank" href={"https://rms.koenig-solutions.com/forms/CRM/frm_FirView.aspx?AssignmentId=" + d.AssignmentId} className="badge btn btn-outline-success">Add FIR</a>
                            &nbsp;&nbsp;&nbsp;
                            <a className="badge btn btn-outline-primary" onClick={() => ViewUploadAttendanceForm(d.AssignmentId, d.ScId.split('-')[0] = null ? 'NA' : d.ScId.split('-')[0], d.TrainerId, d.ILOManagerID)}>Upload Attendance</a>
                            &nbsp;&nbsp;&nbsp;
                            <a className="badge btn btn-outline-primary" onClick={() => InterimFeedbackLink(d.AssignmentId)}>Interim Feedback Link</a>
                            {/* <a className="badge btn btn-outline-primary" onClick={() => ViewIntermFeedbackLink(d.AssignmentId, d.ScId.split('-')[0] = null ? 'NA' : d.ScId.split('-')[0])}>Interim Feedback Link</a> */}
                        </div>


                    </>
                );
            }
        },
        {
            text: 'CheckList',
            key: '2',
            sortable: true,
            cell: d => {
                return (
                    <>
                        {d.CheckList.split(',').map((data) => ReturnCheckUncheckHTML(data, d.AssignmentId))}
                        <br></br>
                        {d.FirRaised != 0 ? <span className="text-monospace activeDateSpan breadcrumb alert-danger">FIR Raised <span className="ml-2"><Icon color='red' link name='bullhorn' /></span></span> : ""}
                    </>
                );
            }
        },
    ]
    useEffect(() => {
        getDelMode();
        getIloManger();
        getWeek();
        getDCData();
    }, [])

    function getDelMode() {
        axios.get("Nishant/DeliveryModeData").then(function (response) {
            setDelMode(response.data);
        }).catch(function (error) {
            alert(error);
        });
    }

    function getIloManger() {
        axios.get("Nishant/GetILOM").then(function (response) {
            setILOM(response.data);
        }).catch(function (error) {
            alert(error);
        });
    }

    function getWeek() {
        let days = [];
        const dateStart = moment(CDay);
        const dateEnd = moment(CDay).add(7, 'days');
        while (dateEnd.diff(dateStart, 'days') > 0) {
            days.push(dateStart.format('DD-MMM-YYYY'))
            dateStart.add(1, 'days')
        }
        setCWeek(days);
    }

    async function getDCData(FDate = "") {
        set_loading(true)
        let s_date = CDay.format("DD-MMM-YYYY");
        let e_date = moment(CDay).add(6, 'days').format("DD-MMM-YYYY");
        let FD = (FDate == "" || FDate == null ? (FilterDate == "" || FilterDate == null ? s_date : FilterDate) : FDate);
        await axios.get("Nishant/GetDCTypeData?DId=" + DelModeValue + "&MngrId=" + MngrValue + "&DateValue=" + s_date + "&EDateValue=" + e_date + "&FDate=" + FD + "&AssignmentStatus=" + AssignmentStatus + "&BType=" + BatchTypeStatus + "&SCType=" + SCType).then(function (response) {
            setRows(response.data);
            set_loading(false);
            ActiveDateSpan(FD);
        }).catch(function (error) {
            alert(error);
        });
    }

    const GetSCType = (id) => {
        switch (id) {
            case 1: return "ESI";
            case 2: return "GSI";
            case 3: return "T4H";
            case 4: return "MTAD";
            default: return "";
        }
    }

    function ChangeILOManager(AID) {
        ReactDOM.render(
            <Modal open={true} closeIcon={<Icon color="red" name="window close" />} onClose={() => hideAlert()}>
                <Modal.Header>{"Assignment Id: " + AID}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <div className="row mr-1">
                            <div className="col-md-6">
                                <select class="form-control" id="ddlILOMngr">
                                    <option key="-1" value="-1">Select Manager</option>
                                    {
                                        ILOM.map((d, i) => {
                                            return (
                                                <option key={i} value={d.ILMOEmpId}>{d.ILMO}</option>
                                            );
                                        })
                                    }
                                </select>
                                <br></br>
                                <button type="button" className="btn btn-success" onClick={() => ChangeILOMngr(AID)}>Update</button>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            ,
            document.getElementById('getSweetModel'))
    }

    const ChangeILOMngr = (AID) => {
        var ILOMngr = document.getElementById('ddlILOMngr').value;
        var ILOText = document.getElementById('ddlILOMngr').options[document.getElementById('ddlILOMngr').selectedIndex].innerHTML;
        if (ILOMngr != '-1') {
            axios.get("Nishant/ChangeILOManager?Mode=ChangeILOManager&AssignmentId=" + AID + "&EmpId=" + ILOMngr).then(function (response) {
                if (response.data != null) {
                    hideAlert();
                    NotificationManager.success('Assignment Successfully Transfered to ' + ILOText);
                    document.getElementById('spanILOMnger_' + AID).innerText = ILOText;
                }
            }).catch(function (error) {
                alert(error);
                set_loading(false);
            });

        }
        else NotificationManager.error('Please select Manager')
    }

    function NextWeek(d) {
        getWeek();
        getDCData(moment(d).format("DD-MMM-YYYY"));
    }

    function PrevWeek(d) {
        getWeek();
        getDCData(moment(d).format("DD-MMM-YYYY"));
    }

    const StudentDetail = (Content) => {
        GetStudentDetail(Content)
    }

    const GetStudentDetail = (AssignmentID) => {
        ReactDOM.render(
            <Modal open={true} closeIcon={<Icon color="red" name="window close" />} onClose={() => hideAlert()}>
                <Modal.Header>{"Assignment Id: " + AssignmentID}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <StudentDetailWithAssignment AID={AssignmentID}></StudentDetailWithAssignment>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            ,
            document.getElementById('getSweetModel'))
    }

    const ViewUploadAttendanceForm = (Up_AssignmentId, Up_Scid, Up_TrainerId, Up_IlomID) => {
        console.log("AssignmentId : " + Up_AssignmentId);
        console.log("Scid : " + Up_Scid);
        UploadAttendanceForm1(Up_AssignmentId, Up_Scid, Up_TrainerId, Up_IlomID);
    }

    const UploadAttendanceForm1 = (Up_AssignmentId, Up_Scid, Up_TrainerId, Up_IlomID) => {
        ReactDOM.render(
            <Modal open={true} closeIcon={<Icon color="red" name="window close" />} onClose={() => hideAlert()}>
                <Modal.Header>{"Assignment Id: " + Up_AssignmentId}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>
                        <UploadAttendanceForm UP_AID={Up_AssignmentId} UP_SCID={Up_Scid} UP_Tid={Up_TrainerId} UP_ILOMID={Up_IlomID} ></UploadAttendanceForm>

                    </Modal.Description>
                </Modal.Content>
            </Modal>
            ,
            document.getElementById('getSweetModel'))
    }

    const InterimFeedbackLink = (U_Content) => {

        InterimFeedbackDetail(U_Content)
    }

    const InterimFeedbackDetail = (AssignmentID) => {
        ReactDOM.render(
            <Modal open={true} closeIcon={<Icon color="red" name="window close" />} onClose={() => hideAlert()}>
                <Modal.Header>{"Assignment Id: " + AssignmentID}</Modal.Header>
                <Modal.Content scrolling>
                    <Modal.Description>

                        <InterimFB Y_AID={AssignmentID} ></InterimFB>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
            ,
            document.getElementById('getSweetModel'))
    }






    function CopydivContent(Content) {
        var copyText = Content
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', copyText);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        alert('Copied');
        return true;
    }

    const ChangeStatus = (Status, AssignmentId, ChkId) => {
        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure to ' + (Status ? "Check" : "UnCheck") + " ?",
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => ChangesChecklistStatus(Status, AssignmentId, ChkId)
                },
                {
                    label: 'No',
                    onClick: () => NotificationManager.error("SuccessFully Cancelled", "Cancelled")
                }
            ]
        });
    }

    const ChangesChecklistStatus = (Status, AssignmentId, ChkId) => {
        RenderLoader(Status, AssignmentId, ChkId, true);
        axios.get("Nishant/UpdateCheckListStatus?ChkId=" + ChkId + "&AssignId=" + AssignmentId + "&Status=" + Status).then(function (response) {
            if (response.data[0].Result == 1) RenderLoader(Status, AssignmentId, ChkId, false);
        }).catch(function (error) {
            alert(error);
            set_loading(false);
        });
    }

    const RenderLoader = (Status, AssignmentId, ChkId, LoaderStatus) => {
        if (LoaderStatus) {
            ReactDOM.render(<Spinner animation="border" size="sm" />, document.getElementById("Spin_" + ChkId.trim() + "_" + AssignmentId))
            ReactDOM.render(<Spinner animation="border" size="sm" />, document.getElementById("btn_" + ChkId.trim() + "_" + AssignmentId))
        }
        else {
            HTMLCheckList(Status, AssignmentId, ChkId)
        }
    }

    const HTMLCheckList = (Status, AssignmentId, ChkId) => {
        if (!Status) {
            ReactDOM.render(<Popup trigger={<Icon link color='red' name='cancel' />} position='top center' content="Not Checked"></Popup>, document.getElementById("Spin_" + ChkId.trim() + "_" + AssignmentId))
            ReactDOM.render(<a className="btn-sm badge btn-outline-success btn ml-4" id={"btn_" + ChkId.trim() + "_" + AssignmentId} onClick={() => ChangeStatus(true, AssignmentId, ChkId.trim())}>Mark as Checked</a>, document.getElementById("btn_" + ChkId.trim() + "_" + AssignmentId))
            NotificationManager.success("Successfully marked as Unchecked", "done");
        }
        else {
            ReactDOM.render(<Popup trigger={<Icon color='green' link name='checkmark' />} position='top center' content="Marked as Checked"></Popup>, document.getElementById("Spin_" + ChkId.trim() + "_" + AssignmentId))
            ReactDOM.render(<a className="btn-sm badge btn-outline-danger btn ml-4" id={"btn_" + ChkId.trim() + "_" + AssignmentId} onClick={() => ChangeStatus(false, AssignmentId, ChkId)}>Mark as UnChecked</a>, document.getElementById("btn_" + ChkId.trim() + "_" + AssignmentId))
            NotificationManager.success("Successfully marked as checked", "done");
        }
    }

    const AddLink = (AssignmentId) => {
        ReactDOM.render(<SweetAlert input required showCancel
            inputType="textarea"
            title="Enter Meeting Link"
            validationMsg="You must enter your Link!"
            onConfirm={(response) => GetMeetingText(response, AssignmentId)} onCancel={() => hideAlert()} />, document.getElementById('getSweetModel'))
    }

    const DisplayCredentials = (id, Link) => {
        ReactDOM.render(<SweetAlert title="Credentials" customClass="sweetAlertWidth30"
            onConfirm={() => hideAlert()} onCancel={() => hideAlert()}><div id="CredentailContent"></div></SweetAlert>, document.getElementById('getSweetModel'))
        GetCredentail(id, Link)
    }

    const GetCredentail = (Id, Link) => {
        let data = null;
        axios.get("Nishant/GetCredentials?ZoomId=" + Id).then(function (response) {
            data = response.data.data;
            if (data != null) {
                ReactDOM.render(<span>{data.length > 0 ?
                    <>
                        <div>Host site: <a href={Link}>{Link}</a></div>
                        <br></br>
                        <div>Username: {data[0].login != null ? data[0].login : "N/A"}</div>
                        <div>Password: {data[0].Password != null ? data[0].Password : "N/A"}</div>
                    </> : "No Credentails Available."}</span>, document.getElementById('CredentailContent'))
            }
        }).catch(function (error) {
            alert(error);
        });
    }

    const hideAlert = () => {
        ReactDOM.render("", document.getElementById('getSweetModel'));
    }

    const GetMeetingText = (res, AssignmentId) => {
        set_loading(true);
        axios.get("Nishant/UpdateMeetingLink?Link=" + res + "&AssignId=" + AssignmentId).then(function (response) {
            if (response.data[0].Result == 1) getDCData();
        }).catch(function (error) {
            alert(error);
            set_loading(false);
        });
        hideAlert();
    }

    function verifyStudentForm() {
        if (stuEMail == '') {
            NotificationManager.error('Email Id Required'); return false;
        }
        else if (StuFName == '') {
            NotificationManager.error('First Name Required'); return false;
        }
        else if (StuLName == '') {
            NotificationManager.error('Last Name Required'); return false;
        }
        return true;
    }

    const ViewStudentForm = (Content, scid) => {
        setStuAssignmentId(Content);
        setScidId(scid);

        if (scid == "NA") {
            NotificationManager.error("Invalid SCID Found");
        }
        else {
            setButtonPopup(true);
        }

    }




    const generateCertificateLink = () => {
        document.getElementById('lblYLink').innerText = "";
        if (verifyStudentForm()) {
            let data = null;
            axios.get('Avishek/GetYALink?Email=' + stuEMail + '&FName=' + StuFName + '&LName=' + StuLName + '&aid=' + stuAssignmentId + '&scid=' + stuScidId).then(function (response) {

                console.log(response.data[0]["sno"]);

                if (response.data[0]["sno"] == "NA") {
                    document.getElementById('lblYLink').innerText = "Template ID does not Exist for the course/Course is not GSI-ESI";
                    NotificationManager.error('Template ID does not Exist for the course/Course is not GSI-ESI');
                }
                else {
                    if (response.data[0]["sno"] > 0) {
                        document.getElementById('lblYLink').innerText = "https://rms2.koenig-solutions.com/IssueCertificate?sno=" + response.data[0]["sno"] + "&assignmentid=" + stuAssignmentId;
                        NotificationManager.success('Created Link Successfully');
                    }
                    else {
                        document.getElementById('lblYLink').innerText = "Cannot generate link";
                        NotificationManager.error('Cannot generate Link');
                    }
                }



            }).catch(function (error) {
                set_loading(false)
                alert(error);
            })
        }
        else {
            // console.log(verifyStudentForm());
        }
    }
    const ViewFeedBack = (Content) => {
        ReactDOM.render(<SweetAlert title="FeedBack" customClass="sweetAlertWidth"
            onConfirm={() => hideAlert()} onCancel={() => hideAlert()}><div id="FeedbackContent"></div></SweetAlert>, document.getElementById('getSweetModel'))
        GetFeedBack(Content)
    }

    const GetFeedBack = (AssignmentID) => {
        let data = null;
        axios.get("Nishant/GetFeedback?AssignmentId=" + AssignmentID).then(function (response) {
            data = response.data;
            if (data != null) {
                ReactDOM.render(<span>{data.length > 0 ? getFeedbackText(data) : "No Feedback Available."}</span>, document.getElementById('FeedbackContent'))
            }
        }).catch(function (error) {
            alert(error);
        });
    }

    function getFeedbackText(data) {
        return (
            <table style={{ width: "100%", color: "#212529", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                    <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>SL No</td>
                    <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Student Name</td>
                    <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Question</td>
                    <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Answer</td>
                </tr>
                {data.map((d, i) =>
                    <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{i + 1}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.StudentName}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.Question}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{d.Feedback}</td>
                    </tr>
                )}
            </table>
        )
    }

    const getDayValue = (value) => {
        setFilterDate(value);
        GetAssignmentData(value)
    }

    function GetAssignmentData(CD) {
        getDCData(CD);
    }

    const ActiveDateSpan = (D) => {
        var li = document.querySelectorAll("a[class*='activeDateSpan']");
        if (li.length > 0) li[0].classList.remove("activeDateSpan");

        var lA = document.querySelectorAll("a[id='dateSpan_" + D + "']");
        if (lA.length > 0) document.getElementById("dateSpan_" + D).classList.add("activeDateSpan");
    }

    function SendMeetingLink(Link, AssignmentID, TId, EId) {
        axios.get("Nishant/GetEmailsForSendingMail?AID=" + AssignmentID + "&TID=" + TId + "&EID=" + EId).then(function (response) {
            var HtmlContent = 'Meeting Link of ' + AssignmentID + ' is <a href="' + Link + '">' + Link + '</a>';
            var SendTo = response.data[0].TrainerEmail + ";" + response.data[0].ILOMEmail + ";" + (response.data[0].StudentEmails != null ? response.data[0].StudentEmails : "");
            SendMail('nishant.sinha@koenig-solutions.com', SendTo, '', 'nishant.sinha@koenig-solutions.com; deepak.goel@koenig-solutions.com;', 'Meeting Link of ' + AssignmentID, HtmlContent)
        }).catch(function (error) {
            alert(error);
        });
    }

    function SendFeedBack(AssignmentID) {
        axios.get("Nishant/GetFeedback?AssignmentId=" + AssignmentID + "&Type=1").then(function (res) {
            var data = res.data;
            if (data.length > 0) {
                data.map((d, i) => {
                    axios.get("http://api.koenig-solutions.com:8182/api/SendFeedbackLink/" + d.ClientId + "/" + d.SCId + "/" + d.CourseId).then(function (res) {
                        //var HtmlContent = ReactDOMServer.renderToStaticMarkup(FeedbackLink(d, res.data));
                        SendMail(d.ILOMEmail, d.StudentEamil, d.ILOMEmail + ";" + d.CCEEmail, 'nishant.sinha@koenig-solutions.com;', 'Feedback for ' + AssignmentID, FeedbackLink(d, res.data))
                    })
                })
                NotificationManager.success("Mail Succesfully sent.")
            }
            else NotificationManager.error("Pax details not added");
        })
    }

    const FeedbackLink = (d, href) => {
        return (
            <div>
                Dear<span style={{ color: "blue" }}>  {d.ClientName}</span>
                <br />
                <br />
                <span>Congratulations on successfully completing the training on {d.CourseName} .</span>
                <br />
                <span >Please click on the following link and submit the feedback –</span>
                <br />
                <br />
                <span><a href={href} target="_blank">Submit Feedback</a></span>
            </div>
        )
    }

    async function SendMail(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };

            let { data } = await axios.post("Nishant/sendemail", obj);
            if (data != null) NotificationManager.success("Feedback successfully sent");
        } catch (er) {
            //alert(JSON.stringify(er));
        }
    }

    const ReturnCheckUncheckHTML = (data, AssignmentId) => {
        return (
            <>
                <li className="text-nowrap">
                    {
                        <span id={"Spin_" + data.split('-')[0].split('.')[0].trim() + "_" + AssignmentId}>
                            {data.split('-')[1] == "Not Checked" ? <Popup trigger={<Icon link color='red' name='cancel' />} position='top center' content="Not Checked"></Popup> :
                                <Popup trigger={<Icon color='green' link name='checkmark' />} position='top center' content="Marked as Checked"></Popup>}
                        </span>
                    }
                    {data.split('-')[0].split('.')[1]}
                    {
                        <span id={"btn_" + data.split('-')[0].split('.')[0].trim() + "_" + AssignmentId}>
                            {data.split('-')[1] != "Not Checked" ?
                                <a className="btn-sm badge btn-outline-danger btn ml-4" onClick={() => ChangeStatus(false, AssignmentId, data.split('-')[0].split('.')[0])}>Mark as UnChecked</a> :
                                <a className="btn-sm badge btn-outline-success btn ml-4" onClick={() => ChangeStatus(true, AssignmentId, data.split('-')[0].split('.')[0])}>Mark as Checked</a>
                            }
                        </span>
                    }
                </li>
            </>
        )
    }

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
            <div style={{
                padding: 20,
                height: '6vh', backgroundColor: '#fafafb', width: '98vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>ILO Manager Panel</span>
            </div>

            <Popup1 trigger={buttonPopup} setTrigger={setButtonPopup}>
                <div>
                    <Input style={{ width: 200 }}
                        onChange={(e) => {
                            setStuEmail(e.target.value)
                        }}
                        placeholder='Student Email' />

                    <br></br>
                    <br></br>
                    <Input
                        style={{ width: 200 }}
                        onChange={(e) => {
                            setStuFName(e.target.value)
                        }}
                        placeholder='First Name' />

                    <br></br>
                    <br></br>
                    <Input
                        style={{ width: 200 }}
                        onChange={(e) => {
                            setStuLName(e.target.value)
                        }}
                        placeholder='Student Last Name' />

                    <br></br>
                    <label id='lblYLink'></label>
                    <br></br>
                    <br></br>
                    <button onClick={() => generateCertificateLink()} >Submit</button>
                </div>
            </Popup1>
            <div id="getSweetModel"></div>

            <div class="row m-4">
                <div class="col-md-2">
                    <select className="form-control" value={DelModeValue} onChange={(e) => setDelModeValue(e.target.value)}>
                        <option value="0">Select Delivery Mode</option>
                        {
                            DelMode.map((d, i) => {
                                return (
                                    <option key={i} value={d.DeliveryId}>{d.DeliveryName}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div class="col-md-2">
                    <select class="form-control" value={MngrValue} onChange={(e) => setMngrValue(e.target.value)}>
                        <option key="-1" value="-1">Select Manager</option>
                        {
                            ILOM.map((d, i) => {
                                return (
                                    <option key={i} value={d.ILMOEmpId}>{d.ILMO}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div class="col-md-2">
                    <select class="form-control" value={AssignmentStatus} onChange={(e) => setAssignmentStatus(e.target.value)}>
                        <option key="0" value="0">Please Select</option>
                        <option key="1" value="1">Starting Today</option>
                        <option key="2" value="2">Ongoing Today</option>
                        <option key="3" value="3">Ending Today</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <select class="form-control" value={BatchTypeStatus} onChange={(e) => setBatchTypeStatus(e.target.value)}>
                        <option key="0" value="0">All</option>
                        <option key="1" value="1">Only Tp</option>
                        <option key="2" value="2">Only Non-Tp</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <select class="form-control" value={SCType} onChange={(e) => setSCType(e.target.value)}>
                        <option key="0" value="0">All SC Type</option>
                        <option key="1" value="1">ESI</option>
                        <option key="2" value="2">GSI</option>
                        <option key="3" value="3">T4H</option>
                        <option key="4" value="4">MTAD</option>
                    </select>
                </div>
                <div class="col-md-2">
                    <button type="button" className="btn btn-success btn-block" onClick={() => getDCData()}>View</button>
                </div>
                <div className="col-md-12">
                    <div className="border mt-2 ui">
                        <div className="row mt-2">
                            <div className="col-md-1">
                                <span onClick={() => PrevWeek(CDay.add(-7, 'days'))}>
                                    <Icon size="big" color="teal" name="caret left" />
                                </span>
                            </div>
                            <div className="col-md-10 text-center">
                                {CWeek.map((d, i) => (
                                    <a id={"dateSpan_" + d} className={i != 0 ? "btn btn-sm mr-5 text-monospace text-primary"
                                        : "btn btn-sm mr-5 text-monospace text-primary activeDateSpan"
                                    } onClick={() => getDayValue(d)}>{moment(d, 'DD-MMM-YYYY').format('DD/MM')}</a>
                                ))}
                            </div>
                            <div className="col-md-1 text-right">
                                <span onClick={() => NextWeek(CDay.add(7, 'days'))}>
                                    <Icon size="big" color="teal" name="caret right" />
                                </span>
                            </div>
                        </div>
                        <SVGLoader loading={loading} SVGType="Table" SVGWidth="1200" SVGHeight="560"></SVGLoader>
                        <div className="row" style={{ display: loading ? 'none' : '' }}>
                            <div className="col-md-12">
                                <div className="overflow-auto border pl-2 pr-4" style={{ height: '40em' }}>
                                    <br></br>
                                    <CSDataTable Rows={Rows} Columns={Coulumn} PageSize={50} Length_menu={[50, 100, 200]}></CSDataTable>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NotificationContainer></NotificationContainer>
        </div>
    )
}
export default IlomManager