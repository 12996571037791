import React, { useEffect } from "react";
import { Menu, Icon } from "semantic-ui-react";
import { Animated } from "react-animated-css";
import { Card, Label } from "semantic-ui-react";

import { useHistory } from "react-router-dom";
const axios = require("axios");

function MenuList(props) {
  let current_page = window.location.pathname.substr(
    1,
    window.location.pathname.length
  );
  const [active, set_active] = React.useState("");
  const [hide_menu, set_hide_menu] = React.useState(false);
  const history = useHistory();
  const [name, set_name] = React.useState(null);
  const [Email, set_Email] = React.useState(null);
  const [RoleId, set_RoleId] = React.useState(null);
  const [designation, set_designation] = React.useState(null);

  //let emp = await localStorage.getItem("emp_data")
  //alert(emp.UserName)
  // if (window.location.href.includes('Conversion'))
  // {
  //     //set_hide_menu(true)
  //     menu_click("Conversion");
  // }

  //alert(JSON.stringify(emp_date))

  function menu_click(name) {
    //
    //alert(name)
    Authenticate();
    if (name == "Assignments") {
      let d = localStorage.getItem("emp_data");
      //alert(JSON.parse(d).login_id)
      //alert(JSON.parse(d).pwd)
      window.location.href =
        "https://rms.koenig-solutions.com/Forms/TrainerPanel/TrainerAssignments.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";

      //set_hide_menu(true);
    }
    if (name == "UpdateRC") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/Trainer/UpdateMyRC.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "Events") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/fd/flpanel.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "Recording") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/new-rms/ILo_RecordingDetails.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "ITPanel") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/DCIT/IT_Panel.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "Accomdation") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/Trainer/Accommodation.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "PIR") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/forms/CRM/frm_FirView.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "Rating") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/Forms/AudioRecording.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "SLeads") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/SCCE/frmEnquiry_T.aspx?UId=mFTRZAAc9PrLZeA3br/XgA==?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "TRequest") {
      let d = localStorage.getItem("emp_data");
      window.location.href =
        "https://rms.koenig-solutions.com/TravelDesk/NTFlightBooking.aspx?ui=" +
        JSON.parse(d).login_id +
        "&" +
        JSON.parse(d).pwd +
        "";
    }
    if (name == "Conversion" || name == "Bucket" || name == "SalesPolicy") {
      set_hide_menu(true);
    }
    if (name == "Outstanding_Policy" || name == "ClientFollowUp") {
      set_hide_menu(true);
    }
    if (name == "ILOM_KPI") {
      set_hide_menu(true);
    }
    if (
      name == "SCQForInvoices" ||
      "InvoiceFirstStep" ||
      "InvoiceSecondStep" ||
      "InvoiceThirdStep"
    ) {
      set_hide_menu(true);
    }
    set_active(name);
    history.push("/" + name + "");
  }
  const [menu_1, set_menu] = React.useState([]);

  const menu_ = [
    { name: "Outstanding_Policy" },
    { name: "Conversion" },
    { name: "ILOBatchRecording" },
    { name: "ILOM_KPI" },
    { name: "Third_Party_Batch" },
    { name: "RCB" },
    //{ "name": "Client" }
    { name: "NR" },
    { name: "CCE_PLI" },
    { name: "SCQ For Invoice" },

    //{ "name": "Sudarshan" }
  ];

  function toggle_menu() {
    set_hide_menu(!hide_menu);
  }

  useEffect(() => {
    let d = localStorage.getItem("emp_data");
    if (d != null) {
      //alert(JSON.parse(d).Designation);
      set_name(JSON.parse(d).UserName);
      set_designation(
        JSON.parse(d).Designation == "Freelancer"
          ? "Koe-Lancers"
          : JSON.parse(d).Designation
      );
      get_menu(JSON.parse(d).role_id, JSON.parse(d).user_id);
      set_Email(JSON.parse(d).email_id);
      set_RoleId(JSON.parse(d).role_id);
      Authenticate();
    }
  }, []);

  async function Authenticate() {
    let emp_id = localStorage.getItem("emp_id");
    // alert(emp_id);
    if (emp_id != 10000) {
      let { data } = await axios.get(
        "yusuf/rms_login_validate?id=" + emp_id + ""
      );

      //alert(JSON.stringify(data) )

      if (data.length <= 0) {
        alert("Oops! Not an Authorized user...Please check with RMS.");
        localStorage.clear();
        window.location.href = window.location.origin;
      }
    }
  }

  async function get_menu(role_id, user_id) {
    let { data } = await axios.get(
      "yusuf/get_menu?role_id=" + role_id + "&user_id=" + user_id + ""
    );
    //alert(JSON.stringify(data) )

    if (data.length > 0) {
      //set_data_list(data)
      //alert(JSON.stringify(data) )
      set_menu(data);
      //set_batch_id(data[0].id)
    }
  }

  const SpLogout = () => {
    return (
      <Label
        onClick={() => {
          logout();
        }}
      >
        {" "}
        Logout {"\u00A0"} <Icon color="orange" name="power" />{" "}
      </Label>
    );
  };

  function logout() {
    //alert('logout')
    localStorage.clear();
    window.location.href = window.location.origin;
  }

  return (
    <div style={{ display: "flex", flexDirection: "row", height: "100vh" }}>
      <Animated
        animationIn="bounceInLeft"
        animationOut="bounceOutLeft"
        animationInDuration={500}
        animationOutDuration={500}
        style={{ display: hide_menu ? "none" : "flex" }}
      >
        <div
          style={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgb(37, 40, 57)",
          }}
        >
          <div style={{ padding: 10 }}>
            {/* <div>
                            <Image src='https://avatars.dicebear.com/api/female/903.svg?mood[]=happy' avatar />
                            <span>Username</span>
                        </div> */}
            <Card style={{ width: 250 }}>
              <Card.Content>
                {/* <Image 
                            style={{height:50,width:50}}
                            floated='right'
                            src="https://avatars.dicebear.com/api/male/1712.svg?mood[]=happy"
                             /> */}

                <Card.Header style={{ fontSize: 16 }}>
                  {name ? name + " (" + Email + ")" : props.emp_data.UserName}
                </Card.Header>
                <Card.Meta style={{ fontSize: 14 }}>
                  {designation ? designation : props.emp_data.Designation}
                </Card.Meta>
                <Card.Meta
                  style={{ fontSize: 12, marginTop: 3, cursor: "pointer" }}
                >
                  {RoleId != 22 ? SpLogout() : ""}
                </Card.Meta>
              </Card.Content>
            </Card>
          </div>

          <Menu pointing secondary vertical className="mt-2">
            {menu_1.map((m, i) => {
              if (m.name === "CCE PLI") {
                return null; // Skip rendering this menu item
              }
              return (
                <Menu.Item
                  className="mt-1"
                  name={m.name}
                  key={i}
                  style={{ color: "#fff", fontSize: 14, fontWeight: "bold" }}
                  active={active === m.name}
                  onClick={(e, { name }) => {
                    menu_click(m.page_name);
                  }}
                />
              );
            })}
          </Menu>
        </div>
      </Animated>

      <div
        style={{ backgroundColor: "rgb(37, 40, 57)", width: 15, zIndex: "99" }}
      >
        <Icon
          style={{ cursor: "pointer" }}
          onClick={() => {
            toggle_menu();
          }}
          circular
          inverted
          color="teal"
          name={hide_menu ? "grid layout" : "angle left"}
        />
      </div>
    </div>
  );
}

export default MenuList;
