import React,{useEffect} from 'react'
import { Dropdown } from 'semantic-ui-react'
const axios = require('axios');


const TrainerDropDown = (props) => {
  const [trainer, set_trainer] = React.useState([])

  useEffect(() => {
    get_trainer();
}, []);

  function get_trainer(){
    axios.get('yusuf/get_trainer')

    .then(function (response) {
      // alert(JSON.stringify(response.data))
      set_trainer(response.data)

  
    })
    .catch(function (error) {
        alert(error);
    })

  }

function send_to_parent(value){
  //alert(value)
  props.passChildData(value); 

}
  
  return (
    <Dropdown

      placeholder='Select Trainer'
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(value)
        // alert(value)
        //alert(e)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={trainer}
      clearable
    />
  )


}

export default TrainerDropDown