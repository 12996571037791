import React, { useEffect } from "react";
import Select from 'react-select'
const axios = require("axios");

const CustomDropDown = (props) => {
    const [ct, set_ct] = React.useState([]);

    useEffect(() => {
        FetchData();
    }, []);

    function FetchData() {
        axios.post(props.link).then(function (response) {
            set_ct(response.data);
        })
    }

    function send_to_parent(text, value) {
        props.passChildData(text, value);
    }

    return (
        <Select options={ct}
            placeholder={props.placeholder}
            value={
                ct.filter(option =>
                    option.value === props.svalue)
            }
            onChange={(e, value) => { send_to_parent(e.label, e.value); }}
            isDisabled={props.disabled != null ? props.disabled : false} />
    );
}

export default CustomDropDown