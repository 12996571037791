import React, { useEffect, useState } from 'react';
import ContentLoader, { Facebook } from 'react-content-loader'
import FormTye from './SVGLoaderType/FormType';
import TableType from './SVGLoaderType/TableType';

const SVGLoader = props => {
    function abc(val){
        if(val == 'Table') return <TableType />
        else if(val == 'Form') return <FormTye/>
    }
    return (
        <ContentLoader 
            display={props.loading ? 'flex' : 'none'}
            speed={2}
            width={props.SVGWidth}
            height={props.SVGHeight}
            viewBox="0 0 1500 600"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
            {...props}
        >
        {abc(props.SVGType)}
        </ContentLoader>
    )

}
export default SVGLoader