import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Container, Input, Button, Image } from 'semantic-ui-react'
import HeaderMenu from "../Nishant/Freelancer/Reusable/HeaderMenu";
import Logout from '../Nishant/Freelancer/Reusable/Logout'
import NotificationManager from 'react-notifications/lib/NotificationManager';
const axios = require('axios');

const ChangeDefaultPw = (props) => {
    const [loading, set_loading] = React.useState(false)
    const [NPwd, set_NPwd] = React.useState('')
    const [CNPwd, set_CNPwd] = React.useState('')

    useEffect(() => {
        Display();
    }, []);

    function Display() {
        alert('It seems your password is too weak please update your password');
    }
    function ChangePWd() {
        let d = localStorage.getItem("emp_data");
        if (verifyPWD()) {
            set_loading(true);
            var obj = {
                UserId: JSON.parse(d).user_id,
                PWD: CNPwd
            }
            console.log(obj)
            axios.post("Nishant/UpdatePassword", obj).then(function (response) {
                set_loading(false)
                if (!response.data.Ok) NotificationManager.error('Invalid Email')
                else {
                    setTimeout(() => {
                        if (JSON.parse(d).role_id == 22) {
                            window.location.href = window.location.origin + '/Add_Skill?DPC=1';
                            NotificationManager.success('Password SuccessFully Updated.');
                            set_loading(false)
                        }
                        else {
                            window.location.href = window.location.origin + '/Outstanding_Policy?DPC=1';
                            NotificationManager.success('Password SuccessFully Updated.');
                            set_loading(false)
                        }
                    }, 2000);
                }
            }).catch(function (error) {
                set_loading(false)
                alert(error);
            })
        }
    }

    function verifyPWD() {
        if (NPwd != '' && NPwd != null && CNPwd != '' && CNPwd != null) {
            if (NPwd == CNPwd) {

                if (!isValidPassword(NPwd)) {
                    //NotificationManager.success('Password must be at least 6 characters long and contain at least one uppercase letter, one digit, and one special character (@$!%*?&).');
                    alert('Password must be at least 6 characters long and contain at least one uppercase letter, one digit, and one special character (@$!%*?&).');
                    return false;
                }
                else {
                    return true
                }
            }
            else {
                //NotificationManager.success('Password did not matched');
                alert('Password did not matched')
                return false;
            }
        }
        else {
            //NotificationManager.success('Please Enter Password');
            alert('Please Enter Password')
            return false;
        }
    }

    function isValidPassword(password) {
        // Regular expression to match passwords
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
        // Check if the password matches the regex pattern
        return passwordRegex.test(password);
    }

    return (
        <>
            <div style={{ marginLeft: "auto", width: "fit-content", marginTop: "5px", padding: "25px" }}>
                <Logout></Logout>
            </div>
            {/* <h2><center>It seems your password is too weak please update your password</center></h2> */}
            <Container style={{ width: 600, backgroundColor: '#fff' }}>

                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    width: 600,
                    paddingLeft: 130, paddingRight: 130,
                    height: '100vh'
                }}>

                    <br></br>
                    <br></br>
                    <br></br>
                    <Image
                        style={{ alignSelf: 'center', height: 100, width: 100 }}
                        src={require('../reusable/images/ko.png')} />
                    <br></br>
                    <Input
                        onChange={(e) => {
                            set_NPwd(e.target.value)

                        }}
                        size='large' type="password" placeholder='New Password' />
                    <br />
                    <Input
                        onChange={(e) => {
                            set_CNPwd(e.target.value)

                        }}
                        size='large' type="password" placeholder='Confirm New Password' />
                    <br />
                    <br></br>
                    <div style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                        <Button
                            style={{ width: 340, fontSize: 16 }}
                            onClick={() => {
                                ChangePWd()
                            }} positive>Update Password</Button>
                    </div>
                    <div style={{ alignSelf: 'center', display: loading ? 'flex' : 'none' }}>
                        <Button
                            style={{ width: 340, fontSize: 16 }}
                            loading positive>
                            Loading
                        </Button>
                    </div>

                </div>
            </Container>
        </>
    )
}

export default ChangeDefaultPw