import React, { useEffect } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Icon, Loader, Button, Modal, Table, Container, Label } from 'semantic-ui-react'
import VendonDropDown from '../reusable/VendonDropDown'
import CountrtDropDown from '../reusable/CountryDropDown'
const axios = require('axios');

export default function Sudarshan() {
    const [loading, set_loading] = React.useState(true)
    const [data_list, set_data_list] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [link, set_link] = React.useState('')
    const [sd, set_sd] = React.useState('');
    const [ed, set_ed] = React.useState('');
    const [cell_data, cell_cell_data] = React.useState([])
    const [month, set_month] = React.useState("");
    const [user, set_user] = React.useState("");
    const [header1, set_header1] = React.useState("Name");
    const [header2, set_header2] = React.useState("");
    const [header3, set_header3] = React.useState("");
    const [vendor_id, set_vendor_id] = React.useState(0);
    const [country_name, set_country_name] = React.useState('all');



    //const [link_placeholder, seset_link_placeholdert_link] = React.useState('')

    useEffect(() => {
        set_loading(true)
        get_data();
    }, []);

    async function get_data() {
        set_loading(true)


        let js_start = new Date(sd);
        let js_end = new Date(ed);
        let sd_ = js_start.getMonth() + 1 + "/" + js_start.getDate() + "/" + js_start.getFullYear()
        let ed_ = js_end.getMonth() + 1 + "/" + js_end.getDate() + "/" + js_end.getFullYear()

        if (sd == '') { sd_ = "04/01/2020" }
        if (ed == '') { ed_ = "8/31/2020" }
        let { data } = await axios.get('anjali/sp_get_ILOM_KPI?from=' + sd_ + '&to=' + ed_ + '')
        if (data.length > 0) {
            set_loading(false)
            set_data_list(data)
        }
        else {
            alert("NO DATA")
            set_loading(false)

        }

    }

    function play(link) {
        //seset_link_placeholdert_link(link)
        set_link(link.split('be/')[1])
        setOpen(true)
    }

    async function cell_click(ilom_name, type) {
        if (type == 'repeated') {
            set_header2("SCID")
            set_header3("Training Date")
        }
        if (type == 'qubits') {
            set_header2("Assignment ID")
            set_header3("Qubits42")
        }
        if (type == 'students') {
            set_header2("Assignment ID")
            set_header3("Training Date")
        }
        if (type == 'assignments') {
            set_header1("SCID")

            set_header2("Assignment ID")
            set_header3("Training Date")
        }
        if (type == 'no_feedback') {

            set_header2("SCID")
            set_header3("Training Date")
        }

        if (type == 'referral') {

            set_header2("Created Date")
            set_header3("SCID")
        }


        set_loading(true)

        let js_start = new Date(sd);
        let js_end = new Date(ed);
        let sd_ = js_start.getMonth() + 1 + "/" + js_start.getDate() + "/" + js_start.getFullYear()
        let ed_ = js_end.getMonth() + 1 + "/" + js_end.getDate() + "/" + js_end.getFullYear()

        if (sd == '') { sd_ = "04/01/2020" }
        if (ed == '') { ed_ = "8/31/2020" }



        try {
            let { data } = await axios.get('anjali/ilom_kpi_click?from=' + sd_ + '&to=' + ed_ + '&ilom_name=' + ilom_name + '&type=' + type + '')
            //alert(JSON.stringify(data))
            if (data.length > 0) {


                cell_cell_data(data)
                setOpen(true)
                set_loading(false)
            }
            else {
                alert("NO DATA")
                set_loading(false)

            }
        }
        catch (er) {
            alert(JSON.stringify(er))
        }



    }

    function opem_qubits(link) {
        var win = window.open(link, '_blank');
        win.focus();
    }

    return (

        <div style={{ width: '80vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>

            {/* HEADER */}
            <div style={{
                padding: 20,
                height: '8vh', backgroundColor: '#fafafb', width: '100vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>Sudarshan</span>
            </div>

            {/* FITER SECTION */}
            <div style={{ display: 'flex', paddingLeft: 43, paddingTop: 20 }}>


                <div style={{ margin: 10 }}>
                    <SemanticDatepicker placeholder="From Date" onChange={(e, v) => {
                        //alert(JSON.stringify(v)) 
                        set_sd(v.value)
                    }} />
                </div>
                <div style={{ margin: 10 }}>

                    <SemanticDatepicker placeholder="To Date" onChange={(e, v) => {
                        //alert(JSON.stringify(v)) 
                        set_ed(v.value)
                    }} />
                </div>
                <div style={{ margin: 10 }}>
                    <CountrtDropDown passChildData={set_country_name} />
                </div>
               
                <div style={{ margin: 10 }}>

                    <Button onClick={() => { get_data() }} color="teal" content='Filter' icon='filter' labelPosition='right' />


                </div>

            </div>


            <div style={{ display: 'flex', padding: 20 }}>
                    <div style={{ padding: 20,overflow: 'scroll' }}>
                    <Table color='teal' singleLine style={{border:"2px solid red"}} >
                    <Table.Header >
                        <Table.Row backgroundColor="#ee5253">
                            <Table.HeaderCell>CLIENT</Table.HeaderCell>
                            <Table.HeaderCell>Country</Table.HeaderCell>
                            <Table.HeaderCell>SCID </Table.HeaderCell>
                            <Table.HeaderCell>COURSE </Table.HeaderCell>
                            <Table.HeaderCell>DATE </Table.HeaderCell>
                            <Table.HeaderCell colSpan={2}> FOLLOW UP DATE</Table.HeaderCell>
                            {/* <Table.HeaderCell rowSpan={2}>NEXT FOLLOW UP </Table.HeaderCell> */}
                            <Table.HeaderCell>REMARKS </Table.HeaderCell> 
                            <Table.HeaderCell>CLOSURE MONTH </Table.HeaderCell>
                            <Table.HeaderCell>REPEATABLE REMARKS</Table.HeaderCell>
                            <Table.HeaderCell>MICRO EMAIL </Table.HeaderCell>                            
                        </Table.Row>
                       
                    </Table.Header>

                    <Table.Body>
                        <Table.Row>
                            <Table.Cell>Apples</Table.Cell>
                            <Table.Cell>0g</Table.Cell>
                            <Table.Cell>0g</Table.Cell>
                            <Table.Cell>Apples</Table.Cell>
                            <Table.Cell>200</Table.Cell>
                            <Table.Cell>0g</Table.Cell>
                            <Table.Cell>0g</Table.Cell>   
                            <Table.Cell>Apples</Table.Cell>
                            <Table.Cell>200</Table.Cell>
                            <Table.Cell>0g</Table.Cell>
                            <Table.Cell>0g</Table.Cell>   
                        </Table.Row>                       
                    </Table.Body>
                </Table>
         
                    </div>             
            </div>      

        </div>


    );
}

