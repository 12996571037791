import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const PaymentTerms = (props) => {
  const [city, set_city] = React.useState([]);

  useEffect(() => {
    get_city();   
  }, []);

  function get_city() {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=16")
      .then(function (response) {        
        set_city(response.data);       
      })
      .catch(function (error) {
        alert(error);
      });
  }
  function send_to_parent(value) {
    props.passChildData(value);
  }

  return (
    <Dropdown
      placeholder="Select Payment Terms"
      openOnFocus={false}
      onChange={(e, { value }) => {
        send_to_parent(value);
      }}
      selection
      options={city}
      value={props.bydefault}
      clearable
      style={{height: "2px",width: "75px" }}
    />
  );
};

export default PaymentTerms;
