import React, { useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
const axios = require('axios');


const CountrtDropDown = (props) => {
  const [country, set_country] = React.useState([])

  useEffect(() => {
    get_country();
  }, []);

  function get_country() {
    axios.get('yusuf/get_country')
      .then(function (response) {
        set_country(response.data)

      })
      .catch(function (error) {
        alert(error);
      })

  }

  function send_to_parent(value) {
    //alert(value)
    props.passChildData(value);

  }

  return (
    <Dropdown

      placeholder='Select Country'
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(value)
        // alert(value)
        //alert(e)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={country}
      clearable
    />
  )


}

export default CountrtDropDown






