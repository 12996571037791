import React, { useState, useEffect } from 'react'
import SVGLoader from '../../MainProject/Reuseble/SVGLoader';
import CSDataTable from '../Reusable/CSDataTable'
import HeaderMenu from '../Reusable/HeaderMenu'

const axios = require("axios");
var moment = require('moment');

const FLFeedback = () => {
    const [Rows, setRows] = useState([]);
    const [loading, set_loading] = React.useState(true)
    const Coulumn = [
        { text: 'Id', key: 'TrainerBatchId', sortable: true },
        { text: 'Course Name', key: 'COURSENAME', sortable: true },
        { text: 'Invoice No', key: 'InvoiceNo', sortable: true },
        { text: 'Amount', key: 'InvAmt', sortable: true },
        {
            text: 'Invoice Date',
            key: 'InvDate',
            sortable: true,
            cell: record => {
                return (
                    <>
                        {moment(record.InvDate).format('DD/MM/YYYY')}
                    </>
                );
            }
        },
    ];

    useEffect(() => {
        Getdata();
    }, [])

    function Getdata() {
        set_loading(true)
        let d = localStorage.getItem("emp_data");
        axios.get("Nishant/FLInvoice?TrainerId=" + JSON.parse(d).trainer_id).then(function (response) {
            setRows(response.data);
            set_loading(false);
        }).catch(function (error) {
            alert(error);
        });
    }

    return (
        <div>
            <HeaderMenu HeaderText="Payment"></HeaderMenu>
            <SVGLoader loading={loading} SVGType="Table" SVGWidth="1200" SVGHeight="560"></SVGLoader>
            <div className="row">
                <div className="col-md-12">
                    <div className="overflow-auto border m-3" style={{ height: '40em' }}>
                        <CSDataTable Rows={Rows} Columns={Coulumn}></CSDataTable>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default FLFeedback
