import React, { useEffect, useState } from 'react'
import { Container, Input, Button, Image } from 'semantic-ui-react'


import SemanticDatepicker from 'react-semantic-ui-datepickers';
//import '../Pushpendra/Conversion/node_modules/react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { Link } from 'react-router-dom';

//import DatePicker from "react-datepicker";import "react-datepicker/dist/react-datepicker.css";

const axios = require('axios');


const EditILORecording = (props) => {

    const [selectedDate, setSelectedate] = useState(null)
    const [loading, set_loading] = React.useState(false)
    const [type, set_type] = React.useState('2')
    const [assignmentid, set_assignmentid] = React.useState('')
    const [assignmentidErr, set_assignmentidErr] = React.useState('')

    const [url, set_url] = React.useState('')
    const [urlErr, set_urlErr] = React.useState('')

    const [dates, set_date] = React.useState('2020-01-01')
    const [sequence, set_sequence] = React.useState('')
    const [sequenceErr, set_sequenceErr] = React.useState('')

    const [remarks, set_remarks] = React.useState('')
    const [remarksErr, set_remarksErr] = React.useState('')

    const [ILO_Id, set_IloId] = React.useState('')
    const [CreatedBy, set_createdby] = React.useState('10')    

    const [ExpiryDate, setExpiryDate] = useState(null);
    const onChange1 = (event, data) => setExpiryDate(data.value);

    const [currentDateErr, setNewDateErr] = useState(null);
    const [currentDate, setNewDate] = useState(null);
    const onChange = (event, data) => setNewDate(data.value);

    

    function IsValid(){        
        if (assignmentid == "" || assignmentid == null) {
            set_assignmentidErr("Please enter Assignment Id")
            //return;
        }

        else if (url == "" || url == null < 0) {
            set_urlErr("Please enter URL");
            set_assignmentidErr('');
            return false;
        }
        else if (currentDate == "" || currentDate == null) {
            setNewDateErr("Please enter Date");
            set_urlErr('')
            return false
         }
        else if (sequence == "" || sequence == null) {
            set_sequenceErr("Please enter Sequence");
            setNewDateErr('')
            return false;
        }
        else if (remarks == "" || remarks == null) {
            set_remarksErr("Please enter Remarks");
            set_sequenceErr('')
            return false;
        }
        else{
            set_remarksErr('')
        return true;
        }
    }


    function get_data() {        
        axios.get('jai/sp_save_ilo_recordings?type=5&assignmentId=0&url=ok&date=1-jan-2020&sequence=1&remarks=ok&ILO_Id=' + localStorage.getItem('id') + '')
            .then(function (response) {
                //alert(JSON.stringify(response.data))
                set_loading(false)
                var data = response.data;

                set_url(data[0].URL);
                set_assignmentid(data[0].AssignmentId)
                var mydate = new Date(data[0].Date);
                setNewDate(mydate)
                set_sequence(data[0].Sequence)
                set_remarks(data[0].Remarks)
                set_IloId(data[0].ILO_Id)

                
                var Exdate = new Date(data[0].LinkExpiryDate);
                setExpiryDate(Exdate)

                // set_city(response.data)

            })
            .catch(function (error) {
                alert(error);
            })
    }
    useEffect(() => {
        set_IloId(localStorage.getItem('id'));
        set_loading(true)
        get_data();
    }, []);
    function submit() {
        if(!IsValid()){
            return;
        }
        
        set_loading(true)
        var js_start = new Date(currentDate);
        var sd_ = js_start.getMonth() + 1 + "/" + js_start.getDate() + "/" + js_start.getFullYear()

        var ExpDate = new Date(ExpiryDate);
        var ExprDate_ = ExpDate.getMonth() + 1 + "/" + ExpDate.getDate() + "/" + ExpDate.getFullYear()
        axios.get('jai/sp_save_ilo_recordings?type=' + type + '&assignmentid=' + assignmentid + '&url=' + url + '&date=' + sd_ + '&sequence=' + sequence + '&remarks=' + remarks + '&ILO_Id=' + ILO_Id + '&IsActive=1&CreatedBy=' + CreatedBy + '&LinkExpiryDate='+ExprDate_+'')
            .then(function (response) {

                if (response.data != "") {
                    if (response.data[0].Result > 0) {
                        alert("Record updated successfully");
                        window.location.href = "/ILOBatchRecording";
                    }
                    else {
                        alert("Opps.. Something went wrong");
                    }

                    set_loading(false)

                }
                else {
                    set_loading(false)

                    alert('invalid cred')
                }
            })
            .catch(function (error) {
                set_loading(false)

                alert(error);
            })
    }



    return (
        <Container>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100vw', height: 'auto', backgroundColor: '#ffffff' }}>

                {/* DATA SECTION */}
                <div style={{
                    padding: 22,
                    height: '8vh', backgroundColor: '#fafafb', width: '100vw',
                    WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                    MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                    boxShadow: "0 8px 6px -6px #d5d4d6"
                }}>

                    <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif' }}>ILO Recording</span>

                </div>


            </div>

            <div style={{ backgroundColor: "white", padding: "30px" }}>
                <table>
                    <tr>
                        <td>
                            <label>Assignment Id</label><br></br>
                            <Input value={assignmentid}
                                onChange={(e) => {
                                    set_assignmentid(e.target.value)
                                }}
                                size='large' placeholder='Assignment Id' /><br></br>
                            <label style={{color:"#dc0d0d"}}>{assignmentidErr}</label>
                        </td>
                        <td>
                            <label>URL</label><br></br>
                            <Input value={url}
                                onChange={(e) => {
                                    set_url(e.target.value)

                                }}
                                size='large' placeholder='URL' /><br></br>
                                <label style={{color:"#dc0d0d"}}>{urlErr}</label>
                        </td>


                        <td>
                            <label>Date</label><br></br>
                            <SemanticDatepicker placeholder="2020-01-01" value={currentDate} onChange={onChange}
                                format="DD-MMM-YYYY" /><br></br>
                                <label style={{color:"#dc0d0d"}}>{currentDateErr}</label>
                        </td>
                        <td>
                            <label>Sequence</label><br></br>
                            <Input value={sequence}
                                onChange={(e) => {
                                    set_sequence(e.target.value)
                                }}
                                size='large' placeholder='Sequence' /><br></br>
                                <label style={{color:"#dc0d0d"}}>{sequenceErr}</label>
                        </td>
                    </tr>
                    <tr>
                    <td>
                            <label>Link Expiry Date</label><br></br>
                            <SemanticDatepicker placeholder="2020-01-01" value={ExpiryDate} onChange={onChange1}
                                format="DD-MMM-YYYY" /><br></br>
                                <label style={{color:"#dc0d0d"}}></label>
                        </td>
                        <td>
                            <label>Remarks</label><br></br>
                            <Input value={remarks}
                                onChange={(e) => {
                                    set_remarks(e.target.value)
                                }}
                                size='large' value={remarks} placeholder='Remarks' /><br></br>
                                <label style={{color:"#dc0d0d"}}>{remarksErr}</label>
                        </td>
                        <td>

                            <div style={{ display: loading ? 'none' : 'block' }}>
                                <Button className="btn btn-success"
                                    onClick={() => {
                                        submit()
                                    }} positive>Update</Button>

                                <Link style={{ padding: "7.4px" }} to='/ILOBatchRecording' size='large' className="btn btn-warning">Back</Link>
                            </div>
                        </td>
                        <td>
                            <div style={{ display: loading ? 'block' : 'none' }}>
                                <Button loading positive>
                                    Loading
            </Button>
                            </div>
                        </td>
                    </tr>
                </table>
                <br></br>
                <br></br>
            </div>

        </Container>
    )

}
export default EditILORecording