import React from 'react'
import ReactDOM from 'react-dom';
import { useEffect } from "react";
import { Loader } from 'semantic-ui-react';
import '../../../pushpendra/Conversion/Conversion.css';
import { Alert } from 'bootstrap';

const axios = require('axios');
const UnSubscribe = () => {
    const [loading, set_loading] = React.useState(true)

    useEffect(() => {
        const params = new URLSearchParams(window.location.search) // id=123
        UnSubscribeMail(params.get('id')) // 123 
    }, [])

    function UnSubscribeMail(id) {
        if (id != '' && id != null) {
            axios.get('Nishant/FGTAPI/UnSubscribe?id=' + id).then(function (response) {
                if (!response.data.Ok) Alert('Invalid Link or Id');
                else {
                    set_loading(false);
                    ReactDOM.render(<> <h1>Succesfully UnSubscribed</h1> </>, document.getElementById('Content'));
                }
            }).catch(function (error) {
                set_loading(false)
                alert(error);
            })
        }
        else Alert('Invalid Link');
    }

    return (
        <div>
            {/* LOADER */}
            <div style={{
                display: loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vw',
                flexDirection: 'column', height: '90vh'
            }}>
                <Loader active inline size='massive'></Loader>
            </div>
            <div style={{
                display: !loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vw',
                flexDirection: 'column', height: '90vh'
            }}>
                <div id="Content"></div>
            </div>
        </div>
    )
}

export default UnSubscribe
