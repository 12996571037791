import React from "react"

const FirstFooter = (props) => {



    return (
        <>
            <tr>
                {(props.invoiceNumber.includes("DXB") || props.invoiceNumber.includes("FZLLC")) ? "" : (
                    <td colSpan="6" style={{ border: "none", border: "1px solid #b0b0b0" }} >
                        <div><center><span>SAC: 999293</span></center></div>
                    </td>
                )}
            </tr>
            <tr>

                {props.AddColumnForSecondCurrency != 0 ? (<td colSpan="4" style={{ border: "1px solid #b0b0b0" }}></td>) : <td style={{ border: "1px solid #b0b0b0" }} colSpan="3"></td>}
                <td style={{ border: "1px solid #b0b0b0" }}>Taxable Value</td>
                <td style={{ border: "1px solid #b0b0b0" }}>Rate</td>
                <td style={{ border: "1px solid #b0b0b0" }}>Tax Amount</td>
            </tr>
            <tr>
                {props.AddColumnForSecondCurrency != 0 ? (<td colSpan="4" style={{ border: "1px solid #b0b0b0" }}> &nbsp;</td>) : <td colSpan="3" style={{ border: "1px solid #b0b0b0" }}> &nbsp;</td>}

                <td style={{ border: "1px solid #b0b0b0" }}>{props.TaxAmount.toFixed(2)}</td>
                <td style={{ border: "1px solid #b0b0b0" }}> {props.FooterRate} %     </td>
                <td style={{ border: "1px solid #b0b0b0" }}> {props.TaxAmountFooter} </td>
            </tr>
        </>
    )
}

export default FirstFooter