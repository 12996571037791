import React,{useEffect} from 'react'
import { Dropdown } from 'semantic-ui-react'
import "./Common.css";

const axios = require('axios');


const ContinentDropDown = (props) => {
  const [continent, set_continent] = React.useState([])

  useEffect(() => {
    get_continent();
}, []);

  function get_continent(){
    axios.get('yusuf/get_continent')
    .then(function (response) {
      set_continent(response.data)
  
    })
    .catch(function (error) {
        alert(error);
    })

  }

function send_to_parent(value){
  //alert(value)
  props.passChildData(value); 

}
  
  return (
    <Dropdown

      placeholder='Select Continent'
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(value)
        // alert(value)
        //alert(e)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={continent}
      clearable
    />
  )


}

export default ContinentDropDown