import React from 'react'
import { Container, Input, Button, Image } from 'semantic-ui-react'
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import ChangeDefaultPw from '../Ashish/ChangeDefaultPw';
const axios = require('axios');

const Login = (props) => {

    const [loading, set_loading] = React.useState(false)
    const [id, set_id] = React.useState('')
    const [pwd, set_pwd] = React.useState('')
    const [emp_data, set_emp_data] = React.useState([])

    const EmailMyPassword = () => {
        const enteredName = prompt('Please enter your email')
        if (enteredName != null && enteredName.trim().length != 0) MailPassword(enteredName.trim())
        else alert('Invalid Email')
    }

    function MailPassword(Tomail) {
        if (Tomail != '' || Tomail != null) {
            axios.get('Nishant/FGTAPI/EmailPassword?id=' + Tomail).then(function (response) {
                set_loading(false)
                if (!response.data.Ok) NotificationManager.error('Invalid Email')
                else NotificationManager.success('Password Successfully sent to your mail')
            }).catch(function (error) {
                set_loading(false)
                alert(error);
            })
        }
    }

    function submit() {
        set_loading(true)
        axios.get('yusuf/rms_login?id=' + id + '&pwd=' + pwd + '')
            .then(function (response) {
                //alert(JSON.stringify(response))
                if (response.data != "" && response.data[0].Blacklist != "1") {
                    //set_emp_id(response.data[0]["EmpId"])
                    //set_emp_data(response.data)
                    //alert(response.data[0]["EmpId"])
                    // if (pwd == '123') {
                    //     alert('password is 123');
                    // }
                    props.passChildData(response.data);
                    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
                    // Check if the password matches the regex pattern
                    if (!passwordRegex.test(pwd) && response.data[0].IsFreelancer == "1") {
                        window.location.href = window.location.origin + '/ChangeDPW'
                        set_loading(false)
                    }
                    else {
                        if (response.data[0]["role_id"] == 22) {

                            window.location.href = window.location.origin + '/Add_Skill'
                            set_loading(false)
                        }
                        else {

                            window.location.href = window.location.origin + '/Outstanding_Policy'
                            set_loading(false)
                        }
                    }


                }
                else if (response.data != "" && response.data[0].Blacklist != "0") {
                    set_loading(false)

                    alert('Please note you do not have access to RMS.Please contact your respective Coordinator from Koenig Solutions.')
                }
                else {
                    set_loading(false)

                    alert('invalid cred')
                }
            })
            .catch(function (error) {
                set_loading(false)

                alert(error);
            })
    }

    return (
        <Container style={{ width: 600, backgroundColor: '#fff' }}>
            <div style={{
                // display: 'flex',
                // flexDirection: 'column',
                // backgroundColor: '#fff',
                // //justifyContent: 'center',
                // alignItems: 'center',
                // width: 800,
                // height: '70vh', position: 'absolute', left: '35%', top: '10%', borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                //justifyContent: 'center',
                //alignItems: 'center',
                width: 600,
                paddingLeft: 130, paddingRight: 130,
                height: '100vh'
            }}>
                <br></br>
                <br></br>

                <Image
                    //size='tiny'
                    style={{ alignSelf: 'center', height: 100, width: 100 }}
                    src={require('./images/ko.png')} />
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <br></br>
                <Input
                    onChange={(e) => {
                        set_id(e.target.value)
                    }}
                    size='large' placeholder='Your Email' />
                <br />
                <Input
                    onChange={(e) => {
                        set_pwd(e.target.value)

                    }}
                    size='large' type="password" placeholder='Password' />
                <br />
                {/* <a href="/ForgotPassword">Forgot Password?</a> */}
                <a href="javascript:void(0)" onClick={() => EmailMyPassword()}>Email my password</a>
                <br></br>
                <div style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                    <Button
                        style={{ width: 340, fontSize: 16 }}
                        onClick={() => {
                            submit()
                        }} positive>Login</Button>
                </div>
                <div style={{ alignSelf: 'center', display: loading ? 'flex' : 'none' }}>
                    <Button
                        style={{ width: 340, fontSize: 16 }}
                        loading positive>
                        Loading
                    </Button>
                </div>

            </div>
            <NotificationContainer></NotificationContainer>
        </Container>
    )
}



export default Login