import React, { useEffect, useState } from "react";
const axios = require("axios");

const IsSEZAddress = (props) => {
  console.log(props.invid)
  // alert(props.invid);
  //const [details,Set_details ] = useState("")
  const [ARNNo, Set_ARNNo] = useState("");
  const [ARNNoDate, Set_ARNNoDate] = useState("");

  const callForDetails = () => {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=14&invid=" + props.invid)
      .then(function (response) {
        response.data.map((i, index) => {
          Set_ARNNo(i.ARNNo);
          Set_ARNNoDate(i.ARNDate);
          console.log(i.ARNNo)

        });
      })
      .catch(function (error) {
        alert(error);
      });
  };

  useEffect(() => {
    callForDetails();
  }, []);

  return (
    <>
      <span>
        Supply meant for supply to SEZ unit for Authorised Operations under letter of undertaking Application Reference Number (ARN) {ARNNo} Dated -
        {ARNNoDate} without payment of integrated Tax @18%
      </span>
    </>
  );
};
export default IsSEZAddress;
