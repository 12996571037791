import React, { Component } from 'react'
import { Icon, Label } from "semantic-ui-react";

const Logout = props => { 
    function logout() {
        //alert('logout')
        localStorage.clear();
        window.location.href = window.location.origin;
      }
    return (
        <a href="javascript:void(0)">
            <Label
                onClick={() => {
                    logout();
                }}
            >
                {" "}
                    Logout {"\u00A0"} <Icon color="orange" name="power" />{" "}
            </Label>
        </a>
    )
}

export default Logout
