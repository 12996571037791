import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Chart } from "react-google-charts";
import { Paper } from "@material-ui/core";
import { Table } from "@material-ui/core";
import { TableBody } from "@material-ui/core";
import { TableContainer } from "@material-ui/core";
import { TableHead } from "@material-ui/core";
import { TablePagination } from "@material-ui/core";
import { TableRow } from "@material-ui/core";
import { TableCell } from "@material-ui/core";
import { TableSortLabel } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { makeStyles, Checkbox } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Input,
  Button,
  Modal,
  Header,
  Icon,
  Loader,
  Segment,
  TextArea,
  Dropdown,
} from "semantic-ui-react";
import PropTypes from "prop-types";
import IndustryDropDown from "../reusable/industrydropdown";
import CountryDropDown from "../reusable/CountryDropDownById";
import ClientDropDown from "../reusable/ClientRegCorp";
import CCEList from "../reusable/CCEList";
import SalesManagerDDL from "../reusable/SalesManagerDDL";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import * as XLSX from 'xlsx';
import ExcelExport from 'react-excel-export';
import "react-toastify/dist/ReactToastify.css";
import "../rajkumar/Custom.css";
import { NotificationContainer, NotificationManager } from "react-notifications";

const axios = require("axios");
const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 14,
  },
}));

function BSTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}
const headCells = [
  { id: "UserName", numeric: false, disablePadding: false, label: "CCE" },
  { id: "URL", numeric: false, disablePadding: false, label: "URL" },
  { id: "TotalNR", numeric: true, disablePadding: false, label: "TotalNR" },
  { id: "TotalNR1", numeric: true, disablePadding: false, label: "TotalNR1" },
  { id: "Frequency", numeric: true, disablePadding: false, label: "Frequency" },
  {
    id: "Clients",
    numeric: true,
    disablePadding: false,
    label: "Clients",
    disableSorting: true,
  },
  {
    id: "CountryName",
    numeric: false,
    disablePadding: false,
    label: "Country",
  },

  // {
  //   id: "PotentialRevenue",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Potential Revenue",
  //   disableSorting: true,
  // },

  // {
  //   id: "NoofEmployee",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "# of Employee",
  //   disableSorting: true,
  // },

  {
    id: "Actions",
    numeric: false,
    disablePadding: false,
    label: "Actions",
    disableSorting: true,
  },
];
const headCells1 = [
  {
    id: "AssignmentId",
    numeric: false,
    disablePadding: false,
    label: "AssignmentId",
  },
  { id: "Date", numeric: false, disablePadding: false, label: "Date" },
  { id: "Course", numeric: false, disablePadding: false, label: "Course" },
  { id: "Feedback", numeric: false, disablePadding: false, label: "Feedback" },
];
const headCellTenPlus = [
  { id: "CCE", numeric: false, disablePadding: false, label: "CCE" },
  { id: "URL", numeric: false, disablePadding: false, label: "URL" },
  {
    id: "NR",
    numeric: false,
    disablePadding: false,
    label: "NR",
  },
];
const headCellScs = [
  { id: "SCId", numeric: false, disablePadding: false, label: "SCId" },
  { id: "Client", numeric: false, disablePadding: false, label: "Client" },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
  {
    id: "TotalNR",
    numeric: false,
    disablePadding: false,
    label: "TotalNR",
  },
];


const headCellQuot = [
  { id: "QuotationId", numeric: false, disablePadding: false, label: "QuotationId" },
  { id: "Quotation_status", numeric: false, disablePadding: false, label: "Quotation status" },
  {
    id: "Client",
    numeric: false,
    disablePadding: false,
    label: "Client",
  },
  {
    id: "TotalNR",
    numeric: false,
    disablePadding: false,
    label: "TotalNR",
  },
  {
    id: "Pax",
    numeric: false,
    disablePadding: false,
    label: "No Of Pax",
  },
  {
    id: "Date",
    numeric: false,
    disablePadding: false,
    label: "Date",
  },
];

const headCellMedianData = [
  { id: "CceName", numeric: false, disablePadding: false, label: "CCE" },
  { id: "Manager", numeric: false, disablePadding: false, label: "Manager" },
  { id: "NoOfCorporate", numeric: true, disablePadding: false, label: "NoOfCorporate" },
  {
    id: "TotalRegCorpNR",
    numeric: false,
    disablePadding: false,
    label: "TotalNR",
  },
  {
    id: "AverageNR_perRegularCorporate",
    numeric: false,
    disablePadding: false,
    label: "AverageNR",
  },
  {
    id: "MedianNR",
    numeric: false,
    disablePadding: false,
    label: "MedianNR",
  },
];
const headCellMOMs = [
  { id: "Date", numeric: false, disablePadding: false, label: "Date" },
  { id: "Remarks", numeric: false, disablePadding: false, label: "Remarks" },
];
const headCellClients = [
  { id: "Name", numeric: false, disablePadding: false, label: "Name" },
  { id: "Email", numeric: false, disablePadding: false, label: "Email" },
];
const headCellClientsTemp = [
  { id: "Name", numeric: false, disablePadding: false, label: "Name" },
  {
    id: "Actions",
    numeric: false,
    disablePadding: false,
    label: "Action",
    disableSorting: true,
  },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}


function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function FeedbackTable(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCells1.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
FeedbackTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function TenPlusTable(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellTenPlus.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
TenPlusTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function MedianTable(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellMedianData.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function SCsTable(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellScs.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
SCsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};


function QuotationTable(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellQuot.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
QuotationTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function MOMsTable(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellMOMs.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
MOMsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function URLClientsTable(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellClients.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
URLClientsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

function URLClientsTableTemp(props) {
  const { classes } = props;
  return (
    <TableHead>
      <TableRow>
        {headCellClientsTemp.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
URLClientsTableTemp.propTypes = {
  classes: PropTypes.object.isRequired,
};

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    classes,
    // onSelectAllClick,
    order,
    orderBy,
    // numSelected,
    // rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (

          <TableCell
            key={headCell.id === "TotalNR" ? "TotalNR1" : headCell.id}
            // align={headCell.numeric ? "right" : "left"}
            align="center"
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={
              orderBy === (headCell.id === "TotalNR" ? "TotalNR1" : headCell.id)
                ? order
                : false
            }
            style={
              headCell.id === "TotalNR1" ? { display: "none" } : { display: "" }
            }
          >
            {headCell.disableSorting ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy ===
                    (headCell.id === "TotalNR" ? "TotalNR1" : headCell.id)
                    ? order
                    : "desc"
                }
                onClick={createSortHandler(
                  headCell.id === "TotalNR" ? "TotalNR1" : headCell.id
                )}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "asc" ? "sorted ascending" : "sorted descending"}
                  </span>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  // numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.object.isRequired,
  // rowCount: PropTypes.number.isRequired,
};

export default function RCB() {
  const [loading, set_loading] = useState(true);
  const [data_list, set_data_list] = useState([]);
  const [openaddcorporate, setOpenAddCorporate] = useState(false);
  const [openeditcorporate, setOpenEditCorporate] = useState(false);
  const [openfeedback, setOpenFeedback] = useState(false);
  const [modalFeedback, modalFeedbackData] = useState([]);
  const [opengraph, setOpenGraph] = useState(false);
  const [modalgraphdata, modalGraphData] = useState([]);
  const [opentenplus, setOpenTenPlus] = useState(false);
  const [modalTenPlus, modalTenPlusData] = useState([]);
  const [openscs, setOpenSCs] = useState(false);
  const [openQuot, setOpenQuot] = useState(false);
  const [MedianData, Set_OpenMedianData] = useState(false);
  const [modalMedianData, setmodalMedianData] = useState([]);
  const [modalSCs, modalSCsData] = useState([]);
  const [modalQuotation, modalQuotationData] = useState([]);
  const [openmoms, setOpenMOMs] = useState(false);
  const [openexpand, setOpenExpand] = useState(false);
  const [openSensitive, setOpenSensitive] = useState(false);
  const [modalMOMs, modalMOMsData] = useState([]);
  const [useremailid, UserEmailId] = useState(null);
  const [username, UserName] = useState(null);
  const [openclientsforurl, setOpenClientsForUrl] = useState(false);
  const [modalClientsForUrl, modalClientsForUrlData] = useState([]);
  const [txtareareserve, txtAreaReserve] = useState("");
  const [sensitiveReason, setSensitiveReason] = useState("");
  const [show_list, set_show_list] = React.useState("none");
  const [searchResults, setSearchResults] = React.useState([]);
  const [setclientexistornot, Set_ClientExistOrNot] = useState({});
  const [approvalcode, Set_ApprovalCode] = useState({});
  const [openapproval, Set_OpenApproval] = useState(false);
  const [openfaq, Set_OpenFAQ] = useState(false);
  const [faq, Set_FAQ] = useState([]);

  const initialList = [];

  const [tempclient, TempClient] = useState(initialList);
  const [urlcce, UrlCce] = useState([]);

  function handleRemovetemp(id) {
    const newList = tempclient.filter((x) => x.id !== id);
    if (newList != null) TempClient(newList);
  }

  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [page, setPage] = useState(0);
  const [imgupload, setImageUpload] = useState(false);
  const [imgupload1, setImageUpload1] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [file, setFile] = useState("");

  const [urlId, set_UrlId] = useState(0);
  const [NoOfDays, set_NoOfDays] = useState(0);
  const [LastCCE, set_LastCCE] = useState(0);
  const [cceEmailId, set_cceEmailId] = useState("");
  const [industry, set_industrylist] = useState([]);
  const [industryId, set_IndustryId] = useState(0);
  const [industryname, set_IndustryName] = useState("");
  const [country, set_country] = useState([]);
  const [countryId, set_CountryId] = useState(0);
  const [countryname, set_CountryName] = useState("");
  const [selectedCountryId, setSelectedCountryId] = useState("0")
  const [cce, set_CCE] = useState([]);
  const [cceid, set_CCEId] = useState(0);
  const [ccename, set_CCEName] = useState("");
  const [managerid, set_MANAGERId] = useState(0);
  const [managername, set_MANAGERName] = useState("");
  const [clientid, set_ClientId] = useState(0);
  const [clientname, set_ClientName] = useState("");
  const [withoutcce, withOutCCE] = useState({});
  const [corporatescbutnotregularcorporate, CorporateScButNotRegularCorporate] = useState({});
  const [freedomain, freeDomain] = useState({});
  const [url, setUrl] = useState("");
  const [approval_code, setApprovalCode] = useState("");
  const [commonname, set_CommonName] = useState("");
  const [forrevival, set_ForRevival] = useState({});
  const [marquee, set_Marquee] = useState({});
  const [empanel, set_Empanel] = useState({});
  const [noempanel, set_NoEmpanel] = useState({});
  const [data1, set_data1] = useState([]);
  const [month, set_Month] = useState({});
  const [nr, set_NR] = useState({});
  const [nrLess, set_NRLess] = useState({});
  const [userid, UserId] = useState(0);
  let fileInput = React.createRef();
  const [source, set_source] = useState(null);
  const [ismanager, Set_IsManager] = useState({});
  const [PotentialRevenu, set_PotentialRevenu] = useState(0);
  const [NoofEmp, set_NoofEmp] = useState(0);
  const [VenddorCode, set_VendorCode] = useState("");

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const recordsAfterPagingAndSorting = () => {
    return stableSort(
      filterFn.fn(data_list),
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, (page + 1) * rowsPerPage);
  };

  const [rows, setRows] = useState([]);

  const handleIncrement = (rowIndex) => {
    const newRows = [...rows];
    newRows[rowIndex].Frequency += 1;
    setRows(newRows);
  }

  const handleDecrement = (rowIndex) => {
    const newRows = [...rows];
    if (newRows[rowIndex].Frequency > 0) {
      newRows[rowIndex].Frequency -= 1;
    }
    setRows(newRows);
  }


  function getDownloadPP(Path) {
    // alert(Path);
    // if (Path != null && Path != "")
    //     return (
    //         <img className="ml-5" src={'http://koshish.koenig-solutions.com/api/yusuf/get_img?file_name=icon-balck.png'} alt='DP'></img>
    //     )
    debugger;
    if (Path != null && Path != "") {
      axios
        .get("rajkumar/get_img?file_name=" + Path + "", {
          responseType: "arraybuffer",
        })
        .then((response) => {
          debugger;
          const base64 = btoa(
            new Uint8Array(response.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          set_source("data:;base64," + base64);
        });
    }
  }

  const handleSearch = (e) => {
    let target = e.target;
    let filter_data = data1.filter((d) => {
      //console.log(d.CountryName)
      if (d.CountryName !== null) {
        return (
          d.UserName.toLowerCase().includes(target.value.toLowerCase()) ||
          d.URL.toLowerCase().includes(target.value.toLowerCase()) || d.CountryName.toLowerCase().includes(target.value.toLowerCase())
        );
      }
      else {
        return (
          d.UserName.toLowerCase().includes(target.value.toLowerCase()) ||
          d.URL.toLowerCase().includes(target.value.toLowerCase())
        );
      }
    });
    set_data_list(filter_data);
  };

  // const handleMoreThanNumber = (e) => {
  //   let target = e.target;

  //   let filter_data = [];
  //   if (target.value != "") {
  //     filter_data = data1.filter((x) => {
  //       return parseInt(x.TotalNR) > parseInt(target.value);
  //     });
  //   } else {
  //     filter_data = data1;
  //     return filter_data;
  //   }
  //   set_data_list(filter_data);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data_list.length - page * rowsPerPage);

  useEffect(() => {
    let d = localStorage.getItem("emp_data");
    if (d != null) {
      UserEmailId(JSON.parse(d).email_id);
      UserName(JSON.parse(d).UserName);
      UserId(JSON.parse(d).user_id);
    }
    set_loading(true);

    if (JSON.parse(d).CountryId == 93) {
      get_data(2, 0, "", 0, "", 0, 0, 0, 12, 0, 0, 0, 0);
    }
    else {
      setSelectedCountryId(JSON.parse(d).CountryId)
      get_data(2, 0, "", 0, "", JSON.parse(d).CountryId, 0, 0, 12, 0, 0, 0, 0);
    }
    get_country();
    get_industry();
    get_cce();

    withOutCCE({ withoutcce: false });
    CorporateScButNotRegularCorporate({ corporatescbutnotregularcorporate: false });
    freeDomain({ freedomain: false });
    set_ForRevival({ forrevival: false });
    set_Marquee({ marquee: false });
    set_Empanel({ empanel: false });
    set_NoEmpanel({ noempanel: false });
    set_Month({ month: 0 });
    set_NR({ nr: 0 });
    set_NRLess({ nrLess: 0 });
    Set_ClientExistOrNot({ setclientexistornot: false });
    Set_ApprovalCode({ approvalcode: false });
    Set_IsManager({ ismanager: false });
    //setImageUpload({ imgupload: false });
  }, []);

  // useEffect(() => {
  //   debugger;
  //   Set_IsManager({ismanager:false});
  // }, [ismanager.ismanager]);
  // useEffect(() => {
  //   debugger;
  //   Set_IsManager({ismanager: true});
  // }, [!ismanager.ismanager]);


  useEffect(() => {
    Set_ClientExistOrNot({ setclientexistornot: false });
  }, [setclientexistornot.setclientexistornot]);

  useEffect(() => {
    if (setclientexistornot.setclientexistornot === true) {
      alert("this client linked with another corporate");
      handleRemovetemp(clientid);
      return;
    }
  }, [!setclientexistornot.setclientexistornot]);

  useEffect(() => {

    Set_ApprovalCode(true);
  }, [approvalcode.approvalcode]);

  // useEffect(() => {
  //   debugger;
  //   setImageUpload(true);
  // }, [imgupload]);

  useEffect(() => {
    if (approvalcode.approvalcode === true) {
      alert("you must enter valid code for approval");
      return;
    }
  }, [!approvalcode.approvalcode]);

  // useEffect(() => {
  //   set_loading(true);
  //   get_country();
  //   get_industry();
  //   get_cce();

  //   withOutCCE({ withoutcce: false });
  //   freeDomain({ freedomain: false });
  //   set_ForRevival({ forrevival: false });
  //   set_Marquee({ marquee: false });
  //   set_Empanel({ empanel: false });
  //   set_NoEmpanel({ noempanel: false });
  //   set_Month({ month: 0 });
  //   set_NR({ nr: 0 });
  //   Set_ClientExistOrNot({ setclientexistornot: false });
  //   Set_ApprovalCode({ approvalcode: false });
  //   set_SaveData({ savedata: false });
  //   get_data(2, 0, "", 0, "", 0, 0, 0, 12, 0);
  //   set_loading(false);
  // }, [!savedata.savedata]);

  // Handles file upload event and updates state
  // function handleUpload(event) {
  //
  //   setFile(event.target.files[0]);
  //   saveRCLogo(event.target.files[0]);
  // }

  const handleChangelogo = (e) => {
    debugger;
    const file = e.target.files[0]; // accesing file
    setFile(file); // storing file
    console.warn("hello : " + file.name);
    setImageUpload1(true);
  };

  const handleUpload = () => {
    const file_data = new FormData();
    file_data.append("smfile", file);
    setImageUpload(true);

    axios
      .post("rajkumar/upload", file_data, {
        // headers: {
        //   'Content-Type': 'multipart/form-data'
        // }
      })
      .then((response) => {
        if (response.status.toString() === "200") {
          getDownloadPP(file.name);
          // setFile(response.data);
          // console.log("handleUpload :" +e.target.files[0])
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  //Component to display thumbnail of image
  // const ImageThumb = ({ image }) => {
  //
  //   return (
  //     <img
  //       id="saveImgrcbLogo"
  //       src={URL.createObjectURL(image)}
  //       alt={image.name}
  //       onClick={onclick}
  //     />
  //   );
  // };

  const handleRemove = () => {
    setFile("");
    set_source(null);
    fileInput.current.value = null;
  };

  function get_cce() {
    axios
      .get("priya/sp_ClientTrackingProject?type=1")
      .then(function (response) {
        set_CCE(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function get_industry() {
    await axios
      .get("rajkumar/GetIndustryList")
      .then(function (response) {
        set_industrylist(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function get_country() {
    await axios
      .get("rajkumar/GetCountryById?Type=3")
      .then(function (response) {
        set_country(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }
  // a

  async function get_FAQ() {
    debugger;
    set_loading(true);
    try {
      let { data } = await axios.get("rajkumar/GetFAQ?Type=16");
      if (data.length > 0) {
        Set_FAQ(data[0]["HtmlContent"].toString());
        Set_OpenFAQ(true);
        set_loading(false);
      } else {
        //alert("NO DATA");

        Set_OpenFAQ(true);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function get_Excel() {
    debugger;
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/SeeAllRCB?Type=18" +
        "&CCE=" +
        cceid +
        "&URL=" +
        url +
        "&withOutCCE=" +
        (withoutcce.withoutcce ? 1 : 0) +
        "&email=''" +
        "&CountryId=" +
        countryId +
        "&FreeDomain=" +
        (freedomain.freedomain ? 1 : 0) +
        "&ForRevival=" +
        (forrevival.forrevival ? 1 : 0) +
        "&Month=" +
        (month.month === 0 ? 12 : month.month) +
        "&NR=" +
        (nr.nr === 0 ? 0 : nr.nr) +
        "&Manager=" +
        managerid +
        ""
      );
      // get_data(
      //   2,
      //   cceid,
      //   url,
      //   withoutcce.withoutcce ? 1 : 0,
      //   "",
      //   countryId,
      //   freedomain.freedomain ? 1 : 0,
      //   forrevival.forrevival ? 1 : 0,
      //   month.month === 0 ? 12 : month.month,
      //   nr.nr === 0 ? 0 : nr.nr,
      //   managerid
      // );
      // console.log(data);
      // const fileName = 'example.xlsx';

      // const exportFile = new ExcelExport();
      // exportFile.addSheet(JSON.stringify(data), 'Sheet 1');
      // exportFile.save(fileName);
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "DataSheet.xlsx");
      set_loading(false);
    } catch (er) {
      //console.log('ashish');
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function save_data(objPost) {
    var objPost1 = {
      Type: "13",
      objPost: objPost,
    };
    console.log(IsManager(objPost[0]._cceid, objPost[0]._userid));
    set_loading(true);
    let { data } = await axios.post(
      "rajkumar/SaveAllRCBData",
      JSON.parse(JSON.stringify(objPost1))
    );
    console.log(data[0].Output);
    if (data[0].Output == 'More Than Fifty') {
      alert("You can not add more than 50 corporate");
      set_loading(false);
      get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
    }
    else if (data[0].Output == 'Below Median NR') {
      alert("You can not add more than 20 corporate as your average NR per regular corporate is less than 7 Lacs.");
      set_loading(false);
      get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
    }
    else if (data[0].Output == 'Resigned') {
      alert("You can't claim corporates in resignation period");
      set_loading(false);
      get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
    }
    else if (data[0].Output !== 'Yes') {
      alert("The Client " + data[0].Output + " is already mapped with other CCE’s Corporate.Action completed. NR will be updated shortly via Script. Please check after 1.5 hour");
      set_loading(false);
      get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
    }
    else {
      if (data.length > 0) {
        alert("Action completed. NR will be updated shortly via Script. Please check after 1.5 hour");
        set_loading(false);
        get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
      } else {
        alert("Action completed. NR will be updated shortly via Script. Please check after 1.5 hour");
        set_loading(false);
      }
    }
  }

  async function saveRCLogo(file) {
    if (file !== null && file !== "" && file !== "undefined") {
      const file_data = new FormData();
      file_data.append("sampleFile", file);

      await axios.post("yusuf/upload", file_data, {}).then((res) => {
        // if (res.status == 200) {
        //   setFile(res.data);
        // }
      });
    }
  }

  async function get_data(
    Type,
    CCE,
    URL,
    withOutCCE,
    email,
    CountryId,
    FreeDomain,
    ForRevival,
    Month,
    NR,
    NR_Less,
    Manager,
    CorporateScButNotRegularCorporate
  ) {
    console.log(NR_Less);
    let { data } = await axios.get(
      "rajkumar/SeeAllRCB?Type=" +
      Type +
      "&CCE=" +
      CCE +
      "&URL=" +
      URL +
      "&withOutCCE=" +
      withOutCCE +
      "&email=" +
      email +
      "&CountryId=" +
      CountryId +
      "&FreeDomain=" +
      FreeDomain +
      "&ForRevival=" +
      ForRevival +
      "&Month=" +
      Month +
      "&NR=" +
      NR +
      "&Manager=" +
      Manager +
      "&NR_Less=" +
      NR_Less +
      "&corporatescbutnotregularcorporate=" +
      CorporateScButNotRegularCorporate +
      ""
    );

    if (data.length > 0) {
      set_loading(false);
      set_data_list(data);
      set_data1(data);
    } else {
      //alert("NO DATA");
      set_loading(false);
      set_data_list(data);
      set_data1(data);
    }
  }

  async function getFeedback(URL) {
    set_loading(true);
    try {
      let { data } = await axios.get("rajkumar/Feedbacks?URL=" + URL + "");
      if (data.length > 0) {
        modalFeedbackData(data);
        setOpenFeedback(true);
        set_loading(false);
      } else {
        //alert("NO DATA");

        modalFeedbackData(data);
        setOpenFeedback(true);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function getTenPlus(GreaterThenAmount) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/Usp_TotalNRperYear?GreaterThenAmount=" +
        GreaterThenAmount +
        ""
      );
      if (data.length > 0) {
        modalTenPlusData(data);
        setOpenTenPlus(true);
        set_loading(false);
      } else {
        modalTenPlusData(data);
        setOpenTenPlus(true);
        //alert("NO DATA");
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function get_MedianData() {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/MedianData"
      );
      if (data.length > 0) {
        setmodalMedianData(data);
        Set_OpenMedianData(true);
        set_loading(false);
      } else {
        setmodalMedianData(data);
        Set_OpenMedianData(true);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function getModalSCs(Type, URL, CCE, month) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/SCs?Type=" + Type + "&URL=" + URL + "&CCE=" + CCE + "&Month=" + (month.month === 0 ? 12 : month.month) + ""
      );
      if (data.length > 0) {
        modalSCsData(data);
        setOpenSCs(true);
        set_loading(false);
      } else {
        modalSCsData(data);
        setOpenSCs(true);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function getModalQuotation(Type, URL, CCE, month) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/Quotation?Type=" + Type + "&URL=" + URL + "&CCE=" + CCE + "&Month=" + (month.month === 0 ? 12 : month.month) + ""
      );
      if (data.length > 0) {
        modalQuotationData(data);
        setOpenQuot(true);
        set_loading(false);
      } else {
        modalQuotationData(data);
        setOpenQuot(true);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function getModalMOMs(CceId, Url) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/MOMs?CceId=" + CceId + "&Url=" + Url + ""
      );
      if (data.length > 0) {
        modalMOMsData(data);
        setOpenMOMs(true);
        set_loading(false);
      } else {
        modalMOMsData(data);
        setOpenMOMs(true);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function deleteCorporate(CceId, Url) {
    set_loading(true);
    try {
      // let { data } = await axios.get(
      //   "rajkumar/DeleteCorporateRCB?CceId=" + CceId + "&Url=" + Url + "&Type=19" + ""
      // );
      if (window.confirm("Do you want to delete corporate?") == true) {

        alert('Corporate deleted succesfully');
        let { data } = await axios.get(
          "rajkumar/DeleteCorporateRCB?CceId=" + CceId + "&Url=" + Url + "&Type=19" + ""
        );
        get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
        set_loading(false);
      } else {
        set_loading(false);
      }

    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  const hideAlert = () => {
    ReactDOM.render("", document.getElementById('getSweetModel'));
  }

  async function marksensitive(Url, row) {
    set_loading(true);
    try {
      console.log(sensitiveReason + ' @ ' + Url + " @ " + row)

      let { data } = await axios.get(
        "rajkumar/sensitiveCorporateRCB?Url=" + Url + "&Type=21" + "&Reason=" + sensitiveReason + ""
      );
      get_data(2, 0, "", 0, "", 0, 0, 0, 12, 0, 0, 0, 0);
      alert('Corporate marked sensitive succesfully');
      set_loading(false);

    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  // function getSweetalert(Url, row) {
  //   ReactDOM.render(<SweetAlert
  //     title="Sensitive Reason"
  //     customClass="sweetAlertWidth50"
  //     showCloseButton={true}
  //     confirmBtnText="Save"
  //     cancelBtnText="Close"
  //     confirmBtnBsStyle="secondary"
  //     onConfirm={() => marksensitive(Url, row)}
  //     onCancel={() => { hideAlert(); }}>
  //     <div id="FeedbackContent"><TextArea
  //       id="txtareaClaim"
  //       rows={2}
  //       placeholder="Mention reason you want to acquire this corporate"
  //       style={{
  //         width: '100%',
  //         padding: '10px',
  //         borderRadius: '5px',
  //         border: '1px solid #ccc',
  //         fontFamily: 'Arial, sans-serif',
  //         fontSize: '14px',
  //         lineHeight: '1.6',
  //       }}
  //       onChange={handleSensitiveReason}
  //     /></div>
  //   </SweetAlert>, document.getElementById('getSweetModel'))
  // }


  const ViewReason = (Reason) => {
    // ReactDOM.render(<SweetAlert
    //   title="Sensitive Reason"
    //   customClass="sweetAlertWidth50"
    //   showCloseButton={true}
    //   cancelBtnText="Close"
    //   onCancel={() => { hideAlert(); }}>
    //   <div id="FeedbackContent">{Reason}</div>
    // </SweetAlert>, document.getElementById('getSweetModel'))

    ReactDOM.render(<SweetAlert title="Sensitive Reason" customClass="sweetAlertWidth50"
      onConfirm={() => hideAlert()} onCancel={() => hideAlert()}><div id="FeedbackContent"><h2>{Reason == '' ? 'Not Available' : Reason}</h2></div></SweetAlert>, document.getElementById('getSweetModel'))
  }


  async function getModalUrlCCEs(Type, URLId) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/UrlCCEs?Type=" + Type + "&URLId=" + URLId + ""
      );
      if (data.length > 0) {
        UrlCce(data);
        // setOpenSCs(true);
        // set_loading(false);
      } else {
        UrlCce(data);
        // alert("NO DATA");
        // set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function getModalGraph(Type, CCE, URL) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/Graph?Type=" + Type + "&CCE=" + CCE + "&URL=" + URL + ""
      );
      if (data.length > 0) {
        const json = data;
        const currentYear = new Date().getFullYear();
        const yearNames = [(currentYear - 3).toString(), (currentYear - 2).toString(), (currentYear - 1).toString(), (currentYear).toString()];
        const chartData = [
          ["Month", ...yearNames, "URLAlloc"],
        ];

        for (let i = 0; i < json.length; i++) {
          chartData.push([
            json[i].Months,
            parseInt(json[i].NR1, 10),
            parseInt(json[i].NR2, 10),
            parseInt(json[i].NR3, 10),
            parseInt(json[i].NR4, 10),
            parseInt(json[i].CCEAlloc, 10),
          ]);
        }
        modalGraphData(chartData);
        setOpenGraph(true);
        set_loading(false);
      } else {
        setOpenGraph(true);
        //alert("NO DATA");
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function getModalClientsForURL(Type, Url, CCEId) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/ClientsForURL?Type=" +
        Type +
        "&Url=" +
        Url +
        "&CCEId=" +
        CCEId +
        ""
      );

      if (data.length > 0) {
        modalClientsForUrlData(data);
        setOpenClientsForUrl(true);
        set_loading(false);
      } else {
        //alert("NO DATA");
        modalClientsForUrlData(data);
        setOpenClientsForUrl(true);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function getClientsForRC(Type, UrlId, CCE) {
    try {
      let { data } = await axios.get(
        "rajkumar/ClientsForRC?Type=" +
        Type +
        "&UrlId=" +
        UrlId +
        "&CCE=" +
        CCE +
        ""
      );

      if (data.length > 0) {
        TempClient(data);
      } else {
        //alert("NO DATA");
        TempClient(data);
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function search(d) {
    if (d.length != "") {
      set_show_list("");
      setSearchResults.value = null;
      let serstr = "";

      await axios
        .get("rajkumar/GetClient?Email=" + d.toString() + "")
        .then(function (response) {
          setSearchResults(response.data);
        })
        .catch(function (error) {
          alert(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  async function ClientExistOrNot(ClientId) {
    try {
      let { data } = await axios.get(
        "rajkumar/ClientExistsOrNot?Type=9&ClientId=" + ClientId + ""
      );
      if (data.length > 0) {
        Set_ClientExistOrNot({ setclientexistornot: true });
      }
      // else {
      //   Set_ClientExistOrNot({ setclientexistornot: false });
      //}
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function ApprovalCodeToAddRegCorp(ApprovalCode, CCE) {
    try {
      let { data } = await axios.get(
        "rajkumar/ApprovalCodeToAddRegCorp?Type=12&ApprovalCode=" +
        ApprovalCode +
        "&CCE=" +
        CCE +
        ""
      );
      if (data.length > 0) {
        setOpenAddCorporate(true);
        Set_OpenApproval(false);
      }
      // else {
      //   Set_ClientExistOrNot({ setclientexistornot: false });
      //}
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  // async function sendMailForReserve(
  //   _uname,
  //   // _upass,
  //   _to,
  //   _cc,
  //   _bcc,
  //   _subject,
  //   _html
  // ) {
  //   console.log(_html);
  //   try {
  //         await axios.post(
  //           "rajkumar/sendemail?_uname=" +
  //           _uname +
  //           // "&_upass=" +
  //           // _upass +
  //           "&_to=" +
  //           _to +
  //           "&_cc=" +
  //           _cc +
  //           "&_bcc=" +
  //           _bcc +
  //           "&_subject=" +
  //           _subject +
  //           "&_html=" +
  //           _html +
  //           ""
  //         )
  //     //alert("mail sent");
  //   } catch (er) {
  //     console.log("error");
  //   }
  // }

  async function transferCorporate(corporateid, userid) {
    set_loading(true);
    try {
      let { data } = await axios.get(
        "rajkumar/TransferCorporateRCB?CceId=" + userid + "&Url=" + corporateid + "&Type=20" + ""
      );
      get_data(2, 0, "", 0, "", 0, 0, 0, 12, 0, 0, 0, 0);
      set_loading(false);
    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }

  async function sendMailForReserve(uname, to, cc, bcc, subject, html) {
    console.log(cc)
    try {
      var obj = {
        _uname: uname,
        _to: to,
        _cc: cc,
        _bcc: bcc,
        _subject: subject,
        _html: html
      };

      let { data } = await axios.post("Nishant/sendemail", obj);
      if (data != null) {
        NotificationManager.success("Mail sent successfully");
        set_loading(false);
      }
    } catch (er) {
      //alert(JSON.stringify(er));
      set_loading(false);
    }
  }


  async function IsManager(UserId1, UserId) {
    try {
      debugger;
      let { data } =
        await axios.get(
          "rajkumar/ManagerOrNot?Type=17&UserId1=" + UserId1 + "&UserId=" + UserId + ""
        )
      // .then(()=>{
      debugger;
      if (data.length > 0) {
        ismanager.ismanager = true;
        //alert("helo1")
        //alert(data.length);
      }
      else {
        ismanager.ismanager = false;
      }
      // }).catch((er)=>{
      //     alert(JSON.stringify(er));
      //     set_loading(false);
      //   })


    } catch (er) {
      alert(JSON.stringify(er));
      set_loading(false);
    }
  }


  const handleTextAreaReserve = (e) => {
    txtAreaReserve(e.target.value);
    setUrl(url);
  };

  const handleSensitiveReason = (e) => {
    setSensitiveReason(e.target.value);
  }

  const handleInputURL = (e) => {
    setUrl(e.target.value);
  };

  const handleInputApprovalCode = (e) => {
    setApprovalCode(e.target.value);
  };

  const handleInputDeleteClick = (e) => {
    set_ClientName("");
    set_show_list("none");
    setSearchResults([]);
  };

  const handleInputCommonName = (e) => {
    set_CommonName(e.target.value);
  };

  const handleInputUrl = (e) => {
    setUrl(e.target.value);
  }
  const handleInputPotential = (e) => {
    set_PotentialRevenu(e.target.value);
  };
  const handleInputNoofEmployee = (e) => {
    set_NoofEmp(e.target.value);
  };

  const handleInputVendorCode = (e) => {
    set_VendorCode(e.target.value);
  }


  const edit = (data) => {
    debugger;
    set_source(null);
    setUrl(data.URL);
    set_IndustryId(data.IndustryId);
    set_CountryId(data.CountryId);
    set_CCEId(data.UserId);
    set_CommonName(data.CorporateName);
    set_Empanel({ empanel: data.Empaneled });
    set_Marquee({ marquee: data.Marquee });
    getClientsForRC("7", data.CorporateID, userid);
    setFile(data.RCLogo);
    set_UrlId(data.CorporateID);
    Set_ClientExistOrNot({ setclientexistornot: false });
    getDownloadPP(data.RCLogo);
    set_PotentialRevenu(data.PotentialRevenue);
    set_NoofEmp(data.NoofEmployee);
    set_VendorCode(data.VendorCode);
    setOpenEditCorporate(true);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
      flexGrow: 1,
      padding: theme.spacing(2),
      borderTopWidth: 1,
      borderColor: "red",
      borderStyle: "solid",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(0),
      textAlign: "center",
      color: theme.palette.text.secondary,
      backgroundColor: "#313F45",
      color: "#FFF",
      margin: theme.spacing(1),
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    container: {
      //maxHeight: "70vh",
      width: "95vw",
    },
    searchInput: {
      float: "left",
      width: "18rem",
    },
    tablePagination: {
      width: "65rem",
    },
    divStyle: {
      float: "left",
      width: "32vw",
      margin: "20px",
    },
    tableCell: {
      display: "inline-flex",
      //maxWidth: "30vw",
    },
  }));

  const classes = useStyles();
  // class="ui raised segment"
  return (

    <div>
      {/************************************ LODER **************************************** */}
      <div
        style={{
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          width: "80vw",
          flexDirection: "column",
          height: "90vh",
        }}
      >
        <Loader active inline size="massive">
          getting data...
        </Loader>
      </div>
      <div id="getSweetModel"></div>

      <div style={{ display: loading ? "none" : "flex" }}>
        <div class="ui main container" style={{ width: "95vw", margin: 10 }}>
          <Header dividing>
            <div style={{ display: "flex", marginTop: 10 }}>
              <div style={{ width: "15vw" }}>
                <Header as="h3" content="New Regular Corporate" />
              </div>
              <div style={{ width: "10vw" }}>
                <Button
                  onClick={() => {
                    userid.toString() === "188" ||
                      userid.toString() === "221" ||
                      userid.toString() === "4562"
                      ? setOpenAddCorporate(true)
                      : setOpenAddCorporate(true)
                    //: Set_OpenApproval(true);
                  }}
                  size="mini"
                >
                  <Icon color="teal" name="plus" />
                  Add
                </Button>
              </div>
              <div style={{ width: "10vw" }}>
                <Button
                  onClick={() => {
                    get_FAQ();
                  }}
                  size="mini"
                >
                  <Icon color="teal" name="question" />
                  FAQ
                </Button>
              </div>
              <div style={{ width: "10vw" }}>
                <Button
                  onClick={() => {
                    get_Excel();
                  }}
                  size="mini"
                >

                  Export to excel
                </Button>
              </div>
              <div style={{ width: "10vw" }}>
                <Button
                  onClick={() => {
                    get_MedianData();
                  }}
                  size="mini"
                >
                  Median Data
                </Button>
              </div>
            </div>
            <Header as="h2" floated="right" style={{ marginTop: "-2%" }}>
              <Modal
                size="mini"
                open={openapproval}
                onClose={() => Set_OpenApproval(false)}
                closeIcon={<Icon color="red" name="window close" />}
                style={{
                  height: "28vh",
                  width: "35vw",
                  marginTop: "20vh",
                  marginLeft: "60vh",
                }}
              >
                <Modal.Header>Approval code to add corporate</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
                    <div style={{ height: "10vh", maxWidth: "15vw" }}>
                      <div
                        className={classes.divStyle}
                        style={{ marginBottom: "0px" }}
                      >
                        <Input
                          placeholder="Enter approval code"
                          style={{ width: "30vw" }}
                          onChange={handleInputApprovalCode}
                        />
                      </div>
                      <div className={classes.divStyle}>
                        <Button
                          icon
                          labelPosition="right"
                          floated="right"
                          size="mini"
                          style={{ marginRight: "3em" }}
                          onClick={() => {
                            if (
                              approval_code === null ||
                              approval_code === ""
                            ) {
                              alert("Approval code should not be blank");
                              return;
                            }
                            ApprovalCodeToAddRegCorp(approval_code, userid);
                          }}
                        >
                          Apply
                          <Icon name="save" />
                        </Button>
                      </div>
                    </div>
                  </Modal.Description>
                </Modal.Content>
              </Modal>
              <Modal
                open={openaddcorporate}
                onClose={() => {
                  setOpenAddCorporate(false);

                  setUrl("");
                  set_IndustryId(0);
                  set_Empanel(0);
                  set_CountryId(0);
                  set_Marquee(0);
                  setFile("");
                  set_CommonName("");
                  TempClient([]);
                  set_CCEId(0);
                  setOpenAddCorporate(false);
                  Set_ClientExistOrNot({ setclientexistornot: false });
                }}
                //onOpen={() => {setOpenAddCorporate(true)}}
                closeIcon={<Icon color="red" name="window close" />}
              // trigger={<Button icon="add" label="Add regular corporate" />}
              >
                <Modal.Header>Add Corporate</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
                    <div style={{ height: "70vh", maxWidth: "75vw" }}>
                      <div
                        className={classes.divStyle}
                        style={{ marginBottom: "0px" }}
                      >
                        <Input
                          placeholder="Enter URL"
                          style={{ width: "30vw" }}
                          onChange={handleInputURL}
                        />
                      </div>
                      <div className={classes.divStyle}>
                        <IndustryDropDown
                          scrolling
                          passChildData={(industryId) => {
                            set_IndustryId(industryId);
                          }}
                        />
                      </div>
                      <div className={classes.divStyle}>
                        <CCEList
                          scrolling
                          passChildData={(cceid) => {
                            set_CCEId(cceid);
                          }}
                        />
                      </div>
                      <div className={classes.divStyle}>
                        <CountryDropDown
                          scrolling
                          passChildData={(countryname, countryId) => {
                            set_CountryId(countryId);
                            set_CountryName(countryname);
                          }}
                        />
                      </div>
                      <div
                        className={classes.divStyle}
                        style={{ width: "20vw" }}
                      >
                        <label>Logo : </label>
                        <Button onClick={handleUpload} size="mini">
                          <Icon color="teal" name="upload" />
                        </Button>
                        <input
                          id="saveFilercbLogo"
                          type="file"
                          style={{ marginLeft: "1em" }}
                          onChange={(e) => {
                            handleChangelogo(e);
                          }}
                          ref={fileInput}
                        />
                      </div>
                      <div
                        className={classes.divStyle}
                        style={{
                          width: "10vw",
                          height: "10vh",
                          marginBottom: "0vh",
                        }}
                        onClick={() => handleRemove()}
                      >
                        {/* {getDownloadPP(file.name?file.name:file)} */}
                        {/* (file !== null &&
                                          file !== "undefined" &&
                                          file !== ""
                                          ? file.name
                                          : "") */}

                        {file && source && (
                          <img
                            id="updateImgrcbLogo"
                            src={source ? source : null}
                            alt={file ? file : null}
                            style={{
                              transform: "scale(5)!important",
                            }}
                          // onClick={onclick}
                          />
                        )}
                        {/* {file && (
                          <img id="saveImgrcbLogo" src={file} alt={file.name} />
                        )} */}
                      </div>

                      <div
                        className={classes.divStyle}
                        style={{ marginTop: "-10px", marginBottom: "0px" }}
                      >
                        <label>Common Name</label>
                        <Input
                          style={{ width: "30vw" }}
                          onChange={handleInputCommonName}
                        />
                      </div>

                      <div
                        className={classes.divStyle}
                        style={{ marginBottom: "0px" }}
                      >
                        <label>Potential Revenue</label>
                        <Input style={{ width: "30vw" }}
                          onChange={handleInputPotential} />
                      </div>
                      <div className={classes.divStyle}>
                        <label># of Employee</label>
                        <Input style={{ width: "30vw" }}
                          onChange={handleInputNoofEmployee
                          } />
                      </div>
                      <div
                        className={classes.divStyle}
                      >
                        <label>Vendor Code</label>
                        <Input
                          style={{ width: "30vw" }}
                          onChange={handleInputVendorCode}

                        />

                      </div>

                      <div
                        class="custom-control custom-checkbox custom-control-inline"
                        className={classes.divStyle}
                      >
                        <div
                          style={{ visibility: "hidden" }}
                          class="custom-control custom-checkbox custom-control-inline"
                        >
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="addforRevival"
                          />
                          <label
                            class="custom-control-label"
                            for="addforRevival"
                          >
                            For Revival
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="addmarquee"
                            onChange={() => {
                              set_Marquee({ marquee: !marquee.marquee });
                            }}
                          />
                          <label class="custom-control-label" for="addmarquee">
                            Marquee
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="addEmpanelment"
                            onChange={() => {
                              set_Empanel({ empanel: !empanel.empanel });
                            }}
                          />
                          <label
                            class="custom-control-label"
                            for="addEmpanelment"
                          >
                            Empanelment done
                          </label>
                        </div>
                        <div class="custom-control custom-checkbox custom-control-inline">
                          <input
                            type="checkbox"
                            class="custom-control-input"
                            id="addNotEmpanelment"
                          />
                          <label
                            class="custom-control-label"
                            for="addNotEmpanelment"
                          >
                            Empanelment not required
                          </label>
                        </div>
                      </div>
                      <div className={classes.divStyle}>
                        <div style={{ margin: 10 }}>
                          <div className="divSearchBoxDesktop">
                            <div className="divSearchBarDesktop">
                              <span className="SearchBarDesktop">
                                <Input
                                  onChange={(t) => {
                                    search(t.target.value);
                                    set_ClientName(t.target.value);
                                  }}
                                  value={clientname}
                                  //className="inputSearchDesktop"
                                  style={{ width: "500px" }}
                                  placeholder="Enter Contacts"
                                  icon={
                                    <Icon
                                      name="delete"
                                      link
                                      onClick={handleInputDeleteClick}
                                    />
                                  }
                                />
                              </span>
                              <span className="SearchBoxItemDesktop">
                                {searchResults.map((item) => {
                                  return (
                                    <span
                                      type="button"
                                      key={item.value}
                                      onClick={(e) => {
                                        set_ClientId(item.key);
                                        // set_ClientName(e.target.innerText);
                                        //ClientExistOrNot(item.value);
                                        // if (
                                        //   setclientexistornot.setclientexistornot ===
                                        //   true
                                        // ) {
                                        //   alert(
                                        //     "this client linked with another corporate"
                                        //   );
                                        //   return;
                                        // }

                                        //ddd[0].URL

                                        const newList = tempclient.concat({
                                          name: item.text,
                                          id: item.value,
                                        });

                                        const newList1 = newList.filter(
                                          (x, i) => x.name !== ""
                                        );

                                        let mymap = new Map();

                                        const newList2 = newList1.filter(
                                          (el) => {
                                            const val = mymap.get(el.name);

                                            if (val) {
                                              if (el.id < val) {
                                                mymap.delete(el.name);
                                                mymap.set(el.name, el.id);
                                                return true;
                                              } else {
                                                return false;
                                              }
                                            }
                                            mymap.set(el.name, el.id);
                                            return true;
                                          }
                                        );

                                        TempClient(newList2);

                                        // set_ClientId(0);
                                        // set_ClientName("");
                                        set_show_list("none");
                                        setSearchResults([]);
                                      }}
                                      className="listSearchDesktop"
                                    >
                                      {item.text}
                                    </span>
                                  );
                                })}
                              </span>
                              <div className="divSelectNumberDesktop"></div>
                            </div>
                          </div>
                        </div>

                        {/* <ClientDropDown
                          scrolling
                          passChildData={(clientname, clientid) => {
                            set_ClientId(clientid);
                            set_ClientName(clientname);

                            const newList = tempclient.concat({
                              name: clientname,
                              id: clientid,
                            });

                            const newList1 = newList.filter(
                              (x, i) => x.name !== ""
                            );

                            let mymap = new Map();

                            const newList2 = newList1.filter((el) => {
                              const val = mymap.get(el.name);

                              if (val) {
                                if (el.id < val) {
                                  mymap.delete(el.name);
                                  mymap.set(el.name, el.id);
                                  return true;
                                } else {
                                  return false;
                                }
                              }
                              mymap.set(el.name, el.id);
                              return true;
                            });

                            TempClient(newList2);

                            set_ClientId(0);
                            set_ClientName("");
                          }}
                        /> */}
                        <Table
                          id="PopUpTempClients"
                          className="table table-bordered table-responsive table-striped"
                          size="sm"
                          stickyHeader
                        >
                          <URLClientsTableTemp />
                          <TableBody>
                            {tempclient.map((d) => {
                              return (
                                <TableRow hover key={d.id}>
                                  <TableCell
                                    component="th"
                                    // id={labelId}
                                    scope="row"
                                    padding="none"
                                    align="center"
                                  >
                                    {d.name}
                                  </TableCell>
                                  <TableCell>
                                    <Button
                                      onClick={() => handleRemovetemp(d.id)}
                                      icon
                                      size="mini"
                                      style={{ marginRight: "3em" }}
                                    >
                                      <Icon name="close icon" />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </div>
                      <div className={classes.divStyle}>
                        <Button
                          icon
                          labelPosition="right"
                          floated="right"
                          size="huge"
                          style={{ marginRight: "3em" }}
                          onClick={() => {
                            if (url === null || url === "") {
                              alert("URL should not be blank");
                              return;
                            }

                            if (
                              (file !== null &&
                                file !== "undefined" &&
                                file !== "") &&
                              ((!imgupload && imgupload1) || (imgupload && !imgupload1))
                            ) {
                              alert("you should click Upload before save as you have select an image file");
                              return;
                            }

                            //saveRCLogo(file);

                            // set_source(null);

                            let tblSaveRCB = [];

                            let objSaveRCB = {
                              _URLId: 0,
                              _Url: url,
                              _industryId: industryId !== null ? industryId : 0,
                              _empanel: empanel.empanel ? 1 : 0,
                              _countryId: countryId !== null ? countryId : 0,
                              _marquee: marquee.marquee ? 1 : 0,
                              _file:
                                file !== null &&
                                  file !== "undefined" &&
                                  file !== ""
                                  ? file.name
                                  : "",
                              _userid: userid !== null ? userid : 0,
                              _commonname:
                                commonname !== null ? commonname : "",
                              _tempclient: JSON.stringify(tempclient),
                              _cceid: cceid !== null ? cceid : 0,
                              _PotentialRevenu: PotentialRevenu !== null ? PotentialRevenu : 0,
                              _NoofEmp: NoofEmp !== null ? NoofEmp : 0,
                              _VendorCode: VenddorCode !== null ? VenddorCode : "",
                            };
                            tblSaveRCB.push(objSaveRCB);
                            save_data(tblSaveRCB);
                            // (
                            //   "5",
                            //   0,
                            //   url,
                            //   industryId !== null ? industryId : 0,
                            //   empanel.empanel ? 1 : 0,
                            //   countryId !== null ? countryId : 0,
                            //   marquee.marquee ? 1 : 0,
                            //   file !== null &&
                            //     file !== "undefined" &&
                            //     file !== ""
                            //     ? file.name
                            //     : "",
                            //   userid !== null ? userid : 0,
                            //   commonname !== null ? commonname : "",
                            //   JSON.stringify(tempclient),
                            //   cceid !== null ? cceid : 0
                            // );
                            // alert("action completed");

                            // set_loading(false);
                            setUrl("");
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                            Set_ClientExistOrNot({
                              setclientexistornot: false,
                            });
                            setImageUpload(false);
                            setImageUpload1(false);
                          }}
                        >
                          Save
                          <Icon name="save" />
                        </Button>
                      </div>
                    </div>
                  </Modal.Description>
                </Modal.Content>
              </Modal>

              <Modal
                open={openfaq}
                onClose={() => {
                  Set_OpenFAQ(false);
                }}
                closeIcon={<Icon color="red" name="window close" />}
              >
                <Modal.Header>FAQ</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
                    <div style={{ height: "70vh", maxWidth: "75vw" }}>
                      <div dangerouslySetInnerHTML={{ __html: faq }} />
                    </div>
                  </Modal.Description>
                </Modal.Content>
              </Modal>

              <Modal
                open={MedianData}
                onClose={() => {
                  Set_OpenMedianData(false);
                }}
                closeIcon={<Icon color="red" name="window close" />}
              >
                <Modal.Header>Corporate Median Data</Modal.Header>
                <Modal.Content scrolling>
                  <Modal.Description>
                    <TableContainer
                      component={Paper}
                    // className={classes.container}
                    >
                      <Table
                        id="MedianData"
                        className="table table-bordered table-responsive table-striped"
                        size="sm"
                        stickyHeader
                      >
                        <MedianTable />
                        <TableBody>
                          {modalMedianData.map((d) => {
                            return (
                              <TableRow key={d.id} hover>
                                <TableCell
                                  component="th"
                                  // id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  {d.CCE}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  // id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  {d.Manager}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  // id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  {d.No_Of_Corporate}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  // id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  {d.TotalRegCorpNR}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  // id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  {<div dangerouslySetInnerHTML={{ __html: d.AverageNR_perRegularCorporate }} />}
                                </TableCell>
                                <TableCell
                                  component="th"
                                  // id={labelId}
                                  scope="row"
                                  padding="none"
                                  align="center"
                                >
                                  {d.MedianNR}
                                </TableCell>
                              </TableRow>
                            );
                          })}

                          {modalMedianData.length === 0 && (
                            <TableRow>
                              {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                              <TableCell
                                colSpan={4}
                                component="th"
                                scope="row"
                                padding="none"
                                textAlign="center"
                                style={{ fontSize: "2rem" }}
                              >
                                No data
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Modal.Description>
                </Modal.Content>
              </Modal>


            </Header>
          </Header>
          <div style={{ marginBottom: 10, display: "flex", width: "95vw" }} className="row">
            <span
              style={{
                fontSize: 12,
                fontWeight: "bolder",
                marginTop: 10,
                display: "none",
              }}
            >
              <br />
              <Checkbox
                color="primary"
                checked={forrevival.forrevival}
                onChange={() => {
                  set_ForRevival({ forrevival: !forrevival.forrevival });
                }}
              />
              Under Revival
            </span>


            <span
              style={{
                fontSize: 12,
                fontWeight: "bolder",
                margin: 10,
                display: "none",
              }}
            >
              <br />
              <Checkbox
                color="primary"
                checked={freedomain.freedomain}
                onChange={() => {
                  freeDomain({ freedomain: !freedomain.freedomain });
                }}
              // onChange={() => {
              //   set_loading(true);
              //   get_data(
              //     2,
              //     cceid,
              //     "",
              //     withoutcce.withoutcce ? 1 : 0,
              //     "",
              //     countryId,
              //     !freedomain.freedomain ? 1 : 0
              //   );
              //   freeDomain({ freedomain: !freedomain.freedomain });
              // }}
              />
              Only free domain
            </span>

            <div style={{ fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <div>
                <CountryDropDown
                  scrolling
                  passChildData={(countryname, countryId) => {
                    set_CountryId(countryId);
                    set_CountryName(countryname);
                    setSelectedCountryId(countryId);
                  }}
                  selectedValue={selectedCountryId}
                />
              </div>
            </div>

            <div style={{ fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <div>
                <CCEList
                  scrolling
                  passChildData={(cceid) => {
                    set_CCEId(cceid);
                  }}
                />
              </div>
            </div>

            <div style={{ fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <div>
                <SalesManagerDDL
                  scrolling
                  passChildData={(managerid) => {
                    set_MANAGERId(managerid);
                  }}
                />
              </div>
            </div>

            <div style={{ fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <div>
                <TextField
                  variant="outlined"
                  label="NR more than"
                  placeholder="0"
                  fullWidth
                  onChange={(e) => {
                    set_NR({
                      nr: e.target.value === "" ? 0 : parseInt(e.target.value),
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      padding: '1.5px 8px', // Set padding here
                    }
                  }}
                >
                  <InputLabel style={{ padding: '1.5px 8px' }} shrink htmlFor="nrl-more-than">
                    NR more than
                  </InputLabel>
                </TextField>
                {/* NR more than
                <br />
                <Input
                  placeholder="0"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    set_NR({
                      nr: e.target.value === "" ? 0 : parseInt(e.target.value),
                    });
                  }}
                /> */}
              </div>
            </div>

            <div style={{ fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <div>
                <TextField
                  variant="outlined"
                  label="NR less than"
                  placeholder="0"
                  fullWidth
                  onChange={(e) => {
                    set_NRLess({
                      nrLess: e.target.value === "" ? 0 : parseInt(e.target.value),
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      padding: '1.5px 8px', // Set padding here
                    }
                  }}
                >
                  <InputLabel style={{ padding: '1.5px 8px' }} shrink htmlFor="nrl-less-than">
                    NR less than
                  </InputLabel>
                </TextField>
              </div>
            </div>

            <div style={{ fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <div>
                <TextField
                  variant="outlined"
                  label="Last Months"
                  placeholder="12"
                  fullWidth
                  onChange={(e) => {
                    set_Month({
                      month:
                        e.target.value === "" ? 12 : parseInt(e.target.value),
                    });
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style: {
                      padding: '1.5px 8px', // Set padding here
                    }
                  }}
                >
                  <InputLabel style={{ padding: '1.5px 8px' }} shrink htmlFor="nrl-months">
                    Last Months
                  </InputLabel>
                </TextField>
                {/* Last Months
                <br />
                <Input
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    set_Month({
                      month:
                        e.target.value === "" ? 12 : parseInt(e.target.value),
                    });
                  }}
                  placeholder="12"
                /> */}
                {/* <b>Months</b> */}
              </div>
            </div>

            <div style={{ fontSize: 10, fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <Checkbox
                color="primary"
                checked={withoutcce.withoutcce}
                onChange={() => {
                  withOutCCE({ withoutcce: !withoutcce.withoutcce });
                }}
              />
              W/O any CCE
            </div>

            <div style={{ fontSize: 10, fontWeight: "bolder", marginTop: 10 }} className="col-md-4 col-lg-3 col-xl-3 col-sm-6 col-12">
              <Checkbox
                color="primary"
                checked={corporatescbutnotregularcorporate.corporatescbutnotregularcorporate}
                onChange={() => {
                  CorporateScButNotRegularCorporate({ corporatescbutnotregularcorporate: !corporatescbutnotregularcorporate.corporatescbutnotregularcorporate });
                }}
              />
              List Of Corporate SCs But Not Regular Corporate
            </div>
            <div style={{ marginRight: "auto" }} className="col-md-12 col-lg-12 col-xl-12 col-sm-12 col-12">
              <br />
              <Button
                onClick={() => {
                  set_loading(true);

                  get_data(
                    2,
                    cceid,
                    url,
                    withoutcce.withoutcce ? 1 : 0,
                    "",
                    countryId,
                    freedomain.freedomain ? 1 : 0,
                    forrevival.forrevival ? 1 : 0,
                    month.month === 0 ? 12 : month.month,
                    nr.nr === 0 ? 0 : nr.nr,
                    nrLess.nrLess === 0 ? 0 : nrLess.nrLess,
                    managerid,
                    corporatescbutnotregularcorporate.corporatescbutnotregularcorporate ? 1 : 0
                  );
                }}
                size="mini"
                style={{ float: "right" }}
              >
                <Icon color="teal" name="filter" />
                Display
              </Button>
            </div>


          </div>
          <p><font style={{ color: "red" }}>**</font>When selling to a new prospect there is a 5 to 20 percent chance of making the sale. When selling to an existing customer, that probability skyrockets to 60 to 70 percent. Focusing on existing customers makes it easier to sell and business.</p>
          <div className="row" style={{ marginBottom: "10px" }}>
            <div className="col-md-12">
              <br />
              <Input
                onChange={handleSearch}
                icon="search"
                placeholder="Search..."
                style={{ float: 'right' }}
              />
            </div>
          </div>
          {/* <div> */}
          <Segment style={{ display: "none" }}>
            <Modal
              open={opentenplus}
              onClose={() => setOpenTenPlus(false)}
              closeIcon={<Icon color="red" name="window close" />}
              onOpen={() => setOpenFeedback(true)}
              trigger={
                <BSTooltip title="Corporates with 10 lacs plus" size="small">
                  <Button
                    onClick={() => {
                      getTenPlus("1000000");
                    }}
                    style={{ width: "12vw", float: "left" }}
                    ui
                    attached
                    size="mini"
                  >
                    Corporates with 10 lacs plus
                  </Button>
                </BSTooltip>
              }
            >
              <Modal.Header>10 lacs plus details</Modal.Header>
              <Modal.Content scrolling>
                <Modal.Description>
                  <TableContainer
                    component={Paper}
                    className={classes.container}
                  >
                    <Table
                      id="PopUpTenPlus"
                      size="small"
                      aria-label="a sticky dense table"
                      stickyHeader
                    >
                      <TenPlusTable />
                      <TableBody>
                        {modalTenPlus.map((d) => {
                          return (
                            <TableRow key={d.id} hover>
                              <TableCell
                                component="th"
                                // id={labelId}
                                scope="row"
                                padding="none"
                                align="center"
                              >
                                {d.UserName}
                              </TableCell>
                              <TableCell align="center">{d.URL}</TableCell>
                              <TableCell align="center">{d.TotalNR}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Modal.Description>
              </Modal.Content>
            </Modal>
          </Segment>
          {/* </div> */}


          <div className={classes.container}>
            <TableContainer component={Paper} className={classes.container}>
              {/* <EnhancedTableToolbar numSelected={data_list.length} /> */}

              <Table
                id="MainData"
                className="table table-bordered table-responsive table-striped"
                size="sm"
                stickyHeader
                style={{ width: "95vw" }}
              >
                <EnhancedTableHead
                  classes={classes}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {recordsAfterPagingAndSorting().map((row) => (
                    <TableRow key={row.id} hover>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        textAlign="center"
                      >
                        {row.UserName}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        textAlign="center"
                      >
                        {row.URL}
                        <br />
                        <b>{row.CorporateName}</b>
                        <b dangerouslySetInnerHTML={{ __html: row.Sensitive }}></b><br></br>
                        <a style={{ display: row.Sensitive ? 'initial' : 'none' }} className="badge btn btn-outline-primary" onClick={() => ViewReason(row.SensitiveReason)}>View Reason</a>
                      </TableCell>
                      <TableCell component="td" scope="row" padding="none">
                        {row.TotalNR}
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        padding="none"
                        style={{ display: "none" }}
                      >
                        {row.TotalNR1}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        textAlign="center"
                      >
                        {row.Frequency}

                      </TableCell>


                      <Modal
                        open={openclientsforurl}
                        onClose={() => {
                          setOpenClientsForUrl(false);
                          setUrl("");
                          set_IndustryId(0);
                          set_Empanel(0);
                          set_CountryId(0);
                          set_Marquee(0);
                          setFile("");
                          set_CommonName("");
                          TempClient([]);
                          set_CCEId(0);
                          setOpenAddCorporate(false);
                        }}
                        closeIcon={<Icon color="red" name="window close" />}
                        trigger={
                          <BSTooltip
                            title="click to see client details"
                            size="small"
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="none"
                              textAlign="center"
                            >
                              <label
                                style={{
                                  color: "#071af4",
                                  textDecoration: "underline",
                                }}
                                onClick={() => {
                                  getModalClientsForURL(
                                    4,
                                    row.CorporateID,
                                    userid
                                  );
                                  setUrl(row.URL);
                                  set_PotentialRevenu(row.PotentialRevenue);
                                  set_NoofEmp(row.NoofEmployee);
                                  //alert("heelo");
                                }}
                              >
                                {row.totalContact}
                              </label>
                            </TableCell>
                          </BSTooltip>
                        }
                      >
                        <Modal.Header>Clients for {url}</Modal.Header>
                        <Modal.Content scrolling>
                          <Modal.Description>
                            <TableContainer
                              component={Paper}
                              className={classes.container}
                            >
                              <Table
                                id="PopUpFeedback"
                                className="table table-bordered table-responsive table-striped"
                                size="sm"
                                stickyHeader
                              >
                                <URLClientsTable />
                                <TableBody>
                                  {modalClientsForUrl.map((d) => {
                                    return (
                                      <TableRow key={d.id} hover>
                                        <TableCell
                                          component="th"
                                          // id={labelId}
                                          scope="row"
                                          padding="none"
                                          align="center"
                                        >
                                          {d.ClientName}
                                        </TableCell>
                                        <TableCell align="center">
                                          {d.ClientEmail}
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                  {modalClientsForUrl.length === 0 && (
                                    <TableRow>
                                      {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                                      <TableCell
                                        colSpan={3}
                                        component="th"
                                        scope="row"
                                        padding="none"
                                        textAlign="center"
                                        style={{ fontSize: "2rem" }}
                                      >
                                        No data
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Modal.Description>
                        </Modal.Content>
                      </Modal>

                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        textAlign="center"
                        style={{ width: "4vw !important" }}
                      >
                        {row.CountryName}
                      </TableCell>

                      {/* <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        textAlign="center"
                        style={{ width: "4vw !important" }}
                      >
                        {row.PotentialRevenue}
                      </TableCell> */}
                      {/* <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        textAlign="center"
                        style={{ width: "4vw !important" }}
                      >
                        {row.NoofEmployee}
                      </TableCell> */}

                      <TableCell className={classes.tableCell}>
                        <Modal
                          open={openeditcorporate}
                          onClose={() => {
                            setOpenEditCorporate(false);
                            setUrl("");
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                          }}
                          // onOpen={() => setOpenEditCorporate(true)}
                          closeIcon={<Icon color="red" name="window close" />}
                          trigger={
                            <BSTooltip title="Edit" size="small">
                              <Button
                                className="ui attached"
                                size="mini"
                                onClick={async () => {
                                  await IsManager(row.UserId ? row.UserId.toString() : '', userid.toString() || '');
                                  // console.log(row.UserId);
                                  // console.log(userid);
                                  (userid.toString() === "4562" ||
                                    userid.toString() === "4669" ||
                                    userid.toString() === "221" ||
                                    userid.toString() === "4595" ||
                                    userid.toString() === "4819" ||
                                    userid.toString() === "1574" ||
                                    userid.toString() === "6167" ||
                                    row.UserId == null || userid.toString() === row.UserId.toString() ||
                                    ismanager.ismanager)
                                    ? edit(row)
                                    : alert("Not your corporate");
                                }}
                              >
                                Edit
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>Update Corporate</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              <div style={{ height: "70vh", maxWidth: "75vw" }}>
                                <div
                                  className={classes.divStyle}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <Input
                                    style={{ width: "30vw" }}
                                    value={url}
                                    // disabled
                                    onChange={handleInputUrl}
                                  />
                                </div>
                                <div className={classes.divStyle}>
                                  <Dropdown
                                    placeholder="Select Industry"
                                    fluid
                                    search
                                    scrolling
                                    value={industryId}
                                    onChange={(e, { value }) => {
                                      set_IndustryId(value);
                                      set_IndustryName(e.target.text);
                                    }}
                                    options={industry}
                                    selection
                                    clearable
                                  />
                                </div>
                                <div className={classes.divStyle}>
                                  <Dropdown
                                    placeholder="Select CCE"
                                    fluid
                                    search
                                    scrolling
                                    value={cceid}
                                    onChange={(e, { value }) => {
                                      set_CCEId(value);
                                      set_CCEName(e.target.text);
                                    }}
                                    selection
                                    options={cce}
                                    clearable
                                  />
                                </div>
                                <div className={classes.divStyle}>
                                  <Dropdown
                                    placeholder="Select Country"
                                    fluid
                                    search
                                    scrolling
                                    value={countryId}
                                    onChange={(e, { value }) => {
                                      set_CountryId(value);
                                      set_CountryName(e.target.text);
                                    }}
                                    selection
                                    options={country}
                                    clearable
                                  />
                                </div>
                                <div
                                  className={classes.divStyle}
                                  style={{ width: "20vw" }}
                                >
                                  <label>Logo : </label>
                                  <Button onClick={handleUpload} size="mini">
                                    <Icon color="teal" name="upload" />
                                  </Button>
                                  <input
                                    id="updateFilercbLogo"
                                    type="file"
                                    style={{ marginLeft: "1em" }}
                                    onChange={(e) => {
                                      handleChangelogo(e);
                                    }}
                                    ref={fileInput}
                                  //value=""
                                  />
                                </div>
                                {/* <div style={{ width: "5vw" }}>
                        <Button
                          onClick={() => {
                            
                          }}
                          size="mini"
                        >
                          <Icon color="teal" name="upload" />
                        </Button>
                      </div> */}
                                <div
                                  className={classes.divStyle}
                                  style={{
                                    width: "10vw",
                                    height: "10vh",
                                    marginBottom: "0vh",
                                  }}
                                  onClick={() => handleRemove()}
                                >
                                  {/* {file && <ImageThumb image={file} />} */}
                                  {/* {getDownloadPP(file.name?file.name:file)} */}
                                  {/* (file !== null &&
                                          file !== "undefined" &&
                                          file !== ""
                                          ? file.name
                                          : "") */}
                                  {file && source && (
                                    <img
                                      id="updateImgrcbLogo"
                                      src={source ? source : null}
                                      alt={file ? file : null}
                                      style={{
                                        transform: "scale(5)!important",
                                      }}
                                    // onClick={onclick}
                                    />
                                  )}
                                </div>


                                <div
                                  className={classes.divStyle}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <label>Common Name</label>
                                  <Input
                                    value={commonname}
                                    style={{ width: "30vw" }}
                                    onChange={handleInputCommonName}
                                  />
                                </div>
                                <div className={classes.divStyle}>
                                  <label>Potential Revenue</label>
                                  <Input
                                    style={{ width: "30vw" }}
                                    value={PotentialRevenu}
                                    onChange={handleInputPotential}

                                  />
                                </div>
                                <div
                                  className={classes.divStyle}
                                  style={{
                                    marginTop: "-10px",
                                    marginBottom: "0px",

                                  }}
                                >
                                  <label># of Employee</label>
                                  <Input
                                    style={{ width: "30vw" }}
                                    value={NoofEmp}
                                    onChange={handleInputNoofEmployee}

                                  />

                                </div>
                                <div
                                  className={classes.divStyle}
                                  style={{
                                    marginTop: "-10px",
                                    marginBottom: "0px",

                                  }}
                                >
                                  <label>Vendor Code</label>
                                  <Input
                                    style={{ width: "30vw" }}
                                    value={VenddorCode}
                                    onChange={handleInputVendorCode}

                                  />

                                </div>
                                <div
                                  class="custom-control custom-checkbox custom-control-inline"
                                  className={classes.divStyle}
                                >
                                  <div
                                    style={{ visibility: "hidden" }}
                                    class="custom-control custom-checkbox custom-control-inline"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="addforRevival"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="addforRevival"
                                    >
                                      For Revival
                                    </label>
                                  </div>
                                  <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="addmarquee"
                                      onChange={() => {
                                        set_Marquee({
                                          marquee: !marquee.marquee,
                                        });
                                      }}
                                      checked={marquee.marquee}
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="addmarquee"
                                    >
                                      Marquee
                                    </label>
                                  </div>
                                  <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="addEmpanelment"
                                      onChange={() => {
                                        set_Empanel({
                                          empanel: !empanel.empanel,
                                        });
                                      }}
                                      checked={empanel.empanel}
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="addEmpanelment"
                                    >
                                      Empanelment done
                                    </label>
                                  </div>
                                  <div class="custom-control custom-checkbox custom-control-inline">
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      id="addNotEmpanelment"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="addNotEmpanelment"
                                    >
                                      Empanelment not required
                                    </label>
                                  </div>
                                </div>
                                <div className={classes.divStyle}>
                                  <div style={{ margin: 10 }}>
                                    <div className="divSearchBoxDesktop">
                                      <div className="divSearchBarDesktop">
                                        <span className="SearchBarDesktop">
                                          <Input
                                            onChange={(t) => {
                                              search(t.target.value);
                                              set_ClientName(t.target.value);
                                            }}
                                            value={clientname}
                                            //className="inputSearchDesktop"
                                            style={{ width: "500px" }}
                                            placeholder="Enter Contacts"
                                            icon={
                                              <Icon
                                                name="delete"
                                                link
                                                onClick={handleInputDeleteClick}
                                              />
                                            }
                                          />
                                        </span>
                                        <span className="SearchBoxItemDesktop">
                                          <Table>
                                            {searchResults.map((item) => {
                                              return (
                                                <span
                                                  key={item.value}
                                                  onClick={() => {
                                                    set_ClientId(item.key);
                                                    // set_ClientName(e.target.innerText);
                                                    // ClientExistOrNot(
                                                    //   item.value
                                                    // );
                                                    // if (
                                                    //   setclientexistornot.setclientexistornot ===
                                                    //   true
                                                    // ) {
                                                    //   alert(
                                                    //     "this client linked with another corporate"
                                                    //   );
                                                    //   return;
                                                    // }

                                                    //ddd[0].URL

                                                    const newList = tempclient.concat(
                                                      {
                                                        name: item.text,
                                                        id: item.value,
                                                      }
                                                    );

                                                    const newList1 = newList.filter(
                                                      (x, i) => x.name !== ""
                                                    );

                                                    let mymap = new Map();

                                                    const newList2 = newList1.filter(
                                                      (el) => {
                                                        const val = mymap.get(
                                                          el.name
                                                        );

                                                        if (val) {
                                                          if (el.id < val) {
                                                            mymap.delete(
                                                              el.name
                                                            );
                                                            mymap.set(
                                                              el.name,
                                                              el.id
                                                            );
                                                            return true;
                                                          } else {
                                                            return false;
                                                          }
                                                        }
                                                        mymap.set(
                                                          el.name,
                                                          el.id
                                                        );
                                                        return true;
                                                      }
                                                    );

                                                    TempClient(newList2);

                                                    // set_ClientId(0);
                                                    // set_ClientName("");
                                                    set_show_list("none");
                                                    setSearchResults([]);
                                                  }}
                                                  className="listSearchDesktop"
                                                >
                                                  {item.text}
                                                </span>
                                              );
                                            })}
                                          </Table>
                                        </span>
                                        <div className="divSelectNumberDesktop"></div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <ClientDropDown
                                    scrolling
                                    passChildData={(clientname, clientid) => {
                                      set_ClientId(clientid);
                                      set_ClientName(clientname);

                                      const newList = tempclient.concat({
                                        name: clientname,
                                        id: clientid,
                                      });

                                      const newList1 = newList.filter(
                                        (x, i) => x.name !== ""
                                      );

                                      let mymap = new Map();

                                      const newList2 = newList1.filter((el) => {
                                        const val = mymap.get(el.name);

                                        if (val) {
                                          if (el.id < val) {
                                            mymap.delete(el.name);
                                            mymap.set(el.name, el.id);
                                            return true;
                                          } else {
                                            return false;
                                          }
                                        }
                                        mymap.set(el.name, el.id);
                                        return true;
                                      });

                                      TempClient(newList2);

                                      set_ClientId(0);
                                      set_ClientName("");
                                    }}
                                  /> */}
                                  <TableContainer
                                    component={Paper}
                                    style={{ maxHeight: 350 }}
                                  >
                                    <Table
                                      id="PopUpTempClients"
                                      className="table table-bordered table-responsive table-striped"
                                      size="sm"
                                      stickyHeader
                                    >
                                      <URLClientsTableTemp />
                                      <TableBody>
                                        {tempclient.map((d) => {
                                          return (
                                            <TableRow hover key={d.id}>
                                              <TableCell
                                                component="th"
                                                // id={labelId}
                                                scope="row"
                                                padding="none"
                                                align="center"
                                              >
                                                {d.name}
                                              </TableCell>
                                              <TableCell>
                                                <Button
                                                  onClick={() =>
                                                    handleRemovetemp(d.id)
                                                  }
                                                  icon
                                                  size="mini"
                                                  style={{ marginRight: "3em" }}
                                                >
                                                  <Icon name="close icon" />
                                                </Button>
                                              </TableCell>
                                            </TableRow>
                                          );
                                        })}
                                        {tempclient.length === 0 && (
                                          <TableRow>
                                            {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                                            <TableCell
                                              colSpan={2}
                                              component="th"
                                              scope="row"
                                              padding="none"
                                              textAlign="center"
                                              style={{ fontSize: "2rem" }}
                                            >
                                              No data
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </TableBody>
                                    </Table>
                                  </TableContainer>
                                </div>
                                <div className={classes.divStyle}>
                                  <Button
                                    icon
                                    labelPosition="right"
                                    floated="right"
                                    size="huge"
                                    style={{ marginRight: "3em" }}
                                    onClick={() => {
                                      if (url === null || url === "") {
                                        alert("URL should not be blank");
                                        return;
                                      }
                                      debugger;
                                      if (
                                        (file !== null &&
                                          file !== "undefined" &&
                                          file !== "") &&
                                        ((!imgupload && imgupload1) || (imgupload && !imgupload1))
                                      ) {
                                        alert(
                                          "you should click Upload before update as you have select an image file"
                                        );
                                        return;
                                      }

                                      // set_source(null);
                                      // saveRCLogo(file);

                                      let tblSaveRCB = [];

                                      let objSaveRCB = {
                                        _URLId: urlId,
                                        _Url: url,
                                        _industryId:
                                          industryId !== null ? industryId : 0,
                                        _empanel: empanel.empanel ? 1 : 0,
                                        _countryId:
                                          countryId !== null ? countryId : 0,
                                        _marquee: marquee.marquee ? 1 : 0,
                                        _file:
                                          file !== null &&
                                            file !== "undefined" &&
                                            file !== ""
                                            ? file.name
                                              ? file.name
                                              : file
                                            : "",
                                        _userid: userid !== null ? userid : 0,
                                        _commonname:
                                          commonname !== null ? commonname : "",
                                        _tempclient: JSON.stringify(tempclient),
                                        _cceid: cceid !== null ? cceid : 0,
                                        _PotentialRevenu: PotentialRevenu != null ? PotentialRevenu : 0,
                                        _NoofEmp: NoofEmp != null ? NoofEmp : 0,
                                        _VendorCode: VenddorCode != null ? VenddorCode : 0
                                      };
                                      tblSaveRCB.push(objSaveRCB);
                                      save_data(tblSaveRCB);
                                      // alert("action completed");
                                      // set_loading(false);
                                      setUrl("");
                                      set_IndustryId(0);
                                      set_Empanel(0);
                                      set_CountryId(0);
                                      set_Marquee(0);
                                      setFile("");
                                      set_CountryId("");
                                      TempClient([]);
                                      set_CCEId(0);
                                      setOpenEditCorporate(false);
                                      set_CommonName("");
                                      set_UrlId(0);
                                      setImageUpload(false);
                                      setImageUpload1(false);
                                    }}
                                  >
                                    Update
                                    <Icon name="save" />
                                  </Button>
                                </div>
                              </div>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>

                        <Modal
                          open={openexpand}
                          //onOpen={() => setOpenExpand(true)}
                          onClose={() => {
                            setOpenExpand(false);
                            setUrl("");
                            set_NoOfDays(0);
                            set_LastCCE(0);
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_cceEmailId('');
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                            get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
                          }}
                          closeIcon={<Icon color="red" name="window close" />}
                          trigger={
                            <BSTooltip title="Claim" size="small">
                              <Button
                                // icon="expand"
                                //label="I want to develop the corporate"
                                className="ui attached"
                                size="mini"
                                onClick={() => {
                                  console.log(row)
                                  setUrl(row.URL);
                                  set_UrlId(row.CorporateID)
                                  set_NoOfDays(row.NoOfDays)
                                  set_LastCCE(row.LastCCE)
                                  set_cceEmailId(row.CCEEmailId)
                                  //setOpenExpand(true);
                                  const confirmation = window.confirm('As per the new RC (Regular Corporate) Policy, kindly commit a minimum of 10 lac NR in the next 6 months or 50% more than your current commitment to proceed with the corporate claim.');

                                  if (confirmation) {
                                    setOpenExpand(true);
                                  } else {
                                    // Cancelled action
                                    console.log('Action cancelled.');
                                  }
                                }}
                              >
                                {row.UserId === null ? 'Claim & transfer' : 'claim'}
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>Dormant Corporate</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              <div>
                                <TextArea
                                  id="txtareaClaim"
                                  rows={2}
                                  placeholder="Mention reason you want to acquire this corporate"
                                  style={{
                                    width: "75vw",
                                    height: "55vh",
                                    margin: 23,
                                    fontSize: "26px",
                                    padding: "10px",
                                  }}
                                  onChange={handleTextAreaReserve}
                                />
                              </div>
                              <div>
                                <Button
                                  icon
                                  labelPosition="right"
                                  floated="right"
                                  size="huge"
                                  style={{ marginRight: "3em" }}
                                  onClick={() => {
                                    if (txtareareserve == '') {
                                      alert('please mention the reason.')
                                    }
                                    else {
                                      set_loading(true)
                                      setUrl(url);
                                      console.log("@ " + NoOfDays + " & " + row.UserId + " * " + row.TotalNR1 + " $ " + row.CorporateName)

                                      if (row.UserId === null && LastCCE != userid) {
                                        // console.log(urlId)
                                        // console.log(userid)
                                        transferCorporate(urlId, userid)
                                      }

                                      if ((row.UserId === null || NoOfDays >= 365) && LastCCE != userid) {
                                        console.log('inside mail sent')
                                        sendMailForReserve(
                                          useremailid,
                                          "CMs@koenig-solutions.com;cce@koenig-solutions.com;OverseasSales@koenig-solutions.com;flsales@koenig-solutions.com;DomesticSales@koenig-solutions.com;CorporateSales@koenig-solutions.com;",
                                          (row.UserId === null ? useremailid : useremailid + ";" + cceEmailId),
                                          "avishek.singh@koenig-solutions.com;maitri.rana@koenig-solutions.com;",
                                          (row.UserId === null ? username + ' has taken ' + url + ' as was without any CCE' : username + ' wants to take ' + url),
                                          (row.UserId === null ? ` Subjected Corporate is claimed by ` + username + ` and is auto Transferred as not owned by any CCE.<br>` : ` Subjected Corporate is being claimed by ` + username + `, who has expressed their desire to take ownership of it.<br> 
                                        If no objection is raised within 3 days,`+ username + ` you can take the ownership and get it transferred  to yourself.<br> Details on how to carry out the transfer can be found in the FAQ section of the RCB Panel.
                                        However, if an objection is raised within the 3-day period, take permission of your manager before the transfer<br>`) + ' CCE Remark - ' + txtareareserve
                                        );
                                      }
                                      if ((row.UserId === null || NoOfDays > 365) && LastCCE != userid)
                                        alert("Mail sent succesfully ");
                                      else if (LastCCE == userid) {
                                        alert("You can't claim this corporate as you were the last owner");
                                      }
                                      else
                                        alert("Corporate cannot be claimed as the creation or last claim date is less than 12 months.");
                                    }
                                  }}
                                >
                                  Send mail
                                  <Icon name="paper plane" />
                                </Button>
                              </div>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>

                        <Modal
                          open={openfeedback}
                          onClose={() => {
                            setOpenFeedback(false);
                            setUrl("");
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                          }}
                          closeIcon={<Icon color="red" name="window close" />}
                          // onOpen={() => setOpenFeedback(true)}
                          trigger={
                            <BSTooltip title="Feedback" size="small">
                              <Button
                                onClick={() => {
                                  //{() => setOpenFeedback(true)}

                                  getFeedback(row.CorporateID);
                                  setUrl(row.URL);
                                  //setOpenFeedback(true);
                                }}
                                // icon="comments"
                                // label="Feedback"
                                className="ui attached"
                                size="mini"
                              >
                                Feedback
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>Feedbacks for {url}</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              <TableContainer
                                component={Paper}
                                className={classes.container}
                              >
                                <Table
                                  id="PopUpFeedback"
                                  className="table table-bordered table-responsive table-striped"
                                  size="sm"
                                  stickyHeader
                                >
                                  <FeedbackTable />
                                  <TableBody>
                                    {modalFeedback.map((d) => {
                                      return (
                                        <TableRow key={d.id} hover>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.AssignmentId + d.Outstanding}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.Date}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.Course}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.Feedback}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {modalFeedback.length === 0 && (
                                      <TableRow>
                                        {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                                        <TableCell
                                          colSpan={6}
                                          component="th"
                                          scope="row"
                                          padding="none"
                                          textAlign="center"
                                          style={{ fontSize: "2rem" }}
                                        >
                                          No data
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>

                        <Modal
                          open={opengraph}
                          onClose={() => {
                            setOpenGraph(false);
                            setUrl("");
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                          }}
                          closeIcon={
                            <Icon
                              color="red"
                              name="window close"
                              size="massive"
                            />
                          }
                          trigger={
                            <BSTooltip title="Graph" size="small">
                              <Button
                                onClick={() => {
                                  getModalGraph(
                                    "6",
                                    row.UserId == null ? 0 : row.UserId,
                                    row.CorporateID
                                  );
                                  getModalUrlCCEs("8", row.CorporateID);
                                  setUrl(row.URL);
                                  //setOpenGraph(true);
                                }}
                                className="ui attached"
                                size="mini"
                              >
                                Graph
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>{url} Graph</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              {/* <Chart
                                width={"65vw"}
                                height={"60vh"}
                                chartType="Line"
                                loader={<div>Loading Chart</div>}
                                data={modalgraphdata}
                                options={{
                                  chart: {
                                    title: "Total NR",
                                    subtitle: " in Rupees(INR))",
                                  },
                                }}
                                rootProps={{ "data-testid": "4" }}
                              /> */}

                              <Chart
                                width={"65vw"}
                                height={"60vh"}
                                chartType="ComboChart"
                                loader={<div>Loading Chart</div>}
                                data={modalgraphdata}
                                options={{
                                  title: "Total NR  in Rupees(INR)",
                                  vAxis: { title: "NR" },
                                  hAxis: { title: "Month" },
                                  seriesType: "line",
                                  series: { 4: { type: "scatter" } },
                                }}
                                rootProps={{ "data-testid": "1" }}
                              />

                              <br />
                              <br />
                              <TableContainer component={Paper}>
                                <Table
                                  id="PopUpURLCCEs"
                                  className="table table-bordered table-responsive table-striped"
                                  size="sm"
                                  stickyHeader
                                  style={{ width: "46vw" }}
                                >
                                  <TableHead>
                                    <TableRow>
                                      <TableCell colSpan="3">
                                        Regular corporate allocated to CCE with
                                        From & To Date
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {urlcce.map((d) => {
                                      return (
                                        <TableRow hover>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.CCE}
                                          </TableCell>
                                          <TableCell align="center">
                                            {d.FDate}
                                          </TableCell>
                                          <TableCell align="center">
                                            {d.TDate}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {urlcce.length === 0 && (
                                      <TableRow>
                                        {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                                        <TableCell
                                          colSpan={3}
                                          component="th"
                                          scope="row"
                                          padding="none"
                                          textAlign="center"
                                          style={{ fontSize: "2rem" }}
                                        >
                                          No data
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>

                        <Modal
                          open={openscs}
                          onClose={() => {
                            setOpenSCs(false);
                            setUrl("");
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                          }}
                          closeIcon={<Icon color="red" name="window close" />}
                          // onOpen={() => setOpenFeedback(true)}
                          trigger={
                            <BSTooltip title="SCs" size="small">
                              <Button
                                onClick={() => {
                                  getModalSCs(1, row.CorporateID, userid, month);
                                  setUrl(row.URL);
                                }}
                                // icon="id card"
                                // label="SCs"
                                className="ui attached"
                                size="mini"
                              >
                                SCs
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>{url} student cards</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              <TableContainer
                                component={Paper}
                              // className={classes.container}
                              >
                                <Table
                                  id="PopUpSCs"
                                  className="table table-bordered table-responsive table-striped"
                                  size="sm"
                                  stickyHeader
                                >
                                  <SCsTable />
                                  <TableBody>
                                    {modalSCs.map((d) => {
                                      return (
                                        <TableRow key={d.id} hover>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.scid == null ? (
                                              "N/A"
                                            ) : (
                                              <a
                                                target="_blank"
                                                href={
                                                  "https://rms.koenig-solutions.com/Forms/CRM/student-card-prev.aspx?scid=" +
                                                  d.scid +
                                                  "&PDFTran=pdfwerw12312123xfcg"
                                                }
                                              >
                                                {d.scid}
                                              </a>
                                            )}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.ClientName}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.CreatedOn}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.TotalNR}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {modalSCs.length === 0 && (
                                      <TableRow>
                                        {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                                        <TableCell
                                          colSpan={4}
                                          component="th"
                                          scope="row"
                                          padding="none"
                                          textAlign="center"
                                          style={{ fontSize: "2rem" }}
                                        >
                                          No data
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>


                        <Modal
                          open={openQuot}
                          onClose={() => {
                            setOpenQuot(false);
                            setUrl("");
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                          }}
                          closeIcon={<Icon color="red" name="window close" />}
                          // onOpen={() => setOpenFeedback(true)}
                          trigger={
                            <BSTooltip title="Quotation" size="small">
                              <Button
                                onClick={() => {
                                  getModalQuotation(25, row.CorporateID, userid, month);
                                  setUrl(row.URL);
                                }}
                                // icon="id card"
                                // label="SCs"
                                className="ui attached"
                                size="mini"
                              >
                                Quot
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>{url} Quotations (Tentative)</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              <TableContainer
                                component={Paper}
                              // className={classes.container}
                              >
                                <Table
                                  id="PopUpSCs"
                                  className="table table-bordered table-responsive table-striped"
                                  size="sm"
                                  stickyHeader
                                >
                                  <QuotationTable />
                                  <TableBody>
                                    {modalQuotation.map((d) => {
                                      return (
                                        <TableRow key={d.id} hover>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.QuotationId}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.Quotation_status}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.Client}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.TotalNR}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.Pax}
                                          </TableCell>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.Date}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {modalQuotation.length === 0 && (
                                      <TableRow>
                                        {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                                        <TableCell
                                          colSpan={4}
                                          component="th"
                                          scope="row"
                                          padding="none"
                                          textAlign="center"
                                          style={{ fontSize: "2rem" }}
                                        >
                                          No data
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>

                        <Modal
                          open={openmoms}
                          onClose={() => {
                            setOpenMOMs(false);
                            setUrl("");
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                          }}
                          closeIcon={<Icon color="red" name="window close" />}
                          trigger={
                            <BSTooltip title="MOMs" size="small">
                              <Button
                                onClick={() => {
                                  getModalMOMs(row.UserId, row.CorporateID);
                                  setUrl(row.URL);
                                }}
                                // icon="tasks"
                                //label="MOMs"
                                className="ui attached"
                                size="mini"
                              >
                                MOMs
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>{url} meetings</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              <TableContainer
                                component={Paper}
                              // className={classes.container}
                              >
                                <Table
                                  id="PopUpMOMs"
                                  className="table table-bordered table-responsive table-striped"
                                  size="sm"
                                  stickyHeader
                                >
                                  <MOMsTable />
                                  <TableBody>
                                    {modalMOMs.map((d) => {
                                      return (
                                        <TableRow key={d.id} hover>
                                          <TableCell
                                            component="th"
                                            // id={labelId}
                                            scope="row"
                                            padding="none"
                                            align="center"
                                          >
                                            {d.MeetingDate}
                                          </TableCell>
                                          <TableCell align="center">
                                            {d.Remarks}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                    {modalMOMs.length === 0 && (
                                      <TableRow>
                                        {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                                        <TableCell
                                          colSpan={2}
                                          component="th"
                                          scope="row"
                                          padding="none"
                                          textAlign="center"
                                          style={{ fontSize: "2rem" }}
                                        >
                                          No data
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>
                        <Button
                          onClick={async () => {
                            var clogin = userid ? userid.toString() : '';
                            var rowUserid = row.UserId ? row.UserId.toString() : '';
                            await IsManager(row.UserId ? row.UserId.toString() : '', userid ? userid.toString() : '' || '');
                            // console.log(ismanager.ismanager);
                            // console.log(clogin === rowUserid);

                            (clogin === rowUserid || ismanager.ismanager)
                              ? deleteCorporate(clogin, row.CorporateID)
                              : alert("Not your corporate");
                          }}
                          // icon="tasks"
                          //label="MOMs"
                          className="ui attached"
                          size="mini"
                        >
                          Delete
                        </Button>
                        <Modal
                          open={openSensitive}
                          //onOpen={() => setOpenExpand(true)}
                          onClose={() => {
                            setOpenSensitive(false);
                            setUrl("");
                            set_NoOfDays(0);
                            set_IndustryId(0);
                            set_Empanel(0);
                            set_cceEmailId('');
                            set_CountryId(0);
                            set_Marquee(0);
                            setFile("");
                            set_CommonName("");
                            TempClient([]);
                            set_CCEId(0);
                            setOpenAddCorporate(false);
                            get_data(2, 0, "", 0, "", selectedCountryId, 0, 0, 12, 0, 0, 0, 0);
                          }}
                          closeIcon={<Icon color="red" name="window close" />}
                          trigger={
                            <BSTooltip title="Sensitive" size="small">
                              <Button
                                className="ui attached"
                                size="mini"
                                style={{ display: userid == 6521 ? 'block' : 'none' }}
                                onClick={() => {
                                  setUrl(row.URL);
                                  set_UrlId(row.CorporateID)
                                  set_NoOfDays(row.NoOfDays)
                                  set_cceEmailId(row.CCEEmailId)
                                  setOpenSensitive(true);
                                }}
                              >
                                Sensitive
                              </Button>
                            </BSTooltip>
                          }
                        >
                          <Modal.Header>Sensitive Reason</Modal.Header>
                          <Modal.Content scrolling>
                            <Modal.Description>
                              <div>
                                <TextArea
                                  id="txtareaSensitive"
                                  rows={2}
                                  placeholder="Mention reason you want to mark this corporate as sensitive"
                                  style={{
                                    width: "95%",
                                    height: "65vh",
                                    margin: 23,
                                    fontSize: "26px",
                                    padding: "10px",
                                  }}
                                  onChange={handleSensitiveReason}
                                />
                              </div>
                              <div style={{ textAlign: "center" }}>
                                <Button
                                  icon
                                  size="huge"
                                  onClick={() => {
                                    if (sensitiveReason == '') {
                                      alert('please mention the reason.')
                                    }
                                    else {
                                      //set_loading(true)
                                      setUrl(url);
                                      marksensitive(urlId, row);
                                    }
                                  }}
                                >
                                  Save
                                </Button>
                              </div>
                            </Modal.Description>
                          </Modal.Content>
                        </Modal>
                        {/* <Button style={{ display: userid == 6521 ? 'block' : 'none' }}
                          className="ui attached"
                          size="mini"
                          onClick={() => getSweetalert(row.CorporateID, row)}
                        >
                          Sensitive
                        </Button> */}
                      </TableCell>
                    </TableRow>
                  ))}

                  {emptyRows === 20 && (
                    <TableRow>
                      {/* style={{ height: (dense ? 33 : 53) * emptyRows }}> */}
                      <TableCell
                        colSpan={6}
                        component="th"
                        scope="row"
                        padding="none"
                        textAlign="center"
                        style={{ fontSize: "2rem" }}
                      >
                        No data
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              // className={classes.tablePagination}
              rowsPerPageOptions={[10, 100, 200, 400, data_list.length]}
              component="div"
              count={data_list.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
