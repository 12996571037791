import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";
import "./App.css";
import "semantic-ui-css/semantic.min.css";
import "@material-ui/core";
import Home from "./component/reusable/Home";
import Bucket from "./component/pushpendra/Bucket/Bucket";
import Conversion from "./component/pushpendra/Conversion/Conversion";
import Outstanding_Policy from "./component/pushpendra/OS Feedback/Outstanding_policy";
import MenuList from "./component/reusable/MenuList";
import Loading from "./component/reusable/Loading";
import Login from "./component/reusable/Login";
import ILOBatchRecording from "./component/jai/ILOBatchRecording";
import EditILORecording from "./component/jai/EditILORecording";
import { Loader } from "semantic-ui-react";
import ILOM_kpi from "./component/anjali/ILOM_kpi";
import Third_party from "./component/without_login/Third_party";
import Third_Party_Batch from "./component/yusuf/Third_Party_Batch";
import Sudarshan from "./component/Priya/Sudarshan";
import ClientFollowUp from "./component/Priya/ClientFollowUp";
import RCB from "./component/rajkumar/rcb";
import NR from "./component/pushpendra/NRD/NR.js";
import Mark_Availability from "./component/yusuf/Freelancer/Mark_Availibility";
import Add_Skill from "./component/yusuf/Freelancer/Add_Skill";
import Assignments from "./component/yusuf/Freelancer/Assignments";
import Profile from "./component/Nishant/Freelancer/Componant/Profile";
import CCE_PLI from "./component/Nishant/MainProject/Componant/CcePli";
import "bootstrap/dist/css/bootstrap.min.css";

import "react-notifications/lib/notifications.css";

import InvoiceFirstStep from "./component/Priya/Invoices/InvoiceFirstStep";
import InvoiceSecondStep from "./component/Priya/Invoices/InvoiceSecondStep";
import InvoiceThirdStep from "./component/Priya/Invoices/InvoiceThirdStep";
import SCQForInvoices from "./component/Priya/Invoices/SCQForInvoices";
import ForgotPassword from "./component/Nishant/MainProject/WithoutLogin/ForgotPassword";
import UnSubscribe from "./component/Nishant/MainProject/WithoutLogin/UnSubscribe";
import AllInvoicesList from "./component/Priya/Invoices/AllInvoicesList";
import LetterHeadInvoice from "./component/Priya/Invoices/Compontents/elements/LetterHeadInvoice";
import IlomManager from "./component/Nishant/MainProject/Componant/IlomManager";
import ResetPassword from "./component/Nishant/Freelancer/Componant/ResetPassword";
import LAPanel from "./component/Nishant/MainProject/Componant/LAPanel";

import FeedBack1 from "./component/Avishek/WithoutLogin/FeedBack";
import TrainerFeedBack1 from "./component/Avishek/WithoutLogin/TrainerFeedBack";
import IssueCert from "./component/Avishek/WithoutLogin/IssueCertificate";

import Feedback from "react-bootstrap/esm/Feedback";
import ChangeDPW from "./component/Ashish/ChangeDefaultPw";
import FlDetails from "./component/Ashish/UpcomingEvent";
import Transcript from "./component/jai/Transcript";
import FLFeedback from "./component/Nishant/Freelancer/Componant/FLFeedback";
import FLPayment from "./component/Nishant/Freelancer/Componant/Payment";
import InvoiceRedirect from "./component/Priya/Invoices/InvoiceRedirect";
import MenuRemoveAdd from "./component/jai/MenuRemoveAdd";
import FLOpportunity from "./component/Nishant/Freelancer/Componant/Opportunity.js";
import AccessDenied from "./component/reusable/AccessDenied";
import SalesPolicy from "./component/pushpendra/Sales Policy/SalesPolicy";
import SIPNew from "./component/pushpendra/Sales Policy/SIPNew";
import Feedbackdetails from "./component/jai/Feedbackdetails";

const axios = require("axios");

export default function App() {
  const [emp_id, set_emp_id] = React.useState(localStorage.getItem("emp_id"));
  const [emp_data, set_emp_data] = React.useState(
    JSON.stringify(localStorage.getItem("emp_data"))
  );
  const [isMenuRequired, setisMenuRequired] = useState(true);
  const [show_auth, set_show_auth] = React.useState(true);
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);

  // useEffect(()=>{

  // },[callForNonMenu])

  function from_child(emp_date) {
    //alert(JSON.stringify(emp_date[0]))
    set_emp_id(emp_date[0].EmpId);
    set_emp_data(emp_date[0]);
    localStorage.setItem("emp_id", emp_date[0].EmpId);
    localStorage.setItem("emp_data", JSON.stringify(emp_date[0]));
    console.log("redirect url");
  }

  useEffect(() => {
    const id = urlParams.get("id");
    const pwd = urlParams.get("pwd");
    const invid = urlParams.get("invid");

    // if(localStorage.getItem("invid") !== null)
    // {
    //   localStorage.removeItem("invid");
    // }
    if (id && pwd) {
      axios
        .get("yusuf/rms_login?id=" + id + "&pwd=" + pwd + "")
        .then(function (response) {
          if (response.data != "") {
            set_emp_id(response.data[0]["EmpId"]);
            set_emp_data(response.data[0]);
            localStorage.setItem("emp_data", JSON.stringify(response.data[0]));
            localStorage.setItem("emp_id", response.data[0]["EmpId"]);
            localStorage.setItem("invid", invid);
            window.location.href =
              window.location.origin + window.location.pathname;
          } else {
            alert("not authorized");
          }
        })
        .catch(function (error) {
          alert(error);
        });
    } else {
      set_show_auth(false);
    }

    // if (emp_id > 0) {
    //   axios
    //     .get("yusuf/rms_login_validate?id=" + emp_id + "")
    //     .then(function (response) {
    //       if (response.data != "") {
    //       } else {
    //         alert("Oops! Not an Authorized user...Please check with RMS.");
    //         localStorage.clear();
    //         window.location.href = window.location.origin;
    //       }
    //     })
    //     .catch(function (error) {
    //       alert(error);
    //     });
    // }
  }, []);

  if (emp_id > 0) {
    return (
      <Router>
        <div style={{ width: "100%" }}>
          <Route path="/ChangeDPW">
            <ChangeDPW />
          </Route>
        </div>
        <div style={{ display: "flex" }}>
          {/* LEFT SECTION */}
          <MenuList emp_data={emp_data}></MenuList>
          {/*END OF LEFT SECTION */}

          <div style={{ width: "100%" }} className="masterDiv">
            <Switch>
              <Route path="/Third_Party_Batch">
                <Third_Party_Batch />
              </Route>
              <Route path="/bucket">
                <Bucket />
              </Route>
              <Route path="/conversion">
                <Conversion />
              </Route>
              <Route path="/Outstanding_Policy">
                <Outstanding_Policy />
              </Route>
              <Route path="/ILOBatchRecording">
                <ILOBatchRecording />
              </Route>
              <Route path="/SalesPolicy">
                <SalesPolicy />
              </Route>
              <Route path="/SIPNew">
                <SIPNew />
              </Route>
              <Route path="/Transcript">
                <Transcript />
              </Route>
              <Route path="/MenuRemoveAdd">
                <MenuRemoveAdd />
              </Route>
              <Route path="/AccessDenied">
                <AccessDenied />
              </Route>
              <Route path="/EditILORecording">
                <EditILORecording />
              </Route>
              {/* <Route path="/ShowRecord">
                <ShowRecord />
              </Route> */}
              {/* <Route path="/CrudOperation">
                <CrudOperation />
              </Route> */}
              <Route path="/Feedbackdetails">
                <Feedbackdetails />
              </Route>
              <Route path="/ILOM_kpi">
                <ILOM_kpi />
              </Route>
              <Route path="/Sudarshan">
                <Sudarshan />
              </Route>
              <Route path="/ClientFollowUp">
                <ClientFollowUp />
              </Route>
              <Route path="/NR">
                <NR />
              </Route>
              <Route path="/Mark_Availability">
                <Mark_Availability />
              </Route>
              <Route path="/Add_Skill">
                <Add_Skill />
              </Route>
              <Route path="/Assignments">
                <Assignments />
              </Route>
              <Route path="/RCB">
                <RCB />
              </Route>
              <Route path="/UpcomingEvent">
                <FlDetails />
              </Route>
              <Route exact path="/SCQForInvoices">
                <SCQForInvoices />
              </Route>
              <Route exact path="/InvoiceFirstStep">
                <InvoiceFirstStep />
              </Route>
              <Route exact path="/InvoiceSecondStep">
                <InvoiceSecondStep />
              </Route>
              <Route exact path="/InvoiceThirdStep">
                <InvoiceThirdStep />
              </Route>
              <Route path="/LetterHeadInvoice">
                <LetterHeadInvoice />
              </Route>
              <Route exact path="/AllInvoicesList">
                <AllInvoicesList />
              </Route>
              <Route path="/Profile">
                <Profile />
              </Route>
              <Route path="/CCE_PLI">
                <CCE_PLI />
              </Route>
              <Route path="/ChangePassword">
                <ResetPassword></ResetPassword>
              </Route>
              <Route path="/ManagerILo">
                <IlomManager></IlomManager>
              </Route>
              <Route path="/FLFeedback">
                <FLFeedback></FLFeedback>
              </Route>
              <Route path="/FeedBack">
                <FeedBack1></FeedBack1>
              </Route>
              <Route path="/TrainerFeedBack">
                <TrainerFeedBack1></TrainerFeedBack1>
              </Route>
              <Route path="/IssueCertificate">
                <IssueCert></IssueCert>
              </Route>
              <Route path="/FLPayment">
                <FLPayment></FLPayment>
              </Route>
              <Route path="/InvoiceRedirect">
                <InvoiceRedirect />
              </Route>
              <Route path="/FLOpportunity">
                <FLOpportunity />
              </Route>
              <Route path="/LAPanel">
                <LAPanel />
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  } else {
    return (
      <Router>
        <div style={{ display: "flex" }}>
          <div style={{ display: "flex", width: "100%" }}>
            <Switch>
              <Route path="/Third_party">
                <Third_party />
              </Route>
              <Route path="/FeedBack">
                <FeedBack1></FeedBack1>
              </Route>
              <Route path="/TrainerFeedBack">
                <TrainerFeedBack1></TrainerFeedBack1>
              </Route>
              <Route path="/IssueCertificate">
                <IssueCert></IssueCert>
              </Route>
              {/* <Route path="/StudentFeedBack">
                <StudentFeedBack></StudentFeedBack>
              </Route> */}
              <Route path="/UnSubscribe">
                <UnSubscribe></UnSubscribe>
              </Route>
              <Route path="/ForgotPassword">
                <ForgotPassword></ForgotPassword>
              </Route>

              <Route path="/">
                <Login passChildData={from_child}></Login>
              </Route>
              <Route path="/login">
                <Login passChildData={from_child}></Login>
              </Route>
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}
