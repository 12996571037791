import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");


const CountrtDropDown = (props) => {
  const [country, set_country] = React.useState([]);

  useEffect(() => {
    get_country();
  }, []);

  function get_country() {
    axios
      .get("rajkumar/GetCountryById?Type=3")
      .then(function (response) {
        set_country(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function send_to_parent(text, value) {
    //alert(value)
    props.passChildData(text, value);
  }

  return (
    <Dropdown
      placeholder="Select Country"
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(e.target.innerText, value);
        // alert(value)
        //alert(e.target.innerText)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={country}
      clearable
      value={props.selectedValue}
    />
  );
};

export default CountrtDropDown;
