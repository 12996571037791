import React, { Component } from 'react'
import { Icon, Label } from 'semantic-ui-react'
import Logout from './Logout'

const HeaderMenu = props => {
    return (
        <div>
            <div
                style={{
                    padding: 20,
                    height: "8vh",
                    backgroundColor: "#fafafb",
                    width: "100vw",
                    WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                    MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                    boxShadow: "0 8px 6px -6px #d5d4d6",
                    display: "flex",
                }}
            >
                <span
                    style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        fontFamily: "sans-serif",
                        marginLeft: 15,
                    }}
                >
                    {props.HeaderText}
                </span>
                <div className="row mr-0" style={{ position: "absolute", right: 10, top: 10 }}>
                    <div className="mr-2" style={{ display: props.ShowChangePassword || props.ShowChangePassword == null ? 'flex' : 'none' }}>
                        <a href="/ChangePassword">
                            <Label> Change Password {"\u00A0"} <Icon color="orange" name="power" />{" "}</Label>
                        </a>
                    </div>
                    <Logout></Logout>
                </div>
            </div>
        </div>
    )
}

export default HeaderMenu
