import React, { useEffect, useState } from "react";
import IsSezAddress from "./IsSEZAddress";

const BillDated = (props) => {
  console.log(props.IsSEZ)
  return (
    <table
      width="100%"
      height="100%"
      border="0"
      cellSpacing="0"
      cellPadding="5"
      style={{borderRight:" 1px solid #b0b0b0"}}
    >
      <tbody>
        <tr>
          <td width="50%" bgcolor="#FFF">
            Invoice No.
            <br />
            <strong>
              <span>{props.invoiceNumber}</span>
            </strong>
          </td>
          <td width="50%" bgcolor="#FFF">
            Dated
            <br />
            <strong>
              <span>{props.InvoiceDate}</span>
            </strong>
          </td>
        </tr>
        <tr>
          <td bgcolor="#FFF">
            Buyers Order No.
            <br />
            <strong>
              <span>{props.BuyerOrderNo}</span>
            </strong>
          </td>
          <td bgcolor="#FFF">
            Dated
            <br />
            <strong>
              <span>{props.OrderDate}</span>
            </strong>
          </td>
        </tr>
        <tr>
          <td bgcolor="#FFF">
            Other Reference(s)
            <br />
            <strong>
              <span>{props.CCEName}</span>
            </strong>
          </td>
          <td bgcolor="#FFF">
            Payment Terms
            <br />
            <span>{props.PayTerms}</span>
          </td>
        </tr>
        <tr>
        <td bgcolor="#F2F2F2">
        <span>Due Date</span>
            <br />
            <strong>
              <span>{props.DueDate}</span>
            </strong>
          </td>
          <td bgcolor="#FFF">           
          {
                                         props.DeliveryType?(
                                            <td
                                            bgcolor="#FFF"
                                            className="borderB borderR"
                                          >
                                            Terms of Delivery
                                            <br />
                                            <strong>
                                              {props.DeliveryType}
                                            </strong>
                                          </td>
                                          ):""
                                        }
          
          </td>
                                       
          {/* <td bgcolor="#F2F2F2">
            <span>Due Date</span>
            <br />
            <span>{props.DueDate}</span>
          </td> */}
        </tr>
        <tr>
          <td colSpan="2" style={{paddingBottom:"3vh",paddingTop:"2vh"}}>
            <span>
              { (props.invoiceNumber.includes("RSEXP")||props.invoiceNumber.includes("RTEXP")) ? " " : props.IsSEZ ? <IsSezAddress invid={props.invid} /> : ""}
             
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default BillDated;
