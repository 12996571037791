import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { Card } from 'react-bootstrap'
import TextField from '@material-ui/core/TextField';
import { NotificationContainer } from "react-notifications";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import './CustomCss.css'
import SVGLoader from '../Reuseble/SVGLoader';
import AccessDenied from '../../../reusable/AccessDenied';
import CustomDropDown from '../Reuseble/Componant/CustomDropDown';
import CustomDropDownMulti from '../Reuseble/Componant/CustomDropDownMulti';
const axios = require("axios");
const serialize = require('form-serialize');

const LAPanel = () => {
    const [EmployeeList, setEmployeeList] = useState([]);
    const [SearchEmployeeList, setSearchEmployeeList] = useState([]);

    const [CountryId, setCountryId] = useState([]);
    const [ContinentId, setContinentId] = useState('');
    const [VendorId, setVendorId] = useState([]);
    const [TechId, setTechId] = useState([]);
    const [EmployeeId, setEmployeeId] = useState('');
    const [IsHR, setIsHR] = React.useState(false)
    const [IsManager, setIsManager] = React.useState(false)
    const [Url, setUrl] = useState('');
    const [NoOfLead, setNoOfLead] = useState('');
    const [MaxLeadMonth, setMaxLeadMonth] = useState(80);
    const [Priority, setPriority] = useState("10.00");
    const [IsMarkRoster, setIsMarkRoster] = useState(true);
    const [IsOverseas, setIsOverseas] = useState(false);
    const [IsAllocateLead, setIsAllocateLead] = useState(false);
    const [IsFLLead, setIsFlLead] = useState(3);
    const [IsForHPL, setIsForHPL] = useState(false);
    const [IsForMPL, setIsForMPL] = useState(false);
    const [IsForNonNonHPLMPL, setIsForNonNonHPLMPL] = useState(true);
    const [IsInternational, setIsInternational] = useState(false);
    const [IsDomestic, setIsDomestic] = useState(false);
    const [Is4Eyes, set4Eyes] = useState(false);

    const [Id, setId] = useState(0);
    const [DisableURL, setDisableURL] = useState(false);
    const [DisableCCE, setDisableCCE] = useState(false);
    const [DisablePriority, setDisablePriority] = useState(false);
    const [DisableContinent, setDisableContinent] = useState(false);
    const [DisableCountry, setDisableCountry] = useState(false);
    const [DisableVendor, setDisableVendor] = useState(false);
    const [DisableTech, setDisableTech] = useState(false);

    const [EmployeeName, setEmployeeName] = useState('');
    const [SearchName, setSearchName] = useState('');
    // const [VendorName, setVendorName] = useState('');
    // const [CountryName, setCountryName] = useState('');

    const [btnText, setbtnText] = useState('Create Rule');
    const [loading, setloading] = useState(false);

    useEffect(() => {
        AuthenticateUser()
    }, [])

    const AuthenticateUser = () => {
        let d = localStorage.getItem("emp_data");
        axios.post("Nishant/GetListOfAlgo?Type=6&EmpId=" + JSON.parse(d).EmpId).then(function (response) {
            if (response.data.length == 0) ReactDOM.render(<AccessDenied></AccessDenied>, document.getElementById('divcontent'))
            else AllowedChanges(response.data)
        }).catch(function (error) {
            alert(error);
        });
    }

    const AllowedChanges = (d) => {

        if (d[0].LoginType == 'HR') {
            setIsHR(true)
            // ReactDOM.render(<>
            // </>
            //     , document.getElementById('spAllocateLead'))
        }
        else {
            if (d[0].IsManager == 0) AllowedChangesToCCE(d)
            //ReactDOM.render(<><input type="hidden" name="IsAllocateLead" value={IsAllocateLead}></input></>, document.getElementById('spAllocateLead'))
        }
    }

    const AllowedChangesToCCE = (d) => {
        Getdata(d[0].UserId)
        ReactDOM.render('', document.getElementById('spSearchEmployee'))
        setDisableURL(true); setDisableCCE(true); setDisablePriority(true); setDisableCountry(true); setDisableContinent(true); setDisableVendor(true); setDisableTech(true);
        //document.getElementById("chkRoster").disabled = true;
        // document.getElementById("MaxLeadPerMonth").disabled = true;
        // document.getElementById("MaxDailyLead").disabled = true;
        document.getElementById("spNonFLLead").disabled = true;
        //document.getElementById("chkHPL").disabled = true;
        //document.getElementById("chkMPL").disabled = true;
        //document.getElementById("chkNonHPLMPL").disabled = true;
        //document.getElementById("chkDomestic").disabled = true;
        //document.getElementById("chkInternational").disabled = true;
        //document.getElementById("chk4Eyes").disabled = true;
        var radios = document.Oform.LeadType;
        // for (var i = 0, iLen = radios.length; i < iLen; i++) {
        //     if (i == 1) radios[i].disabled = true;
        // }
    }

    const Getdata = (CCEID) => {
        let d = localStorage.getItem("emp_data");
        console.log(CCEID + " " + JSON.parse(d).EmpId)
        setIsManager(false)
        axios.post("Nishant/GetListOfAlgo?Type=1&EmpId=" + CCEID + "&EmpIdLogin=" + JSON.parse(d).EmpId).then(function (response) {
            if (response.data[0].isManager == '1')
                setIsManager(true)

            if (response.data != null) EditAlgo(response.data[0].SlNo);
        }).catch(function (error) {
            alert('Rule is not defined. Please Add rule first.');
        });
    }

    const GetEmployee = async (d) => {
        await axios.get("Nishant/GetActiveEmployee?FilterData=" + d).then(function (response) {
            setEmployeeList(response.data);
        }).catch(function (error) {
            alert(error);
        });
    }

    const GetSearchEmployee = async (d) => {
        await axios.get("Nishant/GetActiveEmployee?FilterData=" + d).then(function (response) {
            setSearchEmployeeList(response.data);
        }).catch(function (error) {
            alert(error);
        });
    }

    const EditAlgo = (Id) => {
        Reset();
        axios.post("Nishant/GetAlgoDataAsPerId?FilterData=" + Id).then(function (response) {
            if (response.data != null) {
                console.log(response)
                setbtnText('Update Rule');
                setId(response.data[0].SlNo);
                setUrl(response.data[0].URL != null ? response.data[0].URL : "");
                setNoOfLead(response.data[0].NoOfLeadPerDay);
                setMaxLeadMonth(response.data[0].MaxLeadPerMonth);
                setIsMarkRoster(response.data[0].IsConsiderRoster)
                setIsOverseas(response.data[0].IsOverseas)
                setIsAllocateLead(response.data[0].IsActive)
                setIsFlLead(response.data[0].IsForFlLead)
                setIsForHPL(response.data[0].IsForHPL)
                setIsForMPL(response.data[0].IsForMPL)
                setIsForNonNonHPLMPL(response.data[0].IsForNonNonHPLMPL)
                setIsInternational(response.data[0].IsInternational);
                setIsDomestic(response.data[0].IsDomestic);
                set4Eyes(response.data[0].Is4Eyes);
                setPriority(response.data[0].Priority)

                setEmployeeId(response.data[0].EmpId)
                setVendorId(response.data[0].VendorId != null ? response.data[0].VendorId.split(',').map(Number) : "")
                setTechId(response.data[0].TechId != null ? response.data[0].TechId.split(',').map(Number) : "")
                setCountryId(response.data[0].CountryId != null ? response.data[0].CountryId.split(',').map(Number) : "")
                setContinentId(response.data[0].ContinentId != null ? response.data[0].ContinentId : "")

                setEmployeeName(response.data[0].EmpName + '(' + response.data[0].EmpCode + ')')
                // setVendorName(response.data[0].VName != null ? response.data[0].VName : "");
                // setCountryName(response.data[0].country_name != null ? response.data[0].country_name : "");
            }
        }).catch(function (error) {
            alert(error);
        });
    }

    const SaveData = async () => {
        setloading(true);
        let d = localStorage.getItem("emp_data");
        var obj = serialize(document.querySelector('#Onlineform'), { hash: true });
        obj.Id = Id
        obj.t_id = JSON.parse(d).EmpId;
        obj.SearchVendorId = VendorId
        obj.SearchTechId = TechId
        obj.SearchEmployeeId = EmployeeId
        obj.SearchCountryId = CountryId
        obj.ContinentId = ContinentId
        obj.IsAllAccessGiven = IsHR
        if (VendorId.length <= 2 && TechId.length <= 2) {
            let { data } = await axios.post("Nishant/LeadAllocationAlgo", obj);
            if (data.length > 0) {
                Reset();
                setloading(false);
                if (data[0].SendEmail == 1) SendMail(data[0], EmployeeId)
                if (data[0].Operation == "I") {
                    alert('Successfully Saved For NJ Sales for allocate Lead flag - Please contact Monika Chopra or HR Team');
                }
                else if (data[0].Operation == "E") {
                    alert('You cannot set the maximum lead count per day greater than 3.');
                }
                else {
                    alert('Successfully Saved');
                }
            }
            else { alert('you can change only your reportee or yourself.'); setloading(false); }
        }
        else {
            console.log(VendorId.length)
            if (VendorId.length > 2) {
                alert('cannot select more than 2 Vendors.'); setloading(false);
            }
            else {
                alert('cannot select more than 2 Technology.'); setloading(false);
            }
        }
    }

    const SendMail = (d, EmployeeId) => {
        console.log(EmployeeId);
        axios.get("Nishant/GetDataForSendLAEmail?EmpId=" + EmployeeId).then(function (response) {
            axios.get("Nishant/GetPrevDataForLaAlgo?EmpId=" + EmployeeId).then(function (response2) {
                console.log(response.data[0]);
                var Subject = 'LA Algo changed for ' + response.data[0].Name + ' by ' + d.CreatedBy;
                var SendTo = d.OffEmail;

                var HtmlContent = ReactDOMServer.renderToStaticMarkup(ReturnHtml(d, response.data[0], response2));
                // console.log(HtmlContent);
                SendMail1('Ashish.Kumar@Koenig-Solutions.com', SendTo, 'monika.chopra@koenig-solutions.com;' + d.MangEmail + ';' + d.CCEEmail, 'Ashish.Kumar@Koenig-Solutions.com;nishant.sinha@koenig-solutions.com;', Subject, HtmlContent)
            }).catch(function (error) {

                alert(error);
            });
        }).catch(function (error) {
            alert(error);
        });
    }

    const ReturnHtml = (d, dc, dc2) => {
        // async function getAuditData() {
        //     await axios.get("Nishant/GetPrevDataForLaAlgo?EmpId=" + EmployeeId).then(function (response) {
        //         return response.data[0];
        //     }).catch(function (error) {
        //         alert(error);
        //     });
        // }

        // getAuditData().then(function(response){
        //     data = response.data[0];
        // });
        //console.log(dc2.data[0]["Country Name"]);

        return (
            <>
                <span>FYI </span><br></br><br></br>
                <span>LA Algo {d.Operation == "I" ? ("Inserted by " + d.CreatedBy) : ("changed for " + dc.Name + " by " + d.CreatedBy)}</span><br></br>
                <span>New Algo mentioned below</span><br></br>
                <table style={{ width: "100%", color: "#212529", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                    <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem", backgroundColor: 'cornflowerblue' }}>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Name</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Alias Name</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Vendor Name</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Technology Name</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Country Name</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Continent Name</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>URL</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Priority</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Max Lead Per Month</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Max No Of Lead Per Day</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Use Roster</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Only FL Lead</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Only Non FL Lead</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Both</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>HPL Lead</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>MPL Lead</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Non HPL/MPL Lead</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>Domestic</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>International</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>4 Eyes</td>
                    </tr>
                    <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc.Name}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Alias Name"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Vendor Name"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Tecnology Name"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Country Name"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Continent Name"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc.URL}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc.Priority}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Max Lead Per Month"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Max No Of Lead Per Day"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Use Roster"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Only FL Lead"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Only Non FL Lead"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Both"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["HPL Lead"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["MPL Lead"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["Non HPL/MPL Lead"]}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc.Domestic}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc.International}</td>
                        <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc["4 Eyes"]}</td>
                    </tr>

                    {dc2.data.length > 0 &&
                        <tr style={{ padding: "2px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", marginBottom: "1rem" }}>{dc2.data[0].Name}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Alias Name"] == dc2.data[0]["Alias Name"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Alias Name"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Vendor Name"] == dc2.data[0]["Vendor Name"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Vendor Name"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Tecnology Name"] == dc2.data[0]["Tecnology Name"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Tecnology Name"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Country Name"] == dc2.data[0]["Country Name"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Country Name"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Continent Name"] == dc2.data[0]["Continent Name"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Continent Name"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc.URL == dc2.data[0].URL ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0].URL}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc.Priority == dc2.data[0].Priority ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0].Priority}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Max Lead Per Month"] == dc2.data[0]["Max Lead Per Month"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Max Lead Per Month"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Max No Of Lead Per Day"] == dc2.data[0]["Max No Of Lead Per Day"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Max No Of Lead Per Day"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Use Roster"] == dc2.data[0]["Use Roster"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Use Roster"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Only FL Lead"] == dc2.data[0]["Only FL Lead"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Only FL Lead"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Only Non FL Lead"] == dc2.data[0]["Only Non FL Lead"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Only Non FL Lead"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Both"] == dc2.data[0]["Both"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Both"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["HPL Lead"] == dc2.data[0]["HPL Lead"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["HPL Lead"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["MPL Lead"] == dc2.data[0]["MPL Lead"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["MPL Lead"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["Non HPL/MPL Lead"] == dc2.data[0]["Non HPL/MPL Lead"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["Non HPL/MPL Lead"]}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc.Domestic == dc2.data[0].Domestic ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0].Domestic}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc.International == dc2.data[0].International ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0].International}</td>
                            <td style={{ padding: "10px", border: "1px solid #dee2e6", color: dc["4 Eyes"] == dc2.data[0]["4 Eyes"] ? "black" : "red", marginBottom: "1rem" }}>{dc2.data[0]["4 Eyes"]}</td>
                        </tr>}
                </table>
            </>
        )
    }

    function checkBothTag(international, domestic) {
        if (international == false && domestic == false) {
            alert("You have unchecked the flag for both domestic and international. Atleast one of these should be selected to get leads.")
        }
    }

    async function SendMail1(uname, to, cc, bcc, subject, html) {
        try {
            var obj = {
                _uname: uname,
                _to: to,
                _cc: cc,
                _bcc: bcc,
                _subject: subject,
                _html: html
            };
            let { data } = await axios.post("Nishant/sendemail", obj);
        } catch (er) {
            //alert(JSON.stringify(er));
        }
    }

    const Reset = () => {
        setId(0);
        setVendorId(''); setTechId('') //setVendorName(''); 
        setCountryId(''); //setCountryName('')
        setContinentId('');
        setUrl('')
        setSearchName('')
        setSearchEmployeeList([])
        setEmployeeId(''); setEmployeeName('');
        setNoOfLead(''); setMaxLeadMonth(90);
        setIsMarkRoster(true);
        setIsOverseas(false);
        setIsAllocateLead(false);
        setIsFlLead(3);
        setIsForHPL(false); setIsForMPL(false); setIsForNonNonHPLMPL(true)
        setIsInternational(false); setIsDomestic(false); set4Eyes(false);
        setPriority("10.00");
        setbtnText('Create Rule');
    }

    const GetContinentId = (text, value) => setContinentId(value);
    const GetCountryId = (text, value) => setCountryId(value);
    const GetVendorId = (text, value) => setVendorId(value);
    const GetTechId = (text, value) => setTechId(value);

    const GetTabcontent = (data) => {
        return (
            <div>
                <SVGLoader loading={loading} SVGType="Form" SVGWidth="1200" SVGHeight="560"></SVGLoader>
                <form id="Onlineform" name="Oform" style={{ display: loading ? 'none' : '' }}>
                    <div className="row">
                        <div className="col-md-2" id='spVendor'>
                            <CustomDropDownMulti passChildData={GetVendorId} disabled={DisableVendor} svalue={VendorId} placeholder="Search Vendor" link="Nishant/GetListOfAlgo?Type=8"></CustomDropDownMulti>
                        </div>
                        <div className="col-md-2" id='spVendor'>
                            <CustomDropDownMulti passChildData={GetTechId} disabled={DisableTech} svalue={TechId} placeholder="Search Technology" link="Nishant/GetListOfAlgo?Type=11"></CustomDropDownMulti>
                        </div>
                        <div className="col-md-2" id='spContinent'>
                            <CustomDropDown passChildData={GetContinentId} disabled={DisableContinent} svalue={ContinentId} placeholder="Search Continent" link="Nishant/GetListOfAlgo?Type=5"></CustomDropDown>
                        </div>
                        <div className="col-md-2" id='spCountry'>
                            <CustomDropDownMulti passChildData={GetCountryId} disabled={DisableCountry} svalue={CountryId} placeholder="Search Country" link="Nishant/GetListOfAlgo?Type=7"></CustomDropDownMulti>
                        </div>
                        <div className="col-md-2" id='spURL'>
                            <TextField value={Url} id="txtURL" disabled={DisableURL} className="form-control" size="small" multiline onChange={(e) => { setUrl(e.target.value); }} label="Enter URL" variant="outlined" rows={1} name="URLText" />
                        </div>
                        <div className="col-md-2" id='spEMP'>
                            <TextField value={EmployeeName} disabled={DisableCCE} className="form-control" size="small" onChange={(e) => { setEmployeeName(e.target.value); GetEmployee(e.target.value) }} label="Search CCE" variant="outlined" />
                            <span className="custumAutoCompleteList ml-3 mr-3">
                                {EmployeeList.map((item) => {
                                    return (
                                        <span
                                            key={item.UserId}
                                            onClick={() => {
                                                setEmployeeName(item.EmpName + '(' + item.EmpCode + ')')
                                                setEmployeeId(item.UserId)
                                                setEmployeeList([]);
                                            }}
                                            className="listSearchDesktop"
                                        >
                                            {item.EmpName + '(' + item.EmpCode + ')'}
                                        </span>
                                    );
                                })}
                            </span>
                        </div>
                        {/* <div className="col-md-2" id='spPriority'>
                            <TextField type="number" name="Priority" value={Priority} disabled={DisablePriority} className="form-control" size="small" onChange={(e) => { setPriority(e.target.value) }} label="Set Priority" variant="outlined" />
                        </div> */}
                    </div>
                    <div className="row mt-2 pt-3 border-top">
                        <div className="col-md-2" id='spMaxLeadPerMonth'>
                            <TextField type="number" id='MaxLeadPerMonth' name="MaxLeadPerMonth" value={MaxLeadMonth} className="form-control" size="small" onChange={(e) => { setMaxLeadMonth(e.target.value) }} label="Max. Lead / month" variant="outlined" />
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-7" id='spMaxDailyLead'>
                                    <TextField type="number" id="MaxDailyLead" name="noOfLead" value={NoOfLead} className="form-control" size="small" onChange={(e) => { setNoOfLead(e.target.value) }} label="Max. Lead / Day" variant="outlined" />
                                </div>
                                <div style={{ paddingLeft: '5px' }} className="col-md-5 pt-2">
                                    Use Roster
                                    <input type="checkbox" id='chkRoster' name="IsRoster" checked={IsMarkRoster} onClick={(e) => { IsHR ? setIsMarkRoster(!IsMarkRoster) : setIsMarkRoster(IsMarkRoster) }} className="ml-2" ></input>
                                    <br></br>
                                    <span id='spAllocateLead'>
                                        Allocate Lead
                                        <input type="checkbox" name="IsAllocateLead" checked={IsAllocateLead} onClick={(e) => { IsHR ? setIsAllocateLead(!IsAllocateLead) : setIsAllocateLead(IsAllocateLead) }} className="ml-2"></input>
                                    </span>
                                    <br></br>
                                    <span style={{ display: ["1460", "902"].includes(JSON.parse(localStorage.getItem("emp_data")).EmpId + "") ? 'block' : 'none' }}>
                                        IsOverseas
                                        <input type="checkbox" id='chkIsOverseas' name="IsOverseas" checked={IsOverseas} onClick={(e) => { IsHR || IsManager ? setIsOverseas(!IsOverseas) : setIsOverseas(IsOverseas) }} className="ml-2" ></input>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-7">
                                    <span id='spFLLead'><input type="radio" id='spFLLead' name="LeadType" className='mr-2' value={IsFLLead} checked={IsFLLead == 1 ? true : false} onChange={() => { setIsFlLead(1) }} /> Only FL Lead</span>
                                    <br />
                                    <span id='spNonFLLead'><input type="radio" id='spNonFLLead' name="LeadType" className='mr-2' value={IsFLLead} checked={IsFLLead == 2 ? true : false} onChange={() => { setIsFlLead(2) }} /> Only Non FL Lead</span>
                                    <br />
                                    <span id='spBothLead'><input type="radio" id='spBothLead' name="LeadType" className='mr-2' value={IsFLLead} checked={IsFLLead == 3 ? true : false} onChange={() => { setIsFlLead(3) }} /> Both</span>
                                </div>
                                <div className="col-md-5">
                                    <span id='spHPLLead' style={{ display: "none" }}>
                                        HPL Lead
                                        <input type="checkbox" id="chkHPL" name="IsForHPL" onClick={(e) => { IsHR || IsManager ? setIsForHPL(!IsForHPL) : setIsForHPL(IsForHPL) }} checked={IsForHPL} className="ml-2"></input>
                                        <br />
                                    </span>
                                    <span id='spMPLLead' style={{ display: "none" }}>
                                        MPL Lead
                                        <input type="checkbox" id="chkMPL" name="IsForMPL" checked={IsForMPL} onClick={(e) => { IsHR || IsManager ? setIsForMPL(!IsForMPL) : setIsForMPL(IsForMPL) }} className="ml-2"></input>
                                        <br />
                                    </span>
                                    <span id='spNonHPLMPLLead'>
                                        Non HPL/MPL
                                        <input type="checkbox" id="chkNonHPLMPL" name="IsForNonNonHPLMPL" checked={IsForNonNonHPLMPL} onClick={(e) => { IsHR || IsManager ? setIsForNonNonHPLMPL(!IsForNonNonHPLMPL) : setIsForNonNonHPLMPL(IsForNonNonHPLMPL) }} className="ml-2"></input>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <span id='spDomestic'>
                                Domestic
                                <input type="checkbox" id='chkDomestic' name="IsDomestic" checked={IsDomestic} onClick={(e) => { setIsDomestic(!IsDomestic); checkBothTag(IsInternational, !IsDomestic); }} className="ml-2"></input>
                                <br />
                            </span>
                            <span id='spInternational'>
                                International
                                <input type="checkbox" id='chkInternational' name="IsInternational" checked={IsInternational} onClick={(e) => { setIsInternational(!IsInternational); checkBothTag(!IsInternational, IsDomestic); }} className="ml-2"></input>
                                <br />
                            </span>
                            <span id='sp4Eyes' style={{ display: "none" }}>
                                4 Eyes
                                <input type="checkbox" id='chk4Eyes' name="Is4Eyes" checked={Is4Eyes} onClick={(e) => { IsHR || IsManager ? set4Eyes(!Is4Eyes) : set4Eyes(Is4Eyes) }} className="ml-2"></input>
                            </span>
                        </div>
                        <div className="col-md-2" id='spSearchEmployee'>
                            <TextField value={SearchName} className="form-control" size="small" onChange={(e) => { setSearchName(e.target.value); GetSearchEmployee(e.target.value) }} label="Search CCE for Update" variant="outlined" />
                            <span className="custumAutoCompleteList ml-3 mr-3">
                                {SearchEmployeeList.map((item) => {
                                    return (
                                        <span
                                            key={item.UserId}
                                            onClick={() => {
                                                setSearchName(item.EmpName + '(' + item.EmpCode + ')')
                                                //setSearchEmployeeId(item.UserId)
                                                setSearchEmployeeList([]);
                                                Getdata(item.UserId)
                                            }}
                                            className="listSearchDesktop"
                                        >
                                            {item.EmpName + '(' + item.EmpCode + ')'}
                                        </span>
                                    );
                                })}
                            </span>
                        </div>
                    </div>
                    <div className="row mt-2 pt-3 border-top">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <input type="button" className="btn btn-success form-control" value={btnText} onClick={() => SaveData()}></input>
                        </div>
                        <div className="col-md-4"></div>
                    </div>
                </form>
            </div>
        )
    }

    return (
        <div style={{ height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
            <div style={{
                padding: 20,
                height: '6vh', backgroundColor: '#fafafb', width: '98vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span className="text-uppercase" style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>LA Panel</span>
            </div>
            <div id="getSweetModel"></div>
            <div className="row" id='divcontent'>
                <div className="col-md-12 m-3">
                    <Card className="mr-5">
                        <Card.Header><label className="text-uppercase">Create Rule</label></Card.Header>
                        <Card.Body>
                            {GetTabcontent(null)}
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-12 ml-3 mr-3">
                    <div Id="divdisplayGrid"></div>
                </div>
            </div>
            <NotificationContainer></NotificationContainer>
        </div>
    )
}

export default LAPanel
