import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import "../../MainProject/Componant/CustomCss.css"

const CSDataTable = props => {
 
  const data = props.Rows;
  const columns = props.Columns;
  const config = {
    page_size: props.PageSize == null ? 10 : props.PageSize,
    length_menu: props.Length_menu == null ? [10, 20, 50] : props.Length_menu,
    show_filter: props.ShowSearchBar == null ? true : props.ShowSearchBar,
    show_pagination: props.ShowPagignation == null ? true : props.ShowPagignation,
    pagination: 'advance',
    button: {
      excel: false,
      print: false
    }
  }

  return (
    <ReactDatatable
      config={config}
      records={data}
      columns={columns} />
  )
}

export default CSDataTable
