import { useEffect, useState } from "react";
import React, { Component } from 'react'
import { Label, Container, Image } from 'semantic-ui-react'
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import NotificationManager from 'react-notifications/lib/NotificationManager';
const axios = require('axios');
const queryString = require("query-string");

const IssueYCertificate = (props) => {
    const queryURL = queryString.parseUrl(window.location.href.replace("%20",""));
    console.log(window.location.href.replace("%20",""));

    const [assignmentId, setAssignmentId] = React.useState(queryURL.query.assignmentId);
    const [sno, setSno] = React.useState(queryURL.query.sno);
    const [loading, set_loading] = React.useState(false)

    useEffect(() => {
        set_loading(true);
        get_data();
    }, []);

    async function get_data() {
        set_loading(true);
        console.log("API CALLED");    
        console.log(queryURL.query.assignmentid)
        console.log(queryURL.query.sno)
        
        axios.get('Avishek/GetYAData?aid='+queryURL.query.assignmentid+'&sno='+queryURL.query.sno).then(function (response) {
            console.log("RESULT RECEIVED");    
            set_loading(false)                 

            if (!response.data.ok) {
                console.log(response.data);
                if(response.data=="A")
                {
                    document.getElementById('lblYLink').innerText="Url Already Processed";
                    NotificationManager.success('Url Already Processed')
                }
                else if(response.data=="I")
                {
                    document.getElementById('lblYLink').innerText="Issued Your Accalim Badge Successfully";
                    NotificationManager.success('Issued Your Acclaim Badge Successfully')
                }
                else if(response.data=="E")
                {
                    document.getElementById('lblYLink').innerText="Kindly check mail if certificate not received Try Again !!!!";
                    NotificationManager.success('Kindly check mail if certificate not received Try Again !!!!')
                }
                
            }
            else {
                NotificationManager.error('Something Went Wrong Try Again!!!!');               
            }
        }).catch(function (error) {
            console.log("Error :")
            set_loading(false)
            alert(error);
        })
    }

    return (
        <Container style={{ width: 600, backgroundColor: '#fff' }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                width: 600,
                paddingLeft: 130, paddingRight: 130,
                height: '100vh'
            }}>
                <br></br>
                <br></br>

                <Image
                    //size='tiny'
                    style={{ alignSelf: 'center', height: 100, width: 100 }}
                    src={require('../../reusable/images/ko.png')}
                //src={require('../../../reusable/images/ko.png')}
                />
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <br></br>
                <div style={{ display: "grid" }} id="Content">

                   <label id='lblYLink'>We are Processing....</label>

                    <br></br>                  


                </div>
            </div>
            <NotificationContainer></NotificationContainer>
        </Container>


    )
}

export default IssueYCertificate