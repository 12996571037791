import React, { useState, useEffect } from 'react'
import SVGLoader from '../../MainProject/Reuseble/SVGLoader';
import CSDataTable from '../Reusable/CSDataTable'
import HeaderMenu from '../Reusable/HeaderMenu'

const axios = require("axios");
//var moment = require('moment');

const FLFeedback = () => {
    const [Rows, setRows] = useState([]);
    const [loading, set_loading] = React.useState(true)
    const Coulumn = [
        { text: 'Id', key: 'AssignmentId', sortable: true },
        { text: 'Course Name', key: 'CName', sortable: true },
        { text: 'No Of Participants', key: 'NoOfParticipants', sortable: true },
        { text: 'Feedback', key: 'FeedBack', sortable: true },
    ];

    useEffect(() => {
        Getdata();
    }, [])

    function Getdata() {
        set_loading(true)
        let d = localStorage.getItem("emp_data");
        axios.get("Nishant/GetAssignments?TrainerId=" + JSON.parse(d).trainer_id).then(function (response) {
            //console.log(response.data);
            setRows(response.data);
            set_loading(false);
        }).catch(function (error) {
            alert(error);
        });
    }

    // const SetRow = (d) => {
    //     console.log(d);
    //     var arr = [];
    //     d.map((data, i) => ( 
    //         arr.push(["AssignmentId", data.AssignmentId, "CName",data.CName, "NoOfParticipants",data.NoOfParticipants,"FeedBack",data.FeedBack])
    //     ))
    //     console.log(arr);
    //     return arr;
    // }

    return (
        <div>
            <HeaderMenu HeaderText="My Feed Backs"></HeaderMenu>
            <SVGLoader loading={loading} SVGType="Table" SVGWidth="1200" SVGHeight="560"></SVGLoader>
            <div className="row">
                <div className="col-md-12">
                    <div className="overflow-auto m-3" style={{ height: '45em' }}>
                        <CSDataTable Rows={Rows} Columns={Coulumn} ></CSDataTable>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FLFeedback
