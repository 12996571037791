import { render } from '@testing-library/react';
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Input, Dropdown, Button, Table, Container } from "semantic-ui-react";
import './Grid.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Toast } from 'bootstrap';
import { BorderAll } from '@material-ui/icons';
import AccessDenied from '../reusable/AccessDenied';
const axios = require('axios');

export default function Feedbackdetails() {
    const [IsEmotional, set_IsEmotional] = useState(false);
    const [loading, set_loading] = React.useState(false)
    const [fromdate, set_fromdate] = useState("");
    const [Todate, set_Todate] = useState("");
    const [UserId, set_UserId] = useState("");
    const [FeedbackMod, set_Feedback] = useState("");
    const [FeedbackOrg, set_FeedbackOrg] = useState("");
    const [Feedbackid, set_Feedbackid] = useState(0);
    const [data_list, SetData] = React.useState([])
    useEffect(() => {
        // let d = localStorage.getItem("emp_data");
        // let Role_Id= JSON.parse(d).role_id;
        // set_UserId(Role_Id)
        let d = localStorage.getItem("emp_data");
        axios.get("jai/GetAuthenticated?Type=9&Id=" + JSON.parse(d).EmpId).then(function (response) {
            if (response.data.length == 0) ReactDOM.render(<AccessDenied></AccessDenied>, document.getElementById('divcontent'))
            else set_loading(false)
        }).catch(function (error) {
            alert(error);
        });
    }, []);

    async function Get_data() {
        SetData([])
        set_loading(true)
        console.log("jai/Sp_Add_and_Remove_Menus?Type=6&RoleId=0&MenuId=0&Id=0&FromDate='" + fromdate + "'&ToDate='" + Todate + "'");
        let { data } = await axios.get("jai/Sp_Add_and_Remove_Menus?Type=6&RoleId=0&MenuId=0&Id=0&FromDate='" + fromdate + "'&ToDate='" + Todate + "'")
        if (data.length > 0) {
            set_loading(false)
            SetData(data);
        }
        else {
            alert("NO DATA");
            SetData([]);
            set_loading(false)
        }



    }
    async function SaveData(Feedbackid, FeedbackOrg) {

        let os = IsEmotional ? 1 : 0
        let fd = FeedbackMod == "" ? FeedbackOrg : FeedbackMod
        console.log(Feedbackid);
        console.log(FeedbackOrg);
        console.log(fd);

        console.log("jai/Sp_Add_and_Remove_Menus?Type=7&RoleId=" + os + "&MenuId=0&Id=" + Feedbackid + "&FromDate='28-apr-2021'&ToDate='28-apr-2021'&Feedback=" + fd + "");
        let { data } = await axios.get("jai/Sp_Add_and_Remove_Menus?Type=7&RoleId=" + os + "&MenuId=0&Id=" + Feedbackid + "&FromDate='28-apr-2021'&ToDate='28-apr-2021'&Feedback=" + fd + "")
        if (data.length > 0) {
            alert("Update successfully");
            set_loading(false)
            Get_data();
        }
        else {
            SetData([]);
        }
    }



    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
            <div className="row" id='divcontent'></div>
            <div style={{ width: '90vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
                <div style={{
                    padding: 20,
                    backgroundColor: '#fafafb', width: '100vw',
                    WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                    MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                    boxShadow: "0 8px 6px -6px #d5d4d6"
                }}>

                    <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>Feedback</span>
                </div>
                <br />

                <div className="container">

                    <div className="row panelBorder" >
                        <div className="col-md-12">&nbsp;</div>
                        <div className="col-md-1"></div>
                        <div className="col-md-3">
                            <label>From Date</label><br />
                            <SemanticDatepicker onChange={(e, v) => { set_fromdate(v.value) }}

                                placeholder="DD-MMM-YYYY" format="DD-MMM-YYYY" />
                        </div>
                        <div className="col-md-3">
                            <label>To Date</label><br />
                            <SemanticDatepicker onChange={(e, v) => { set_Todate(v.value) }}
                                placeholder="DD-MMM-YYYY" format="DD-MMM-YYYY" />
                        </div>
                        <div className="col-md-3" style={{ margin: '0px 0px 60px 0px', display: loading ? 'none' : 'block' }}>
                            <br />
                            <Button className="btn-primary" onClick={() => { Get_data() }} positive>
                                Search</Button>
                        </div>

                        <div style={{ display: loading ? 'block' : 'none' }}>
                            <br />
                            <Button loading positive>
                                Loading
                            </Button>
                        </div>



                        {
                            data_list.length > 0 ?
                                <div style={{ overflowY: 'scroll', height: '500px', width: '90%' }}>
                                    <table style={{ width: '100%' }} className="table table-bordered table-condensed">
                                        <thead className='headerBackGround'>
                                            <tr>
                                                <th>
                                                    Student Name
                                                </th>
                                                <th>
                                                    Feedback
                                                </th>
                                                <th>
                                                    Course
                                                </th>
                                                <th>
                                                    Feedback Date
                                                </th>
                                                <th>
                                                    Is Emotional
                                                </th>
                                                <th>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data_list.map((d) => {
                                                return (
                                                    <tr>
                                                        <td>
                                                            {d.Name}
                                                        </td>

                                                        <td>

                                                            <textarea onChange={(e) => { set_Feedback(e.target.value) }}
                                                                defaultValue={d.TextAnswer} style={{
                                                                    border: '0',
                                                                    overflow: 'hidden',
                                                                    height: '61px',
                                                                    width: '350px', outline: '0'
                                                                }} />
                                                        </td>

                                                        <td>
                                                            {d.CName}
                                                        </td>
                                                        <td>
                                                            {d.FeedBackDate}
                                                        </td>
                                                        <td><input defaultChecked={d.IsEmotional} onChange={(e) => { set_IsEmotional(e.target.checked) }} type="checkbox" /> </td>
                                                        <td>
                                                            {
                                                                <button
                                                                    onClick={() => { SaveData(d.id, d.TextAnswer) }}
                                                                    className="btn-primary">Update</button>
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                : ""
                        }

                    </div>
                </div>
            </div>
        </div>


    )
}