import React, { useEffect,refs,useState } from 'react'
import { Container, Input, Button, Image, Checkbox } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
//import '../Pushpendra/Conversion/node_modules/react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import { StickyTable, Row, Cell,link } from 'react-sticky-table';
import { useHistory,Link } from "react-router-dom";
import ILORecordingDetails from './ILORecordingDetails';
const axios = require('axios');


const ILOBatchRecording = (props) => {

    const [data_list, set_data_list] = React.useState([])
    const [header, set_header] = React.useState([])
    const [loading, set_loading] = React.useState(false)
    const [type, set_type] = React.useState('1')
    const [assignmentid, set_assignmentid] = React.useState('')
    const [assignmentidErr, set_assignmentidErr] = React.useState('')
    
    const [url, set_url] = React.useState('')
    const [urlErr, set_urlErr] = React.useState('')

    const [date, set_date] = React.useState('')
    const [dateErr, set_dateErr] = React.useState('')
    
    const [sequence, set_sequence] = React.useState('')
    const [sequenceErr, set_sequenceErr] = React.useState('')

    const [remarks, set_remarks] = React.useState('')
    const [remarksErr, set_remarksErr] = React.useState('')


    const [Isactive, set_IsActive] = React.useState('')
    //const [ErrorName, set_error] = React.useState('')
    const [CreatedBy, set_createdby] = React.useState('0')   
    const [LinkExpiryDate,set_LinkExpiryDate]=React.useState(''); 
    const [refresh, doRefresh] = useState(0);

    function IsValid(){        
        if (assignmentid == "" || assignmentid == null) {
            set_assignmentidErr("Please enter Assignment Id")
            //return;
        }

        else if (url == "" || url == null < 0) {
            set_urlErr("Please enter URL");
            set_assignmentidErr('');
            return false;
        }
        else if (date == "" || date == null) {
            set_dateErr("Please enter Date");
            set_urlErr('')
            return false
         }
        else if (sequence == "" || sequence == null) {
            set_sequenceErr("Please enter Sequence");
            set_dateErr('')
            return false;
        }       
        else{
            set_sequenceErr('')
        return true;
        }
    }
    useEffect(() => {
        set_loading(true)        
        get_data();
    }, [props.refresh]);
   
    function get_data() {
        axios.get('api/jai/Sp_GetILORecordingDetails')
            .then(function (response) {
                //alert(JSON.stringify(response.data))
                set_loading(false)
                set_data_list(response.data)

                //alert(JSON.stringify(response.data))
                // set_city(response.data)

            })
            .catch(function (error) {
                alert(error);
            })
    }
    function submit() {
        set_data_list([])
        if(!IsValid()){
            return;
        }
        set_loading(true)
        //set_createdby(Emp_data.EmpId)
        //alert(CreatedBy);
        var js_start = new Date(date);
        var sd_ = js_start.getMonth() + 1 + "/" + js_start.getDate() + "/" + js_start.getFullYear()
        
        var ExpDate = new Date(LinkExpiryDate);
        var ExprDate_ = ExpDate.getMonth() + 1 + "/" + ExpDate.getDate() + "/" + ExpDate.getFullYear()
        //console.log('jai/sp_save_ilo_recordings?type=' + type + '&assignmentid=' + assignmentid + '&url=' + url + '&date=' + sd_ + '&sequence=' + sequence + '&remarks=' + remarks + '&ILO_Id=1&IsActive=0&CreatedBy=' + CreatedBy + '&LinkExpiryDate='+ExprDate_+'');
        axios.get('jai/sp_save_ilo_recordings?type=' + type + '&assignmentid=' + assignmentid + '&url=' + url + '&date=' + sd_ + '&sequence=' + sequence + '&remarks=' + remarks + '&ILO_Id=1&IsActive=0&CreatedBy=' + CreatedBy + '&LinkExpiryDate='+ExprDate_+'')
            .then(function (response) {
                alert("Record save successfully...");                    
                set_data_list(response.data)
                if (response.data != "") {
                    //set_emp_id(response.data[0]["EmpId"])
                    //set_emp_data(response.data)
                    //alert(response.data[0]["EmpId"])
                    //props.passChildData(response.data);
                    // if (response.data[0].Result > 0) {
                        
                    //     //doRefresh(prev => prev + 1)
                        
                    //     // this.ref.child.get_data();

                    // }
                    // else {
                    //     alert("Opps. Something went wrong...");
                    // }

                    set_loading(false)
                }
                else {
                    set_loading(false)

                    alert('invalid cred')
                }
            })
            .catch(function (error) {
                set_loading(false)

                alert(error);
            })
    }
    
    function fnOnlick(id){
        localStorage.setItem("id",id);
        window.location.href="/EditILORecording"
    }
    function fnOnDelete(id){
        axios.get('api/jai/sp_save_ilo_recordings?type=3&assignmentId=0&url=ok&date=1-jan-2020&sequence=1&remarks=ok&IsActive=0&ILO_Id='+id+'')
        .then(function (response) {
            //alert(JSON.stringify(response.data))
            set_loading(false)
            if(response.data[0].Result>0)
            {
                alert("Record deleted successfully");
                get_data();
            }
            else
            {
                alert("Opps.. Something went wrong...");   
            }

        })
        .catch(function (error) {
            alert(error);
        })
    }

   
    return (
        
        <div style={{ width: '80vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>

                {/* DATA SECTION */}
                <div style={{
                padding: 20,
                height: '8vh', backgroundColor: '#fafafb', width: '100vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>ILO Recording</span>
            </div>

    
            <div style={{paddingLeft: 43, paddingTop: 20 }}>
                <table>
                    <tr>
                        <td>
                            <label>Assignment Id</label><br></br>
                            <Input
                                onChange={(e) => {
                                    set_assignmentid(e.target.value)
                                }}
                                size='large' placeholder='Assignment Id' /><br></br>
                            <label style={{color:"#dc0d0d"}}>{assignmentidErr}</label>
                        </td>
                        <td>
                            <label>URL</label><br></br>
                            <Input
                                onChange={(e) => {
                                    set_url(e.target.value)

                                }}
                                size='large' placeholder='URL' /><br></br>
                            <label style={{color:"#dc0d0d"}}>{urlErr}</label>
                        </td>


                        <td>
                            <label>Date</label><br></br>
                            <SemanticDatepicker placeholder="01-jan-2020" format="DD-MMM-YYYY" onChange={(e, v) => {
                                //alert(JSON.stringify(v)) 
                                set_date(v.value)
                            }} /><br></br>
                            <label style={{color:"#dc0d0d"}}>{dateErr}</label>
                        </td>
                        <td>
                            <label>Sequence</label><br></br>
                            <Input
                                onChange={(e) => {
                                    set_sequence(e.target.value)
                                }}
                                size='large' placeholder='Sequence' /><br></br>
                            <label style={{color:"#dc0d0d"}}>{sequenceErr}</label>
                        </td>
                    </tr>
                    <tr>
                       
                        <td>
                            <label>Remarks</label><br></br>
                            <Input
                                onChange={(e) => {
                                    set_remarks(e.target.value)
                                }}
                                size='large' placeholder='Remarks' /><br></br>
                            <label style={{color:"#dc0d0d"}}></label>
                        </td>
                        <td>
                            <label>Link Expiry Date</label><br></br>
                            <SemanticDatepicker placeholder="01-jan-2020" format="DD-MMM-YYYY" onChange={(e, v) => {
                                //alert(JSON.stringify(v)) 
                                set_LinkExpiryDate(v.value)
                            }} /><br></br>
                            <label style={{color:"#dc0d0d"}}></label>
                        </td>
                        <td>
                            <div style={{ display: loading ? 'none' : 'block' }}>
                                
                                <Button
                                    onClick={() => {
                                        submit()
                                    }} positive>Save</Button>
                            </div>
                        </td>
                        <td>
                            <div style={{ display: loading ? 'block' : 'none' }}>
                                <Button loading positive>
                                    Loading
                            </Button>
                            </div>
                        </td>
                    </tr>
                </table>
                <br></br>    
                {/* <div style={{paddingTop: 20 }}></div>                     
                <ILORecordingDetails refresh={refresh}/>
                </div> */}



<StickyTable style={{border: "1px solid #e5e5e5",height:"50vh", overflow: 'scroll'
}}>
    <Row style={{ fontSize: 16, fontWeight: 'bold' }}>
        <Cell>Assignment</Cell>
        <Cell>Date</Cell>
        <Cell>URL</Cell>                        
        <Cell>Remarks</Cell>
        <Cell>Sequence</Cell>
        <Cell>Link Expiry Date</Cell>
        <Cell>Action</Cell>

    </Row>
    {data_list.map((d) => {
        return (
            <Row>
                <Cell>{d.AssignmentId}</Cell>                                
                <Cell>{d.Date}</Cell>
                <Cell>{d.URL}</Cell>                                
                <Cell>{d.Remarks}</Cell>  
                <Cell>{d.Sequence}</Cell>
                <Cell>{d.LinkExpiryDate}</Cell>
                <Cell><Link 
                onClick={() => {
                    fnOnlick(d.ILO_Id)
                }}
                 className="btn btn-primary">Edit</Link>&nbsp;
                <Link onClick={() => {
                    fnOnDelete(d.ILO_Id)
                }} className="btn btn-danger">Delete</Link>
                </Cell>                                
                
            </Row>
        )
    })}

</StickyTable>


</div>
            </div>
            
        
        
    );

}
export default ILOBatchRecording