import React, { useEffect } from 'react';
import { Icon, Loader, Button, Modal, Table, Label, Input, Popup, Container } from 'semantic-ui-react'
import '../../anjali/ilom.css'
import './HomepageStyle.css';

import { NotificationContainer, NotificationManager } from 'react-notifications';


const axios = require('axios');

export default function Assignment() {
    const [data_list, set_data_list] = React.useState([])
    const [loading, set_loading] = React.useState(true)

    useEffect(() => {
        //set_loading(true)
        //get_data();
    }, []);


    async function get_data() {
        set_loading(true)
        let d = localStorage.getItem("emp_data");
        let { data } = await axios.get('yusuf/get_assignment?t_id=' + JSON.parse(d).trainer_id + '')
        if (data.length > 0) {
            set_loading(false)
            set_data_list(data)
        }
        else {
            // alert("NO DATA")
            set_loading(false)

        }

    }



    return (

        <div style={{ width: '80vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>

            {/* HEADER */}
            {/* <div style={{
                padding: 20,
                height: '8vh', backgroundColor: '#fafafb', width: '100vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>My Assignments</span>
            </div> */}

            {/* FITER SECTION */}
            <div style={{ display: 'flex', paddingLeft: 43, paddingTop: 20 }}>






            </div>

            <div style={{ display: loading ? 'none' : 'block', padding: 50,overflow:'scroll' }}>




                {data_list.map((data) => {
                    return (
                        <List_ d={data}></List_>
                    )
                })}



            </div>


            {/* LOADER */}
            {/* <div style={{
                display: loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                flexDirection: 'column', height: '90vh'
            }}>
                <Loader active inline size='massive'>getting data...</Loader>

            </div> */}
            <NotificationContainer />

        </div>

    );
}



function List_(props) {
    const [client_data_list, set_client_data_list] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [cce_email, set_cce_email] = React.useState(props.d.cce_email)

    async function Send(header_id) {
        //alert('mail send to ' + cce_email + '' + '---' + header_id)

        let { data } = await axios.get('yusuf/send_mail?header_id=' + header_id + '&cce_email=' + cce_email + '')
        alert(JSON.stringify(data))

    }

    async function get_client_info(id) {
        let { data } = await axios.get('yusuf/get_data?id=' + id + '&type=get_assignment_student')
        if (data.length > 0) {
            //alert(JSON.stringify(data))
            setOpen(true)
            set_client_data_list(data)

        }
        else {
            NotificationManager.info('Student Detail not updated', 'missing');

            //alert('no student fill the form yet')
        }

    }

    return (
        <div>


            {/* <div>
                <Modal
                    style={{ marginTop: '30px', marginLeft: '19%' }}
                    basic
                    closeIcon={<Icon color="orange" name='close' />}
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}

                >
                    <Modal.Actions>

                    </Modal.Actions>
                    <Modal.Content scrolling>
                        <Container style={{ width: 800 }}>

                            <div style={{
                                display: 'flex',
                                padding: 20,
                                flexDirection: 'column',
                                borderRadius: 5,
                                backgroundColor: '#fff',

                                // height: '100vh'
                            }}>

                                <Table celled fixed singleLine>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Email</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                           
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>



                                        {
                                            client_data_list.map((d) => {
                                                // alert(JSON.stringify(d))
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell>{d.Email}</Table.Cell>
                                                        <Table.Cell>{d.Name}</Table.Cell>
                                                    
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>
                                </Table>

                            </div>
                        </Container>
                    </Modal.Content>

                </Modal>

            </div>

            <div style={{
                'box-shadow': '10px 10px 15px #aaaaaa',
                borderRadius: 5,
                backgroundColor: '#00b5ad', margin: 10, padding: 20, display: 'flex', flexDirection: 'row',
                color:'#fff'
            }}>


                <span style={{ flex: 2, fontWeight: 'bolder' }}>
                    {props.d.date}
                    <br></br>
                    <span style={{opacity:0.7,fontSize:12}}>
                    {props.d.day}
                    </span>
                   
                </span>
                
                <span style={{ flex: 2, fontWeight: 'bolder' }}>
                    {props.d.time}
                    <br></br>
                    <span style={{opacity:0.7,fontSize:12}}>
                    24 hour format
                    </span>
                   
                </span>

                <span style={{  flex: 2, fontWeight: 'bolder' }}>
                    {props.d.c_name} ({props.d.assignment})
                </span>

                <span style={{ cursor: 'pointer', flex: 2, fontWeight: 'bolder' }}>
                    <Label onClick={() => { get_client_info(props.d.assignment) }} > {'\u00A0'} Student Details {'\u00A0'} <Icon color='teal' name='info' /> </Label>

                </span>



            </div> */}

        </div>
    )
}