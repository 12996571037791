import React from "react"
import CallForSignature from "./CallForSignature.js"
import "../elements/SecondFooter.css"

const SecondFooter = (props) => {
    return (
        <>
            <tr>
                <td colSpan={4}>
                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style={{ border: "none" }}>
                        <tbody>
                            <tr>
                                <td width="54%">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" className="down" style={{ borderRight: "none", borderBottom: "none" }}>
                                        <tbody>
                                            <tr><td colspan="2">&nbsp;</td></tr>

                                            <tr>
                                                {(props.invoiceNumber.includes("DXB") || props.invoiceNumber.includes("FZLLC")) ? (<td className="tdDeclarate"><span> <strong>Dubai Licence # : 20528</strong></span> <br></br><strong>Declaration</strong> <br /><span>Late payment processing fee of 24% P.A. compounded quarterly</span></td>) : <td className="tdDeclarate"><strong>Declaration</strong> <br /><span>Late payment processing fee of 12% P.A. compounded quarterly</span></td>}
                                            </tr>
                                            <tr>
                                                <td colspan="2" style={{ paddingBottom: "3.2%" }}>&nbsp;</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td width="46%" valign="bottom">
                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style={{ borderBottom: "0", borderRight: "0" }}>
                                        <tbody>
                                            <tr> <td colspan="2" className="tdAuthor"> For <span>{props.KoeName} </span></td></tr>

                                            <tr>
                                                <td colspan="2" align="right" className="tdAuthors" style={{ height: "50px", float: "right" }}>
                                                    {props.invoiceNumber.includes("KSCAD") ? null :
                                                        (
                                                            <CallForSignature invoiceNumber={props.invoiceNumber} KoeName={props.KoeName} />
                                                        )}
                                                </td>
                                            </tr>
                                            <tr> <td colspan="2" className="tdAuthor"> Authorised Signatory</td></tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>


        </>


    )
}

export default SecondFooter