import React, { useEffect } from 'react'
import Select from 'react-select'
const axios = require("axios");

const CustomDropDownMulti = (props) => {
    const [ct, set_ct] = React.useState([]);

    useEffect(() => {
        FetchData();
    }, []);

    function FetchData() {
        axios.post(props.link).then(function (response) {
            set_ct(response.data);
        })
    }

    const SetVendorValueList = (e) => {
        var arrValue = []; var arrName = []
        if (e != null) {
            e.forEach(d => {
                arrValue.push(d.value); arrName.push(d.label);
            })
        }
        send_to_parent(arrName, arrValue)
    };

    function send_to_parent(text, value) {
        props.passChildData(text, value);
    }

    return (
        <Select options={ct}
            placeholder={props.placeholder}
            isMulti={true}
            value={
                props.svalue.length > 0 ? props.svalue.map((ele) => ct[ct.map((o) => o.value).indexOf(ele)]) : null
            }
            isDisabled={props.disabled != null ? props.disabled : false}
            onChange={(e, value) => { SetVendorValueList(e) }}
        />
    )
}

export default CustomDropDownMulti