import React, { useEffect } from 'react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Icon, Loader, Button, Modal, Table, Container, Label, Input } from 'semantic-ui-react'
import '../../anjali/ilom.css'

import {NotificationContainer, NotificationManager} from 'react-notifications';


const axios = require('axios');

export default function Mark_Availibility() {

    //const [link_placeholder, seset_link_placeholdert_link] = React.useState('')
    const [data_list, set_data_list] = React.useState([])
    const [loading, set_loading] = React.useState(true)
    const [date, set_date] = React.useState('');
    const [remarks, set_remarks] = React.useState('');
    //const [trainer_id, set_trainer_id] = React.useState(0);

    

    useEffect(() => {
        set_loading(true)
        get_data();
    }, []);

 
function cell_click(){}

    async function get_data() {
        set_loading(true)

        let d = localStorage.getItem("emp_data");

        let { data } = await axios.get('yusuf/get_fl_availability?t_id=' + JSON.parse(d).trainer_id + '')
        if (data.length > 0) {
            set_loading(false)
            set_data_list(data)
        }
        else {
           // alert("NO DATA")
            set_loading(false)

        }

    }

    async function mark_available(){
        let d = localStorage.getItem("emp_data");
        //alert(JSON.stringify(d))
        if (d != null) {
            try{
                let { data } = await axios.get('yusuf/mark_available?date=' + date + '&remarks=' + remarks + '&t_id=' + JSON.parse(d).trainer_id + '&t_name=' + JSON.parse(d).UserName + '')
                if (data.length > 0) {
                    NotificationManager.success('Your availablity been marked.', 'Done');

                   // alert(JSON.stringify(data) )
                   get_data();

                 
                }

            }
            catch(err){
            alert(JSON.stringify(err))
            }
     
        }
     
      }

      async function mark_un_available(id) {
          alert(id)
        let { data } = await axios.get('yusuf/get_data?id=' + id + '&type=mark_un_available')
        if (data.length > 0) {
            //alert(JSON.stringify(data))
            NotificationManager.info('Marked Unavailable', 'Done');
            get_data();


        }
        else {
            NotificationManager.info('Somrthing went wrong please try after sometime', 'Info');

            //alert('no student fill the form yet')
        }

    }
      

    return (

        <div style={{ width: '80vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>

            {/* HEADER */}
            <div style={{
                padding: 20,
                height: '8vh', backgroundColor: '#fafafb', width: '100vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>Mark Availability</span>
            </div>

            {/* FITER SECTION */}
            <div style={{ display: 'flex', paddingLeft: 43, paddingTop: 20 }}>

                <div style={{ margin: 10 }}>
                    <SemanticDatepicker placeholder="Available Date" onChange={(e, v) => {
                        //alert(JSON.stringify(v)) 
                        set_date(v.value.toLocaleString("en-US"))
                    }} />

                </div>

                <div style={{ margin: 10 }}>
                <Input
                   // label={{ icon: 'asterisk' }}
                    labelPosition='right corner'
                    onChange={(e) => {
                        set_remarks(e.target.value)

                    }}
                    placeholder='Remarks' />
                </div>
             
           
                <div style={{ margin: 10 }}>

                    <Button onClick={() => { mark_available() }} color="teal" content='Mark Available' icon='check' labelPosition='right' />

                </div>

            </div>

            <div style={{ display: loading ? 'none' : 'flex', padding: 50 }}>

                <Table celled >
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Remarks</Table.HeaderCell>
                            <Table.HeaderCell>Available</Table.HeaderCell>
                     


                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data_list.map((d) => {
                            return (
                                <Table.Row >
                                    <Table.Cell onClick={() => { cell_click(d.ILOM_Name, 'assignments') }} style={{ textAlign: 'center' }} >{d.date}</Table.Cell>
                                    <Table.Cell onClick={() => { cell_click(d.ILOM_Name, 'students') }} style={{ textAlign: 'center' }} >{d.remarks}</Table.Cell>
                                    <Table.Cell>
                                    <Button size='tiny' onClick={() => { mark_un_available(d.id) }}  content='Mark Un-Available' icon='close' labelPosition='right' />

                                    </Table.Cell>

                                </Table.Row>
                            )
                        })}
                    </Table.Body>

                </Table>
            </div>

    
            {/* LOADER */}
            <div style={{
                display: loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                flexDirection: 'column', height: '90vh'
            }}>
                <Loader active inline size='massive'>getting data...</Loader>

            </div>
            <NotificationContainer/>

        </div>

    );
}


