import React, { useEffect } from "react";
import ReactDOM from 'react-dom';
import { Typography, Grid, Box, Checkbox } from "@material-ui/core";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  Icon,
  Loader,
  Button,
  Modal,
  Table,
  Header,
  Input,
  Label,
  Segment,
  Dropdown
} from "semantic-ui-react";
import "../../anjali/ilom.css";
import "./HomepageStyle.css";
import InputBase from "@material-ui/core/InputBase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from "react-router-dom";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import HeaderMenu from "../../Nishant/Freelancer/Reusable/HeaderMenu";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import SweetAlert from "react-bootstrap-sweetalert";
import { getDate } from "@material-ui/x-grid-data-generator";
import { red } from "@material-ui/core/colors";

const axios = require("axios");
const serialize = require('form-serialize');

export default function Add_Skill() {
  //#region 
  let raw_data =
    '[{"CId":235,"magic_number":0,"CName":"AZ-900T01: Microsoft Azure Fundamentals (1 day)","is_technology":0,"has_child":null,"Frequency":199.00},{"CId":9055,"magic_number":0,"CName":"AZ-104T00-A: Microsoft Azure Administrator","is_technology":0,"has_child":null,"Frequency":79.00},{"CId":6117,"magic_number":0,"CName":"AZ-400T00-A: Designing and Implementing Microsoft DevOps solutions","is_technology":0,"has_child":null,"Frequency":71.00},{"CId":3964,"magic_number":0,"CName":"AZ-300 Microsoft Azure Architect Technologies","is_technology":0,"has_child":null,"Frequency":59.00},{"CId":9051,"magic_number":0,"CName":"AZ-204: Developing Solutions for Microsoft Azure","is_technology":0,"has_child":null,"Frequency":53.00},{"CId":2524,"magic_number":0,"CName":"Certified Ethical Hacker (CEH v10)","is_technology":0,"has_child":null,"Frequency":49.00},{"CId":7151,"magic_number":0,"CName":"DP-200T01: Implementing an Azure Data Solution","is_technology":0,"has_child":null,"Frequency":47.00},{"CId":6116,"magic_number":0,"CName":"AZ-500: Microsoft Azure Security Technologies","is_technology":0,"has_child":null,"Frequency":45.00},{"CId":742,"magic_number":0,"CName":"Certified Information Systems Security Professional (CISSP)","is_technology":0,"has_child":null,"Frequency":30.00},{"CId":8225,"magic_number":0,"CName":"DP-100T01: Designing and Implementing a Data Science Solution on Azure","is_technology":0,"has_child":null,"Frequency":29.00}]';
  let top_10_courses = JSON.parse(raw_data);
  //const [link_placeholder, seset_link_placeholdert_link] = React.useState('')
  // const [state, setState] = React.useState({
  //   checkbox1: false,
  //   checkbox2: false,
  //   checkbox3: false,
  //   checkbox4: false,
  //   checkbox5: false,
  //   checkbox6: false,
  //   checkbox7: false,
  //   checkbox8: false,
  // });

  // const handleChange = (event) => {
  //   setState({ ...state, [event.target.name]: event.target.checked });
  // };

  const [AAI, setcheckboxAAI] = React.useState(false);
  const [courseware_prepax, setcourseware_perpax] = React.useState(false);
  const [dc_prepax, setdc_perpax] = React.useState(false);

  const [CCSI, setcheckboxCCSI] = React.useState(false);
  const [VCI, setcheckboxVCI] = React.useState(false);
  const [RHCI, setcheckboxRHCI] = React.useState(false);
  const [MCT, setcheckboxMCT] = React.useState(false);
  const [WantToTravel, setcheckboxWantToTravel] = React.useState(false);
  const [NotWantToTravel, setcheckboxNotWantToTravel] = React.useState(false);
  const [NightBatches, setcheckboxNightBatches] = React.useState(false);
  const [data_list, set_data_list] = React.useState([]);
  const [loading, set_loading] = React.useState(true);
  const [date, set_date] = React.useState("");
  const [remarks, set_remarks] = React.useState("");
  //const [trainer_id, set_trainer_id] = React.useState(0);
  const [currencyId, set_CurrencyId] = React.useState(0);
  const [currencyname, set_CurrencyName] = React.useState("");
  const [currency, set_currency] = React.useState([]);

  const [searchResults, setSearchResults] = React.useState([]);
  const [cname, set_cname] = React.useState("");
  const [cid, set_cid] = React.useState(0);
  const [total_question, set_total_question] = React.useState("");
  const [show_list, set_show_list] = React.useState("none");
  const [open, setOpen] = React.useState(false)

  const [cname_comm, set_comm_cname] = React.useState("");
  const [courseId, set_courseid] = React.useState("");
  const [ModelTitle, set_modelTitle] = React.useState("");
  // const [pwdCW, setpwdCW] = React.useState('0');
  // const [pwdLabs, setLabs] = React.useState('0');
  const [CostFor, setCostFor] = React.useState('1');
  const [DCTypeList, setDCType] = React.useState([]);
  const [Currency, setCurrency] = React.useState([]);
  const [CourseWareType, setCourseWareType] = React.useState([]);
  const [CoursewareCurrency, setCoursewareCurrency] = React.useState([]);
  // const [DailyRateForTravalling, setDailyRateForTravalling] = React.useState();
  // const [SkillLevel, setSkillLevel] = React.useState();
  // const [CoursewareCost, setCoursewareCost] = React.useState();
  // const [DCTypeCost, setDCTypeCost] = React.useState();
  // const [DCTypeValue, setDCTypeValue] = React.useState('');
  // const [CurrencyValue, setCurrencyValue] = React.useState('');
  // const [CourseWareTypeValue, setCourseWareTypeValue] = React.useState('');
  const [PerDayCharge, SetPerDayCharge] = React.useState('');
  const [CommonCharges, setCommonCharges] = React.useState('');
  const [DCCharge, setDCCharge] = React.useState('');
  const [CoursewareCurrencyValue, setCoursewareCurrencyValue] = React.useState('');
  const src = useLocation().search;
  const name = new URLSearchParams(src).get('DPC');
  //#region 
  useEffect(() => {
    set_loading(true);
    get_DCType();
    GetCurrency();
    GetCourseWareType();
    get_data();
    check_Dpc();
    get_currency();
  }, []);

  function check_Dpc() {
    if (name == 1) {
      alert('Password updated succesfully');
    }
  }
  function get_DCType() {
    axios.get("Nishant/GetDCType")
      .then(function (response) {
        setDCType(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function GetCurrency() {
    axios.get("Nishant/GetCurrency")
      .then(function (response) {
        setCurrency(response.data);
        setCoursewareCurrency(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function GetCourseWareType() {
    axios.get("Nishant/GetCourseWareType")
      .then(function (response) {
        setCourseWareType(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function search(d) {
    if (d.length != "") {
      set_show_list("");
      setSearchResults.value = null;
      let serstr = "";
      axios
        .get("yusuf/Proc_get_CLT_Search?text=" + d.toString() + "")
        .then(function (response) {
          // let obj = JSON.parse(response.data);
          //alert(JSON.stringify(response.data)) //total_question
          setSearchResults(response.data);
        })
        .catch(function (error) {
          alert(error);
        })
        .then(function () {
          // always executed
        });
    }
  }

  function cell_click() { }

  async function get_data() {
    set_loading(true);

    let d = localStorage.getItem("emp_data");

    let { data } = await axios.get(
      "yusuf/get_skill?t_id=" + JSON.parse(d).trainer_id + ""
    );
    console.log(data)
    if (data.length > 0) {

      set_loading(false);
      set_data_list(data);
    } else {
      // alert("NO DATA")
      set_loading(false);
    }

    axios.get("yusuf/get_FreelancerData?t_id=" + JSON.parse(d).trainer_id + "")
      .then(function (response) {
        // handle the response data here
        let data2 = response.data;
        setcheckboxAAI(response.data[0].AAI)
        setcheckboxCCSI(response.data[0].CCSI)
        setcheckboxMCT(response.data[0].MCT)
        setcheckboxRHCI(response.data[0].RHCI)
        setcheckboxVCI(response.data[0].vci)
        setcheckboxNotWantToTravel(response.data[0].DontWantTravel)
        setcheckboxWantToTravel(response.data[0].WantToTravel)
        setcheckboxNightBatches(response.data[0].NightBatches)
      })
      .catch(function (error) {
        // handle errors here
        console.log(error);
      });

  }

  async function mark_available() {
    let d = localStorage.getItem("emp_data");
    //alert(JSON.stringify(d))
    if (d != null) {
      try {
        let { data } = await axios.get(
          "yusuf/mark_available?date=" +
          date +
          "&remarks=" +
          remarks +
          "&t_id=" +
          JSON.parse(d).trainer_id +
          "&t_name=" +
          JSON.parse(d).UserName +
          ""
        );
        if (data.length > 0) {
          NotificationManager.success("Skill added successfully", "Done");

          // alert(JSON.stringify(data) )
          get_data();
        }
      } catch (err) {
        alert(JSON.stringify(err));
      }
    }
  }

  async function add_skill() {
    if (cid != null && cid != "" && cid != 0) {
      let d = localStorage.getItem("emp_data");

      let { data } = await axios.get(
        "yusuf/add_skill?t_id=" + JSON.parse(d).trainer_id + "&cid=" + cid + ""
      );
      if (data.length > 0) {
        NotificationManager.success("Skill added successfully", "Done");
        get_data();
      }
    }
    else NotificationManager.error("Please select any course", "Error");
  }

  async function add_commercial_courses(e) {
    e.preventDefault();
    let d = localStorage.getItem("emp_data");

    var obj = serialize(document.querySelector('#Onlineform'), { hash: true });
    if (obj['PerDayCharge'] < 15000) {
      alert("Please Contact Koenig Team for cost addition on fm@koenig-solutions.com.");
      return;
    }
    if (!obj.hasOwnProperty('course_ware_cost_perpax')) {
      obj.course_ware_cost_perpax = 'false'
    }
    if (!obj.hasOwnProperty('dc_cost_perpax')) {
      obj.dc_cost_perpax = 'false'
    }
    obj.currencyid = currencyId;
    obj.t_id = JSON.parse(d).trainer_id;
    obj.courseId = courseId;
    //console.log(d)
    console.log('this is ', obj)
    console.log(ModelTitle)

    let { data } = await axios.post("Nishant/CommercialCourseDetail", obj);
    console.log(data);
    if (data.length > 0) {
      if (obj.PerDayCharge < 15000) {
        SendMail1('rms@koenig-solutions.com', 'FM@koenig-solutions.com', '', 'Ashish.kumar@koenig-solutions.com', 'Course Added With Cost Less Than 15000', JSON.parse(d).UserName + ' has added course ' + ModelTitle + ' with cost ' + obj.PerDayCharge)
      }
      NotificationManager.success("Course Updated successfully", "Done");
      get_data()
      setOpen(false)
    }
  }

  async function SendMail1(uname, to, cc, bcc, subject, html) {
    try {
      var obj = {
        _uname: uname,
        _to: to,
        _cc: cc,
        _bcc: bcc,
        _subject: subject,
        _html: html
      };
      let { data } = await axios.post("Nishant/sendemail", obj);
    } catch (er) {
      //alert(JSON.stringify(er));
    }
  }

  function EditSkills(Id, Coursename, DailyRate, CostPerDay, CoursewareCost, DCTypeCost, Currency) {
    setOpen(true)
    set_courseid(Id);

    //GetDetailData(Id, Coursename,flCourseId);
    set_modelTitle(Coursename)
    SetData(Coursename, DailyRate, CostPerDay, CoursewareCost, DCTypeCost, Currency)
  }
  //#endregion
  async function GetDetailData(Id, CourseName, flCourseId) {

    set_modelTitle('Getting Data from server');
    let d = localStorage.getItem("emp_data");

    axios.get("Nishant/getCourseDetailedData?flCourseId=" + flCourseId)
      .then(function (response) {
        console.log(response.data)

        SetData(response.data);
        set_modelTitle(CourseName)
      })
  }
  async function get_currency() {
    await axios
      .get("Nishant/getCurrencycode")
      .then(function (response) {
        console.log(response.data);
        set_currency(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }
  function SetData(Coursename, DailyRate, CostPerDay, CoursewareCost, DCTypeCost, Currency) {
    if (DailyRate != "" || CostPerDay != "" || CoursewareCost != "" || DCTypeCost != "" || Currency != "") {

      console.log(Currency)
      SetPerDayCharge(DailyRate);
      setCommonCharges(CoursewareCost)
      // setCoursewareCurrencyValue(Currency)
      // setCoursewareCurrency(Currency)
      setCoursewareCurrencyValue(Currency)
      setDCCharge(DCTypeCost)
      setCurrency(Currency)
      // set_comm_cname(data[0].CourseName);
      // setDailyRateForTravalling(data[0].DailyRate);
      // setSkillLevel(data[0].SkillLevel);
      // setpwdCW(data[0].ProvideCourseWare.toString());
      // setLabs(data[0].ProvideLab.toString());
      // setCourseWareTypeValue(data[0].CoursewareType);
      // setCoursewareCurrencyValue(data[0].CourseWareCurrency);
      // setCoursewareCost(data[0].CourseWareCost);
      // setDCTypeValue(data[0].DCType);
      // setCurrencyValue(data[0].DCTypeCurrency);
      // setDCTypeCost(data[0].DCTypeCost);
      let cFor = '1';
      if (CostPerDay == '1') cFor = '1'
      else if (CostPerDay == '2') cFor = '2'
      else if (CostPerDay == '3') cFor = '3'
      setCostFor(cFor);
      //setDCTypeCost(data[0].CourseName);
    }
    else {
      SetPerDayCharge('');
      setCommonCharges('')
      setCoursewareCurrencyValue('')
      setDCCharge('')
      // set_comm_cname('');
      // setDailyRateForTravalling('');
      // setSkillLevel('');
      // setpwdCW('0');
      // setLabs('0');
      // setCourseWareTypeValue('');
      // setCoursewareCurrencyValue('');
      // setCoursewareCost('');
      // setDCTypeValue('');
      // setCurrencyValue('');
      // setDCTypeCost('');
      setCostFor('1')
    }
  }

  const SaveData = async () => {
    let d = localStorage.getItem("emp_data");
    console.log(d);
    await axios.get(
      "yusuf/add_Preferences?t_id=" + JSON.parse(d).trainer_id + "&aai=" + AAI + "&vci=" + VCI + "&rhci=" + RHCI + "&ccsi=" + CCSI + "&mct=" + MCT + "&WantToTravel=" + WantToTravel + "&NotWantToTravel=" + NotWantToTravel + "&NightBatches=" + NightBatches + ""
    ).then(function (response) {
      // handle the response data here
      alert("Data updated succesfully")
      get_data()
    }).catch(function (error) {
      // handle errors here
      console.log(error);
    });

  }

  // const SendMail = (d, EmployeeId) => {
  //   console.log(EmployeeId);
  //   axios.get("Nishant/GetDataForSendLAEmail?EmpId=" + EmployeeId).then(function (response) {
  //     axios.get("Nishant/GetPrevDataForLaAlgo?EmpId=" + EmployeeId).then(function (response2) {
  //       console.log(response.data[0]);
  //       var Subject = 'LA Algo changed for ' + response.data[0].Name + ' by ' + d.CreatedBy;
  //       var SendTo = "sakshi.dhawan@koenig-solutions.com;";

  //       var HtmlContent = ReactDOMServer.renderToStaticMarkup(ReturnHtml(d, response.data[0], response2));
  //       // console.log(HtmlContent);
  //       SendMail1('Ashish.Kumar@Koenig-Solutions.com', SendTo, 'monika.chopra@koenig-solutions.com;' + d.OffEmail, 'Ashish.Kumar@Koenig-Solutions.com;nishant.sinha@koenig-solutions.com;', Subject, HtmlContent)
  //     }).catch(function (error) {

  //       alert(error);
  //     });
  //   }).catch(function (error) {
  //     alert(error);
  //   });
  // }

  function DeleteSkill(Id, Coursename, FLCourseId) {
    if (window.confirm("Do you really want to delete " + Coursename + "?")) {
      let d = localStorage.getItem("emp_data");
      axios.get(
        "Nishant/DeleteSkill?flCourseId=" + FLCourseId
      ).then(function (res) {
        console.log(res);
        if (res.status == 200) {
          get_data();
          NotificationManager.success("successfully Deleted", "Done");
        }
      });
    }
  }

  const AddSuggestedCourse = () => {
    const enteredName = prompt('Please Enter Course Name')
    if (enteredName != null && enteredName.trim().length != 0) SendEmail(enteredName.trim())
    else NotificationManager.error("You must have enter course name", "error");
    // ReactDOM.render(<SweetAlert input required showCancel
    //   inputType="textarea"
    //   title="Enter Course Name"
    //   validationMsg="You must have enter course name!"
    //   onConfirm={(response) => GetMeetingText(response)} onCancel={() => hideAlert()} />, document.getElementById('getSweetModel'))
  }

  const hideAlert = () => {
    ReactDOM.render("", document.getElementById('getSweetModel'));
  }

  const GetMeetingText = (res) => {
    if (res.trim.length > 0) SendEmail(res.trim())
    else NotificationManager.error("You must have enter some text", "error");
  }

  async function SendEmail(res) {
    if (window.confirm("Do you want to add " + res + " as new course?")) {
      let d = localStorage.getItem("emp_data");
      let TName = JSON.parse(d).UserName + " wants to add there course named(" + res + ")."


      var obj = {
        _uname: 'fm@koenig-solutions.com',
        _to: 'fm@koenig-solutions.com',
        _cc: '',
        _bcc: 'ashish.kumar@koenig-solutions.com',
        _subject: 'New Course Request',
        _html: JSON.parse(d).UserName + " wants to add a new course named(" + res + ")."
      }
      console.log(obj)
      let { data } = await axios.post("Nishant/sendemail", obj);

      if (data != null) {
        NotificationManager.success("Mail sent successfully");
      }
      hideAlert();
    }
    else hideAlert();
  }
  //#endregion
  //add_commercial_courses
  return (
    <div
      style={{
        width: "80vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
      }}
    >
      {/* HEADER */}
      <HeaderMenu HeaderText="My Skills/Details"></HeaderMenu>
      {/* FITER SECTION */}
      <div style={{ display: "flex", paddingLeft: 43, paddingTop: 20 }}>
        <div style={{ margin: 10 }}>
          <div className="divSearchBoxDesktop">
            <div className="divSearchBarDesktop">
              <span className="SearchBarDesktop">
                <InputBase
                  onChange={(t) => {
                    search(t.target.value);
                    set_cname(t.target.value);
                  }}
                  value={cname}
                  className="inputSearchDesktop"
                  placeholder="Select Course"
                />
              </span>
              <span className="SearchBoxItemDesktop">
                {searchResults.map((item) => {
                  return (
                    <span
                      key={item.CId}
                      onClick={() => {
                        //alert(item.CId)
                        set_cname(item.CName);
                        set_cid(item.CId);
                        set_total_question(item.total_question);
                        set_show_list("none");
                        setSearchResults([]);
                      }}
                      className="listSearchDesktop"
                    >
                      {item.CName}
                    </span>
                  );
                })}
              </span>
              <div className="divSelectNumberDesktop"></div>
            </div>
          </div>
        </div>
        <div id="getSweetModel"></div>
        <div style={{ margin: 13 }}>
          <Button
            onClick={() => {
              add_skill();
            }}
            color="teal"
            content="Add Skill"
            icon="plus"
            labelPosition="right"
          />
        </div>
        <div style={{ margin: 13 }}>
          <Button
            onClick={() => {
              AddSuggestedCourse()
            }}
            color="teal"
            content="Request To Add Course"
            icon="plus"
            labelPosition="right"
          />
        </div>
      </div>
      <h3 style={{ fontWeight: "bolder" }} class="ml-4">Certification & Preferences</h3>
      <div className="mb-5 ml-4 mr-5">
        <Box border={1} borderRadius={8} p={2}>
          {/* <Grid item>
            <Typography variant="h5" gutterBottom>
              Preferences
            </Typography>
          </Grid> */}
          <Grid container spacing={2}>

            <Grid item xs={3} key={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={AAI}
                    onClick={(e) => { setcheckboxAAI(!AAI) }}
                    name="checkbox1"
                  />
                }
                label="AAI"
              />
            </Grid>
            <Grid item xs={3} key={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={CCSI}
                    onClick={(e) => { setcheckboxCCSI(!CCSI) }}
                    name="checkbox2"
                  />
                }
                label="CCSI"
              />
            </Grid>
            <Grid item xs={3} key={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={VCI}
                    onClick={(e) => { setcheckboxVCI(!VCI) }}
                    name="checkbox3"
                  />
                }
                label="VCI"
              />
            </Grid>
            <Grid item xs={3} key={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={RHCI}
                    onClick={(e) => { setcheckboxRHCI(!RHCI) }}
                    name="checkbox4"
                  />
                }
                label="RHCI"
              />
            </Grid>
            <Grid item xs={3} key={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={MCT}
                    onClick={(e) => { setcheckboxMCT(!MCT) }}
                    name="checkbox8"
                  />
                }
                label="MCT"
              />
            </Grid>
            <Grid item xs={3} key={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={NightBatches}
                    onClick={(e) => { setcheckboxNightBatches(!NightBatches) }}
                    name="checkbox5"
                  />
                }
                label="I am ok to deliver night batches"
              />
            </Grid>
            <Grid item xs={3} key={7}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={WantToTravel}
                    onClick={(e) => { setcheckboxWantToTravel(!WantToTravel) }}
                    name="checkbox6"
                  />
                }
                label="Ok to travel"
              />
            </Grid>
            <Grid item xs={3} key={8}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={NotWantToTravel}
                    onClick={(e) => { setcheckboxNotWantToTravel(!NotWantToTravel) }}
                    name="checkbox7"
                  />
                }
                label="Not ok to travel"
              />
            </Grid>
            <Grid item xs={12} key={9}>
              <center><input style={{ width: "20%" }} type="button" className="btn btn-success form-control" value="Update" onClick={() => SaveData()}></input></center>
            </Grid>
          </Grid>
        </Box>
      </div>
      <h3 style={{ fontWeight: "bolder" }} class="ml-4">My Skills</h3>
      <div style={{ display: loading ? "none" : "block" }} class="ml-4 mr-5 mb-4">
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="text-left">Skill (Enter costing in INR only)</Table.HeaderCell>
              <Table.HeaderCell className="text-left">Daily Rate</Table.HeaderCell>
              <Table.HeaderCell className="text-left">Course ware Cost</Table.HeaderCell>
              <Table.HeaderCell className="text-left">DC Cost</Table.HeaderCell>
              <Table.HeaderCell className="text-left">Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data_list.map((d) => {
              return (
                <Table.Row>
                  <Table.Cell>{d.c_name}</Table.Cell>
                  <Table.Cell>{d.DailyRate}</Table.Cell>

                  <Table.Cell>{d.CourseWareCost}
                    <Checkbox checked={d.course_ware_cost_perpax}
                      onClick={(e) => { }}
                      name="perpax" ></Checkbox>
                    perpax

                  </Table.Cell>
                  <Table.Cell>{d.DCTypeCost}
                    <Checkbox checked={d.dc_cost_perpax}
                      onClick={(e) => { }}
                      name="perpax" ></Checkbox>
                    perpax
                  </Table.Cell>
                  <Table.Cell className="badge">
                    <a href="javascript:void(0)" className="btn-sm btn-success mr-2" onClick={() => EditSkills(d.Id, d.c_name, d.DailyRate, d.CostPerDay, d.CourseWareCost, d.DCTypeCost, d.Currency)}><i className="icon edit"></i> Update</a>
                    <a href="javascript:void(0)" className="btn-sm btn-danger" onClick={() => DeleteSkill(d.Id, d.c_name, d.FLCourseId)}><i className="icon delete"></i> Delete</a>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
      {/* <div className="mb-4 ml-4 mr-5 checkbox-grid" style={{ border: "1px solid", padding: 10 }}>
        <label className="col-md-3" htmlFor="interest1">
          <input type="checkbox" id="interest1" />
          AAI
        </label>
        <label className="col-md-3" htmlFor="interest2">
          <input type="checkbox" id="interest2" />
          CCSI
        </label>
        <label className="col-md-3" htmlFor="interest3">
          <input type="checkbox" id="interest3" />
          VCI
        </label>
        <label className="col-md-3" htmlFor="interest4">
          <input type="checkbox" id="interest4" />
          RHCI
        </label>
        <label className="col-md-3" htmlFor="interest5">
          <input type="checkbox" id="interest5" />
          I am ok to deliver night batches
        </label>
        <label className="col-md-3" htmlFor="interest6">
          <input type="checkbox" id="interest6" />
          Ok to travel
        </label>
        <label className="col-md-3" htmlFor="interest7">
          <input type="checkbox" id="interest7" />
          MCT
        </label>
        <label className="col-md-3" htmlFor="interest8">
          <input type="checkbox" id="interest8" />
          Not ok to travel
        </label>
      </div> */}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Modal.Content>
          <>
            <div><h3>{'Course Name: ' + ModelTitle}</h3> </div>
            <hr></hr>
            {/* <label><b>"DA and Cab to include in daily rate, only accommodation, flight and visa (if required) will be extra."</b></label> */}
            <form id="Onlineform">
              <div class="row">
                <div className="col-md-6" style={{ display: 'none' }}>
                  <select class="form-control" value={CoursewareCurrencyValue} name="CoursewareCurrency" onChange={(e) => { setCoursewareCurrencyValue(e.target.value) }}>
                    <option key='' value="">Select Currency</option>
                    {
                      CoursewareCurrency.map((d) => {
                        return (
                          <option key={d.CurrencyCode} value={d.CurrencyCode}>{d.CurrencyName}</option>
                        );
                      })
                    }
                  </select>
                  <br></br>
                </div>
                {/* <div className="col-md-6"></div> */}
                <div className="col-md-4">
                  <label>Per Day Charges</label>
                  <div> <input onChange={(e) => {
                    SetPerDayCharge(e.target.value)
                  }} className="form-control" value={PerDayCharge} name="PerDayCharge" placeholder='Enter Per Day Charges' ></input></div>
                  <br></br>
                </div>
                <div className="col-md-2">
                  <label>Select Currency</label>
                  <div>
                    {/* drop down cost */}
                    <Dropdown
                      name="currency"
                      placeholder="Select Currency"
                      fluid
                      search
                      scrolling
                      //value={currencyId}
                      onChange={(e, { value }) => {
                        set_CurrencyId(value);
                        console.log(value);
                        console.log(e.target.innerText);
                        set_CurrencyName(e.target.innerText);
                      }}
                      selection
                      options={currency}
                      clearable
                    />
                  </div>
                  <br></br>
                </div>
                {/* <div className="col-md-6"></div> */}
                <div className="col-md-6">
                  <label>Courseware Charges Per Participant</label>
                  <div> <input onChange={(e) => {
                    setCommonCharges(e.target.value)
                  }} className="form-control" value={CommonCharges} name="CommonCharges" placeholder='Enter Courseware Charges' ></input></div>
                  <br></br>
                </div>
                {/* <div className="col-md-6"></div> */}
                <div className="col-md-6">
                  <label>DC Charges</label>
                  <RadioGroup row aria-label="CostFor" name="CostFor" value={CostFor} onChange={(e) => setCostFor(e.target.value)}>
                    <FormControlLabel value="1" control={<Radio />} label="Per Day" />
                    <FormControlLabel value="2" control={<Radio />} label="Per Participant" />
                    <FormControlLabel value="3" control={<Radio />} label="Per Participant Per Day" />
                  </RadioGroup>
                  <div> <input onChange={(e) => {
                    setDCCharge(e.target.value)
                  }} className="form-control" value={DCCharge} name="DCCharge" placeholder='Enter DC Charges' ></input></div>
                  <br></br>
                </div>
                <div className="col-md-3 mt-5">

                  <input type="checkbox"
                    checked={courseware_prepax}
                    onClick={(e) => { setcourseware_perpax(!courseware_prepax) }}
                    name="course_ware_cost_perpax"

                    style={{ border: red }}

                  />
                  <b style={{ fontSize: '15px' }}> CourseWare Cost Per Pax</b>
                </div>
                <div className="col-md-3 mt-5">
                  <input type="checkbox"
                    checked={dc_prepax}
                    onClick={(e) => { setdc_perpax(!dc_prepax) }}
                    name="dc_cost_perpax"

                    style={{}}
                  />
                  <b style={{ fontSize: '15px' }}> DC Cost Per Pax</b>
                </div>
                {/* <div className="col-md-6">
                  <div className="MuiButtonBase-root">
                    <RadioGroup row aria-label="CostFor" className="ml-3" name="CostFor" value={CostFor} onChange={(e) => setCostFor(e.target.value)}>
                      <FormControlLabel value="1" control={<Radio />} label="Is it per day cost?" />
                      <FormControlLabel value="2" control={<Radio />} label="Is it per Participant cost?" />
                      <FormControlLabel value="3" control={<Radio />} label="Is it per Participant per day cost?" />
                    </RadioGroup>
                  </div>
                </div> */}
                {/* <div class="col-md-6">
                  <div> <input
                    onChange={(e) => {
                      setDailyRateForTravalling(e.target.value)
                    }}
                    className="form-control" name="DailyTravelRate" value={DailyRateForTravalling} placeholder='You daily rate in INR excluding travel'></input></div>
                  <br></br>
                  <div className="MuiButtonBase-root">
                    <Label>Can you provide Courseware?</Label>
                    <RadioGroup row aria-label="PWDCW" className="ml-3" name="PWDCW" value={pwdCW} onChange={(e) => setpwdCW(e.target.value)}>
                      <FormControlLabel value="1" control={<Radio />} label="Yes" />
                      <FormControlLabel value="0" control={<Radio />} label="No" />
                    </RadioGroup>
                  </div>
                  <br></br>
                </div>
                <div class="col-md-6">
                  <div> <input onChange={(e) => {
                    setSkillLevel(e.target.value)
                  }} className="form-control" name="SkillLevel" value={SkillLevel} placeholder='Skill Level' ></input></div>
                  <br></br>
                  <div className="MuiButtonBase-root">
                    <Label>Can you provide Labs?</Label>
                    <RadioGroup row aria-label="PWDLab" className="ml-3" name="PWDLab" value={pwdLabs} defaultValue={pwdLabs} onChange={(e) => setLabs(e.target.value)}>
                      <FormControlLabel value="1" control={<Radio />} label="Yes" />
                      <FormControlLabel value="0" control={<Radio />} label="No" />
                    </RadioGroup>
                  </div>
                </div>
                <div class="col-md-12">
                  <Segment className="p-2 mb-3">
                    <div className="row">
                      <div className="col-md-4">
                        <select class="form-control" value={CourseWareTypeValue} name="CourseWareType" onChange={(e) => { setCourseWareTypeValue(e.target.value) }}>
                          <option key='' value="">Select Courseware Type</option>
                          {
                            CourseWareType.map((d) => {
                              return (
                                <option key={d.CoursewareId} value={d.CoursewareId}>{d.CoursewareType}</option>
                              );
                            })
                          }
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select class="form-control" value={CoursewareCurrencyValue} name="CoursewareCurrency" onChange={(e) => { setCoursewareCurrencyValue(e.target.value) }}>
                          <option key='' value="">Select Currency</option>
                          {
                            CoursewareCurrency.map((d) => {
                              return (
                                <option key={d.CurrencyCode} value={d.CurrencyCode}>{d.CurrencyName}</option>
                              );
                            })
                          }
                        </select>
                      </div>
                      <div className="col-md-4">
                        <div> <input onChange={(e) => {
                          setCoursewareCost(e.target.value)
                        }} className="form-control" value={CoursewareCost} name="CourseWareCost" placeholder='Cost' ></input></div>
                      </div>
                    </div>
                  </Segment>
                </div>
                <div class="col-md-12">
                  <Segment className="p-2 mb-3">
                    <div className="row">
                      <div className="col-md-4">
                        <select class="form-control" value={DCTypeValue} name="DCType" onChange={(e) => { setDCTypeValue(e.target.value) }}>
                          <option key='' value="">Select DC Type</option>
                          {
                            DCTypeList.map((d) => {
                              return (
                                <option key={d.DCId} value={d.DCId}>{d.DCType}</option>
                              );
                            })
                          }
                        </select>
                      </div>
                      <div className="col-md-4">
                        <select class="form-control" value={CurrencyValue} name="Currency" onChange={(e) => { setCurrencyValue(e.target.value) }}>
                          <option key='' value="">Select Currency</option>
                          {
                            Currency.map((d) => {
                              return (
                                <option key={d.CurrencyCode} value={d.CurrencyCode}>{d.CurrencyName}</option>
                              );
                            })
                          }
                        </select>
                      </div>
                      <div className="col-md-4">
                        <input onChange={(e) => {
                          setDCTypeCost(e.target.value)
                        }} className="form-control" value={DCTypeCost} name="Cost" placeholder='Cost' ></input>
                      </div>
                      <div className="col-md-12">
                        <div className="MuiButtonBase-root">
                          <RadioGroup row aria-label="CostFor" className="ml-3" name="CostFor" value={CostFor} onChange={(e) => setCostFor(e.target.value)}>
                            <FormControlLabel value="1" control={<Radio />} label="Is it per day cost?" />
                            <FormControlLabel value="2" control={<Radio />} label="Is it per pax cost?" />
                            <FormControlLabel value="3" control={<Radio />} label="Is it per pax per day cost?" />
                          </RadioGroup>
                        </div>
                      </div>
                    </div>
                  </Segment>
                </div>
                 */}
                <div class="col-md-12">
                  <div>
                    <Button color='red' onClick={() => setOpen(false)}>
                      <Icon name='remove' /> Cancel
                    </Button>
                    <Button color='green' onClick={(e) => add_commercial_courses(e)}>
                      <Icon name='checkmark' /> Save
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </>
        </Modal.Content>
      </Modal>
      {/* LOADER */}
      <div
        style={{
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          flexDirection: "column",
          height: "90vh",
        }}
      >
        <Loader active inline size="massive">
          getting data...
        </Loader>
      </div>
      <NotificationContainer />
      <ToastContainer />
    </div>
  );
}
