import React, { useState, useEffect } from 'react';
import CSDataTable from '../../../Freelancer/Reusable/CSDataTable';
import SVGLoader from '../SVGLoader';

const axios = require("axios");
const StudentDetailWithAssignment = (props) => {
    const Coulumn = [
        { text: 'Student Id', key: 'StdId', sortable: true },
        { text: 'Student Name', key: 'StudentName', sortable: true },
        { text: 'Email', key: 'Email', sortable: true },
        {
            text: 'Action',
            key: '',
            sortable: false,
            cell: d => {
                return (
                    <>
                        <div><a onClick={() => GoToLET(d.StdLoginId, d.StdPwd)} className="badge btn btn-outline-primary">GO TO LET</a></div>
                    </>
                );
            }
        },
    ]
    const [Rows, setRows] = useState([]);
    const [loading, set_loading] = React.useState(true)
    useEffect(() => {
        GetStudentDetail();
    }, [])

    const GetStudentDetail = () => {
        set_loading(true);
        axios.get("Nishant/GetStudentDetailOfAssignment?AID=" + props.AID).then(function (response) {
            setRows(response.data);
            set_loading(false);
        }).catch(function (error) {
            alert(error);
        });
    }

    const GetDataTable = () => {
        if (Rows.length > 0) return <CSDataTable Rows={Rows} Columns={Coulumn} PageSize={20} Length_menu={[20, 50, 100]}></CSDataTable>
        else return <div className="text-center"><label className="alert-danger alert">Pax details are not added</label></div>
    }

    const GoToLET = async (SID, SPWD) => {
        if (SID != '' || SPWD != '') {
            let SIDEncryptedValue = await GetEncryptedValue(SID);
            let SPWDEncryptedValue = await GetEncryptedValue(SPWD);
            if (SIDEncryptedValue != '' && SPWDEncryptedValue != '') {
                let link = "https://www.mykoenig.com/account/index?id=" + SIDEncryptedValue + "&pwd=" + SPWDEncryptedValue + "&t=1";
                window.open(link, "_blank");
            }
        }
        else alert("Student credentials are not availabe");
    }

    const GetEncryptedValue = async (Value) => {
        let V = "";
        await axios.get("Nishant/GETStudedentEncryptedValue?type=0&TextValue=" + Value).then(function (response) {
            V = response.data[0].EValue;
        }).catch(function (error) {
            alert(error);
        });
        return V;
    }

    return (
        <div className="pl-2 pr-2">
            <SVGLoader loading={loading} SVGType="Table" SVGWidth="1200" SVGHeight="560"></SVGLoader>
            {GetDataTable()}
        </div>
    )
}

export default StudentDetailWithAssignment
