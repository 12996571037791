import React from 'react'
import './Popup.css'

function Popup(props) {

     return (props.trigger) ? (
          <div className="popup">
               <div className="popup-inner">
               <a href="javascript:void(0)" className="badge btn btn-outline-primary close-btn" onClick={()=>props.setTrigger(false)}>close</a>                                
                    {props.children}
               </div>
          </div>
     ) : "";
}


export default Popup