import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const CurrencyDropdown = (props) => {
  const [city, set_city] = React.useState([]);

  useEffect(() => {
    get_city();
  }, []);

  function get_city() {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=24")
      .then(function (response) {
        set_city(response.data);
      
      })
      .catch(function (error) {
        alert(error);
      });
  }
  function send_to_parent(value) {
    //alert(value)
    props.passChildData(value);
  }

  return (
    <Dropdown
      placeholder="All Currency"
      openOnFocus={false}
      onChange={(e, { value }) => {
      
        send_to_parent(value);
      }}
      selection
      options={city}
      clearable
      style={{ width: "200px" }}
    />
  );
};

export default CurrencyDropdown;
