
import React, { useEffect, useState } from 'react';
import { Image } from "semantic-ui-react";

export default function AccessDenied() {

    return(
        <div>
            <Image
                    //size='tiny'
                    style={{ alignSelf: 'center', height: 800, width: 1000 }}
                    src={require('./images/accessdenied.jpg')} />
        </div>
    )
}