import React, { useEffect } from 'react';

import { StickyTable, Row, Cell,link } from 'react-sticky-table';
import {Link} from 'react-router-dom';
import Master from '../../../src/App.css';

import { Loader, Form, Radio, Button, Icon, Checkbox, Search } from 'semantic-ui-react'

const axios = require('axios');

export default function ILORecordingDetails(props) {
    const [loading, set_loading] = React.useState(true)
    const [data_list, set_data_list] = React.useState([])


    useEffect(() => {
        set_loading(true)        
        get_data();
    }, [props.refresh]);

    function get_data() {
        axios.get('jai/Sp_GetILORecordingDetails')
            .then(function (response) {
                //alert(JSON.stringify(response.data))
                set_loading(false)
                set_data_list(response.data)

                //alert(JSON.stringify(response.data))
                // set_city(response.data)

            })
            .catch(function (error) {
                alert(error);
            })
    }

    function fnOnlick(id){
        localStorage.setItem("id",id);
        window.location.href="/EditILORecording"
    }
    function fnOnDelete(id){
        axios.get('jai/sp_save_ilo_recordings?type=3&assignmentId=0&url=ok&date=1-jan-2020&sequence=1&remarks=ok&IsActive=0&ILO_Id='+id+'')
        .then(function (response) {
            //alert(JSON.stringify(response.data))
            set_loading(false)
            if(response.data[0].Result>0)
            {
                alert("Record deleted successfully");
                get_data();
            }
            else
            {
                alert("Opps.. Something went wrong...");   
            }

        })
        .catch(function (error) {
            alert(error);
        })
    }
    

    return (

        <div style={{ width: '77vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>

            {/* HEADER */}
           

            <div style={{ display: loading ? 'none' : 'flex' }}>

                <StickyTable style={{border: "1px solid #e5e5e5",height:"50vh", overflow: 'scroll'
}}>
                    <Row style={{ fontSize: 16, fontWeight: 'bold' }}>
                        <Cell>Assignment</Cell>
                        <Cell>Date</Cell>
                        <Cell>URL</Cell>                        
                        <Cell>Remarks</Cell>
                        <Cell>Sequence</Cell>
                        <Cell>Link Expiry Date</Cell>
                        <Cell>Action</Cell>

                    </Row>
                    {data_list.map((d) => {
                        return (
                            <Row>
                                <Cell>{d.AssignmentId}</Cell>                                
                                <Cell>{d.Date}</Cell>
                                <Cell>{d.URL}</Cell>                                
                                <Cell>{d.Remarks}</Cell>  
                                <Cell>{d.Sequence}</Cell>
                                <Cell>{d.LinkExpiryDate}</Cell>
                                <Cell><Link 
                                onClick={() => {
                                    fnOnlick(d.ILO_Id)
                                }}
                                 className="btn btn-primary">Edit</Link>&nbsp;
                                <Link onClick={() => {
                                    fnOnDelete(d.ILO_Id)
                                }} className="btn btn-danger">Delete</Link>
                                </Cell>                                
                                
                            </Row>
                        )
                    })}

                </StickyTable>

                
            </div>

            {/* LOADER */}
            <div style={{
                display: loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vw',
                flexDirection: 'column', height: '90vh'
            }}>
                <Loader active inline size='massive'>getting data...</Loader>


            </div>

        </div>


    );
}