import React, { useEffect } from "react"


const CallForLogo = (props) => {

    const CallForLogoFun = () => {
        if (props.invoiceNumber != null) {

        }
        if (props.invoiceNumber.includes("DXB") || props.invoiceNumber.includes("FZLLC")) {
            return (
                <img src="https://www.koenig-solutions.com/platinum/images/koenig-solutions-dubai.png" style={{ height: "50px", float: "right" }} ></img>
            )
        }
        else {
            return (
                <img src="https://www.koenig-solutions.com/platinum/images/koenig-solutions.jpg" style={{ height: "50px", float: "right" }} />
            )
        }
    }

    useEffect(() => {
        CallForLogoFun();
    }, [])


    return <> {CallForLogoFun()}
    </>
}

export default CallForLogo