import React from "react"

const CallForBank = (props) => {
  return <>
    {props.bankRequired ? (
      "") : <tr>
      <td style={{ border: "1px solid #b0b0b0" }}></td>
      <td style={{ border: "1px solid #b0b0b0" }}>
        {
          props.BankData.map((Data, index) => (
            <span key={index}>
              {
                Data.BankNamee != null ? <span><b>Bank Details:</b><br /> BankName : {Data.BankNamee}<br /> </span> : null}

              {
                Data.BankAddress != null ? (<span dangerouslySetInnerHTML={{ __html: " Branch Address :" + Data.BankAddress + "<br>" }}></span>) : ("")

              }

              {
                Data.AccountName != null ? (<span dangerouslySetInnerHTML={{ __html: "<br> Account Name  :" + Data.AccountName + "<br>" }}></span>) : ("")

              }

              {
                Data.AccountNumber != null ? (<span dangerouslySetInnerHTML={{ __html: "Account Number :" + Data.AccountNumber + "<br>" }}></span>) : ("")

              }

              {
                Data.IBANCode != null ? (<span dangerouslySetInnerHTML={{ __html: "IBANCode :" + Data.IBANCode + "<br>" }}></span>) : ("")

              }
              {
                Data.MICR != null ? (<span dangerouslySetInnerHTML={{ __html: "MICR :" + Data.MICR + "<br>" }}></span>) : ("")
              }

              {
                Data.SwiftCode != null ? (<span dangerouslySetInnerHTML={{ __html: "SwiftCode :" + Data.SwiftCode + "<br>" }}></span>) : ("")

              }

              {
                Data.CCY != null ? (<span dangerouslySetInnerHTML={{ __html: "CCY :" + Data.CCY + "<br>" }}></span>) : ("")

              }

              {
                Data.SchemeCode != null ? (<span dangerouslySetInnerHTML={{ __html: "SchemeCode :" + Data.SchemeCode + "<br>" }}></span>) : ("")

              }

              {
                (Data.SOLID != null) ? (" SOLID:" + Data.SOLID) : ("")
              }

              {
                (Data.INTERMEDIARY_BANK != null) != 0 ? (" INTERMEDIARY_BANK:" + Data.INTERMEDIARY_BANK) : ("")
              }

              {
                Data.BankCode != null ? (<span dangerouslySetInnerHTML={{ __html: "BankCode :" + Data.BankCode + "<br>" }}></span>) : ("")

                // (Data.BankCode != null) ? (" BankCode:" + Data.BankCode) : ("")
              }

              {
                Data.branchcode != null ? (<span dangerouslySetInnerHTML={{ __html: "Branchcode :" + Data.branchcode + "<br>" }}></span>) : ("")
                // (Data.branchcode != null) ? (" Branchcode:" + Data.branchcode) : ("")
              }

              {
                (Data.BSBNO != null) ? (" BSBNO:" + Data.BSBNO) : ("")
              }

              {
                (Data.Branch != null) ? (" Branch:" + Data.Branch) : ("")
              }

              {
                (Data.FinancialInstitution != null) ? (" Branchcode:" + Data.FinancialInstitution) : ("")
              }

              {
                (Data.Routingno != null) ? (" BSBNO:" + Data.Routingno) : ("")
              }
              <br />
              {
                (Data.IFCCODE != null) ? ("IFSC CODE:" + Data.IFCCODE) : ("")

              }
            </span>
          ))
        }


      </td>

      <td style={{ border: "1px solid #b0b0b0" }}></td>
      <td style={{ border: "1px solid #b0b0b0" }}></td>
      <td style={{ border: "1px solid #b0b0b0" }}></td>
      <td style={{ border: "1px solid #b0b0b0" }}></td>

      {props.AddColumnForSecondCurrency ? (<td style={{ border: "1px solid #b0b0b0" }}></td>) : null}
      {props.AddColumnForCurrecny ? <td style={{ border: "1px solid #b0b0b0" }}></td> : null}
    </tr>

    }

  </>
}

export default CallForBank