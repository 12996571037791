import React from "react";

export default function TopHeading(props) {
  return (
    <>
      <div
        style={{
          padding: 20,
          height: "8vh",
          backgroundColor: "#fafafb",
          width: "100vw",
          WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
          MozBoxShadow: "0 8px 6px -6px #d5d4d6",
          boxShadow: "0 8px 6px -6px #d5d4d6",
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            marginLeft: 15,
          }}
        >
          {props.HeadingName}
          {/* Client Follow Ups */}
        </span>
      </div>
    </>
  );
}
