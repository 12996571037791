import React, { Component } from 'react'
import { Input, Button, Label, Icon } from "semantic-ui-react";
import ReactDOM from 'react-dom';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import ChangePassword from './ChangePassword'

const VerifyOtp = props => { 
    const [loading, set_loading] = React.useState(false)
    const [OTPEntered, setOTP] = React.useState('')
    function Verify() {
        set_loading(true);
        if (OTPEntered == props.OTPValue) {
            NotificationManager.success('OTP Verified');
            ReactDOM.render(<ChangePassword Email={props.Email}></ChangePassword>, document.getElementById('Content'));
        }
        else { NotificationManager.error('Invalid OTP'); set_loading(false) }
    }
    return (
        <>
            <span>
                OTP successfully Sent to:
                <Label className="mb-4">{props.Email}
                    <a className="ml-2" href="/ForgotPassword"><Icon className="edit"></Icon></a>
                </Label>
            </span>
            <Input
                onChange={(e) => {
                    setOTP(e.target.value)
                }}
                size='large' placeholder='Enter OTP' />
            <br></br>
            <div style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                <Button
                    style={{ width: 340, fontSize: 16 }}
                    onClick={() => {
                        Verify()
                    }} positive>Verify OTP</Button>
            </div>
        </>
    )
}

export default VerifyOtp
