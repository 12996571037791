import React,{useEffect} from 'react'
import { Dropdown } from 'semantic-ui-react'
const axios = require('axios');


const StudentList = (props) => {
  const [student, set_student] = React.useState([])

  useEffect(() => {
    get_student();
}, []);

  function get_student(){
    axios.get('priya/sp_ClientTrackingProject?type=4')
    .then(function (response) {
      set_student(response.data)
  
    })
    .catch(function (error) {
        alert(error);
    })

  }

function send_to_parent(value){
  //alert(value)
  props.passChildData(value); 

}
  
  return (
    <Dropdown
    style={{width:180}}
      placeholder='Select Student'
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(value)
       /// alert(value)
        //alert(e)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={student}
      clearable
    />
  )


}

export default StudentList






