import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");


const SCTypeDropDown = (props) => {
  const [regcorp, set_regcorp] = React.useState([]);

  useEffect(() => {
    get_sctype();
  }, []);

  function get_sctype() {
    axios
      .get("yusuf/GetSCType")
      .then(function (response) {
        set_regcorp(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function send_to_parent(text, value) {
    //alert(value)
    props.passChildData(text, value);
  }

  return (
    <Dropdown
      placeholder="Select SC Type"
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(e.target.innerText, value);
        // alert(value)
        //alert(e)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={regcorp}
      clearable
    />
  );
};

export default SCTypeDropDown