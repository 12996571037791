import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const ClientDropDown = (props) => {
  const [client, set_Client] = React.useState([]);

  useEffect(() => {
    get_client();
  }, []);

  function get_client() {
    axios
      .get("rajkumar/GetClient")
      .then(function (response) {
        set_Client(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function send_to_parent(text, value) {
    //alert(value)
    props.passChildData(text, value);
  }

  return (
    <Dropdown
      placeholder="Select Contacts"
      onChange={(e, { value }) => {
        debugger;
        send_to_parent(e.target.innerText, value);
      }}
      selection
      fluid
      search
      options={client}
      clearable
    />
  );
};

export default ClientDropDown;
