import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const BankDropdown = (props) => {
  const [city, set_city] = React.useState([]);

  useEffect(() => {
    get_city();
  }, []);

  useEffect(() => {
    if (props.ShowAllLocation == true) {
      get_city2();
    }
    else {
      get_city();
    }
  }, [props.ShowAllLocation])


  useEffect(() => {
    //console.log("change", props.billingLocationId)
    //var vv = props.billingLocationId
    // if billing location changes then bank drop down will update
    //@CurrIds
    axios
      .get("/Invoice/proc_ForBank2?type=42&billingLocation=" + props.billingLocationId + "&CurrIds=" + props.scid)
      .then(function (response) {

        set_city(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }, [props.billingLocationId])

  function get_city2() {
    axios
      .get("/Invoice/proc_ForBank?type=34")
      .then(function (response) {
        set_city(response.data);

      })
      .catch(function (error) {
        alert(error);
      });
  }

  function get_city() {
    axios
      .get("/Invoice/proc_ForBank?type=4&CurrIds=" + props.scid)
      .then(function (response) {

        set_city(response.data);

        if (set_city.length > 0) {

          for (var v = 0; v < response.data.length; v++) {
            if (response.data[v].IsValue = 1) {
              console.log("TEST" + response.data[v].bankId);
            }
          }
        }
        // response.data.map(function (player) {
        //   return if(player.value=1)
        //   {
        //      player.IsValue;
        //   }
        //   // -------------------^^^^^^^^^^^---------^^^^^^^^^^^^^^
        // })
        // value


      })
      .catch(function (error) {
        alert(error);
      });
  }
  function send_to_parent(value) {
    props.passChildData(value);
  }

  return (
    <span>
      <Dropdown
        placeholder="Select Bank"
        openOnFocus={false}
        onChange={(e, { value }) => {
          send_to_parent(value);
        }}
        selection
        options={city}

        clearable
        style={{ width: "400px" }}
      />
    </span>
  );
};

export default BankDropdown;
