import React, { useEffect } from "react";
import Trainer from "../../reusable/Trainer";
import Student from "../../reusable/Student";
import { useLocation } from "react-router-dom";
import {
  Loader,
  Label,
  Embed,
  Button,
  Icon,
  Modal,
  Table,
} from "semantic-ui-react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "bootstrap/dist/css/bootstrap.css";
import "./Outstanding_policy.css";

const axios = require("axios");

export default function Outstanding_Policy() {
  const [loading, set_loading] = React.useState(true);
  const [data_list, set_data_list] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);

  const [link, set_link] = React.useState("");
  const [header, set_header] = React.useState([]);
  const [sd, set_sd] = React.useState("");
  const [ed, set_ed] = React.useState("");
  const [four_eye, set_four_eye] = React.useState(false);

  const [trainer, set_trainer] = React.useState("");
  const [student, set_student] = React.useState("");
  const [type, set_type] = React.useState("cce");
  const [cell_data, cell_cell_data] = React.useState([]);
  const [month, set_month] = React.useState("");
  const [user, set_user] = React.useState("");
  const [list_data, set_list_data] = React.useState([]);
  const src = useLocation().search;
  const name = new URLSearchParams(src).get("DPC");
  //const [link_placeholder, seset_link_placeholdert_link] = React.useState('')

  useEffect(() => {
    set_loading(true);
    get_data();
    check_Dpc();
  }, []);

  function check_Dpc() {
    if (name == 1) {
      alert("Password updated succesfully");
    }
  }
  let separator = "-";
  let sdnewDate = new Date();
  let sddate = sdnewDate.getDate();
  let sdmonth = sdnewDate.getMonth() + 1;
  let sdyear = sdnewDate.getFullYear();

  let fsdate = `${sdyear}${separator}${
    sdmonth < 10 ? `0${sdmonth}` : `${sdmonth}`
  }${separator}${sddate < 10 ? `0${sddate}` : `${sddate}`}`;

  async function Search_click() {
    debugger;
    set_data_list([]);
    set_loading(true);
    let js_start = new Date(sd);
    let js_end = new Date(ed);
    let sd_ =
      js_start.getMonth() +
      1 +
      "/" +
      js_start.getDate() +
      "/" +
      js_start.getFullYear();
    let ed_ =
      js_end.getMonth() +
      1 +
      "/" +
      js_end.getDate() +
      "/" +
      js_end.getFullYear();
    if (sd == "") {
      sd_ = "";
    }
    if (ed == "") {
      ed_ = "";
    }
    debugger;
    let four_eye_ = four_eye ? "yes" : "no";
    // let { data } = await axios.get('yusuf/get_outstanding_assignments?sd=' + sd_ + '&ed=' + ed_ + '&ctryname=' + country_name + '&city_id=' + city_id + '&vendor_id=' + vendor_id + '&four_eye=' + four_eye_ + '&type=' + type + '')
    let { data } = await axios.get(
      "yusuf/get_outstanding_assignments?sd=" +
        sd_ +
        "&ed=" +
        ed_ +
        "&trainer=" +
        trainer +
        "&student=" +
        student +
        ""
    );
    if (data.length > 0) {
      debugger;
      set_header(Object.keys(data[0]));
      set_data_list(data);
    } else {
      set_data_list([]);
      set_loading(false);

      // setTimeout(()=>{
      //     alert("No Data Available!!")
      // },1000)
    }
    set_loading(false);

    // .then(function (response) {
    //     set_loading(false)

    //     if (response.data.length > 0) {
    //         set_header(Object.keys(response.data[0]))
    //         set_data_list(response.data)
    //     }
    //     else {
    //         alert("NO DATA")
    //     }
    //     set_loading(false)

    // })
    // .catch(function (error) {
    //     alert(error);
    // })
  }

  function get_data() {
    let js_start = new Date(sd);
    let js_end = new Date(ed);
    let sd_ =
      js_start.getMonth() +
      1 +
      "/" +
      js_start.getDate() +
      "/" +
      js_start.getFullYear();
    let ed_ =
      js_end.getMonth() +
      1 +
      "/" +
      js_end.getDate() +
      "/" +
      js_end.getFullYear();
    if (sd == "") {
      sd_ = "";
    }
    if (ed == "") {
      ed_ = "";
    }

    axios
      .get(
        "yusuf/get_outstanding_assignments?sd=" +
          sd_ +
          "&ed=" +
          ed_ +
          "&trainer=" +
          trainer +
          ""
      )
      .then(function (response) {
        debugger;
        //alert(JSON.stringify(response.data))
        set_loading(false);
        set_data_list(response.data);
        //alert(JSON.stringify(response.data))
        // set_city(response.data)
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function play(link) {
    //seset_link_placeholdert_link(link)
    set_link(link.split("be/")[1]);
    setOpen(true);
  }

  //http://koshish.koenig-solutions.com/api/yusuf/get_passed_student?a_id=67606

  async function get_passed_student(a_id) {
    set_loading(false);

    let { data } = await axios.get(
      "yusuf/get_passed_student?a_id=" + a_id + ""
    );
    if (data.length > 0) {
      //set_header(Object.keys(data[0]))
      //alert(JSON.stringify(data))
      set_list_data(data);
      setOpen1(true);
    } else {
      set_loading(false);
      setTimeout(() => {
        alert("No Data Available!!");
      }, 1000);
    }
    set_loading(false);
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "100vh",
      }}
    >
      {/* FILTER SECTION */}

      <div
        style={{
          padding: 20,
          height: "100vh",
          backgroundColor: "rgb(73, 82, 133)",
        }}
      >
        <div style={{ flexDirection: "row" }}>
          <span
            style={{
              fontSize: 16,
              color: "#fff",
              opacity: 0.8,
              textAlign: "right",
              marginLeft: 10,
            }}
          >
            {" "}
            Filters <Icon name="filter" />{" "}
          </span>
        </div>
        <div>&nbsp;</div>

        <SemanticDatepicker
          className="ss"
          placeholder="2020-04-01"
          onChange={(e, v) => {
            //alert(JSON.stringify(v))
            set_sd(v.value);
          }}
        />

        <div>&nbsp;</div>
        <SemanticDatepicker
          placeholder={fsdate}
          onChange={(e, v) => {
            //alert(JSON.stringify(v))
            set_ed(v.value);
          }}
        />

        <div>&nbsp;</div>
        <div style={{ width: 200 }}>
          <Trainer passChildData={set_trainer} />
        </div>

        <div>&nbsp;</div>
        <div style={{ width: 200 }}>
          <Student passChildData={set_student} />
        </div>

        <div>&nbsp;</div>
        <div>
          <Button
            color="teal"
            onClick={() => {
              Search_click();
            }}
          >
            Search
          </Button>
        </div>

        <div>&nbsp;</div>
      </div>

      <div
        style={{
          width: "90vw",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#ffffff",
        }}
      >
        {/* HEADER */}
        <div
          style={{
            padding: 20,
            height: "8vh",
            backgroundColor: "#fafafb",
            width: "",
            WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
            MozBoxShadow: "0 8px 6px -6px #d5d4d6",
            boxShadow: "0 8px 6px -6px #d5d4d6",
          }}
        >
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ width: "88%", marginTop: "" }}>
              <span
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  fontFamily: "sans-serif",
                }}
              >
                Intoxicating Feedbacks
              </span>
            </div>

            <div style={{ width: "12%", marginTop: "-0.5%" }}>
              <ReactHTMLTableToExcel
                className="btn btn-primary c"
                table="outstanding"
                filename="Outstanding"
                sheet="Sheet"
                buttonText="Export to Excel"
              />
            </div>
          </div>
        </div>

        <Modal
          basic
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          size="small"
        >
          <Modal.Content>
            <div style={{ padding: 100 }}>
              <Embed id={link} placeholder={link} source="youtube" />
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button basic color="red" inverted onClick={() => setOpen(false)}>
              <Icon name="remove" /> Back
            </Button>
          </Modal.Actions>
        </Modal>

        <div
          style={{
            display: loading ? "none" : "flex",
            padding: "1% 7.6%",
            height: "100vh",
          }}
        >
          <span style={{ height: "88vh", width: "99.5%" }}>
            <StickyTable stickyHeaderCount={1}>
              <Row style={{ fontWeight: "bold", fontSize: 16 }}>
                <Cell>Date</Cell>
                <Cell>Trainer</Cell>
                <Cell>Assignment</Cell>
                <Cell>Reason</Cell>
                <Cell>Reference</Cell>
              </Row>
              {data_list.length > 0 ? (
                data_list.map((d) => {
                  return (
                    <Row>
                      <Cell>{d.date}</Cell>
                      <Cell>{d.TrainerName}</Cell>
                      <Cell>{d.assignment_id}</Cell>
                      <Cell>{d.reason}</Cell>
                      <Cell
                        style={{
                          display:
                            d.reason == "Video Feedback" ||
                            d.reason == "Student Passed"
                              ? "none"
                              : "block",
                        }}
                      >
                        {d.result}
                      </Cell>
                      <Cell
                        style={{
                          display:
                            d.reason == "Student Passed" ? "block" : "none",
                        }}
                      >
                        {/* {d.result.substring(0, 50)} */}
                        {d.result == null
                          ? d.result
                          : d.result.substring(0, 50)}
                        {"\u00A0"}{" "}
                        <Label
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            get_passed_student(d.assignment_id);
                          }}
                        >
                          {" "}
                          Details {"\u00A0"} <Icon color="teal" name="group" />{" "}
                        </Label>
                      </Cell>
                      <Cell
                        style={{
                          display:
                            d.reason == "Video Feedback" ? "block" : "none",
                        }}
                      >
                        <Icon
                          onClick={() => {
                            play(d.result);
                          }}
                          size="big"
                          color="red"
                          name="youtube"
                        />
                      </Cell>
                    </Row>
                  );
                })
              ) : (
                <Row>
                  <Table.Cell
                    colSpan={5}
                    style={{
                      textAlign: "center",
                      border: "2px solid black",
                      padding: "1%",
                      fontSize: "large",
                      fontWeight: "bold",
                    }}
                  >
                    No Record Found!
                  </Table.Cell>
                </Row>
              )}
            </StickyTable>
          </span>
        </div>

        <div
          style={{
            display: "none",
            padding: 40,
            overflow: "scroll",
            height: "100vh",
          }}
        >
          <span
            style={{
              border: "2px solid #eeeeee",
              width: "100%",
              height: "80vh",
              overflowY: "scroll",
            }}
          >
            <table id="outstanding" className="table" style={{ height: "" }}>
              {/* <StickyTable> */}
              <thead>
                <tr style={{ fontSize: 16, fontWeight: "bold" }}>
                  <th>Date</th>
                  <th>Trainer</th>
                  <th>Assignment</th>
                  <th>Reason</th>
                  <th>Reference</th>
                </tr>
              </thead>
              <tbody>
                {data_list.map((d) => {
                  return (
                    <tr>
                      <td>{d.date}</td>
                      <td>{d.TrainerName}</td>
                      <td>{d.assignment_id}</td>
                      <td>{d.reason}</td>
                      <td
                        style={{
                          display:
                            d.reason == "Video Feedback" ||
                            d.reason == "Student Passed"
                              ? "none"
                              : "block",
                        }}
                      >
                        {d.result}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              {/* </StickyTable> */}
            </table>
          </span>
        </div>

        <div>
          <Modal
            style={{ marginTop: "0px", marginLeft: "20%", width: "60%" }}
            open={open1}
            onClose={() => setOpen1(false)}
            onOpen={() => setOpen1(true)}
          >
            <Modal.Actions>
              <Button onClick={() => setOpen1(false)} primary>
                Close <Icon style={{ marginLeft: 10 }} name="close" />
              </Button>
            </Modal.Actions>
            <Modal.Content scrolling>
              <Modal.Description>
                <Table className="conversion" celled fixed singleLine>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>No </Table.HeaderCell>
                      <Table.HeaderCell>Learner </Table.HeaderCell>
                      <Table.HeaderCell>Status </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {list_data.map((d) => {
                      return (
                        <Table.Row>
                          <Table.Cell>{d.r}</Table.Cell>
                          <Table.Cell>{d.name}</Table.Cell>
                          <Table.Cell>{d.Result}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>

        {/* LOADER */}
        <div
          style={{
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            width: "80vw",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Loader active inline size="massive">
            getting data...
          </Loader>
        </div>
      </div>
    </div>
  );
}
