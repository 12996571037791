import React, { useEffect, Component, useState } from 'react';
import { Icon, Loader, Button, Modal, Table, Container, Label, Dropdown, Segment } from 'semantic-ui-react'
import SVGLoader from '../Reuseble/SVGLoader';
import "./common.css"

const axios = require("axios");
var moment = require('moment'); 

export default function CcePli() {
    const [loading, set_loading] = React.useState(true)
    const [data_list, SetData] = React.useState([])
    const [monthList, SetMonthList] = React.useState([])
    const [month, SetMonth] = React.useState('')
    const [CCEType, SetCCEType] = useState('')
    const [Mgr, SetMgr] = useState('')
    const [Manager, SetManager] = React.useState([])

    let monthVal = month == "" ? new Date(moment().add(-1, 'months')).toLocaleDateString('en-GB', {
        month: 'short', year: 'numeric'
    }).replace(/ /g, '-') : month;
    let MgrVal = Mgr;
    let CCETypeVal = CCEType;

    useEffect(() => {
        set_loading(true);
        get_monthsList();
        get_mngrList();
        get_data();
    }, []);

    function get_monthsList() {
        let EndDate = moment().add(-1, 'months').clone().endOf('month').format('YYYY-MM-DD');
        let StartDate = moment().add(-1, 'months').add(-2, 'years').clone().endOf('month').format('YYYY-MM-DD');
        axios.get("Nishant/GetMonthBetweenTwoDate?from=" + StartDate + "&to=" + EndDate)
            .then(function (response) {
                SetMonthList(response.data);
            })
            .catch(function (error) {
                alert(error);
            });
    }

    function get_mngrList() {
        axios.get("Nishant/GetManagerList")
            .then(function (response) {
                SetManager(response.data);
            })
            .catch(function (error) {
                alert(error);
            });
    }

    function getDataByMonth(Mon) {
        SetMonth(Mon);
        monthVal = Mon;
        get_data();
    }

    const getMgr = (val) => {
        SetMgr(val);
        MgrVal = val;
        get_data();
    }

    const getCCEType = (val) => {
        SetCCEType(val);
        CCETypeVal = val;
        get_data();
    }

    async function get_data() {
        let d = localStorage.getItem("emp_data");
        set_loading(JSON.parse(d).EmpId);
        let { data } = await axios.get("Nishant/CCEPLIData?month=" + monthVal + "&mgr=" + MgrVal + "&CCEType=" + CCETypeVal)
        if (data.length > 0) {
            set_loading(false)
            SetData(data);
        }
        else {
            //alert("NO DATA")
            SetData([]);
            set_loading(false)
        }
    }

    return (
        <div style={{ width: '80vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
            <div style={{
                padding: 20,
                height: '6vh', backgroundColor: '#fafafb', width: '100vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>CCE PLI</span>
            </div>
            <div class="row m-4">
                <div class="col-md-3">
                    <select class="form-control" value={month} onChange={(e) => { getDataByMonth(e.target.value) }}>
                        {
                            monthList.map((d,i) => {
                                return (
                                    <option key={i} value={d.MonthName}>{d.MonthName}</option>
                                )
                            })
                        }
                    </select>
                </div>
                <div class="col-md-3">
                    <select class="form-control" value={Mgr} onChange={(e) => { getMgr(e.target.value) }}>
                        <option value="">Select Manager</option>
                        {
                            Manager.map((d) => {
                                return (
                                    <option key={d.EmployeeId} value={d.EmployeeId}>{d.EmployeeName}</option>
                                );
                            })
                        }
                    </select>
                </div>
                <div class="col-md-3">
                    <span class="mr-2"><input type="radio" value="1" name="ChkType" onClick={(e) => { getCCEType(e.target.value) }}></input> Domestic</span>
                    <span><input type="radio" value="2" name="ChkType" onClick={(e) => { getCCEType(e.target.value) }}></input> International</span>
                </div>
                <div className="col-md-3 text-right">
                    <a className="btn btn-success" href="/Document/CCEPLI/CCE PLI Updated Doc.xlsx">Download T&C</a>
                </div>
            </div>

            {/* <div class="row m-4"> */}
            <SVGLoader loading={loading} SVGType="Table" SVGWidth="1200" SVGHeight="560"></SVGLoader>
            <div class="col-md-12" style={{ display: loading ? 'none' : 'flex', overflow: 'scroll' }}>

                <div style={{
                    display: loading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100vw',
                    flexDirection: 'column', height: '90vh'
                }}>
                </div>
                
                {/* <Segment style={{ overflow: 'auto', maxHeight: 500 }}> */}
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>CCE</Table.HeaderCell>
                            <Table.HeaderCell>Expected NR</Table.HeaderCell>
                            <Table.HeaderCell>Actual NR</Table.HeaderCell>
                            <Table.HeaderCell>Points</Table.HeaderCell>
                            <Table.HeaderCell># 10+</Table.HeaderCell>
                            <Table.HeaderCell># 20+</Table.HeaderCell>
                            <Table.HeaderCell># 50+</Table.HeaderCell>
                            <Table.HeaderCell># Point</Table.HeaderCell>
                            <Table.HeaderCell>Total Point</Table.HeaderCell>
                            <Table.HeaderCell>PLI Payable </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {data_list.map((d) => {
                            return (
                                <Table.Row style={{ cursor: 'pointer' }}>
                                    <Table.Cell style={{ textAlign: 'center' }} >{d.CCE}</Table.Cell>
                                    <Table.Cell className="cc"> {d.ExpNR} </Table.Cell>
                                    <Table.Cell className="cc" >{d.AchNR}</Table.Cell>
                                    <Table.Cell style={{ textAlign: 'center' }} >{d.Points}</Table.Cell>
                                    <Table.Cell style={{ textAlign: 'center' }} >{d.Amt10lakh}</Table.Cell>
                                    <Table.Cell style={{ textAlign: 'center' }} >{d.Amt20lakh}</Table.Cell>
                                    <Table.Cell style={{ textAlign: 'center' }} >{d.Amt50lakh}</Table.Cell>
                                    <Table.Cell style={{ textAlign: 'center' }} >{d.TotCorpPoint}</Table.Cell>
                                    <Table.Cell style={{ textAlign: 'center' }} >
                                        {d.Points > 0 ? d.TotalPoint :  <div> 0 <br></br><span className="text-danger"> (The PLI not applicable  – If NR Target not met)</span></div>}
                                    </Table.Cell>
                                    <Table.Cell style={{ textAlign: 'center' }} >{d.Points > 0 ? d.TotalPaybleAmount : 0}</Table.Cell>
                                </Table.Row>
                            );
                        })
                        }
                    </Table.Body>
                </Table>
                {/* </Segment> */}
            </div>
            <div class="col-md-3"></div>
            {/* </div> */}
        </div>
    )
}