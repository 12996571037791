import React, { useEffect,useState } from "react";

const queryString = require("query-string");

const InvoiceRedirect = () => {


const queryURL = queryString.parseUrl(window.location.href);
var invid2=0 ; invid2 = queryURL.query.invid;
var invid= localStorage.getItem("invid")

    useEffect(() => {      
        if(invid2 > 0 )
        {
            invid= localStorage.setItem("invid",queryURL.query.invid);
            window.location.href = "/InvoiceSecondStep?invid=" +invid2;
            console.log("invid2 : "+invid2)


        }
        else{
            window.location.href = "/InvoiceSecondStep?invid=" +invid;

        }
        
        
        debugger
    }, []);

  
  return (
    <span>
     
    </span>
  );
};

export default InvoiceRedirect;
