import React from 'react'
import { Loader, Radio, Button, Icon, Checkbox, Modal, Table, Dropdown } from 'semantic-ui-react'
const axios = require('axios');

const LA = (props) => {

    const [id, set_id] = React.useState('')
    const [loading, set_loading] = React.useState(true)
    const [dispdiv, disp_div] = React.useState(false);
    const [type1, set_type1] = React.useState(true);
    const [type2, set_type2] = React.useState(false);
    const [type3, set_type3] = React.useState(false);
    const [open, setOpen] = React.useState(false)
    const [cell_data, set_cell_data] = React.useState([])


    React.useEffect(() => {
        axios.post('yusuf/get_cce_type?cce_name=' + props.cce + '')
            .then(function (response) {

                // console.log(props.cce + 'XXXXXXX' + '---' + response.data[0])

                if (response.data[0].Dom == 1) {
                    set_type1(true)
                }
                else {
                    set_type1(false)
                }

                if (response.data[0].Intl == 1) {
                    set_type2(true)
                }
                else {
                    set_type2(false)
                }

                if (response.data[0].Adv == 1) {
                    set_type3(true)
                }
                else {
                    set_type3(false)
                }

            })
            .catch(function (error) {
                // alert(error);
            })
        //get_cce_type
    }, [])

    function showseparately() {
        set_loading(true)
        axios.get('yusuf/get_cce_type?cce_name=' + props.cce + '')
            .then(function (response) {

                console.log(props.cce + 'XXXXXXX' + '---' + response.data[0])
                debugger
                if (response.data[0].Dom == 1) {
                    set_type1(true)
                }
                else {
                    set_type1(false)
                }

                if (response.data[0].Intl == 1) {
                    set_type2(true)
                }
                else {
                    set_type2(false)
                }

                if (response.data[0].Adv == 1) {
                    set_type3(true)
                }
                else {
                    set_type3(false)
                }

            })
            .catch(function (error) {
                // alert(error);
            })
            set_loading(false)
    }

    async function save() {
        alert(props.cce)
        //update_cce_type
        let from = ''
        let to = ''
        //  let { data } =  axios.get('yusuf/update_cce_type?name=' + props.cce + '&int=' + type1 ? 0 : 1 + '&dom=' + type1 ? 1 : 0 + '&adv=' + type3 ? 1 : 0  + '&from=' + from + '&to=' + to + '')

        //alert(JSON.stringify(data))

        await axios.post('yusuf/update_cce_type?name=' + props.cce + '&int=' + (type2 ? 1 : 0) + '&dom=' + (type1 ? 1 : 0) + '&adv=' + (type3 ? 1 : 0) + '&from=' + from + '&to=' + to + '')
            .then(function (response) {
                // alert(response.data[0].status)
                alert("CCE updated successfully");
                window.location.href = window.location.origin + '/Conversion'
            })
            .catch(function (error) {
                alert(error);
            })

        //  if (data.length > 0) {
        //      set_header(Object.keys(data[0]))
        //      set_data_list(data)
        //  }
        //  else {
        //      alert("NO DATA")
        //  }
    }

    function advanced_list() {

        axios.get('yusuf/get_country')
            .then(function (response) {
                set_cell_data(response.data)
                setOpen(true)


            })
            .catch(function (error) {
                alert(error);
            })

    }

    function set_advanced(country_id, geo_name) {


        if (geo_name == 'Advanced') {
            geo_name = ''
        }
        else {
            geo_name = 'Advanced'

        }

        axios.get('yusuf/update_advanced_country?country_id=' + country_id + '&geo_name=' + geo_name + '')
            .then(function (response) {
                alert(response.data[0].status)
            })
            .catch(function (error) {
                alert(error);
            })
    }
    return (
        <span className="laButton">
            <span>
                <button type="button" className="btn btn-info" style={{ float: "none", margin: "0% 4%", padding: "0% 4%", borderRadius: "0px" }} onClick={() => { dispdiv ? disp_div(false) : disp_div(true) }}>LA</button>
            </span>
            <br></br>
            <div style={{ border: "2px solid rgba(0,0,0,0.7)", padding: "5% 0%", width: "100%", display: dispdiv ? 'block' : 'none' }} >
                <label style={{ marginRight: "5%", fontSize: "110%", fontWeight: "bold" }}>Domestic</label>
                {type1 ? 'Yes' : 'No'} <Checkbox checked={type1} onChange={() => { set_type1(!type1) }} />
                {/* NO <Radio checked={(type1 == 'no') ? true : false} onChange={() => { set_type1('no') }} /> */}
                <br></br>
                <label style={{ marginRight: "5%", fontSize: "110%", fontWeight: "bold" }}>International</label>
                {type2 ? 'Yes' : 'No'} <Checkbox checked={type2} onChange={() => { set_type2(!type2) }} />
                <br></br>
                <label style={{ cursor: 'pointer', marginRight: "5%", fontSize: "110%", fontWeight: "bold" }} onClick={() => { advanced_list() }}> Advanced</label>
                {type3 ? 'Yes' : 'No'} <Checkbox checked={type3} onClick={() => { set_type3(!type3) }} />

                <br></br>
                <button type="button" className="btn btn-success" style={{ float: "none", margin: "0% 4%", padding: "0% 4%", borderRadius: "0px" }} onClick={() => { save() }}>Save</button>
            </div>


            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                style={{ marginTop: '0px', marginLeft: '10%', width: '80%' }}
            >

                <Modal.Header>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div>
                            <div ></div>
                            <div ></div>

                        </div>
                        <div style={{ position: 'absolute', right: 10 }}>
                            <Button onClick={() => setOpen(false)} primary>
                                Close <Icon style={{ marginLeft: 10 }} name='close' />
                            </Button>
                        </div>
                    </div>

                </Modal.Header>

                <Modal.Content scrolling>

                    <Modal.Description>
                        <Table className="conversion" celled fixed singleLine>
                            <Table.Header>
                                <Table.Row>
                                    {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                                    <Table.HeaderCell>Country</Table.HeaderCell>
                                    <Table.HeaderCell>Advanced</Table.HeaderCell>

                                </Table.Row>
                            </Table.Header>

                            <Table.Body>

                                {cell_data.map((d) => {
                                    return (
                                        <Table.Row>
                                            {/* <Table.Cell>{d.Name}</Table.Cell> */}
                                            <Table.Cell>{d.value}</Table.Cell>
                                            <Table.Cell>
                                                <Checkbox onClick={() => { set_advanced(d.key, d.GeoName) }} defaultChecked={(d.GeoName == 'Advanced') ? true : false} />

                                            </Table.Cell>

                                        </Table.Row>
                                    )
                                })}



                            </Table.Body>
                        </Table>


                    </Modal.Description>
                </Modal.Content>

            </Modal>

        </span>
    )
}



export default LA
