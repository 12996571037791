//import { send } from 'emailjs-com';
import React, { useEffect } from 'react';
//import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Table, Icon, Loader, Button, Modal, Label, Container, Input, Popup } from 'semantic-ui-react'

const axios = require('axios');

export default function Third_Party_Batch() {
    const [loading, set_loading] = React.useState(true)
    const [data_list, set_data_list] = React.useState([])
    const [open, setOpen] = React.useState(false)

    const [heading, set_heading] = React.useState('')
    const [a_id, set_a_id] = React.useState('')



    async function submit() {
        let { data } = await axios.get('yusuf/gen_tb_batch_header?batch_header=' + heading + '&a_id=' + a_id + '')
        //alert(JSON.stringify(data))
        setOpen(false)
        get_data()
    }


    useEffect(() => {

        set_loading(true)
        get_data();
    }, []);



    async function get_data() {
        set_loading(true)

        let { data } = await axios.get('yusuf/header_get')
        if (data.length > 0) {
            set_loading(false)
            set_data_list(data)
        }
        else {
            alert("NO DATA")
            set_loading(false)
        }


    }



    return (

        <div style={{ width: '80vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>


            {/* HEADER */}
            <div style={{
                padding: 20,
                height: '8vh', backgroundColor: '#fafafb', width: '100vw',
                WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                boxShadow: "0 8px 6px -6px #d5d4d6"
            }}>
                <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>Third Party Batch attendance</span>
                <div style={{ position: 'absolute', right: 20, top: 10 }}>

                    <Button onClick={() => { setOpen(true) }} color="teal" content='Add' icon='add' labelPosition='right' />


                </div>
            </div>

            {/* FITER SECTION */}
            <div style={{ display: 'flex', paddingLeft: 43, paddingTop: 20 }}>


            </div>


            <div style={{ display: loading ? 'none' : 'flex', padding: 50, flexDirection: 'column', overflow: 'scroll', height: '90vh' }}>

                {data_list.map((data) => {
                    return (
                        <List_ d={data}></List_>
                    )
                })}

            </div>


            <div>
                <Modal
                    style={{ marginTop: '30px', marginLeft: '19%' }}
                    basic
                    closeIcon={<Icon color="orange" name='close' />}
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}

                >
                    <Modal.Actions>

                    </Modal.Actions>
                    <Modal.Content scrolling>
                        <Container style={{ width: 800 }}>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 5,
                                backgroundColor: '#fff',
                                paddingLeft: 130, paddingRight: 130,
                                // height: '100vh'
                            }}>

                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <Input
                                    onChange={(e) => {
                                        set_heading(e.target.value)
                                    }}
                                    size='large' placeholder='Attendance Form Heading' />
                                <br />
                                <Input
                                    onChange={(e) => {
                                        set_a_id(e.target.value)

                                    }}
                                    size='large' placeholder='assignmet ID' />
                                <br />


                                <br />
                                <div style={{ alignSelf: 'center', display: loading ? 'none' : 'block' }}>
                                    <Button size='large'
                                        onClick={() => {
                                            submit()
                                        }} positive>Submit</Button>
                                </div>
                                <div style={{ alignSelf: 'center', display: loading ? 'block' : 'none' }}>
                                    <Button size='large' loading positive>
                                        Loading
                                    </Button>
                                </div>
                                <br></br>
                                <br></br>
                                <br></br>
                            </div>
                        </Container>
                    </Modal.Content>

                </Modal>

            </div>

            {/* LOADER */}
            <div style={{
                display: loading ? 'flex' : 'none',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80vw',
                flexDirection: 'column', height: '90vh'
            }}>
                <Loader active inline size='massive'>getting data...</Loader>


            </div>

        </div>


    );
}

function List_(props) {
    const [client_data_list, set_client_data_list] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [cce_email, set_cce_email] = React.useState(props.d.cce_email)

    async function Send(header_id) {
        //alert('mail send to ' + cce_email + '' + '---' + header_id)

        let { data } = await axios.get('yusuf/send_mail?header_id=' + header_id + '&cce_email=' + cce_email + '')
        alert(JSON.stringify(data))
    }

    async function Send_all(header_id) {
        //alert('mail send to ' + cce_email + '' + '---' + header_id)

        let { data } = await axios.get('yusuf/send_mail?header_id=' + header_id + '&cce_email=' + cce_email + '&type=1')
        alert(JSON.stringify(data))

    }


    async function get_client_info(id) {
        let { data } = await axios.get('yusuf/get_data?id=' + id + '&type=get_third_party_client')

        if (data.length > 0) {
            //alert(JSON.stringify(data))
            setOpen(true)
            set_client_data_list(data)

        }
        else {
            alert('no student fill the form yet')
        }

    }

    return (
        <div>


            <div>
                <Modal
                    style={{ marginTop: '30px', marginLeft: '19%' }}
                    basic
                    closeIcon={<Icon color="orange" name='close' />}
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}

                >
                    <Modal.Actions>

                    </Modal.Actions>
                    <Modal.Content scrolling>
                        <Container style={{ width: 800 }}>

                            <div style={{
                                display: 'flex',
                                padding: 20,
                                flexDirection: 'column',
                                borderRadius: 5,
                                backgroundColor: '#fff',

                                // height: '100vh'
                            }}>

                                <Table celled fixed singleLine>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell>Email</Table.HeaderCell>
                                            <Table.HeaderCell>Name</Table.HeaderCell>
                                            <Table.HeaderCell>Trainer Name</Table.HeaderCell>
                                            <Table.HeaderCell>Training Date</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>



                                        {
                                            client_data_list.map((d) => {
                                                // alert(JSON.stringify(d))
                                                return (
                                                    <Table.Row>
                                                        <Table.Cell>{d.email}</Table.Cell>
                                                        <Table.Cell>{d.name}</Table.Cell>
                                                        <Table.Cell>{d.tn}</Table.Cell>
                                                        <Table.Cell>{d.td}</Table.Cell>
                                                    </Table.Row>
                                                )
                                            })
                                        }
                                    </Table.Body>
                                </Table>

                            </div>
                        </Container>
                    </Modal.Content>

                </Modal>

            </div>

            <div style={{
                'box-shadow': '10px 10px 15px #aaaaaa',
                borderRadius: 5,
                backgroundColor: '#f7f7f7', margin: 10, padding: 20, display: 'flex', flexDirection: 'row'
            }}>

                <span style={{ opacity: 0.7, flex: 2, fontWeight: 'bolder' }}>
                    {props.d.batch_header}
                </span>

                <span style={{ opacity: 0.7, flex: 1, fontWeight: 'bolder' }}>
                    {props.d.assignment_id}-{props.d.trainerName}
                </span>

                <span style={{ cursor: 'pointer', flex: 1, fontWeight: 'bolder' }}>

                    <Popup
                        content={props.d.form_link}
                        on='click'
                        pinned
                        trigger={<Label> Copy Form Link {'\u00A0'}<Icon color='teal' name='linkify' /> </Label>}
                    />


                </span>

                <span style={{ cursor: 'pointer', flex: 1, fontWeight: 'bolder' }}>
                    <Label onClick={() => { get_client_info(props.d.assignment_id) }} > {props.d.marked_yet} {'\u00A0'} student marked yet {'\u00A0'} <Icon color='teal' name='info' /> </Label>

                </span>


                <span style={{ cursor: 'pointer', flex: 1, fontWeight: 'bolder' }}>

                    <Popup
                        content={
                            <div style={{ display: 'flex', width: 600, flexDirection: 'row' }}>

                                <Input
                                    style={{ width: 350 }}
                                    value={cce_email}
                                    onChange={(e) => {
                                        set_cce_email(e.target.value)
                                    }}
                                    placeholder='CCE Email' />
                                <Button
                                    style={{ width: 100 }}
                                    onClick={() => {
                                        Send(props.d.assignment_id)
                                    }} color='teal' content='send for today' fluid />
                                <Button
                                    style={{ width: 100 }}
                                    onClick={() => {
                                        Send_all(props.d.assignment_id)
                                    }} color='teal' content='send for all days' fluid />

                            </div>
                        }
                        on='click'
                        pinned
                        trigger={
                            <Label> Mail to CCE {'\u00A0'} <Icon color='teal' name='mail' /> </Label>
                        }
                    />


                </span>

            </div>

        </div>
    )
}