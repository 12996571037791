import React, { useEffect, Component, useState } from 'react';
import {
    Icon,
    Loader,
    Button,
    Modal,
    Table,
    Header,
    Input,
    Segment,
} from "semantic-ui-react";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import HeaderMenu from '../Reusable/HeaderMenu';
import SVGLoader from '../../MainProject/Reuseble/SVGLoader';

const axios = require("axios");

const Profile = () => {
    useEffect(() => {
        set_loading(true);
        get_data();
    }, []);


    const [loading, set_loading] = React.useState(false);
    const [Phone, set_phone] = useState("");
    const [gst, set_GST] = useState("");
    const [Linkedin, set_LinkedIn] = useState("");
    const [pan, set_pan] = useState("");
    const [FilePAth, set_filePath] = useState("");
    const [Aadharpath, set_aadharPath] = useState("");
    const [pancardPAth, set_pancardPath] = useState("");
    const [passportPAth, set_PassportPath] = useState("");

    const [ProfilePicPath, set_ProfilePic] = useState("");
    const [cv, set_cv] = useState("");
    const [PP, set_PP] = useState("");
    const [source, set_source] = useState();

    async function get_data() {
        set_loading(true);
        let d = localStorage.getItem("emp_data");
        axios.get("Nishant/getProfileData?t_id=" + JSON.parse(d).trainer_id)
            .then(function (response) {
                if (response.data.length > 0) SetData(response.data);
                set_loading(false);
            })
    }

    function SetData(data) {
        set_phone(data[0].Phone);
        set_pan(data[0].PANNo);
        set_GST(data[0].GSTNo);
        set_filePath(data[0].resume1);
        set_ProfilePic(data[0].photopath);
        set_LinkedIn(data[0].Linkedin)
        set_aadharPath(data[0].aadhar);
        set_PassportPath(data[0].passport);
        set_pancardPath(data[0].pancard);
    }

    function getDownloadCV(Path) {
        if (Path != null && Path != "")
            return (
                <a target="_blank" href={'http://koshish.koenig-solutions.com/api/Nishant/downloadCV?name=' + Path} class="btn btn-primary ml-3"><i className="icon download"></i></a>
            )
    }

    function getDownloadPP(Path) {
        // alert(Path);
        // if (Path != null && Path != "")
        //     return (
        //         <img className="ml-5" src={'http://koshish.koenig-solutions.com/api/yusuf/get_img?file_name=icon-balck.png'} alt='DP'></img>
        //     )
        if (Path != null && Path != "") {

            axios
                .get(
                    'yusuf/get_img?file_name=' + Path + '',
                    { responseType: 'arraybuffer' },
                )
                .then(response => {
                    const base64 = btoa(
                        new Uint8Array(response.data).reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            '',
                        ),
                    );
                    set_source("data:;base64," + base64);
                });
        }
    }

    function CheckValidity() {
        if (Phone == null || Phone == "") return false;
        //if (gst == null || gst == "") return false;
        //if (pan == null || pan == "") return false;
        //if (Linkedin == null || Linkedin == "") return false;
        return true;
    }

    function onSavePersonalDetail() {
        let d = localStorage.getItem("emp_data");
        if (CheckValidity()) {
            set_loading(true);
            SaveData(d);
        }
        else NotificationManager.error("Phone, Pan and Gst is mandetory", "Error");
    }

    function SaveData(d) {
        axios.get(
            "Nishant/Save_profile?t_id=" + JSON.parse(d).trainer_id + "&phone=" + Phone + "&pan=" + pan + "&gst=" + gst + "&CV=" + FilePAth + "&PP=" + ProfilePicPath + "&linkedin=" + Linkedin + "&aadhar=" + Aadharpath + "&pancard=" + pancardPAth + "&passport=" + passportPAth
        ).then(function (res) {
            if (res.data.length > 0) {
                NotificationManager.success("Profile successfully Updated", "Done");
            }
        });
        set_loading(false);
    }

    // On file upload (click the upload button) 
    function onFileUpload(e) {
        const file_data = new FormData()
        file_data.append('sampleFile', e.target.files[0])
        axios.post("yusuf/upload", file_data, {
            // receive two    parameter endpoint url ,form data
        }).then(res => { // then print response status
            alert(res.status)
            if (res.status == 200) {
                debugger
                set_filePath(res.data);
                // let d = localStorage.getItem("emp_data");
                // set_loading(true);

                // axios.get(
                //     "Nishant/UpdateCV?t_id=" + JSON.parse(d).trainer_id + "&FilePath=" + res.data
                // ).then(function (res) {
                //     if (res.data.length > 0) {
                //         set_loading(false);
                //         get_data();
                //         NotificationManager.success("CV successfully Updated", "Done");
                //     }
                // });
            }
        })
    };

    function onAadharUpload(e) {
        const file_data = new FormData()
        file_data.append('sampleFile', e.target.files[0])
        axios.post("yusuf/upload", file_data, {
            // receive two    parameter endpoint url ,form data
        }).then(res => { // then print response status
            alert(res.status)
            if (res.status == 200) {
                debugger
                set_aadharPath(res.data);
                // let d = localStorage.getItem("emp_data");
                // set_loading(true);

                // axios.get(
                //     "Nishant/UpdateCV?t_id=" + JSON.parse(d).trainer_id + "&FilePath=" + res.data
                // ).then(function (res) {
                //     if (res.data.length > 0) {
                //         set_loading(false);
                //         get_data();
                //         NotificationManager.success("CV successfully Updated", "Done");
                //     }
                // });
            }
        })
    };
    function onPancardUpload(e) {
        const file_data = new FormData()
        file_data.append('sampleFile', e.target.files[0])
        axios.post("yusuf/upload", file_data, {
            // receive two    parameter endpoint url ,form data
        }).then(res => { // then print response status
            alert(res.status)
            if (res.status == 200) {
                debugger
                set_pancardPath(res.data);
                // let d = localStorage.getItem("emp_data");
                // set_loading(true);

                // axios.get(
                //     "Nishant/UpdateCV?t_id=" + JSON.parse(d).trainer_id + "&FilePath=" + res.data
                // ).then(function (res) {
                //     if (res.data.length > 0) {
                //         set_loading(false);
                //         get_data();
                //         NotificationManager.success("CV successfully Updated", "Done");
                //     }
                // });
            }
        })
    };
    function onPassportUpload(e) {
        const file_data = new FormData()
        file_data.append('sampleFile', e.target.files[0])
        axios.post("yusuf/upload", file_data, {
            // receive two    parameter endpoint url ,form data
        }).then(res => { // then print response status
            alert(res.status)
            if (res.status == 200) {
                debugger
                set_PassportPath(res.data);
                // let d = localStorage.getItem("emp_data");
                // set_loading(true);

                // axios.get(
                //     "Nishant/UpdateCV?t_id=" + JSON.parse(d).trainer_id + "&FilePath=" + res.data
                // ).then(function (res) {
                //     if (res.data.length > 0) {
                //         set_loading(false);
                //         get_data();
                //         NotificationManager.success("CV successfully Updated", "Done");
                //     }
                // });
            }
        })
    };

    function onProfileUpload(e) {
        const file_data = new FormData()
        file_data.append('sampleFile', e.target.files[0])
        axios.post("yusuf/upload", file_data, {
            // receive two    parameter endpoint url ,form data
        }).then(res => { // then print response status
            if (res.status == 200) {
                set_ProfilePic(res.data);
                // let d = localStorage.getItem("emp_data");
                // set_loading(true);
                // axios.get(
                //     "Nishant/UpdatePP?t_id=" + JSON.parse(d).trainer_id + "&FilePath=" + res.data
                // ).then(function (res) {
                //     if (res.data.length > 0) {
                //         set_loading(false);
                //         get_data();
                //         NotificationManager.success("Profile Pic successfully Updated", "Done");
                //     }
                // });
            }
        })
    };

    // File content to be displayed after 
    // file upload is complete 
    return (
        <div>
            <HeaderMenu HeaderText="My Profile"></HeaderMenu>
            <SVGLoader loading={loading} SVGType="Form" SVGWidth="1200" SVGHeight="560"></SVGLoader>
            <Segment style={{ display: loading ? 'none' : 'flex', width: '70%', left: '1%' }}>
                <form className="form-horizontal pt-2 w-100">
                    <fieldset className="fieldset">
                        {/* <h3 className="fieldset_title">Personal Info</h3> */}
                        <div className="form-group">
                            <label className="col-md-2 col-sm-3 col-xs-12 control-label">Phone</label>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                                <input type="number" value={Phone} className="form-control" onChange={(e) => { set_phone(e.target.value) }} ></input>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-md-2 col-sm-3 col-xs-12 control-label">PAN #</label>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                                <input type="text" value={pan} className="form-control text-uppercase" onChange={(e) => { set_pan(e.target.value) }} ></input>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-md-2 col-sm-3 col-xs-12 control-label">GST #</label>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                                <input type="text" value={gst} className="form-control text-uppercase" onChange={(e) => { set_GST(e.target.value) }}></input>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="col-md-2 col-sm-3 col-xs-12 control-label">Linkedin</label>
                            <div className="col-md-10 col-sm-9 col-xs-12">
                                <input type="text" value={Linkedin} className="form-control text-uppercase" onChange={(e) => { set_LinkedIn(e.target.value) }}></input>
                            </div>
                        </div>
                    </fieldset>
                    <hr></hr>
                    <div className="row mt-4 mb-5">
                        <div className='col-md-4'>
                            <strong className="form-check">Upload Aadhar</strong>
                            <div className="form-inline col-md-10 col-sm-9 col-xs-12">
                                <input type="file" className="file-uploader pull-left" onChange={(e) => { onAadharUpload(e) }}></input>
                                {/* <input className="btn btn-primary" type="button" onClick={() => { onFileUpload() }} value="Update CV"></input> */}
                                {getDownloadCV(Aadharpath)}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <strong className="form-check">Upload Pancard</strong>
                            <div className="form-inline col-md-10 col-sm-9 col-xs-12">
                                <input type="file" className="file-uploader pull-left" onChange={(e) => { onPancardUpload(e) }}></input>
                                {/* <input className="btn btn-primary" type="button" onClick={() => { onFileUpload() }} value="Update CV"></input> */}
                                {getDownloadCV(pancardPAth)}
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <strong className="form-check">Upload Passport</strong>
                            <div className="form-inline col-md-10 col-sm-9 col-xs-12">
                                <input type="file" className="file-uploader pull-left" onChange={(e) => { onPassportUpload(e) }}></input>
                                {/* <input className="btn btn-primary" type="button" onClick={() => { onFileUpload() }} value="Update CV"></input> */}
                                {getDownloadCV(passportPAth)}
                            </div>
                        </div>

                    </div>
                    <div className="form-group avatar">
                        <strong className="form-check">Upload CV</strong>
                        <div className="form-inline col-md-10 col-sm-9 col-xs-12">
                            <input type="file" className="file-uploader pull-left" onChange={(e) => { onFileUpload(e) }}></input>
                            {/* <input className="btn btn-primary" type="button" onClick={() => { onFileUpload() }} value="Update CV"></input> */}
                            {getDownloadCV(FilePAth)}
                        </div>
                    </div>
                    <hr></hr>
                    <div className="form-group avatar">
                        <strong className="form-check">Upload Profile Pic</strong>
                        <div className="form-inline col-md-10 col-sm-9 col-xs-12">
                            <input type="file" className="file-uploader pull-left" onChange={(e) => { onProfileUpload(e) }}></input>
                            {/* <input className="btn btn-primary" type="button" onClick={() => { onProfileUpload() }} value="Upload Profile Pic"></input> */}
                            {getDownloadPP(ProfilePicPath)}
                            <img src={source} />
                        </div>
                    </div>
                    <hr></hr>
                    <div className="form-group">
                        <div className="row m-0">
                            <div className="col-md-6 col-sm-6 col-xs-6">

                            </div>
                            <div className="col-md-6 col-sm-6 col-xs-6 text-right">
                                <input type="button" className="btn btn-primary" defaultValue="Update" onClick={() => { onSavePersonalDetail() }}></input>
                            </div>
                        </div>
                    </div>
                </form>
                <NotificationContainer />
            </Segment>
        </div>
    )
}

export default Profile