import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
//import "../Priya/Table.css";
const axios = require("axios");

const SalesManagerList = (props) => {
  const [manager, set_manager] = React.useState([]);

  useEffect(() => {
    get_manager();
  }, []);

  function get_manager() {
    axios
      .get("rajkumar/GetCCEManager?Type=14")
      .then(function (response) {
        set_manager(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function send_to_parent(value) {
    //alert(value)
    props.passChildData(value);
  }

  return (
    <Dropdown
      // style={{ width: 180 }}
      placeholder="All Manager"
      fluid
      search
      onChange={(e, { value }) => {
        send_to_parent(value);
        // alert(value)
        //alert(e)
      }}
      // onChange={() => { alert('Country') }}
      selection
      options={manager}
      clearable
    />
  );
};

export default SalesManagerList;
