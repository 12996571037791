import React, { useEffect, useState } from 'react';

import { Input, Dropdown, Button, Table, Container } from "semantic-ui-react";
import './Grid.css';
import Menus from '../reusable/Menus';
import Roles from '../reusable/Roles';
import { Link } from 'react-router-dom';
import AccessDenied from '../reusable/AccessDenied';
import { AlternateEmail } from '@material-ui/icons';




const axios = require("axios");
export default function MenuRemoveAdd() {
    const [loading, set_loading] = React.useState(false)
    const [RoleId, set_Roleid] = useState(0);
    const [MenuId, set_MenuId] = useState(0);
    const [Rolename, set_RoleName] = useState("");
    const [Menuname, set_MenuName] = useState("");
    const [Id, set_Id] = useState("");
    const [Role_Id, set_Role_Id] = useState(0);
    const [data_list, SetData] = React.useState([])

    useEffect(() => {
        let d = localStorage.getItem("emp_data");
        let Role_Id = JSON.parse(d).role_id;

        set_Role_Id(Role_Id);

        // if(Role_Id!=26 && Role_Id!=20)
        // {
        //     alert(Role_Id);
        //     <AccessDenied/>;
        // }

    }, []);
    async function get_data() {

        SetData([])
        set_loading(true)
        let { data } = await axios.get("jai/Sp_Add_and_Remove_Menus?Type=3&RoleId=" + RoleId + "&MenuId=" + MenuId + "&Id=0&FromDate='28-apr-2021'&ToDate='28-apr-2021'&Feedback='test'")

        if (data.length > 0) {
            set_loading(false)
            SetData(data);
        }
        else {
            //alert("NO DATA")
            SetData([]);
            set_loading(false)
        }
    }

    async function Delete_data(Id) {
        let { data } = await axios.get("jai/Sp_Add_and_Remove_Menus?Type=4&RoleId=" + RoleId + "&MenuId=" + MenuId + "&Id=" + Id + "&FromDate='28-apr-2021'&ToDate='28-apr-2021'&Feedback='test'")

        if (data.length > 0) {
            alert("Record Deleted Successfully");
            set_loading(false)
            get_data();
        }
        else {
            //alert("NO DATA")
            SetData([]);
            set_loading(false)
        }
    }

    async function Add_data() {
        if (RoleId == 0 || RoleId == 'undefined' || RoleId == "") {
            alert("Please Select Role");
            return false;
        }
        else if (MenuId == 0 || MenuId == 'undefined' || MenuId == "") {
            alert("Please Select Menu");
            return false;
        }

        let { data } = await axios.get("jai/Sp_Add_and_Remove_Menus?Type=5&RoleId=" + RoleId + "&MenuId=" + MenuId + "&Id=0&FromDate='28-apr-2021'&ToDate='28-apr-2021'&Feedback='test'")

        if (data.length > 0) {

            if (data[0].Duplicate == 100) {
                alert("Record already exists");
            }
            else {
                alert("Record Saved Successfully");
            }
            set_loading(false)
            get_data();
        }
        else {
            //alert("NO DATA")
            SetData([]);
            set_loading(false)
        }
    }



    return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
            <div style={{ width: '90vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
                <div style={{
                    padding: 20,
                    backgroundColor: '#fafafb', width: '100vw',
                    WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                    MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                    boxShadow: "0 8px 6px -6px #d5d4d6"
                }}>
                    <span style={{ fontSize: 16, fontWeight: 'bold', fontFamily: 'sans-serif', marginLeft: 15 }}>Add & Remove Menus</span>
                </div>


                {
                    (Role_Id != 26 && Role_Id != 20) ?
                        <AccessDenied /> : null


                }
                <Container>

                    <div className="grid-container">
                        <div className="item1">
                            <label>Roles</label><br />
                            <Roles
                                scrolling
                                passChildData={(RoleId, Rolename) => {
                                    set_Roleid(RoleId);
                                    set_RoleName(Rolename);
                                }}
                            />
                        </div>

                        <div className="item2">
                            <label>Menus</label><br />
                            <Menus
                                scrolling
                                passChildData={(MenuId, Menuname) => {
                                    set_MenuId(MenuId);
                                    set_MenuName(Menuname);
                                }} />
                        </div>

                        <div className="item3">
                            <label>&nbsp;</label><br />
                            <Button onClick={() => { get_data() }}

                                style={{ backgroundColor: 'orange', color: 'white' }}>Show</Button>
                        </div>

                        <div className="item4">
                            <label>&nbsp;</label><br />
                            <Button onClick={() => { Add_data() }} style={{ backgroundColor: 'green', color: 'white' }}>Add</Button>
                        </div>
                    </div>

                    <div style={{ display: loading ? 'none' : 'grid', overflowY: 'scroll', height: '400px', width: '80%', marginLeft: '10px' }}>
                        <Table celled style={{ width: '100%' }}>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Role</Table.HeaderCell>
                                    <Table.HeaderCell>Menu</Table.HeaderCell>
                                    <Table.HeaderCell>Action</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {data_list.map((d) => {
                                    return (
                                        <Table.Row style={{ cursor: 'pointer' }}>
                                            <Table.Cell style={{ textAlign: 'center' }} >{d.RoleName}</Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }} >{d.Menu_Name}</Table.Cell>
                                            <Table.Cell style={{ textAlign: 'center' }} >
                                                <Link
                                                    onClick={() => {
                                                        Delete_data(d.ID)
                                                    }}
                                                    className="btn btn-danger">Delete</Link>
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })
                                }
                            </Table.Body>
                        </Table>
                    </div>
                </Container>

            </div>
        </div>
    )
}