import React, { useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
const axios = require("axios");

const BillingDropDown = (props) => {
  const [city, set_city] = React.useState([]);
  const [widthStyle, set_widthStyle] = React.useState("400px");



  useEffect(() => {
    if (props.type == 1) {
      get_city1();
      set_widthStyle("200px")
    }
    else {

      get_city();
    }
  }, []);

  useEffect(() => {

    if (props.ShowAllLocation == true) {
      get_city2();
    }
    else if (props.RTInvoice == true) {
      get_city3();
    }
    else {

      if (props.type == 1) {
        get_city1();
        set_widthStyle("200px")
      }
      else {
        console.log("IN ELSE 2");
        get_city();
      }
    }
  }, [props.ShowAllLocation, props.RTInvoice])

  function get_city2() {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=33")
      .then(function (response) {
        set_city(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }


  function get_city3() {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=33")
      .then(function (response) {
        // console.log("priya", response.data)
        let city_ = response.data.filter((i) => {
          return (i.key == 1|| i.key == 456) ? i : 0
        })
        set_city(city_);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function get_city1() {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=25")
      .then(function (response) {
        set_city(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  }
  function get_city() {


    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=5&CurrIds=" + props.scid)
      .then(function (response) {
        console.log(response.data);
        set_city(response.data);

        // response.data.map(function (val) {
        //   return () => {
        //     if (val.isValue === 1) {
        //       console.log(val);
        //       //  set_city.value = val.value;
        //       //console.log(this.state);
        //     }
        //   }
        // })


      })
      .catch(function (error) {
        alert(error);
      })
  }
  function send_to_parent(value) {
    //alert(value)
    props.passChildData(value);
  }

  return (
    <Dropdown
      placeholder="All Location"
      openOnFocus={false}
      onChange={(e, { value }) => {
        send_to_parent(value);
      }}
      selection
      options={city}

      clearable
      style={{ width: widthStyle }}
    />
  );
};

export default BillingDropDown;