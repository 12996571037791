import React, { useState, useEffect, useRef } from "react";
import KoeAddressDetails from "./KoeAddressDetails.js";

import CallForBank from "./CallForBank.js";
import ".././main.css";
import BuyerAddressDetails from "./BuyerAddressDetails.js";
import FirstFooter from "./FirstFooter.js";
import BillDated from "./BillDated.js";
import SecondFooter from "./SecondFooter.js";
import { useReactToPrint } from "react-to-print";
import CallForLogo from "./CallForLogo.js";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import LetterHead from  "./LetterHead.jpeg"
import "./LetterHeadInvoice.css"

const queryString = require("query-string");

const LetterHeadInvoice = React.forwardRef((props, ref) => {

  var dateFormat = require('dateformat');

  const [invoiceData, Set_invoiceData] = useState([]);
  const [BankData, Set_BankData] = useState([]);
  const [invoiceCurrency, Set_invoiceCurrency] = useState([]);
  const [KoeName, set_KoeName] = useState("");
  const [KoeAddress, set_KoeAddress] = useState("");
  const [invoiceNo, Set_invoiceNo] = useState("");
  const [Currency, set_Currency] = useState("");
  const [ClientCountry, set_ClientCountry] = useState("");
  const [BuyerName, set_BuyerName] = useState("");
  const [BuyerAddress, set_BuyerAddress] = useState("");
  const queryURL = queryString.parseUrl(window.location.href);
  const [invid, setinvid] = useState(queryURL.query.invid);
  const [isSez, set_isSez] = useState(0);
  const [ICGST, set_ICGST] = useState(0);
  const [SCGST, set_SCGST] = useState(0);
  const [GCGST, set_GCGST] = useState(0);
  const [VAT, set_VAT] = useState(0);
  const [WHT, set_WHT] = useState(0);
  const [WHTRate, set_WHTRate] = useState();
  const [BankComm, set_BankComm] = useState(0);
  const [InvoiceDate, set_InvoiceDate] = useState(null);
  const [BuyerOrderNo, set_BuyerOrderNo] = useState(null);
  const [OrderDate, set_OrderDate] = useState(null);
  const [OtherRef, set_OtherRef] = useState(null);
  const [payterms, set_payterms] = useState(null);
  const [DueDate, set_DueDate] = useState();
  const [TaxAmount, set_TaxAmount] = useState(0);
  let TaxAmount1=0;
  const [InvAmount, set_InvAmount] = useState(0);
  const [bankRequired, set_bankRequired] = useState(false);
  const [TACOST,set_TACOST] = useState(0);
  const [DACOST,set_DACOST] = useState(0);
  const [FlightCost,set_FlightCost] = useState(0);
  const [HotelCost,set_HotelCost] = useState(0);
  const [MiscCost,set_MiscCost] = useState(0);
  const [VisaCost,set_VisaCost] = useState(0);
  const [TACOST_Desc,set_TACOST_Desc] = useState("TA Cost");
  const [DACOST_Desc,set_DACOST_Desc] = useState("DA Cost");
  const [FlightCost_Desc,set_FlightCost_Desc] = useState("Flight Cost");
  const [HotelCost_Desc,set_HotelCost_Desc] = useState("Hotel Cost");
  const [MiscCost_Desc,set_MiscCost_Desc] = useState("Misc Cost");
  const [VisaCost_Desc,set_VisaCost_Desc] = useState("Visa Cost");
  const [Loading, set_Loading] = useState(true);
  const [serviceData, set_serviceData] = useState([]);
  const [GST,set_GST] =useState("");
  const [PAN,set_PAN] =useState("");
  const [TRN,set_TRN] =useState("");
  const [FooterRate,set_FooterRate] = useState("");
  const [NoClientCountryRequired, set_NoClientCountryRequired] = useState(
    false
  );
  

  useEffect(() => {
    set_Loading(true);
    callForInvoiceShow();
    
    set_Loading(false);
  }, []);

  useEffect(()=>{
    callForLoader();
  },[Loading])

  const axios = require("axios");
  let rowno = 0;
  var commaNumber = require("comma-number");


  const callForLoader = () => {
    return (
      <Segment style={{ height: "100vh" }}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  };


  const callForInvoiceShow = () => {
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=20&invid=" + invid)
      .then(function (response) {
        Set_invoiceData(response.data);

        response.data.map((i, index) => {
          setHooks(i, index);
        });
      })
      .catch(function (error) {
        alert(error);
      });

    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=12&invid=" + invid)
      .then(function (response) {
        Set_BankData(response.data);
        
      })
      .catch(function (error) {
        alert(error);
      });
  };



  const callForBankComm = () => {

    return (
      <>
        {BankComm != null && BankComm > 0 ? (
          <tr>
            <td></td>
            <td>
              <span>Bank Commission</span>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <span>{commaNumber(BankComm.toFixed(2))} </span>
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  const callForTax = () => {
    return (
      <>
      {
        ICGST!=null && ICGST>0 ?(
            <tr>
              <td></td> <td> ICGT 18% </td>  <td></td>              <td></td>              <td></td>
              <td>             <span>{commaNumber(ICGST.toFixed(2))} </span>              </td>
            </tr>
        ):null
      }
      {
        SCGST!=null && SCGST>0 ?(
          <>
            <tr>
              <td></td>              <td> SCGST 9% </td>              <td></td>              <td></td>              <td></td>
              <td>              <span>{commaNumber(SCGST.toFixed(2))} </span>              </td>
            </tr>
             <tr>
             <td></td>              <td> CGST 9% </td>              <td></td>              <td></td>              <td></td>
             <td>              <span>{commaNumber(SCGST.toFixed(2))} </span>              </td>
           </tr>
           </>
        ):null
      }
      {
        VAT!=null && VAT>0 ?(
            <tr>
              <td></td>              <td> VAT </td>              <td></td>              <td></td>              <td></td>
              <td>              <span>{commaNumber(VAT.toFixed(2))} </span>              </td>
            </tr>
        ):null
      }
      {
        WHT!=null && WHT>0 ?(
            <tr>
              <td></td>              <td> WHT  {WHTRate} % </td>              <td></td>              <td></td>              <td></td>
              <td>              <span>{commaNumber(WHT.toFixed(2))} </span>              </td>
            </tr>
        ):null
      }
     
      </>
    );
  };
  
  const ExtraServiceSection = () =>{
    if(invoiceNo.includes("GST")){
 return(
   <>
   <tr>
       <td></td>
       <td>              
        {TACOST>0?( <span dangerouslySetInnerHTML={{ __html:TACOST_Desc+"<br>"}}></span>): ("")}
        {DACOST>0?(<span dangerouslySetInnerHTML={{ __html:DACOST_Desc+"<br>"}}></span> ): ("")}
        {FlightCost>0?(<span dangerouslySetInnerHTML={{ __html:FlightCost_Desc+"<br>"}}></span>): ("")}
        {HotelCost>0?(<span dangerouslySetInnerHTML={{ __html:HotelCost_Desc+"<br>"}}></span>): ("")}
        {MiscCost>0?(<span dangerouslySetInnerHTML={{ __html:MiscCost_Desc+"<br>"}}></span>): ("")}
        {VisaCost>0?(<span dangerouslySetInnerHTML={{ __html:VisaCost_Desc+"<br>"}}></span>): ("")}
       </td>
       <td></td>
       <td></td>
       <td></td>
       <td>
        {TACOST>0?( <span dangerouslySetInnerHTML={{ __html:commaNumber(TACOST.toFixed(2))+"<br>"}}></span>): ("")}
        {DACOST>0?(<span dangerouslySetInnerHTML={{ __html:commaNumber(DACOST.toFixed(2))+"<br>"}}></span> ): ("")}
        {FlightCost>0?(<span dangerouslySetInnerHTML={{ __html:commaNumber(FlightCost.toFixed(2))+"<br>"}}></span>): ("")}
        {HotelCost>0?(<span dangerouslySetInnerHTML={{ __html:commaNumber(HotelCost.toFixed(2))+"<br>"}}></span>): ("")}
        {MiscCost>0?(<span dangerouslySetInnerHTML={{ __html:commaNumber(MiscCost.toFixed(2))+"<br>"}}></span>): ("")}
        {VisaCost>0?(<span dangerouslySetInnerHTML={{ __html:commaNumber(VisaCost.toFixed(2))+"<br>"}}></span>): ("")}
       </td>
   </tr>
   </>
 )
       }
 }
 
 

  const setHooks = (i, index) => {
    set_InvAmount(i.InvAmount);
    set_KoeAddress(i.KoeAddress);
    set_KoeName(i.KoenigName);
    set_Currency(i.Currency);
    Set_invoiceNo(i.InvoiceNo);
    set_ClientCountry(i.ClientCountry);
    set_BuyerName(i.BuyerName);
    set_BuyerAddress(i.BuyerAddress);
    set_isSez(i.isSez);
    set_ICGST(i.InvICGSTAmt);
    set_GCGST(i.InvCGSTAmt);
    set_SCGST(i.InvSGSTAmt);
    set_WHT(i.whtAmt);
    set_VAT(i.InvVatAmnt);
    set_WHTRate(i.whtRate);
    set_InvoiceDate(i.InvoiceDate);
    set_BuyerOrderNo(i.BuyerOrderNo);
    set_OrderDate(i.OrderDate);
    set_OtherRef(i.OtherRef);
    set_DueDate(i.DueDate);
    TaxAmount1=TaxAmount1+i.InvAmt;
    set_TaxAmount(TaxAmount1);
    set_bankRequired(i.NoBankRequired);
    set_GST(i.GST);
    set_PAN(i.PAN);
    set_TRN(i.TRN);
    set_payterms(i.PayTerms);
    set_TACOST(i.TABillAmt)
    set_DACOST(i.DABillAmt)
    set_FlightCost(i.tripamt)
    set_MiscCost(i.MiscBillAmt)
    set_VisaCost(i.VisaBillAmt)
    set_HotelCost(i.HotelCost)
    set_FooterRate(i.FooterRate)
    if (i.NoClientCountryRequired == true) {
      set_NoClientCountryRequired(i.NoClientCountryRequired);
    }
    rowno++;
    set_serviceData((prevArray) => [
      ...prevArray,
      {
        invid: invid,
        Sno: rowno,
        Description: i.Description,
        StartDate: i.StartDate,
        EndDate:i.EndDate,
        NoOfParticipate: i.NoOfParticipate,
        NoOfWorkingDays: i.NoOfWorkingDays,
        InvAmt: i.InvAmt,
      },
    ]);
  };

   async function ActiveInvoice() {
     let res = await axios
      .get("/Invoice/proc_NewInvoiceCreate?type=21&invid="+invid )
      .then(function (response) {
        alert("Invoice is active now")
      })
      .catch(function (error) {
        alert("Invoice not save");
      });
  }



  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
   <>
    {Loading ? (
      callForLoader()
    ) : (

    <div style={{ height: "100vh" }}>
      <center>
        <button
          type="button"
          className="bg-gray-500 border border-gray-500 p-2 mb-4"
          onClick={handlePrint}
        >
          Print Invoice
        </button> 
      </center>
      <div style={{ overflow: "scroll", height: "80%" }} ref={componentRef}>
        
          <img src={LetterHead} className="divbgIMG"></img>
          <center>  
          <table 
            width="1000"
            border="0"
            cellSpacing="0"
            cellPadding="0"
            style={{ margin: "1% 7% 5% 7%", padding: "30px"}}
          >
            <tbody >
              <tr>
                <td colSpan="2"></td>
              </tr>
              <tr>
                <td
                  width="60%"
                  style={{ fontWeight: "bold", fontSize: "20px" }}
                >
                  {invoiceNo.includes("KSL-PI")?(
                    <span
                    style={{
                      float: "right",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                  PROFORMA INVOICE
                  </span>
                  ):(
                    <span
                    style={{
                      float: "right",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                  >
                    TAX INVOICE
                  </span>
                  )}
                
                </td>
                <td width="40%" style={{ padding: "2%",borderRight:"1px solid #b0b0b0" }}>
                  <CallForLogo invoiceNumber={invoiceNo} />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <table
                    width="1000"
                    border="0"
                    cellSpacing="0"
                    cellPadding="0"
                    style={{ borderTop: "1px solid #b0b0b0" }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <table
                            width="1000"
                            border="0"
                            cellSpacing="0"
                            cellPadding="0"
                            style={{ border: "none" }}
                          >
                            <tbody>
                              <tr>
                                <td width="349" valign="top">
                                  <KoeAddressDetails
                                    KoeName={KoeName}
                                    KoeAddress={KoeAddress}
                                    invoiceNumber={invoiceNo}
                                    GST={GST}
                                    PAN={PAN}
                                    TRN={TRN}
                                  />
                                  <BuyerAddressDetails
                                    ClientCountry={ClientCountry}
                                    BuyerName={BuyerName}
                                    BuyerAddress={BuyerAddress}
                                    NoClientCountryRequired={
                                      NoClientCountryRequired
                                    }
                                  />
                                </td>
                                <td
                                  width="351"
                                  valign="top"
                                  style={{
                                    height: "100%",
                                    borderTop: "solid 1px #ccc",
                                  }}
                                >
                                  <BillDated
                                    invid={queryURL.query.invid}
                                    IsSEZ={isSez}
                                    CCEName={OtherRef}
                                    invoiceNumber={invoiceNo}
                                    DueDate={DueDate}
                                    InvoiceDate={InvoiceDate}
                                    OrderDate={OrderDate}
                                    PayTerms={payterms}
                                    BuyerOrderNo={BuyerOrderNo}
                                    //  PayTermsName={set_payterms}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table
                            width="100%"
                            border="1"
                            cellSpacing="5"
                            cellPadding="5"
                            className="borderB bordera"
                          >
                            <tbody>
                              <tr className="borderB">
                                <td
                                  width="4%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  S.No.
                                </td>
                                <td
                                  width="30%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  Service Description
                                </td>
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  Start Date
                                </td>
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  End Date
                                </td>
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB borderR"
                                >
                                  Working Days/Participants
                                </td>
                                <td
                                  width="12%"
                                  style={{
                                    color: "#000",
                                    fontWeight: "bold",
                                    padding: "5px",
                                  }}
                                  className="borderB "
                                >
                                  Amount (
                                  <span
                                    style={{
                                      color: "#000",
                                      fontWeight: "bold",
                                      padding: "5px",
                                    }}
                                  >
                                    {Currency}
                                  </span>
                                  )
                                </td>
                              </tr>
                              {/***************************************** service description row start**************************************************/}

                              {serviceData.map((data, index) => (
                                <tr key={index}>
                                  <td className="borderR">{data.Sno}</td>
                                  <td align="left" className="borderR">
                                    <span style={{ Display: "inline-block" }}>
                                      {data.Description}
                                      <br />
                                    </span>
                                  </td>
                                  <td className="borderR">
                                    <b>
                                      <span style={{ Display: "inline-block" }}>
                                        
                                        {data.StartDate != null
                                          ? data.StartDate
                                          : null}  
                                      </span>
                                    </b>
                                  </td>
                                  <td className="borderR">
                                    <b>
                                      <span style={{ Display: "inline-block" }}>
                                        {data.EndDate != null
                                          ? data.EndDate
                                          : null}
                                      </span>
                                    </b>
                                  </td>

                                  <td className="borderR">
                                    <b>
                                      {data.NoOfWorkingDays != null &&
                                      data.NoOfWorkingDays > 0 ? (
                                        <span
                                          style={{ display: "inline-block" }}
                                        >
                                          
                                          Days : {data.NoOfWorkingDays}  
                                        </span>
                                      ) : null}
                                    </b>
                                    <br />
                                    <b>
                                      {data.NoOfParticipate != null &&
                                      data.NoOfParticipate > 0 ? (
                                        <span
                                          style={{ display: "inline-block" }}
                                        >
                                          
                                          Participate : {data.NoOfParticipate}
                                        </span>
                                      ) : null}
                                    </b>
                                  </td>
                                  <td>
                                    <span> {commaNumber(data.InvAmt.toFixed(2))}</span>
                                  </td>
                                </tr>
                              ))}
                              {/***************************************** service description row end**************************************************/}
                         
                              {ExtraServiceSection()}
                              {/*************************bank commission row start****************************/}
                              {callForBankComm()}
                              {/*************************bank commission row end******************************/}
                              {/***************************************** tax row start**************************************************/}
                                 {isSez == 1 ? null : callForTax()}
                              {/***************************************** tax row end**************************************************/}
                              {/***************************************** bank details row start**************************************************/}
                              <CallForBank
                                BankData={BankData}
                                bankRequired={bankRequired}
                              />
                              {/***************************************** bank details row end**************************************************/}
                             
                              <tr style={{height:"50px"}}>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>         
                              <tr>
                                <td style={{ border: "none" }}></td>
                                <td style={{ border: "none", float: "right" }}>
                                  <strong>Total</strong>
                                </td>
                                <td style={{ border: "none" }}></td>
                                <td style={{ border: "none" }}></td>
                                <td style={{ border: "none" }}></td>
                                <td style={{ border: "none" }}>
                                  <strong>
                                    <span> {Currency} </span>
                                    <span> {commaNumber(InvAmount.toFixed(2))}</span>
                                  </strong>
                                  <br />
                                  <div
                                    style={{
                                      float: "right",
                                      marginRight: "5px",
                                    }}
                                  >
                                    E. &amp; O.E
                                  </div>
                                </td>
                              </tr>

                              <FirstFooter
                                invoiceNumber={invoiceNo}
                                TaxAmount={TaxAmount}
                                WHTRATE={WHTRate}
                                ICGST={ICGST}
                                VAT={VAT}
                                WHT={WHT}
                                SCGST={SCGST}
                                invid={invid}
                                FooterRate={FooterRate}
                                FinalAmount={InvAmount.toFixed(2)}
                              />
                            </tbody>
                          </table>
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <SecondFooter invoiceNumber={invoiceNo}  KoeName={KoeName} />
            </tbody>
          </table>

          <span style={{ margin: "auto", width: "60%", padding: "10px" }}>
            This is Computer Generated No Signature is Required.
          </span>
        </center>
      </div>
    </div>
    
    )}
    </>
  )
});

export default LetterHeadInvoice;
