import React, { useEffect } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  Icon,
  Loader,
  Button,
  Label,
  Modal,
  Segment,
  Header,
  Popup,
  Checkbox,
  Table,
} from "semantic-ui-react";
import CCEList from "../reusable/CCEList";
import RTCList from "../reusable/RTCList";
import Student from "../reusable/Students";
import { makeStyles } from "@material-ui/core/styles";

import { Form, Input } from "semantic-ui-react";
import ReactTable from "react-table";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-table/react-table.css";
import "./Table.css";
import "react-notifications/lib/notifications.css";
import { MDBInput } from "mdbreact";
import CustomDropDown from "../Nishant/MainProject/Reuseble/Componant/CustomDropDown";
import CustomDropDownMulti from "../Nishant/MainProject/Reuseble/Componant/CustomDropDownMulti";

const axios = require("axios");
var moment = require('moment'); // require
var data1 = [];
var data2 = [];
export default function ClientFollowUp() {
  const [loading, set_loading] = React.useState(true);
  const [data_list, set_data_list] = React.useState([]);
  const [data_list1, set_data_list1] = React.useState([]);
  const [me_data, set_data_list2] = React.useState([]);
  const [sd, set_sd] = React.useState("");
  const [ed, set_ed] = React.useState("");
  const [cce_id, set_cce_id] = React.useState("");
  const [RTCId, set_RTCId] = React.useState("");
  const [CountryId, setCountryId] = React.useState([]);
  const [ContinentId, setContinentId] = React.useState('');
  const [student, set_student] = React.useState("");
  const [remarks, set_remarks] = React.useState("");
  const [open2, setOpen2] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [html_content, set_html_content] = React.useState(false);

  const [email, set_email] = React.useState("");
  const [from, set_from] = React.useState("");
  const [to, set_to] = React.useState("");
  const [cce, set_cce] = React.useState(0);

  const [client_id, set_client_id] = React.useState(0);

  const [firstOpen, set_FirstOpen] = React.useState(false);
  const [only_student, set_only_student] = React.useState(false);
  const [only_enquiry, set_only_enquiry] = React.useState(false);
  const [only_revival, set_only_revival] = React.useState(false);
  const [k, set_k] = React.useState("");

  const [next_fu, set_next_fu] = React.useState("");
  const [closure_month, set_closure_month] = React.useState("");

  const [RegCorp, set_RegCorp] = React.useState(false);
  const [OnlyCCEs, set_OnlyCCEs] = React.useState(false);
  const [ExCCEs, set_ExCCEs] = React.useState(false);
  const [SelectedExport, set_SelectedExport] = React.useState(false);
  let emp_data = localStorage.getItem("emp_data");

  {
    /*********************************Main Tabel Fliters and CSS Start***************************************/
  }
  const useStyles = makeStyles({
    root: {
      width: "80%",
    },
    container: {
      maxHeight: 450,
    },
  });
  const classes = useStyles();
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  {
    /*********************************Main Tabel Fliters and CSS End***************************************/
  }

  useEffect(() => {
    get_data(0);
  }, []);

  {
    /***************************************ON LOAD GRID DATA***********************************************/
  }
  async function get_data(cceid) {
    set_data_list([]);
    set_loading(true);
    let js_start = new Date(sd);
    let js_end = new Date(ed);
    let sd_ =
      js_start.getMonth() +
      1 +
      "/" +
      js_start.getDate() +
      "/" +
      js_start.getFullYear();
    let ed_ =
      js_end.getMonth() +
      1 +
      "/" +
      js_end.getDate() +
      "/" +
      js_end.getFullYear();
    if (sd == "") {
      sd_ = "04/01/2016";
    }
    if (ed == "") {
      ed_ = "10/31/2020";
    }

    //alert( cceid + '-' + sd_ + '-' + ed_)

    let { data } = await axios.get(
      "priya/sp_ClientTrackingProject?type=2&cceid=" +
      cceid +
      "&start_date=" +
      sd_ +
      "&end_date=" +
      ed_ +
      ""
    );

    if (data.length > 0) {
      console.log(data)
      set_loading(false);
      set_data_list(data);
      data1 = data;
      data2 = data;
    } else {
      alert("NO DATA FOUND");
      set_data_list([]);
      set_loading(false);
    }
  }

  {
    /***************************************POPUP SAVE BUTTON***********************************************/
  }
  const GetContinentId = (text, value) => setContinentId(value);
  const GetCountryId = (text, value) => setCountryId(value);

  async function setMEData(clientID) {
    let { data } = await axios.get(
      "priya/sp_ClientTrackingProject?type=6&client_id="
      + clientID
      + "&next_fu=" + "1900-01-01"
      + "&closure_month=" + "10"
      + "&remark=" + "df"
    );
    if (data.length > 0) {
      set_loading(false);
      set_data_list2(data);
    }
    else {
      alert("NO DATA FOUND");
      set_data_list2([]);
      set_loading(false);
    }
  }
  async function insertData() {
    // let next_fu = new Date(sd);
    // let closure_mo = new Date(ed);

    if (next_fu == "" || closure_month == "") {
      alert("select next follow up and closure date");
      return;
    }

    axios
      .get(
        "priya/sp_ClientTrackingProject?type=3&client_id=" +
        client_id +
        "&next_fu=" +
        next_fu.toLocaleDateString() +
        "&closure_month=" +
        closure_month.toLocaleDateString() +
        "&remarks=" +
        remarks
      )
      .then(function (response) {
        //alert(response.data[0].status)
        setOpen1(false);
        NotificationManager.success("Follow Up Dedail Added", "Done");
        get_data(0);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function keyword_search(text) {
    let filter_data = data1.filter((d) => {
      return (
        d.Name.toLowerCase().includes(text.toLowerCase()) ||
        d.Country.toLowerCase().includes(text.toLowerCase())
      );
    });
    set_data_list(filter_data);

    //alert(JSON.stringify(filter_data))
  }

  function allocate(cl_id, client) {
    let d = localStorage.getItem("emp_data");
    //alert(client)
    // alert(JSON.parse(d).email_id)
    // alert(cl_id)

    axios
      .get(
        "yusuf/Client_tracking_allocation?e=" +
        JSON.parse(d).EmpId +
        "&c=" +
        cl_id +
        "&f=" +
        from +
        "&t=" +
        to +
        "&to_email=" +
        JSON.parse(d).email_id +
        "&client=" +
        client +
        ""
      )
      .then(function (response) {
        alert(response.data[0].status);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  async function filter() {
    // alert("OnlyCCE : ",OnlyCCEs)
    let os = only_student ? 1 : 0;
    let oe = only_enquiry ? 1 : 0;
    let or = only_revival ? 1 : 0;
    let OneYearOldRegCorpEmail = RegCorp ? 1 : 0;
    let OnlyCCE = OnlyCCEs ? 1 : 0;
    let ExCCE = ExCCEs ? 1 : 0;

    let RTCEmp = RTCId ? RTCId : 0;
    set_loading(true);
    let js_start = new Date(sd);
    let js_end = new Date(ed);
    let sd_ =
      js_start.getMonth() +
      1 +
      "/" +
      js_start.getDate() +
      "/" +
      js_start.getFullYear();
    let ed_ =
      js_end.getMonth() +
      1 +
      "/" +
      js_end.getDate() +
      "/" +
      js_end.getFullYear();
    if (sd == "") {
      sd_ = "04/01/2013";
    }
    if (ed == "") {
      ed_ = "04/01/2030";
    }
    const inputStartDate = moment(sd_).format('YYYY-MM-DD');
    const inputEndDate = moment(ed_).format('YYYY-MM-DD');
    const LastSixMonthDate = moment().add(-6, 'M').format('YYYY-MM-DD');
    const differenceStartDate = moment(inputStartDate).diff(moment(LastSixMonthDate), 'days')
    const differenceEndDate = moment(inputEndDate).diff(moment(LastSixMonthDate), 'days')

    if (differenceStartDate < 0 && differenceEndDate < 0) {
      var obj = {
        c: cce_id,
        s: sd_,
        e: ed_,
        k: k,
        os: os,
        oe: oe,
        or: or,
        OneYearOldRegCorpEmail: OneYearOldRegCorpEmail,
        OnlyCCE: OnlyCCE,
        ExCCE: ExCCE,
        RTCEmp: RTCEmp,
        SearchCountryId: CountryId,
        ContinentId: ContinentId
      };
      console.log(differenceStartDate, differenceEndDate, obj)
      let { data } = await axios.post("Nishant/ct_filter", obj);
      // let { data } = await axios.get(
      //   "yusuf/ct_filter?c=" +
      //   cce_id +
      //   "&s=" +
      //   sd_ +
      //   "&e=" +
      //   ed_ +
      //   "&k=" +
      //   k +
      //   "&os=" +
      //   os +
      //   "&oe=" +
      //   oe +
      //   "&or=" +
      //   or +
      //   "&OneYearOldRegCorpEmail=" +
      //   OneYearOldRegCorpEmail +
      //   "&OnlyCCE=" +
      //   OnlyCCE +
      //   "&RTCEmp=" +
      //   RTCEmp +
      //   ""
      // );

      // console.log(
      //   "yusuf/ct_filter?c=" +
      //   cce_id +
      //   "&s=" +
      //   sd_ +
      //   "&e=" +
      //   ed_ +
      //   "&k=" +
      //   k +
      //   "&os=" +
      //   os +
      //   "&oe=" +
      //   oe +
      //   "&or=" +
      //   or +
      //   ""
      // );
      if (data.length > 0) {
        set_loading(false);
        set_data_list(data);
        console.log(data);
      } else {
        //alert("NO DATA FOUND")
        set_data_list([]);
        set_loading(false);
      }
    }
    else {
      alert("Start Date and End date must be selected 6 months old date")
      set_data_list([]);
      set_loading(false);
    }
  }

  const holdTbl = [];

  const selectedRowsData = (e, UID) => {
    if (e.target.checked) {
      let resultArr = data_list.filter((data) => data.UID == UID);
      resultArr = resultArr.values();
      for (let val of resultArr) {
        resultArr = val;
      }
      set_data_list1((prevArray) => [...prevArray, resultArr]);
    } else {
      let resultArr = data_list1.filter((c) => c.UID != UID);
      set_data_list1([]);
      set_data_list1(resultArr);
    }
  };
  return (
    <div
      style={{
        width: "100vw",
        height: "150vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
      }}
    >
      {/***********************************************HEADER*********************************************/}
      <div
        style={{
          padding: 10,
          height: "8vh",
          backgroundColor: "#fafafb",
          width: "100vw",
          WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
          MozBoxShadow: "0 8px 6px -6px #d5d4d6",
          boxShadow: "0 8px 6px -6px #d5d4d6",
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            marginLeft: 15,
          }}
        >
          Student and Leads Revival
        </span>
      </div>

      <div style={{ position: "absolute", right: 10, top: 10 }}>
        <ReactHTMLTableToExcel
          className="btn btn-primary c"
          table="conversion"
          filename="Client Tracking"
          sheet="Sheet"
          buttonText="Export to Excel"
        />
      </div>
      <div style={{ display: "flex", padding: "30px 0px 0px 30px" }}>
        <div>
          <h4>Loading data on default including following filter</h4>
          <ol>
            <li>Including W/O Regular Coporate, Lead allocated to only CCE</li>
            <li>TP student excluded</li>
            <li>500 data loading on default w/o any filter</li>
            <li>Unsubscribed excluded</li>

          </ol>
        </div>
      </div>

      {/*********************************FITER SECTION*********************************************************/}

      <div style={{ display: "flex", paddingLeft: 43, paddingTop: 20 }}>
        <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
          <span>From</span>
          <SemanticDatepicker
            placeholder="Select Date"
            onChange={(e, v) => {
              set_sd(v.value);
            }}
          />
        </div>
        <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
          <span>To</span>

          <SemanticDatepicker
            placeholder="Select Date"
            onChange={(e, v) => {
              set_ed(v.value);
            }}
          />
        </div>

        <div
          style={{
            margin: 10,
            display: "flex",
            flexDirection: "column",
            width: "100px",
            paddingTop: "7px",
          }}
        >
          <span>CCE</span>

          <CCEList
            passChildData={(cce_id) => {
              set_cce_id(cce_id);
            }}
          />
        </div>
        <div
          style={{
            margin: 10,
            display: "flex",
            flexDirection: "column",
            width: "100px",
            paddingTop: "7px",
          }}
        >
          <span>RTC</span>

          <RTCList
            passChildData={(RTCId) => {
              set_RTCId(RTCId);
            }}
          />
        </div>

        <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
          <span>Keyword</span>
          <Input
            onChange={(e, v) => {
              set_k(v.value);
            }}
            placeholder="Keyword Search.."
          />
          {/* <Button onClick={() => { get_data() }} color="teal" content='Filter' icon='filter' labelPosition='right' /> */}
        </div>
        <div
          style={{
            margin: 10,
            display: "flex",
            flexDirection: "column",
            width: "200px",
            paddingTop: "7px",
          }}
        >
          <span>Continent</span>
          <CustomDropDown passChildData={GetContinentId} disabled={false} svalue={ContinentId} placeholder="Search Continent" link="Nishant/GetListOfAlgo?Type=5"></CustomDropDown>
        </div>
        <div
          style={{
            margin: 10,
            display: "flex",
            flexDirection: "column",
            width: "200px",
            paddingTop: "7px",
          }}
        >
          <span>Country</span>
          <CustomDropDownMulti passChildData={GetCountryId} disabled={false} svalue={CountryId} placeholder="Search Country" link="Nishant/GetListOfAlgo?Type=7"></CustomDropDownMulti>
        </div>
      </div>

      <div style={{ display: "flex", paddingLeft: 43, paddingTop: 20 }}>
        <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
          <span>Only Student</span>

          <Checkbox
            onChange={() => {
              set_only_student(!only_student);
            }}
          />
        </div>

        <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
          <span>Only Enquiry</span>
          <Checkbox
            onChange={() => {
              set_only_enquiry(!only_enquiry);
            }}
          />
        </div>

        <div style={{ margin: 10, display: "none", flexDirection: "column" }}>
          <span>Leads Allocated For Only Revival</span>
          <Checkbox
            onChange={() => {
              set_only_revival(!only_revival);
            }}
          />
        </div>

        <div style={{ margin: 10, display: "none", flexDirection: "column" }}>
          <span>Only Selected Export</span>
          <Checkbox
            onChange={() => {
              set_SelectedExport(!SelectedExport);
            }}
          />
        </div>
        <div style={{ margin: 10, display: "none", flexDirection: "column" }}>
          <span>W/O Regular Corporate </span>
          <Checkbox
            onChange={() => {
              set_RegCorp(!RegCorp);
            }}
          />
        </div>
        <div style={{ margin: 10, display: "none", flexDirection: "column" }}>
          <span>Leads Allocated To Only CCE's </span>
          <Checkbox
            onChange={() => {
              set_OnlyCCEs(!OnlyCCEs);
            }}
          />
        </div>
        <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
          <span>Leads Allocated To Ex Kites </span>
          <Checkbox
            onChange={() => {
              set_ExCCEs(!ExCCEs);
            }}
          />
        </div>

        <div style={{ margin: 10, display: "flex", flexDirection: "column" }}>
          <br></br>
          <Button
            color="teal"
            content="Filter"
            icon="filter"
            labelPosition="right"
            onClick={() => {
              filter();
            }}
          />
        </div>
      </div>

      {/*********************************MAIN TABEL*********************************************************/}

      <Modal
        onClose={() => {
          set_FirstOpen(false);
        }}
        open={firstOpen}
      >
        <Modal.Header>Allocate Client</Modal.Header>
        <Modal.Content image>
          <div style={{ margin: 2 }}>
            <div style={{ width: 200 }}>RTC/CCE</div>
            <div style={{ width: 200 }}>
              <CCEList
                passChildData={(cce_id) => {
                  set_cce(cce_id);
                }}
              />
            </div>
          </div>
          <div style={{ margin: 2 }}>
            <div style={{ width: 200 }}>Client</div>
            <Input disabled value={email} />
          </div>
          <div style={{ margin: 2 }}>
            <div style={{ width: 200 }}>From Date</div>
            <Input disabled value={from} />
          </div>
          <div style={{ margin: 2 }}>
            <div style={{ width: 200 }}>To Date</div>
            <Input disabled value={to} />
          </div>

          <div style={{ margin: 2 }}>
            <Button
              onClick={() => {
                allocate();
              }}
              primary
            >
              Allocate
            </Button>
          </div>
        </Modal.Content>
      </Modal>

      <div
        style={{
          margin: 20,
          display: loading ? "none" : "flex",
          overflow: "scroll",
        }}
      >
        <table
          id="conversion"
          className="table"
          style={{ display: "none", width: "100%" }}
        >
          <thead>
            <tr>
              <th>CCE</th>
              <th>NAME</th>
              <th>COUNTRY</th>
              <th>SCID</th>
              <th>COURSE</th>
              <th>START DATE</th>
              <th>LAST FU</th>
              <th>NEXT FU</th>
              <th>REMARKS</th>
              <th>REPEATABLE </th>
              <th>FEEDBACK </th>
            </tr>
          </thead>
          <tbody>
            {SelectedExport
              ? data_list1.map((d, ii) => (
                <tr>
                  <td style={{ textAlign: "center" }}> {d.cce}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.Name} <br></br> {d.emailid ? "" : "(Only Student)"}{" "}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.Country}</td>
                  <td style={{ textAlign: "center" }}> {d.SCId}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.last_course_attend}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.training_date}</td>
                  <td style={{ textAlign: "center" }}> {d.followUp_date}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.next_followUp_date}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.Remarks}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.repeatable ? d.repeatable : "--"} {d.closure_month}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.feedback}</td>
                </tr>
              ))
              : data_list.map((d, ii) => (
                <tr>
                  <td style={{ textAlign: "center" }}> {d.cce}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.Name} <br></br> {d.emailid ? "" : "(Only Student)"}{" "}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.Country}</td>
                  <td style={{ textAlign: "center" }}> {d.SCId}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.last_course_attend}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.training_date}</td>
                  <td style={{ textAlign: "center" }}> {d.followUp_date}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.next_followUp_date}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.Remarks}</td>
                  <td style={{ textAlign: "center" }}>
                    {" "}
                    {d.repeatable ? d.repeatable : "--"} {d.closure_month}
                  </td>
                  <td style={{ textAlign: "center" }}> {d.feedback}</td>
                </tr>
              ))}
          </tbody>
        </table>

        <ReactTable
          data={data_list}
          columns={[
            {
              Header: " ",
              columns: [
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>Select</div>
                  ),
                  id: "Select",
                  style: { whiteSpace: "unset" },
                  accessor: (d) => (
                    <div style={{ backgroundColor: d.color }}>
                      <MDBInput
                        label=""
                        type="checkbox"
                        style={{ height: "15px" }}
                        onClick={(e) => {
                          selectedRowsData(e, d.UID);
                        }}
                      />
                    </div>
                  ),
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>CCE</div>
                  ),
                  id: "cce",
                  style: { whiteSpace: "unset" },
                  accessor: (d) => (
                    <div style={{ backgroundColor: d.color }}>
                      {d.cce1} {"\u00A0"}
                    </div>
                  ),
                },
                // <b onClick={() => {
                //   set_html_content(d.chat)
                //   setOpen(true)
                // }} style={{ cursor:'pointer', color: 'blue', display: d.emailid ? 'block' : 'none' }} >chat</b>
                {
                  Header: () => <div style={{ textAlign: "Center" }}>Name</div>,
                  id: "Name",
                  accessor: (d) => (
                    <div>
                      {d.Name}
                      <br></br>
                      <span title="Domain" style={{ display: "none" }}>
                        {d.emailid
                          ? '@' + d.emailid.split('@')[1]
                          // JSON.parse(emp_data).user_id == "4565"
                          //   ? d.emailid.split('@')[1]
                          //   : ""
                          : "(No Email)"}
                      </span>
                    </div>
                  ),
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>Country</div>
                  ),

                  id: "Country",
                  accessor: (d) => d.Country,
                },
                {
                  Header: () => <div style={{ textAlign: "Center" }}>SCID</div>,

                  id: "SCID",
                  accessor: (d) => d.SCId,
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>Course</div>
                  ),

                  id: "last_course_attend",
                  accessor: (d) => (
                    <Popup
                      position="top center"
                      content={d.last_course_attend}
                      trigger={<span> {d.last_course_attend} </span>}
                    />
                  ),
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>Start Date</div>
                  ),

                  id: "training_date",
                  accessor: (d) => d.training_date,
                },
              ],
            },
            {
              Header: "Follow Up",
              columns: [
                {
                  Header: () => <div style={{ textAlign: "Center" }}>Last</div>,

                  id: "followUp_date",
                  accessor: (d) => (
                    <div style={{ textAlign: "center" }}>
                      {d.followUp_date ? d.followUp_date : "--"}
                    </div>
                  ),
                },
                {
                  Header: () => <div style={{ textAlign: "Center" }}>Next</div>,

                  id: "next_followUp_date",
                  accessor: (d) => (
                    <div style={{ textAlign: "center" }}>
                      {d.next_followUp_date ? d.next_followUp_date : "--"}
                    </div>
                  ),
                },
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>Remarks</div>
                  ),

                  id: "Remarks",
                  accessor: (d) => (
                    <div style={{ textAlign: "center" }}>
                      {d.Remarks ? d.Remarks : "--"}
                    </div>
                  ),
                }
              ],
            },
            {
              Header: (
                <div style={{ paddingLeft: 10, paddingRight: 10 }}>
                  Repeatable {"\u00A0"} {"\u00A0"} {"\u00A0"}
                  {"\u00A0"}
                  {"\u00A0"}
                  {"\u00A0"}
                </div>
              ),
              columns: [
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>Closure Month</div>
                  ),
                  id: "repeatable",
                  accessor: (d) => (
                    <div style={{ textAlign: "center" }}>
                      {d.repeatable ? d.repeatable : "--"} {d.closure_month}
                    </div>
                  ),
                },
              ],
            },
            {
              Header: "Micro Email",
              columns: [
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}># Of ME</div>
                  ),
                  id: "webinar_date",
                  accessor: (d) => (
                    <div style={{ cursor: "pointer" }}>
                      <Label
                        onClick={() => {
                          setMEData(d.ClientID);
                          setOpen(true);
                        }}
                      >
                        {d.TotalMESent ? d.TotalMESent : "0"}
                      </Label>
                    </div>
                  ),
                },
                {
                  Header: () => (
                    <div
                      style={{
                        width: 100,
                        textAlign: "Center",
                        marginRight: 20,
                      }}
                    >
                      Allocate {"\u00A0"}
                      {"\u00A0"}
                      {"\u00A0"}
                    </div>
                  ),
                  id: "Allocate",
                  style: { whiteSpace: "unset" },
                  accessor: (d) => (
                    <div style={{ cursor: "pointer" }}>
                      <Label
                        onClick={() => {
                          //setOpen1(true)
                          set_client_id(d.stdid);
                          set_email(d.emailid ? d.emailid : d.Name);
                          var from = new Date();
                          var to = new Date();
                          to.setDate(from.getDate() + 100);
                          set_from(from.toLocaleDateString("en-US"));
                          set_to(to.toLocaleDateString("en-US"));
                          // set_FirstOpen(true)
                          allocate(d.stdid, d.emailid ? d.emailid : d.Name);
                        }}
                      >
                        {" "}
                        Allocate to me
                      </Label>
                    </div>
                  ),
                },

                // {
                //   Header: () => (
                //     <div style={{ textAlign: "Center" }}>Chat</div>

                //   ),
                //   id: "Chat",
                //   accessor: d => <div>
                //     <Button style={{marginLeft:10}} size='tiny' color="teal" content='Chat' onClick={() => {
                //       //setOpen1(true)
                //       set_client_id(d.stdid)
                //       set_email(d.Name)
                //       var from = new Date();
                //       var to = new Date();
                //       to.setDate(from.getDate() + 100);
                //       set_from(from.toLocaleDateString('en-US'))
                //       set_to(to.toLocaleDateString('en-US'))
                //       set_FirstOpen(true)

                //     }} />

                //   </div>
                // },
                {
                  Header: () => (
                    <div style={{ textAlign: "Center" }}>Feedback</div>
                  ),

                  id: "feedback",
                  accessor: (d) => (
                    <div>
                      <Popup
                        position="top center"
                        content={d.feedback}
                        trigger={<span> {d.feedback} </span>}
                      />
                    </div>
                  ),
                },
              ],
            },
          ]}
          defaultPageSize={100}
        />
      </div>
      <b
        style={{
          marginLeft: 20,
          display: loading ? "none" : "flex",
          fontWeight: "bold",
          fontSize: 20,
        }}
      >
        Explanation
      </b>
      <b
        style={{
          marginLeft: 20,
          display: loading ? "none" : "flex",
          fontWeight: "bold",
        }}
      >
        Only Student :- Student not exists in enquiry table
      </b>
      <b
        style={{
          marginLeft: 20,
          display: loading ? "none" : "flex",
          fontWeight: "bold",
        }}
      >
        Closure Month :- If mentioned{" "}
      </b>

      {/************************************ LODER **************************************** */}
      <div
        style={{
          display: loading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          width: "80vw",
          flexDirection: "column",
          height: "90vh",
        }}
      >
        <Loader active inline size="massive">
          getting data...
        </Loader>
      </div>

      {/*****************************modal*********************************************/}
      <Modal
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          marginLeft: "19%",
          height: "350px",
        }}
        open={open1}
        onClose={() => setOpen1(false)}
        onOpen={() => setOpen1(true)}
      >
        <Modal.Header>
          Add Next Follow Up Details
          <Icon name="close" onClick={() => setOpen1(false)} />
        </Modal.Header>
        <Modal.Content scrolling>
          <Modal.Description>
            <Form>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Student</label>
                  <Input value={email} disabled></Input>
                </Form.Field>

                <Form.Field>
                  <label>Next Follow Up</label>
                  <SemanticDatepicker
                    placeholder="select date"
                    onChange={(e, v) => {
                      set_next_fu(v.value);
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Closure Month</label>
                  <SemanticDatepicker
                    placeholder="select date"
                    onChange={(e, v) => {
                      set_closure_month(v.value);
                    }}
                  />
                </Form.Field>
              </Form.Group>
              <Form.TextArea
                label="Remarks"
                placeholder="Enter your remarks here..."
                value={remarks}
                onChange={(e) => set_remarks(e.target.value)}
              />
              <Segment basic textAlign={"center"}>
                <Form.Button
                  style={{ textAlign: "center" }}
                  color="purple"
                  onClick={() => {
                    insertData();
                  }}
                >
                  Save
                </Form.Button>
              </Segment>
            </Form>
          </Modal.Description>
        </Modal.Content>
      </Modal>

      <Modal
        style={{
          marginTop: "20px",
          marginBottom: "10px",
          marginLeft: "19%",
          height: "350px",
        }}
        closeIcon
        open={open}
        onClose={() => setOpen(false)}
        size="small"
      >
        <br></br>
        <br></br>
        <Header content="" />

        <Modal.Content scrolling>
          {
            <Table>
              <thead>
                <tr>
                  <td>S. No</td>
                  <td>ME Email</td>
                  <tr>ME Date</tr>
                </tr>
              </thead>
              <tbody>
                {
                  me_data.map((d, index) => (
                    <tr key={index}>
                      <td>{d.sentNumber}</td>
                      <td>{d.meSubject}</td>
                      <td>{d.meSentDate}</td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          }
          <p>{html_content}</p>
        </Modal.Content>
      </Modal>
    </div>
  );
}
