import React, { useEffect, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  Icon,
  Loader,
  Button,
  Modal,
  Table,
  Container,
  Label,
  Dropdown,Input,
} from "semantic-ui-react";
import SVGLoader from "../Nishant/MainProject/Reuseble/SVGLoader";
const axios = require("axios");
export default function Transcript() {
    const [loading, set_loading] = React.useState(true);
    const [data_list, set_data_list] = React.useState([]);
    const [type, set_type] = React.useState(1);
    const [CertificateUrl, set_CertificateUrl] = React.useState("");
    const [logoPath, set_logoPath] = React.useState("");

    async function Save_data() {
        set_loading(true);
        alert(logoPath);
        let { data } = await axios.get("jai/Sp_Transcript?type="+type+"&CertificateUrl="+CertificateUrl+"&logoPath="+logoPath+""
          );
    }
    useEffect(() => {
        set_loading(true);
        //get_data();
      }, []);

    return (
        <div style={{width: "80vw", height: "100vh",display: "flex",flexDirection: "column",backgroundColor: "#ffffff",
        }}>

            {/* HEADER */}
      <div
        style={{padding: 20,height: "8vh",backgroundColor: "#fafafb",width: "100vw",WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
          MozBoxShadow: "0 8px 6px -6px #d5d4d6",
          boxShadow: "0 8px 6px -6px #d5d4d6",
        }}>
        <span
          style={{
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            marginLeft: 15,
          }}
        >
          Transcript
        </span>
      </div>

      {/* FITER SECTION */}
      <div style={{ display: "flex", paddingLeft: 43, paddingTop: 20 }}>
        <div style={{ margin: 10 }}>
          <Input
            placeholder="Transcript"
            onChange={(e)=>{set_CertificateUrl(e.target.value)}}
          />
        </div>

        
        <div style={{ margin: 10 }}>
        <Input type="file" 
            placeholder="file"      
            onChange={(e)=>{set_logoPath(e.target.value)}}      
          />       
        </div>
        

        <div style={{ margin: 10 }}>
          <Button           
            color="teal"
            content="Save"
            icon="filter"
            labelPosition="right"
            onClick={()=>{Save_data();}}
          />
        </div>
      </div>
          </div>
    )
}