import React, { useEffect } from 'react'
import { Container, Input, Button, Message, Dropdown } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import './tp.css'

import CountryDropDown from "../reusable/CountryDropDownById";

const axios = require('axios');
let domainList = [];
const Third_party = (props) => {
    const urlParams = new URLSearchParams(window.location.search);

    const [loading, set_loading] = React.useState(false)
    const [name, set_name] = React.useState('')
    const [email, set_email] = React.useState('')
    const [trainer_name, set_traner_name] = React.useState('')

    const [training_date, set_training_date] = React.useState('')

    const [emp_data, set_emp_data] = React.useState([])

    const [header, set_header] = React.useState('Attendance Detail')
    const [batch_id, set_batch_id] = React.useState(0)
    const [submitted, set_submitted] = React.useState(false)

    const [countryId, set_CountryId] = React.useState(0);
    const [countryname, set_CountryName] = React.useState("");



    useEffect(() => {
        const id = urlParams.get("batch_header");
        //alert(id)

        if (id) {
            get_header(id);
            // hook define-  function add in array
            get_DomainList(id);
        }
    }, []);

    async function get_DomainList(id) {
        // if GSI batch then list will be compared
        let { data } = await axios.get('yusuf/get_domain_list?id=' + id + '')
        if (data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                domainList.push(data[i]);
                //  console.log(domainList[i]);
            }
            //domainList[] = data;
        }
    }

    async function get_header(id) {
        let { data } = await axios.get('yusuf/get_header?id=' + id + '')
        if (data.length > 0) {
            //set_data_list(data)
            //alert(JSON.stringify() )
            set_header(data[0].batch_header)
            set_batch_id(data[0].id)
        }
    }




    async function submit() {
        //alert(name + '-' + email + '-' + trainer_name + '-' + training_date )
        // let { data } = await axios.get('yusuf/add_tp_batch?email=' + email + '&name=' + name + '&tn=' + trainer_name + '&sd=' + training_date + '&batch_header_id=' + batch_id + '')
        console.log(email);
        console.log(name);
        console.log(trainer_name);
        console.log(training_date);
        console.log(batch_id);
        console.log(countryId);
        //console.log(domainList[0]);
        var chkDomain = false;
        for (var i = 0; i < domainList.length; i++) {
            var res = email.split("@");



            console.log(res[0]);
            console.log(res[1]);
            if (res.length > 1) {
                if (res[1].toString().trim() == domainList[i]["DomainName"]) {

                    chkDomain = true;
                }
            }

            // below code showing error for @tcs as entry of @cs available
            // if (email.indexOf(domainList[i]["DomainName"]) !== -1) {
            //     console.log(domainList[i]["DomainName"]);

            //     //mean true
            //     chkDomain = true;
            // }
        }
        // console.log(chkDomain);
        if (chkDomain == true) {
            alert('Enter Official Email Id !!!!');
            return;
        }

        if(name == "") {
            alert('Name Required !!! This name will be displayed on your Certificate.');
            return;
        }

        if (countryId == "") {
            alert('Country Required !!!!');
            return;
        }

        let { data } = await axios.get('yusuf/add_tp_batch?email=' + email + '&name=' + name + '&tn=' + trainer_name + '&sd=' + training_date + '&batch_header_id=' + batch_id + '&country_id=' + countryId + '')

        //alert(JSON.stringify(data))

        if (data[0].result == 'success') {
            set_submitted(true)
        }
        else {
            alert(data[0].result)
        }
    }

    return (
        <Container style={{ width: 800, backgroundColor: '#fff' }}>


            <br></br>
            <div style={{
                display: submitted ? 'none' : 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                //justifyContent: 'center',
                //alignItems: 'center',
                //width: 600,
                paddingLeft: 130, paddingRight: 130,
                height: '100vh'
            }}>

                <div style={{}}>
                    <br></br>

                    <h3 style={{ fontWeight: 'bold' }}>{header}	</h3>
                    <br></br>
                    <br></br>

                    <h5>Please share this information to receive your DMOC code and other relevant information. </h5>
                </div>

                <br></br>
                <Input
                    label={{ icon: 'asterisk' }}
                    labelPosition='right corner'
                    onChange={(e) => {
                        set_name(e.target.value)
                    }}
                    size='large' placeholder='Name'
                    required />
                <br />
                <Input
                    label={{ icon: 'asterisk' }}
                    labelPosition='right corner'
                    onChange={(e) => {
                        set_email(e.target.value)

                    }}
                    size='large' placeholder='Email' />
                <br />
                <CountryDropDown
                    scrolling
                    passChildData={(countryname, countryId) => {
                        set_CountryId(countryId);
                        set_CountryName(countryname);
                    }}
                />

                <br />
                <Input
                    label={{ icon: 'asterisk' }}
                    labelPosition='right corner'
                    onChange={(e) => {
                        set_traner_name(e.target.value)

                    }}
                    size='large' placeholder='Trainer Name' />
                <br />
                <SemanticDatepicker disabled className="wid" placeholder={new Date()} format="DD-MMM-YYYY" onChange={(e, v) => {
                    set_training_date(v.value)
                }} />
                <br />

                <br />
                <div style={{ alignSelf: 'center', display: loading ? 'none' : 'block' }}>
                    <Button size='large'
                        onClick={() => {
                            submit()
                        }} positive>Submit</Button>
                </div>
                <br></br>
                <div style={{ display: loading ? 'block' : 'none' }}>
                    <Button size='large' loading positive>
                        Loading
                    </Button>
                </div>

            </div>

            <div style={{ height: '100vh', display: submitted ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}>
                <Message positive>
                    <Message.Header>Thank You !</Message.Header>
                    <p>Your data saved succesfully,   want to try your IT skill try <span onClick={() => { window.location.href = 'https://www.qubits42.com/' }} style={{ fontWeight: 'bolder', color: 'teal', cursor: 'pointer' }}>QUBITS42</span> </p>

                </Message>
            </div>

        </Container>
    )
}



export default Third_party