import React, { useState } from "react";
import { Dropdown, Button, Input } from "semantic-ui-react";

function ReportDownload({ exportToExcelSalesManager, exportToExcelFreelancer, exportToExcelASM, exportToExcelCCE }) {
    const [reportType, setReportType] = useState("");
    const [fromDate, setFromDate] = useState("");
    const [endDate, setEndDate] = useState("");

    // Dropdown options
    const reportOptions = [
        { key: "SalesManager", text: "Sales Manager", value: "SalesManager" },
        { key: "Freelancer", text: "Freelancer", value: "Freelancer" },
        { key: "Managers", text: "Managers", value: "Managers" },
        { key: "CCE", text: "CCE", value: "CCE" }
    ];


    const handleReportChange = (event, { value }) => setReportType(value);


    const handleFromDateChange = (event) => setFromDate(event.target.value);
    const handleEndDateChange = (event) => setEndDate(event.target.value);


    const handleDownload = () => {
        if (!reportType) {
            alert("Please select a report type to download");
            return;
        }
        if (!fromDate || !endDate) {
            alert("Please select both Start Date and End Date");
            return;
        }
        if (new Date(fromDate) > new Date(endDate)) {
            alert("Start Date should be earlier than End Date");
            return;
        }


        switch (reportType) {
            case "SalesManager":
                exportToExcelSalesManager(fromDate, endDate);
                break;
            case "Freelancer":
                exportToExcelFreelancer(fromDate, endDate);
                break;
            case "Managers":
                exportToExcelASM(fromDate, endDate);
                break;
            case "CCE":
                exportToExcelCCE(fromDate, endDate);
                break;
            default:
                alert("Report type is not handled yet.");
                break;
        }
    };

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px", padding: "10px" }}>
            <Dropdown
                placeholder="Select Report Type"
                selection
                options={reportOptions}
                value={reportType}
                onChange={handleReportChange}
                style={{ minWidth: "150px" }}
            />
            <label>Start Date:</label>
            <Input
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                style={{ minWidth: "140px" }}
            />
            <label>End Date:</label>
            <Input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                style={{ minWidth: "140px" }}
            />
            <Button primary onClick={handleDownload} style={{ marginLeft: "10px" }}>
                Download Excel
            </Button>
        </div>
    );
}

export default ReportDownload;
