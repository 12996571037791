import React, { useState, useEffect } from 'react';
import CSDataTable from '../../../Freelancer/Reusable/CSDataTable';
import SVGLoader from '../SVGLoader';
import readXlsxFile from 'read-excel-file'
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import { ControlCameraOutlined, MeetingRoomOutlined } from '@material-ui/icons';
import { NotificationContainer, NotificationManager } from "react-notifications";
import ReactDOM from 'react-dom';


import * as XLSX from "xlsx";

var moment = require('moment');
//const readXlsxFile1 = require('read-excel-file/node')

// const [columns, setColumns] = useState([]);
// const [data, setData] = useState([]);


const axios = require("axios");
const UploadAttendance = (props) => {

    const [Rows, setRows] = useState([]);
    const [loading, set_loading] = React.useState(true)

    useEffect(() => {
        // load event no function call required
        //  GetStudentDetail();

    }, [])

    //const input = 
    // async function InsertData(Session_Id, Participant_Id, Full_Name, UserAgent, UTC_Event_Timestamp, Action, Role) {
    //     let { data } = await axios.post("Nishant/SaveAttendanceDetail", obj[i]);
    // }
    // async function InsertData(obj) {
    // }


    // process CSV data
    // const processData = dataString => {
    //     const dataStringLines = dataString.split(/\r\n|\n/);
    //     const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    //     const list = [];
    //     for (let i = 1; i < dataStringLines.length; i++) {
    //         const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    //         if (headers && row.length == headers.length) {
    //             const obj = {};
    //             for (let j = 0; j < headers.length; j++) {
    //                 let d = row[j];
    //                 if (d.length > 0) {
    //                     if (d[0] == '"')
    //                         d = d.substring(1, d.length - 1);
    //                     if (d[d.length - 1] == '"')
    //                         d = d.substring(d.length - 2, 1);
    //                 }
    //                 if (headers[j]) {
    //                     obj[headers[j]] = d;
    //                 }
    //             }

    //             // remove the blank rows
    //             if (Object.values(obj).filter(x => x).length > 0) {
    //                 list.push(obj);
    //             }
    //         }
    //     }

    //     // prepare columns list from headers
    //     const columns = headers.map(c => ({
    //         name: c,
    //         selector: c,
    //     }));

    //     console.log(list);
    //     console.log(columns);

    //     // setData(list);
    //     // setColumns(columns);
    // }


    // handle file upload
    // const handleFileUpload = e => {
    //     const file = e.target.files[0];
    //     const reader = new FileReader();
    //     reader.onload = (evt) => {
    //         /* Parse data */
    //         const bstr = evt.target.result;
    //         const wb = XLSX.read(bstr, { type: 'binary' });
    //         /* Get first worksheet */
    //         const wsname = wb.SheetNames[0];
    //         const ws = wb.Sheets[wsname];
    //         /* Convert array of arrays */
    //         const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    //         console.log(data);
    //         // processData(data);
    //     };
    //     reader.readAsBinaryString(file);
    // }


    const uploadMe = () => {
        set_loading(true);
        const promise = new Promise((resolve, reject) => {

            var fname = document.getElementById('uploadAttendanceFile').files[0].name;
            var extension = fname.split(".").pop()
            if (extension == "xlsx" || extension == "xls") {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(document.getElementById('uploadAttendanceFile').files[0]);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const wsname = wb.SheetNames[0];
                    const ws = wb.Sheets[wsname];
                    const data = XLSX.utils.sheet_to_json(ws);
                    resolve(data);
                };
                fileReader.onerror = (error) => {
                    alert('Invalid File !!!!');
                    reject(error);
                };
            }
            else {
                alert('File Type allowed .xlsx or .xls !!! Kindly .csv save as .xls or .xlsx');
            }
        });

        promise.then((d) => {
            console.log(d);
            let IsDataFlag = 0;
            var meetingTitle = "";
            var meetingId = "";
            var meetingStartTime = "";
            var meetingEndTime = "";
            var totalParticipant = 0;
            var totalDuration = 0
            let finalList = [];
            let sendList = [];
            let hour = 0;
            let min = 0;
            let duration = "";
            let tempJoiningDate = "";
            let emailFlag = 0;

            let AssignmentId_1 = 0;
            var Session_Id_1 = "";
            var Participant_Id_1 = "";
            var Full_Name_1 = "";
            var joiningDate_1 = "";
            var joiningTime_1 = "";
            var joiningDateTime_1 = "";
            var LeftDateTime_1 = "";
            var leftTime_1 = "";
            var IsPresent_1 = 0;
            let totalDuration_1 = 0;

            let First_Flag = 0;

            for (let i = 0; i < d.length; i++) {
                console.log(d[i]);
                if (d[i]["Meeting Summary"] == "Full Name") {
                    //alert("Full name");
                    IsDataFlag = 1;
                }
                else if (IsDataFlag == 1) {
                    totalDuration = 0;

                    duration = d[i]["__EMPTY_2"];
                    var durationArray = duration.split(' ');


                    for (let j = 0; j < durationArray.length; j++) {
                        if (durationArray[j].includes('h')) {
                            totalDuration = totalDuration + (parseInt(durationArray[j].replace('h', '')) * 60);
                        }
                        else if (durationArray[j].includes('m')) {
                            totalDuration = totalDuration + parseInt(durationArray[j].replace('m', ''));
                        }
                        else if (durationArray[j].includes('s')) {
                            // no action
                        }
                    }

                    // if (i == 6) {
                    //     alert(duration);
                    //     alert("TD : " + totalDuration);
                    //     // date format   MM/d/yyyy                        
                    //     tempJoiningDate = d[i]["__EMPTY"];
                    //     var vv = moment(tempJoiningDate.replace(',', ' ').replace('  ', ' '));
                    //     alert(vv);
                    //     alert(vv.format('YYYY-MM-DD HH:mm:ss'))
                    // }

                    let new_Entry = {
                        AssignmentId: props.UP_AID,
                        Session_Id: meetingId,
                        Participant_Id: d[i]["__EMPTY_3"],
                        Full_Name: d[i]["Meeting Summary"],
                        joiningDate: meetingStartTime,
                        joiningTime: moment(d[i]["__EMPTY"].replace(',', ' ').replace('  ', ' ')).format("HH:mm:ss"),
                        joiningDateTime: moment(d[i]["__EMPTY"].replace(',', ' ').replace('  ', ' ')).format('YYYY-MM-DD HH:mm:ss'),
                        LeftDateTime: moment(d[i]["__EMPTY_1"].replace(',', ' ').replace('  ', ' ')).format('YYYY-MM-DD HH:mm:ss'),
                        leftTime: moment(d[i]["__EMPTY_1"].replace(',', ' ').replace('  ', ' ')).format("HH:mm:ss"),
                        IsPresent: 1,
                        totalDuration: totalDuration
                    }
                    finalList.push(new_Entry);
                }
                else {
                    if (d[i]["Meeting Summary"].includes("Total Number of Participants")) {
                        //  alert("Participant count");
                        totalParticipant = d[i]["__EMPTY"];
                    }
                    else if (d[i]["Meeting Summary"].includes("Meeting Title")) {
                        //  alert("Tital");
                        meetingTitle = d[i]["__EMPTY"];
                    }
                    else if (d[i]["Meeting Summary"].includes("Meeting Id")) {
                        //  alert("Id");
                        meetingId = d[i]["__EMPTY"];
                    }
                    else if (d[i]["Meeting Summary"].includes("Meeting Start Time")) {
                        //   alert("Start Time");
                        meetingStartTime = moment(d[i]["__EMPTY"].replace(',', ' ').replace('  ', ' ')).format('YYYY-MM-DD');
                    }
                    else if (d[i]["Meeting Summary"].includes("Meeting End Time")) {
                        //   alert("End time");
                        meetingEndTime = d[i]["__EMPTY"];
                    }
                    else {
                    }
                }
            }

            // alert(totalParticipant);
            // alert(meetingTitle);
            // alert(meetingId);
            // alert(meetingStartTime);
            // alert(meetingEndTime);
            // console.log(d);

            //console.log(finalList);
            let uniqueParticipant = [...new Set(finalList.map(obj => obj.Full_Name))];
            console.log(uniqueParticipant);


            for (let k = 0; k < uniqueParticipant.length; k++) {
                AssignmentId_1 = 0;
                Session_Id_1 = "";
                Participant_Id_1 = "";
                Full_Name_1 = "";
                joiningDate_1 = "";
                joiningTime_1 = "";
                joiningDateTime_1 = "";
                LeftDateTime_1 = "";
                leftTime_1 = "";
                IsPresent_1 = 0;
                totalDuration_1 = 0;
                First_Flag = 0;

                // if (k == 0) {
                //     alert(uniqueParticipant[k]);
                // }

                for (let m = 0; m < finalList.length; m++) {
                    if (finalList[m].Full_Name == uniqueParticipant[k]) {
                        if (First_Flag > 0) {
                            LeftDateTime_1 = finalList[m].LeftDateTime;
                            leftTime_1 = finalList[m].leftTime;
                            totalDuration_1 = totalDuration_1 + finalList[m].totalDuration;
                        }
                        else {
                            AssignmentId_1 = finalList[m].AssignmentId;
                            Session_Id_1 = finalList[m].Session_Id;
                            if (finalList[m].Participant_Id == "") {
                                Participant_Id_1 = "";
                            }
                            else {
                                Participant_Id_1 = finalList[m].Participant_Id;
                            }
                            Full_Name_1 = finalList[m].Full_Name;
                            joiningDate_1 = finalList[m].joiningDate;
                            joiningTime_1 = finalList[m].joiningTime;
                            joiningDateTime_1 = finalList[m].joiningDateTime;
                            LeftDateTime_1 = finalList[m].LeftDateTime;
                            leftTime_1 = finalList[m].leftTime;
                            IsPresent_1 = finalList[m].IsPresent;
                            totalDuration_1 = finalList[m].totalDuration;
                            First_Flag = 1;
                        }
                    }
                }

                let new_Entry = {
                    AssignmentId: AssignmentId_1,
                    Session_Id: Session_Id_1,
                    Participant_Id: Participant_Id_1,
                    Full_Name: Full_Name_1,
                    joiningDate: joiningDate_1,
                    joiningTime: joiningTime_1,
                    joiningDateTime: joiningDateTime_1,
                    LeftDateTime: LeftDateTime_1,
                    leftTime: leftTime_1,
                    IsPresent: IsPresent_1,
                    totalDuration: totalDuration_1
                }
                sendList.push(new_Entry);
            }

            console.log("list");
            console.log(sendList);
            var objPost = [];
            var jsonDT = "";

            if (sendList.length > 60) {
                alert('Multiple call');
                for (let ll = 0; ll < sendList.length; ll = ll + 50) {
                    console.log("Loop  " + ll);
                    const items = sendList.slice(ll, ll + 50);
                    objPost = {
                        jsonDT: items,
                        AssignmentId: props.UP_AID,
                        TID: props.UP_Tid,
                        ILOM_ID: props.UP_ILOMID,
                        ADate: meetingStartTime,
                        SCID: props.UP_SCID
                    }

                    axios.post('Nishant/SaveAttendanceDetail', objPost).then(response => {
                        // return response.data.message;
                        if (response.data == 'Done') {
                            //emailFlag = 1;
                        }
                        console.log("Result : " + response.data);

                    }).catch(err => {
                        console.log(err);
                    })
                    // console.log(objPost);
                }

                var objPost1 = [];
                objPost1 = {
                    AssignmentId: props.UP_AID,
                    TID: props.UP_Tid,
                    ILOM_ID: props.UP_ILOMID,
                    ADate: meetingStartTime,
                    SCID: props.UP_SCID
                }
                console.log(objPost1);
                // email List and mark absent
                let { data1 } = axios.post("Nishant/EmailAttendanceDetail", objPost1);
                // console.log(data1);
                ReactDOM.render("", document.getElementById('getSweetModel'));
                NotificationManager.success("Successfully marked", "done");
            }
            else {
                // alert('One call');
                objPost = {
                    jsonDT: sendList,
                    AssignmentId: props.UP_AID,
                    TID: props.UP_Tid,
                    ILOM_ID: props.UP_ILOMID,
                    ADate: meetingStartTime,
                    SCID: props.UP_SCID
                }
                // let { data } = axios.post("Nishant/SaveAttendanceDetail", objPost);

                emailFlag = axios.post('Nishant/SaveAttendanceDetail', objPost).then(response => {
                    // return response.data.message;
                    if (response.data == "Done") {
                        var objPost1 = [];
                        objPost1 = {
                            AssignmentId: props.UP_AID,
                            TID: props.UP_Tid,
                            ILOM_ID: props.UP_ILOMID,
                            ADate: meetingStartTime,
                            SCID: props.UP_SCID
                        }
                        console.log(objPost1);
                        // email List and mark absent
                        let { data1 } = axios.post("Nishant/EmailAttendanceDetail", objPost1);

                        // console.log(data1);
                        ReactDOM.render("", document.getElementById('getSweetModel'));
                        NotificationManager.success("Successfully marked", "done");
                    }
                    else {
                        ReactDOM.render("", document.getElementById('getSweetModel'));
                        NotificationManager.error('Data Not Saved !!!! No PAX found in Assignment');
                    }
                    console.log("Result : " + response.data);

                }).catch(err => {
                    console.log(err);
                })

            }


            // console.log("Email List : " + emailFlag);
            // // email and mark IsPresent=0 for the student not in excel
            // if (emailFlag == 1) {
            //     var objPost1 = [];
            //     objPost1 = {
            //         AssignmentId: props.UP_AID,
            //         TID: props.UP_Tid,
            //         ILOM_ID: props.UP_ILOMID,
            //         ADate: meetingStartTime,
            //         SCID: props.UP_SCID
            //     }
            //     console.log(objPost1);
            //     // email List and mark absent
            //     let { data1 } = axios.post("Nishant/EmailAttendanceDetail", objPost1);

            //     // console.log(data1);
            //     ReactDOM.render("", document.getElementById('getSweetModel'));
            //     NotificationManager.success("Successfully marked", "done");
            // }
            // else {
            //     ReactDOM.render("", document.getElementById('getSweetModel'));
            //     NotificationManager.error('Data Not Saved !!!! No PAX found in Assignment');
            // }
            // setItems(d);
        });

    }


    // const uploadMe = () => {
    //     set_loading(true);

    //     readXlsxFile(document.getElementById('uploadAttendanceFile').files[0]).then((rows) => {
    //         // http://172.22.50.3:8182/createMeetingByAssignmentId

    //         try {
    //             let obj = [];
    //             var jsonDT = "";
    //             // Get all Record from excel
    //             //for (let i = 0; i < rows.length; i++) {
    //             for (let i = 0; i < rows.length; i++) {

    //                 if (rows[i][0].includes("Session")) {
    //                 }
    //                 else if (rows[i][1] == '') {
    //                 }
    //                 else if (rows[i][1] == null) {
    //                 }
    //                 else {
    //                     obj.push({ Session_Id: rows[i][0], Participant_Id: rows[i][1], Full_Name: rows[i][2], UTC_Event_Timestamp: rows[i][4], Action: rows[i][5], Role: rows[i][6] });
    //                 }
    //                 // axios.get("http://api.koenig-solutions.com:8182/api/SendFeedbackLink/" + d.ClientId + "/" + d.SCId + "/" + d.CourseId).then(function (res) {
    //                 // var HtmlContent = ReactDOMServer.renderToStaticMarkup(FeedbackLink(d, res.data));
    //                 //  SendMail(d.ILOMEmail, d.StudentEamil, d.ILOMEmail + ";" + d.CCEEmail, 'nishant.sinha@koenig-solutions.com;', 'Feedback for ' + AssignmentID, FeedbackLink(d, res.data))
    //                 //})
    //             }

    //             // get unique record
    //             let uniqueParticipantId = [...new Set(obj.map(obj => obj.Participant_Id))];

    //             let finalList = [];
    //             let uniqueParticipantAllData = [];
    //             let uniqueDate = [];
    //             //console.log(unique);
    //             let splitDate = [];

    //             let joiningTimeList = [];
    //             let leftTimeList = [];
    //             let particularDateinfo = [];
    //             let uniqueSessionStr = [];
    //             var uniqueSession = [];

    //             let tempCalcuteHourfromList = []
    //             let totalDuration = 0;
    //             let tempDuration = 0;

    //             for (let k = 0; k < uniqueParticipantId.length; k++) {
    //                 //  console.log("value", k);
    //                 uniqueParticipantAllData = [];
    //                 uniqueDate = [];
    //                 splitDate = [];
    //                 uniqueSession = [];

    //                 for (let j = 0; j < obj.length; j++) {
    //                     if (obj[j].Participant_Id == uniqueParticipantId[k]) {
    //                         //  console.log("Count ", j);
    //                         uniqueParticipantAllData.push({ Session_Id: obj[j].Session_Id, Participant_Id: obj[j].Participant_Id, Full_Name: obj[j].Full_Name, UTC_Event_Timestamp: obj[j].UTC_Event_Timestamp, Action: obj[j].Action, Role: obj[j].Role });
    //                     }
    //                 }

    //                 // make data from extracted data
    //                 // Step 1 : get distinct date
    //                 uniqueDate = [...new Set(uniqueParticipantAllData.map(uniqueParticipantAllData => uniqueParticipantAllData.UTC_Event_Timestamp))];
    //                 //console.log("uniqueDate" + uniqueDate);

    //                 // unique session for exact calculation of hours attended by client
    //                 uniqueSessionStr = [...new Set(uniqueParticipantAllData.map(uniqueParticipantAllData => uniqueParticipantAllData.Session_Id))];
    //                 //console.log("uniqueSessionStr" + uniqueSessionStr);

    //                 //console.log("uniqueParticipantAllData" + uniqueParticipantAllData.Session_Id);
    //                 // for (let h = 0; h < uniqueSessionStr.split(',').length; h++) {
    //                 //     alert(uniqueSessionStr.split(',')[h].Session_Id)
    //                 //     uniqueSession.push({ Session_Id: uniqueSessionStr.split(',')[h].Session_Id });
    //                 // }

    //                 // split and update
    //                 for (let l = 0; l < uniqueDate.length; l++) {
    //                     //  console.log(uniqueDate[l].split(' ')[0]);
    //                     splitDate.push({ UTC_Event_Timestamp: uniqueDate[l].split(' ')[0] });
    //                 }

    //                 // get distinct date
    //                 splitDate = [...new Set(splitDate.map(splitDate => splitDate.UTC_Event_Timestamp))];

    //                 // make actual data to insert
    //                 for (let l = 0; l < splitDate.length; l++) {
    //                     // from unique date get the data for Joined and Left
    //                     joiningTimeList = [];
    //                     leftTimeList = [];
    //                     particularDateinfo = [];

    //                     // console.log("uniqueParticipantAllData", uniqueParticipantAllData)
    //                     // Loop through Participant data to get Joining time and left time
    //                     for (let m = 0; m < uniqueParticipantAllData.length; m++) {
    //                         //console.log("Action ", uniqueParticipantAllData[m].Action);
    //                         if (uniqueParticipantAllData[m].UTC_Event_Timestamp.includes(splitDate[l])) {
    //                             if (uniqueParticipantAllData[m].Action.includes("Joined")) {
    //                                 joiningTimeList.push({ Session_Id: uniqueParticipantAllData[m].Session_Id, Participant_Id: uniqueParticipantAllData[m].Participant_Id, Full_Name: uniqueParticipantAllData[m].Full_Name, UTC_Event_Timestamp: uniqueParticipantAllData[m].UTC_Event_Timestamp, Action: uniqueParticipantAllData[m].Action, Role: uniqueParticipantAllData[m].Role });
    //                             }
    //                             else if (uniqueParticipantAllData[m].Action.includes("Left")) {
    //                                 leftTimeList.push({ Session_Id: uniqueParticipantAllData[m].Session_Id, Participant_Id: uniqueParticipantAllData[m].Participant_Id, Full_Name: uniqueParticipantAllData[m].Full_Name, UTC_Event_Timestamp: uniqueParticipantAllData[m].UTC_Event_Timestamp, Action: uniqueParticipantAllData[m].Action, Role: uniqueParticipantAllData[m].Role });
    //                             }
    //                         }
    //                     }

    //                     //  console.log("Left time count :" + leftTimeList.length);
    //                     //  console.log("Joining time count :" + joiningTimeList.length);

    //                     // now we have joining time and left time of that date
    //                     // console.log(joiningTimeList);
    //                     // console.log(leftTimeList);                          
    //                     // console.log(leftTimeList[leftTimeList.length - 1].UTC_Event_Timestamp);

    //                     // calculate exact hours from seesion id data
    //                     totalDuration = 0;

    //                     // console.log(joiningTimeList);
    //                     // console.log(leftTimeList);
    //                     for (let q = 0; q < joiningTimeList.length; q++) {
    //                         // console.log(joiningTimeList);
    //                         tempCalcuteHourfromList = [];
    //                         tempDuration = 0;
    //                         //console.log("Joining Session " + joiningTimeList[q].Session_Id);
    //                         // one session will have two entry
    //                         for (let z = 0; z < uniqueParticipantAllData.length; z++) {
    //                             if (uniqueParticipantAllData[z].Session_Id == joiningTimeList[q].Session_Id) {
    //                                 // console.log("Session " + q + " :" + uniqueParticipantAllData[z].UTC_Event_Timestamp);
    //                                 tempCalcuteHourfromList.push({ UTC_Event_Timestamp: uniqueParticipantAllData[z].UTC_Event_Timestamp });
    //                             }
    //                         }

    //                         //  console.log(tempCalcuteHourfromList);

    //                         if (tempCalcuteHourfromList.length > 1) {
    //                             //  console.log(tempCalcuteHourfromList[0].UTC_Event_Timestamp);
    //                             // console.log(tempCalcuteHourfromList[1].UTC_Event_Timestamp);
    //                             if (moment(tempCalcuteHourfromList[0].UTC_Event_Timestamp) < moment(tempCalcuteHourfromList[1].UTC_Event_Timestamp)) {
    //                                 const diff = moment(tempCalcuteHourfromList[1].UTC_Event_Timestamp).diff(moment(tempCalcuteHourfromList[0].UTC_Event_Timestamp));
    //                                 const diffDuration = moment.duration(diff);
    //                                 //  console.log(diffDuration.seconds());
    //                                 // console.log("H1" + tempCalcuteHourfromList[0].UTC_Event_Timestamp.diff(tempCalcuteHourfromList[1].UTC_Event_Timestamp).minutes());
    //                                 tempDuration = diffDuration.seconds()
    //                             }
    //                             else {
    //                                 const diff = moment(tempCalcuteHourfromList[0].UTC_Event_Timestamp).diff(moment(tempCalcuteHourfromList[1].UTC_Event_Timestamp));
    //                                 const diffDuration = moment.duration(diff);
    //                                 //  console.log(diffDuration.seconds());

    //                                 tempDuration = diffDuration.seconds()
    //                             }
    //                         }

    //                         // console.log("temp duration :" + tempDuration);
    //                         totalDuration = totalDuration + tempDuration;
    //                     }

    //                     // console.log("Total Duartion :" + totalDuration);

    //                     let new_Entry = {
    //                         AssignmentId: props.UP_AID,
    //                         Session_Id: joiningTimeList[0].Session_Id,
    //                         Participant_Id: joiningTimeList[0].Participant_Id,
    //                         Full_Name: joiningTimeList[0].Full_Name,
    //                         joiningDate: moment(joiningTimeList[0].UTC_Event_Timestamp).format('YYYY-MM-DD'),
    //                         joiningTime: moment(joiningTimeList[0].UTC_Event_Timestamp).format("HH:mm:ss"),
    //                         joiningDateTime: joiningTimeList[0].UTC_Event_Timestamp,
    //                         LeftDateTime: leftTimeList[leftTimeList.length - 1].UTC_Event_Timestamp,
    //                         leftTime: moment(leftTimeList[leftTimeList.length - 1].UTC_Event_Timestamp).format("HH:mm:ss"),
    //                         IsPresent: 1,
    //                         totalDuration: totalDuration
    //                     }

    //                     finalList.push(new_Entry);
    //                 }
    //             }

    //             // console.log("Final List");
    //             console.log(finalList);
    //             console.log("Length : " + finalList.length);

    //             if (finalList.length > 50) {
    //                 jsonDT = "";
    //                 var objPost = [];
    //                 // logic as getting error while sending long json                    
    //                 //const items = finalList.slice(0, 2);
    //                 //console.log(items[0]);
    //                 var items = [];
    //                 for (let ll = 0; ll < finalList.length; ll = ll + 50) {
    //                     // console.log("loop : " + ll);
    //                     const items = finalList.slice(ll, ll + 50);
    //                     objPost = {
    //                         jsonDT: items
    //                     }
    //                     // console.log(objPost);
    //                     let { data } = axios.post("Nishant/SaveAttendanceDetail", JSON.parse(JSON.stringify(objPost)));
    //                     // console.log(objPost);
    //                 }
    //             }
    //             else {
    //                 jsonDT = "";
    //                 var objPost = [];
    //                 objPost = {
    //                     jsonDT: finalList
    //                 }
    //                 let { data } = axios.post("Nishant/SaveAttendanceDetail", JSON.parse(JSON.stringify(objPost)));
    //             }
    //             console.log(props.UP_Tid);
    //             console.log(props.UP_ILOMID);

    //             var objPost1 = [];
    //             objPost1 = {
    //                 AssignmentId: props.UP_AID,
    //                 TID: props.UP_Tid,
    //                 ILOM_ID: props.UP_ILOMID
    //             }
    //             // email List
    //             // let { data1 } = axios.post("Nishant/EmailAttendanceDetail", objPost1);

    //             ReactDOM.render("", document.getElementById('getSweetModel'));
    //             NotificationManager.success("Successfully marked", "done");

    //             // post data
    //             set_loading(false);

    //         } catch (er) {
    //             set_loading(false);
    //             alert(JSON.stringify(er));
    //         }
    //     })
    // }




    // const GetStudentDetail = () => {
    //     set_loading(true);
    //     axios.get("Nishant/GetStudentDetailOfAssignment?AID=" + props.AID).then(function (response) {
    //         setRows(response.data);
    //         set_loading(false);
    //     }).catch(function (error) {
    //         alert(error);
    //     });
    // }

    // const GetDataTable = () => {
    //     if (Rows.length > 0) return <CSDataTable Rows={Rows} Columns={Coulumn} PageSize={20} Length_menu={[20, 50, 100]}></CSDataTable>
    //     else return <div className="text-center"><label className="alert-danger alert">Pax details are not added</label></div>
    // }



    // input.addEventListener('change', () => {
    // readXlsxFile(input.files[0]).then((rows) => {

    //     console.log(rows[0]);
    //     console.log(rows[1]);
    //     // `rows` is an array of rows
    //     // each row being an array of cells.
    // })
    //})

    // readXlsxFile(fs.createReadStream('/path/to/file')).then((rows) => {
    //     // `rows` is an array of rows
    //     // each row being an array of cells.
    // })
    // readXlsxFile1('/path/to/file').then((rows) => {
    //     // `rows` is an array of rows
    //     // each row being an array of cells.
    // })
    return (
        <div className="pl-2 pr-2">

            <input type="file" accept=".xlsx,.xls" id="uploadAttendanceFile" />
            <button onClick={() => uploadMe()} >Submit</button>

            {/* <input
                type="file"
                accept=".csv,.xlsx,.xls"
                onChange={handleFileUpload}
            /> */}

        </div>
    )
}

export default UploadAttendance
