import React, { useEffect } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import {
  Loader,
  Radio,
  Button,
  Icon,
  Checkbox,
  Modal,
  Table,
  Dropdown,
  Select,
} from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import "react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css";
import "./SIPNew.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import "bootstrap/dist/css/bootstrap.css";
import ReactDOM from "react-dom";
import AccessDenied from "../../reusable/AccessDenied";

const axios = require("axios");

export default function SIPNew() {
  const [emp_id, set_emp_id] = React.useState(localStorage.getItem("emp_id"));
  const [loading, set_loading] = React.useState(true);
  const [data_list, set_data_list] = React.useState([]);
  const [data_list2, set_data_list2] = React.useState([]);
  const [header, set_header] = React.useState([]);
  const [header2, set_header2] = React.useState([]);
  const [cell_data, cell_cell_data] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [openConditon, setOpenCondition] = React.useState(false);
  const [month, set_month] = React.useState("");
  const [user, set_user] = React.useState("");
  const [type, set_type] = React.useState(0);
  const [paidornot, set_paidornot] = React.useState("nonpaid");
  const [cceorasm, set_cceorasm] = React.useState("cce");
  const [headerMonth, setHeaderMonth] = React.useState("");
  const [headerMonth1, setHeaderMonth1] = React.useState("");
  const [moveMonths, setMoveMonths] = React.useState(1);

  const [pliCOndition, setPliCondition] = React.useState("");

  const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

  const addMonths = (input, months) => {
    const date = new Date(input);

    date.setDate(1);
    date.setMonth(date.getMonth() + months - 1);
    date.setDate(
      Math.min(
        input.getDate(),
        getDaysInMonth(date.getFullYear(), date.getMonth() + 1)
      )
    );
    return date;
  };

  useEffect(() => {
    set_loading(true);
    let d = localStorage.getItem("emp_data");
    axios
      .get("jai/GetAuthenticated?Type=10&Id=" + JSON.parse(d).EmpId)
      .then(function (response) {
        if (response.data.length == 0)
          ReactDOM.render(
            <AccessDenied></AccessDenied>,
            document.getElementById("divcontent")
          );
        // else set_loading(false);
      })
      .catch(function (error) {
        alert(error);
      });
    get_data2(moveMonths);
  }, [moveMonths]);

  let separator = "-";
  let sdnewDate = new Date();
  let sddate = sdnewDate.getDate();
  let sdmonth = sdnewDate.getMonth() + 1;
  let sdyear = sdnewDate.getFullYear();

  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  let monthIndex = sdnewDate.getMonth() - 1 == 0 ? 11 : sdnewDate.getMonth();
  let monthIndex1 = sdnewDate.getMonth() - 2 == 0 ? 10 : sdnewDate.getMonth();
  let monthIndex2 = sdnewDate.getMonth() - 3 == 0 ? 9 : sdnewDate.getMonth();
  let sddmonth = months[sdnewDate.getMonth()].toUpperCase();
  let sddmonth1 = months[monthIndex].toUpperCase();
  let sddmonth2 = months[monthIndex1].toUpperCase();
  let sddmonth3 = months[monthIndex2].toUpperCase();
  let sddyear = sdnewDate.getFullYear();

  let fsdate = `${sdyear}${separator}${sdmonth < 10 ? `0${sdmonth}` : `${sdmonth}`
    }${separator}${sddate < 10 ? `0${sddate}` : `${sddate}`}`;

  function get_data2(m) {
    var d = new Date();
    d = addMonths(d, m);
    var curr_month = d.getMonth() + 1; //Months are zero based
    var curr_year = d.getFullYear();

    let date = curr_year + "-" + curr_month + "-01";
    setHeaderMonth(
      months[d.getMonth()].toUpperCase() + " - " + d.getFullYear()
    );
    let mIndex = d.getMonth() == 0 ? 11 : d.getMonth() - 1;
    let mYear = d.getMonth() == 0 ? d.getFullYear() - 1 : d.getFullYear();

    setHeaderMonth1(months[mIndex].toUpperCase() + " - " + mYear);

    axios
      .get("yusuf/salespolicyOverseas?month=" + date + "")
      .then(function (response) {
        //alert(JSON.stringify(response.data))
        //alert( JSON.stringify(response.data[0]))
        set_header2(Object.keys(response.data[0]));
        set_data_list2(response.data);
        set_loading(false);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  function openConditions() {
    set_loading(true);
    axios.get("yusuf/getOverseasPLIConditions").then(
      (response) => {
        set_loading(false);
        if (response.data.length > 0) {
          // console.log(response.data[0].HtmlContent);
          setPliCondition(response.data[0].HtmlContent);
          setOpenCondition(true);
          set_loading(false);

        }
        else {
          alert("No Data Found");
        }
        set_loading(false);
      }
    ).catch((error) => {
      alert(error);
    })
  }

  function cell_onclick(u, m) {
    if (m == "Name") {
      return;
    }
    m = "01-" + m;
    set_month(m);
    set_user(u);
    set_loading(true);
    axios
      .get(
        "yusuf/salespolicyOverseasClick?month=" +
        m +
        "&name=" +
        u +
        "&type=" +
        "1" +
        ""
      )
      // axios.get('yusuf/spSalesPolicyOnclick?ispaid=' + paidornot + '&cce=' + u + '&header=' + m + '')
      .then(function (response) {
        set_loading(false);
        if (response.data.length > 0) {
          // alert(JSON.stringify(response.data))
          // set_header(Object.keys(response.data[0]))
          cell_cell_data(response.data);
          setOpen(true);
          set_loading(false);
        } else {
          alert("NO DATA");
        }
        set_loading(false);
      })
      .catch(function (error) {
        alert(error);
      });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100vw",
        height: "100vh",
      }}
      className="mainDivCV"
      id="divcontent"
    >
      {/* DATA SECTION */}
      {/* <div style={{ width: '100vw', height: '100vh', display: (emp_id == 2131 || emp_id == 1687 || emp_id == 1460) ? 'flex' : 'none', flexDirection: 'column', backgroundColor: '#ffffff' }}> */}
      <div
        style={{
          width: "100vw",
          height: "100vh",
          flexDirection: "column",
          backgroundColor: "#ffffff",
        }}
      >
        <div
          style={{
            display: "block",
            marginTop: "1%",
          }}
        >
          <div
            style={{
              display: loading ? "none" : "flex",
              padding: "1% 1%",
              textAlign: "center",
            }}
          >
            <span style={{ width: "100%", fontSize: "140%" }}>
              <Icon
                onClick={() => {
                  setMoveMonths(moveMonths + 2);
                }}
                size="big"
                color="teal"
                name="caret left"
                style={{ cursor: "pointer" }}
              />
              <span title="Click to Check Conditions" style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }} onClick={openConditions}>Overseas PLI</span>
              <Icon
                onClick={() => {
                  setMoveMonths(moveMonths - 2);
                }}
                size="big"
                color="teal"
                name="caret right"
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>

          <div
            style={{
              display: loading ? "none" : "flex",
              padding: "0.2%",
              overflow: "scroll",
              height: "100vh",
              marginTop: "1%",
            }}
          >
            {/* <DynamicDataTable style={{border:2}} rows={data_list} /> */}

            <span style={{ width: "100%", overflowY: "scroll" }}>
              <table
                id="cconversion"
                className="table"
                style={{ width: "100%" }}
              >
                <thead>
                  <tr style={{ fontSize: 14, fontWeight: "bold" }}>
                    {header2.map((key, index) => {
                      if (key.toUpperCase() == "Name") {
                        return (
                          <th className="text-center" key={index}>
                            ASM
                          </th>
                        );
                      } else {
                        return (
                          <th className="text-center" key={index}>
                            {key.toUpperCase()}
                          </th>
                        );
                      }
                    })}
                  </tr>
                </thead>

                <tbody>
                  {data_list2.map((row, ii) => (
                    <tr key={ii}>
                      {Object.values(row).map((rowValue, i) => {
                        return (
                          <td
                            className="text-center"
                            style={{ cursor: "pointer" }}
                            key={i}
                            onClick={() => {
                              cell_onclick(data_list2[ii].Name, header2[i]);
                            }}
                            dangerouslySetInnerHTML={{ __html: rowValue }}
                          ></td>
                        );
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </span>
          </div>
        </div>

        {/* LOADER */}
        <div
          style={{
            display: loading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            flexDirection: "column",
            height: "90vh",
          }}
        >
          <Loader active inline size="massive">
            getting data...
          </Loader>
        </div>

        <div>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            style={{ marginTop: "0px", marginLeft: "10%", width: "80%" }}
          >
            <Modal.Header>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "20%" }}>
                  <div>{user}</div>
                  {/* <div>{month}</div> */}
                </div>
                <div
                  style={{
                    width: "60%",
                    textAlign: "center",
                    fontSize: "large",
                    paddingTop: "1%",
                  }}
                >
                  {"TRUE NR"}
                </div>
                <div style={{ width: "10%", position: "absolute", right: 10 }}>
                  <Button
                    onClick={() => {
                      setOpen(false);
                      set_type(0);
                    }}
                    primary
                  >
                    Close <Icon style={{ marginLeft: 10 }} name="close" />
                  </Button>
                </div>
              </div>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <Table className="cconversion" celled fixed singleLine>
                  <Table.Header style={{ display: "revert" }}>
                    <Table.Row>
                      {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                      {/* <Table.HeaderCell>MonYear</Table.HeaderCell> */}
                      <Table.HeaderCell>Name</Table.HeaderCell>
                      <Table.HeaderCell>Month</Table.HeaderCell>
                      <Table.HeaderCell>SCID</Table.HeaderCell>
                      <Table.HeaderCell>NR</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {cell_data.map((d) => {
                      return (
                        <Table.Row>
                          <Table.Cell>
                            <center>{d.Name}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.month}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.scid}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.total_nr}</center>
                          </Table.Cell>
                          <Table.Cell>
                            <center>{d.type}</center>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>


        <div>
          <Modal
            open={openConditon}
            onClose={() => setOpenCondition(false)}
            onOpen={() => setOpenCondition(true)}
            style={{ height: "auto", left: "auto", right: "auto", bottom: "auto", top: "auto" }}
          >
            <Modal.Header>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <div
                  style={{
                    width: "60%",
                    textAlign: "center",
                    fontSize: "large",
                    paddingTop: "1%",
                  }}
                >
                  {"PLI Conditions"}
                </div>
                <div style={{ width: "10%", position: "absolute", right: 10 }}>
                  <Button
                    onClick={() => {
                      setOpenCondition(false);
                      // set_type(0);
                    }}
                    primary
                  >
                    <Icon style={{ marginLeft: 10 }} name="close" />
                  </Button>
                </div>
              </div>
            </Modal.Header>

            <Modal.Content scrolling>
              <Modal.Description>
                <div dangerouslySetInnerHTML={{ __html: pliCOndition }} >
                </div>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </div>

      </div>
    </div >
  );
}
