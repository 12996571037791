import React, { useEffect, useState } from "react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import {
  Icon,
  Loader,
  Button,
  Modal,
  Table,
  Container,
  Label,
  Dropdown,
} from "semantic-ui-react";
import SVGLoader from "../Nishant/MainProject/Reuseble/SVGLoader";
import "./ilom.css";
const axios = require("axios");
var moment = require('moment'); // require

const ILOM_kpi = () => {
  const [loading, set_loading] = React.useState(true);
  const [data_list, set_data_list] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [link, set_link] = React.useState("");
  const [sd, set_sd] = React.useState("");
  const [ed, set_ed] = React.useState("");
  const [cell_data, cell_cell_data] = React.useState([]);
  const [header1, set_header1] = React.useState("Name");
  const [header2, set_header2] = React.useState("");
  const [header3, set_header3] = React.useState("");
  const [type1, set_type] = React.useState("only_non_tp");

  const options = [
    { key: 1, text: "All", value: "all" },
    { key: 2, text: "Only Third Party", value: "only_tp" },
    { key: 3, text: "Only Non-third Party", value: "only_non_tp" },
  ];

  //const [link_placeholder, seset_link_placeholdert_link] = React.useState('')

  useEffect(() => {
    set_loading(true);
    get_data();
  }, []);

  let separator = "-";
  const today1 = moment();

  // start date
  // let ddate = today1.date();
  // let dmonth = (today1.month() - 1);
  // let dyear = today1.year();

  let Ssdate = moment(today1).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');

  // let Ssdate = `${dyear}${separator}${dmonth < 10 ? `0${dmonth}` : `${dmonth}`
  //   }${separator}${ddate}`;

  // END date
  // let sddate = today1.date();
  // let sdmonth = today1.month() + 1;
  // let sdyear = today1.year();

  let fsdate = moment(today1).add(0, 'months').startOf('month').format('YYYY-MM-DD');
  // let fsdate = `${sdyear}${separator}${sdmonth < 10 ? `0${sdmonth}` : `${sdmonth}`
  //   }${separator}${sddate}`;

  async function get_data() {
    set_loading(true);
    // let js_start = moment(sd);
    // let js_end = moment(ed);

    if (sd == '') {
      set_sd(Ssdate);
    }
    else {
      const today1 = moment(sd);
      Ssdate = today1.format('YYYY-MM-DD');
    }

    if (ed == '') {
      set_ed(fsdate);
    }
    else {
      const today1 = moment(ed);
      fsdate = today1.format('YYYY-MM-DD');
    }

    // alert(Ssdate);
    // alert(fsdate);
    console.log("sd_" + Ssdate);
    console.log("sd_" + fsdate);
    // let sd_ =

    //   js_start.month() + 1 +
    //   "/" +
    //   js_start.date() +
    //   "/" +
    //   js_start.year();

    // let ed_ =
    //   js_end.month() + 1 +
    //   "/" +
    //   js_end.date() +
    //   "/" +
    //   js_end.year();


    // console.log("sd " + sd_);
    // console.log("ed " + ed_);

    // if (sd == "") {
    //   const today = moment();

    //   console.log(today.month());
    //   console.log(today.year());
    //   console.log(today.date());

    //   //let CurrDate = new Date();
    //   // sd_ = "04/01/2021";
    //   sd_ =
    //     today.month()
    //     +
    //     "/" +
    //     today.date() +
    //     "/" +
    //     today.year();


    //   console.log("sd_" + sd_);

    // }


    // if (ed == "") {
    //   const today = moment();

    //   let CurrDate = new Date();
    //   ed_ =
    //     today.month() +
    //     1 +
    //     "/" +
    //     today.date() +
    //     "/" +
    //     today.year();

    //   console.log("ed_" + ed_);
    // }




    let { data } = await axios.get(
      "anjali/sp_get_ILOM_KPI?from=" +
      Ssdate +
      "&to=" +
      fsdate +
      "&type=" +
      type1 +
      ""
    );
    if (data.length > 0) {
      set_loading(false);
      set_data_list(data);
    } else {
      alert("NO DATA");
      set_loading(false);
    }
  }

  function play(link) {
    //seset_link_placeholdert_link(link)
    set_link(link.split("be/")[1]);
    setOpen(true);
  }

  async function cell_click(ilom_name, type) {
    if (type == "repeated") {
      set_header1("Learner");

      set_header2("SCID");
      set_header3("Training Date");
    }
    if (type == "qubits") {
      set_header1("Name");

      set_header2("Assignment ID");
      set_header3("Qubits42");
    }
    if (type == "students") {
      set_header1("Learner");
      set_header2("Assignment ID");
      set_header3("Training Date");
    }
    if (type == "assignments") {
      set_header1("SCID");

      set_header2("Assignment ID");
      set_header3("Training Date");
    }
    if (type == "no_feedback") {
      set_header1("Learner");

      set_header2("SCID");
      set_header3("Training Date");
    }

    if (type == "referral") {
      set_header2("Created Date");
      set_header3("SCID");
    }
    if (type == "ref_regn") {
      set_header2("Created Date");
      set_header3("SCID");
    }

    if (type == "video") {
      set_header1("Name");

      set_header2("Assignment ID");
      set_header3("");
    }

    if (type == "fir") {
      set_header1("Assignment ID");
      set_header2("Course");
      set_header3("Remark");
    }

    //testing
    set_loading(true);
    //alert(sd);
    //alert(ed);
    const today3 = moment(sd);
    //sd = today3.format('YYYY-MM-DD');

    let frm11 = today3.format('YYYY-MM-DD');
    const today4 = moment(ed);
    let to11 = today4.format('YYYY-MM-DD');
    // alert("test" + frm11);

    //alert("to" + to11);
    //ed = today4.format('YYYY-MM-DD');
    //alert(sd);
    //alert(ed);
    console.log("to = " + to11);
    console.log("from =" + frm11);

    // let js_start = new Date(sd);
    // let js_end = new Date(ed);
    // let sd_ =
    //   js_start.getMonth() +
    //   "/" +
    //   js_start.getDate() +
    //   "/" +
    //   js_start.getFullYear();
    // let ed_ =
    //   js_end.getMonth() +
    //   "/" +
    //   js_end.getDate() +
    //   "/" +
    //   js_end.getFullYear();


    // if (sd == "") {
    //   let CurrDate = new Date();
    //   ed_ =
    //     CurrDate.getMonth() - 1 +
    //     "/" +
    //     CurrDate.getDate() +
    //     "/" +
    //     CurrDate.getFullYear();
    //   //sd_ = "04/01/2021";
    // }


    // if (ed == "") {
    //   let CurrDate = new Date();
    //   ed_ =
    //     CurrDate.getMonth() +
    //     "/" +
    //     CurrDate.getDate() +
    //     "/" +
    //     CurrDate.getFullYear();
    // }

    // if (sd == "") {
    //   sd_ = "04/01/2020";
    // }
    // if (ed == "") {
    //   ed_ = "10/14/2020";
    // }

    // console.log(sd_);
    // console.log(ed_);

    try {
      let { data } = await axios.get(
        "anjali/ilom_kpi_click?from=" +
        frm11 +
        "&to=" +
        to11 +
        "&ilom_name=" +
        ilom_name +
        "&type=" +
        type +
        "&type1=" +
        type1 +
        ""
      );
      //alert(JSON.stringify(data))

      if (data.length > 0) {
        console.log(data);
        cell_cell_data(data);
        setOpen(true);
        set_loading(false);
      } else {
        alert("NO DATA");
        set_loading(false);
      }
    } catch (er) {
      alert(JSON.stringify(er));
    }
  }

  function opem_qubits(link) {
    var win = window.open(link, "_blank");
    win.focus();
  }

  return (
    <div
      style={{
        width: "80vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#ffffff",
      }}
    >
      {/* HEADER */}
      <div
        style={{
          padding: 20,
          height: "8vh",
          backgroundColor: "#fafafb",
          width: "100vw",
          WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
          MozBoxShadow: "0 8px 6px -6px #d5d4d6",
          boxShadow: "0 8px 6px -6px #d5d4d6",
        }}
      >
        <span
          style={{
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: "sans-serif",
            marginLeft: 15,
          }}
        >
          ILOM KPI
        </span>
      </div>

      {/* FITER SECTION */}
      <div style={{ display: "flex", paddingLeft: 43, paddingTop: 20 }}>
        <div style={{ margin: 10 }}>
          <SemanticDatepicker
            placeholder={Ssdate}
            onChange={(e, v) => {
              //alert(JSON.stringify(v))
              set_sd(v.value);
            }}
          />
        </div>
        <div style={{ margin: 10 }}>
          <SemanticDatepicker
            placeholder={fsdate}
            onChange={(e, v) => {
              //alert(JSON.stringify(v))
              set_ed(v.value);
            }}
          />
        </div>

        <div style={{ margin: 10 }}>
          <Dropdown
            value={type1}
            onChange={(e, { value }) => {
              set_type(value);
            }}
            clearable
            options={options}
            selection
          />
        </div>
        <div style={{ margin: 10 }}>
          <Button
            onClick={() => {
              get_data();
            }}
            color="teal"
            content="Filter"
            icon="filter"
            labelPosition="right"
          />
        </div>
      </div>

      <div
        style={{
          display: loading ? "none" : "flex",
          padding: 50,
          overflow: "scroll",
        }}
      >
        <Table celled singleLine>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>ILO Manager</Table.HeaderCell>
              <Table.HeaderCell># Assignments</Table.HeaderCell>
              {/* <Table.HeaderCell># Learners</Table.HeaderCell>
              <Table.HeaderCell># Video Feedbacks</Table.HeaderCell> */}
              <Table.HeaderCell># Repeated Learners</Table.HeaderCell>

              {/* <Table.HeaderCell># Students not taken Qubits42</Table.HeaderCell> */}
              {/* <Table.HeaderCell>
                # Learners not given feedback{" "}
              </Table.HeaderCell> */}
              <Table.HeaderCell># Referrals</Table.HeaderCell>
              <Table.HeaderCell># Referral regn</Table.HeaderCell>
              <Table.HeaderCell># O/S FIR </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {data_list.map((d) => {
              return (
                <Table.Row style={{ cursor: "pointer" }}>
                  <Table.Cell>{d.ILOM_Name}</Table.Cell>
                  <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "assignments");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.NoOfAssignmentManaged}
                  </Table.Cell>

                  {/* <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "students");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.NoOfStudent}
                  </Table.Cell> */}

                  {/* <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "video");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.VideoFeedback}
                  </Table.Cell> */}

                  <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "repeated");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.RepeatedStudent}
                  </Table.Cell>

                  {/* <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "qubits");
                    }}
                    style={{
                      textAlign: "center",
                      display: d.NoOfStudent == 0 ? "none" : "block",
                    }}
                  >
                    {d.TestTaken} (
                    {((d.TestTaken / d.NoOfStudent) * 100).toFixed(0)}%)
                  </Table.Cell> */}

                  {/* <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "qubits");
                    }}
                    style={{
                      textAlign: "center",
                      display: d.NoOfStudent == 0 ? "block" : "none",
                    }}
                  >
                    N/A
                  </Table.Cell> */}

                  {/* <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "no_feedback");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.no_feedback}
                    (
                    {((d.no_feedback / d.NoOfStudent) * 100).toFixed(0)}%)
                  </Table.Cell> */}
                  <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "referral");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.referral}
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Name, "ref_regn");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.ref_regn}
                  </Table.Cell>
                  <Table.Cell
                    onClick={() => {
                      cell_click(d.ILOM_Empid, "fir");
                    }}
                    style={{ textAlign: "center" }}
                  >
                    {d.FIR}
                  </Table.Cell>

                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>

      <div>
        <Modal
          style={{ marginTop: "30px", marginLeft: "19%" }}
          basic
          closeIcon={<Icon color="red" name="close" />}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
        >
          <Modal.Actions></Modal.Actions>
          <Modal.Content scrolling>
            <Container style={{ padding: "0px" }}>
              <div
                style={{
                  display: "flex",
                  //padding: 20,
                  flexDirection: "column",
                  borderRadius: 5,
                  backgroundColor: "#fff",
                  //paddingLeft: 130, paddingRight: 130,
                  // height: '100vh'
                }}
              >
                <Table striped className="conversion">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center">
                        {header1}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {header2}
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        {header3}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>

                    {cell_data.map((d) => {
                      return (
                        <Table.Row>
                          <Table.Cell>{d.data.split("~")[0] == null ? "" : d.data.split("~")[0]}</Table.Cell>

                          <Table.Cell>
                            {d.data.split("~")[1]}
                            <Label
                              style={{
                                display: d.data.split("~")[3] == "" ? "none" : "flex",
                              }}
                              onClick={() => {
                                window.location.href = d.data.split("~")[3] == null ? "" : d.data.split("~")[3];
                              }}
                            >
                              {" "}
                              ILO Recording {"\u00A0"}{" "}
                              <Icon color="teal" name="video" /> {"\u00A0"}{" "}
                              {d.data.split("~")[4] == null ? "" : d.data.split("~")[4]}{" "}
                            </Label>
                            {/* <Label style={{display:d.data.split('~')[4]?'flex':'none'}}  > Expiry {'\u00A0'} {d.data.split('~')[4]} </Label> */}
                          </Table.Cell>
                          <Table.Cell
                            style={{
                              display:
                                d.data.split("~")[2].length > 20
                                  ? "block"
                                  : "none",
                            }}
                            textAlign="right"
                          >
                            <Label
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                opem_qubits(d.data.split("~")[2]);
                              }}
                            >
                              {" "}
                              Test Taker Dashboard{" "}
                            </Label>
                          </Table.Cell>
                          {/* <Table.Cell style={{ display: (d.data.split('~')[4].length > 20) ? 'block' : 'none' }} textAlign='right'>
                                                        <Label style={{ cursor: 'pointer' }} onClick={() => { opem_qubits(d.data.split('~')[4]) }} > Video </Label>
                                                    </Table.Cell> */}
                          <Table.Cell
                            style={{
                              display:
                                d.data.split("~")[2].length < 20
                                  ? "block"
                                  : "none",
                            }}
                            textAlign="right"
                          >
                            {d.data.split("~")[2]}{" "}
                            {d.data.split("~")[5] == null ? "" : d.data.split("~")[5]}{" "}
                          </Table.Cell>


                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </div>
            </Container>
          </Modal.Content>
        </Modal>
      </div>
      <SVGLoader
        loading={loading}
        SVGType="Table"
        SVGWidth="1200"
        SVGHeight="560"
      ></SVGLoader>
    </div>
  );
};

export default ILOM_kpi;
