import React, { useEffect } from 'react'
import { Dropdown } from 'semantic-ui-react'
const axios = require('axios');


const VendonDropDown = (props) => {
    const [vendors, set_vendors] = React.useState([])

    useEffect(() => {
        get_vendor();
    }, []);

    function get_vendor() {
        axios.get('yusuf/get_vendor')
            .then(function (response) {
                set_vendors(response.data)

            })
            .catch(function (error) {
                alert(error);
            })
    }

    function send_to_parent(value) {
        //alert(value)
        props.passChildData(value);

    }
    return (
            <Dropdown
                placeholder='All Vendor'
                openOnFocus={false}
                search
                fluid
                onChange={(e, { value }) => {
                    send_to_parent(value)

                }}
                selection
                options={vendors}
                clearable
            />
    )

}

export default VendonDropDown