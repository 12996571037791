import { render } from '@testing-library/react';
import React, { useEffect } from 'react'
import ReactDOM from 'react-dom';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { Container, Input, Button, Image } from 'semantic-ui-react'
import VerifyOtp from './VerifyOtp';
const axios = require('axios');

const ForgotPassword = (props) => {
    const [loading, set_loading] = React.useState(false)
    const [id, set_id] = React.useState('')

    function submit() {
        if (id != '' && id != null) {
            set_loading(true)
            axios.get('Nishant/FGTAPI/VerifyUser?id=' + id).then(function (response) {
                set_loading(false)
                if (!response.data.Ok) NotificationManager.error('Invalid Email')
                else {
                    ReactDOM.render(<VerifyOtp OTPValue={response.data.OTP} Email={response.data.data[0].UserEmail}></VerifyOtp>, document.getElementById('Content'));
                }
            }).catch(function (error) {
                set_loading(false)
                alert(error);
            })
        }
        else NotificationManager.error('Please Enter Email');
    }

    return (
        <Container style={{ width: 600, backgroundColor: '#fff' }}>
            <div style={{
                // display: 'flex',
                // flexDirection: 'column',
                // backgroundColor: '#fff',
                // //justifyContent: 'center',
                // alignItems: 'center',
                // width: 800,
                // height: '70vh', position: 'absolute', left: '35%', top: '10%', borderRadius: 10,
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#fff',
                //justifyContent: 'center',
                //alignItems: 'center',
                width: 600,
                paddingLeft: 130, paddingRight: 130,
                height: '100vh'
            }}>
                <br></br>
                <br></br>

                <Image
                    //size='tiny'
                    style={{ alignSelf: 'center', height: 100, width: 100 }}
                    src={require('../../../reusable/images/ko.png')} />
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <br></br>
                <div style={{ display: "grid" }} id="Content">
                    <Input
                        onChange={(e) => {
                            set_id(e.target.value)
                        }}
                        size='large' placeholder='Your Email' />

                    <br></br>
                    <div style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                        <Button
                            style={{ width: 340, fontSize: 16 }}
                            onClick={() => {
                                submit()
                            }} positive>Reset Password</Button>
                    </div>
                </div>
                <br />
                <a href="/login">Login</a>
                <div style={{ alignSelf: 'center', display: loading ? 'flex' : 'none' }}>
                    <Button
                        style={{ width: 340, fontSize: 16 }}
                        loading positive>
                        Loading
                    </Button>
                </div>
            </div>
            <NotificationContainer></NotificationContainer>
        </Container>
    )
}

export default ForgotPassword
