import React, { useState, useEffect } from "react";
import TopHeading from "./Compontents/elements/TopHeading.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "semantic-ui-react";
import BankDropdown from "./Compontents/elements/BankDropdown.js";
import BillingDropDown from "./Compontents/elements/BillingDropDown.js";
import { Dropdown } from "semantic-ui-react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import "../Invoices/Compontents/invoice.css";
import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import CurrencyDropdown from "./Compontents/elements/CurrencyDropdown.js";
import { MDBInput } from "mdbreact";


const axios = require("axios");





const InvoiceFirstStep = () => {
  //#region desgin
  const desgin = {
    border: "0.5px solid #7f8c8d",
    boxShadow: " 1px 2px 4px 2px #7f8c8d",
    padding: "15px",
    width: "1400px",
    margin: "30px 50px",
  };

  //#endregion

  const [show_list, set_show_list] = React.useState("none");
  const [searchResults, setSearchResults] = React.useState([]);
  const [buyerName, set_buyerName] = React.useState("");
  const [Currency, set_Currency] = useState(0);

  const [bankId, set_bankId] = React.useState(0);
  const [billingLocationId, set_billingLocationId] = React.useState(0);
  const [City, set_City] = React.useState(0);
  const [CityId, set_CityId] = React.useState(0);
  const [State, set_State] = React.useState();
  const [StateId, set_StateId] = React.useState(0);
  const [SCQ, setSCQ] = useState(localStorage.getItem("SelectedScids"));
  const [buyerId, setbuyerId] = useState("");
  const [invids, setinvids] = useState("");
  const [Loading, set_Loading] = useState(false);
  const [ShowAllLocation, set_ShowAllLocation] = useState(false);
  const [MultipleInvoice, set_MultipleInvoice] = useState(0);
  const [RTInvoice, set_RTInvoice] = useState(false);
  let d = localStorage.getItem("emp_data");
  const [userid, set_userid] = useState(JSON.parse(d).user_id);
  const [buyerAddress, set_buyerAddress] = React.useState("");

  const btnGenrateInvoice = (e) => {
    console.log("rtinv", RTInvoice)

    //let type_ = RTInvoice == true ? 40 : 29
    let type_ = RTInvoice == true ? 40 : 44
    let JsonData = {
      type: type_,
      buyerName: replaceAll(replaceAll(replaceAll(replaceAll(buyerName, "#", "_sharp_"), "&", "_and_"), "%", ""),"\n"," "),
      buyerId: buyerId,
      CityId: CityId,
      StateId: StateId,
      bankId: bankId,
      SCQ: SCQ,
      userid: userid,
      Currency: Currency,
      billingLocationId: billingLocationId,
      MultipleInvoice: MultipleInvoice,
      BuyerAddress:replaceAll( replaceAll(replaceAll(replaceAll(buyerAddress, "#", "_sharp_"), "&", "_and_"), "%", ""),"\n"," "),
    }
    if(billingLocationId==35 && CityId==0)
    {
      alert("Please select City, if you are selecting Billing Location Canada");
    }
      else {
    if (billingLocationId == 0 || bankId == 0) {
      alert("Please select bank location and billing location");
    } else {
      if (billingLocationId == 1 || billingLocationId == 2 || billingLocationId == 3 || billingLocationId == 4 || billingLocationId == 6) {
        console.log(JSON.stringify(JsonData))
        insertData(JSON.stringify(JsonData));
      }
      else {
        insertData(JSON.stringify(JsonData));
      }
    }
    }
  };

  function replaceAll(string, search, replace) {
    string = string.replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f")
    //    .replace("#","\\#")
    return string.split(search).join(replace);
  }


  async function insertData(jsonDT) {

    set_Loading(true);
    //let type_ = RTInvoice == true ? 40 : 29
    let type_ = RTInvoice == true ? 40 : 44

    //.get("/Invoice/proc_NewInvoiceCreate?type=19&CurrIds=" + SCQ)
    //alert(jsonDT);

    let { data } = await axios.get(
      "/Invoice/proc_NewInvoiceCreate1?type=" + type_ +
      "&jsonDT=" +
      jsonDT
    )
      .then(function (response) {
        response.data.map((value) => {
          //  debugger;

          let { INVId } = value;

          if (INVId != "") {
            //   set_Loading(false);
            //  window.location.href = "/InvoiceSecondStep?invid=" + INVId;
            // setinvids(INVId);
            if (INVId == "0") {
              alert('Sales cannot create invoice from Location India or WHT location');
              setinvids(0);
              window.location.href = "/InvoiceFirstStep";
            }
            else {
              window.location.href = "/InvoiceSecondStep?invid=" + INVId;
              setinvids(INVId);
            }

          }
        });
      })
      .catch(function (error) {
        alert("Invoice not save");
      });


  }

  const CallForBuyerName = () => {

    let { data } = axios
      .get("/Invoice/proc_NewInvoiceCreate?type=19&CurrIds=" + SCQ)
      .then(function (response) {
        response.data.map((i) => {
          set_buyerName(i.buyername)
          set_buyerAddress(i.buyeraddress)
          setbuyerId(i.buyerid)
        })
      })
      .catch(function (error) {
        alert(error);
      });

    console.log(data)

  }
  useEffect(() => {
    CallForBuyerName();
  }, [])


  useEffect(() => {
    isRT_Check();
  }, []);
  function isRT_Check() {
    axios
      .get("/Invoice/proc_IsRT?type=43&CurrIds=" + SCQ)
      .then(function (response) {
        console.log("RT check");
        response.data.map((i) => {
          console.log(i.IsRT);
          if (i.IsRT > 0) {
            console.log(i.IsRT);
            set_RTInvoice(true);
          }
        })

      })
      .catch(function (error) {
        alert(error);
      });
  }



  function search(d) {
    debugger;

    if (d.length != "") {
      set_show_list("");
      setSearchResults.value = null;
      let serstr = "";
      axios
        .get(
          "/Invoice/proc_NewInvoiceCreate?type=11&CurrIds=" + d.toString() + ""
        )
        .then(function (response) {
          debugger;
          let obj = [];
          response.data.map((value) => {
            obj.push({ text: value.text, id: value.id, CorporateName: value.CorporateName, Address: value.Address });
            return console.log(value.text, " id : ", value.id);
          });
          setSearchResults(obj);
        })
        .catch(function (error) {
          alert(error);
        })
        .then(function () { });
    }
  }

  const OnStateDropDownChange = (value) => {

    set_CityId(value);
    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=8&noinvoice=" + value)
      .then(function (response) {
        set_State(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  
  const OnCityDropDownChange = () => {

    axios
      .get("/Invoice/proc_NewInvoiceCreate?type=7&noinvoice=" + billingLocationId)
      .then(function (response) {
        set_City(response.data);
      })
      .catch(function (error) {
        alert(error);
      });
  };

  useEffect(() => {
    //OnCityDropDownChange();


  }, []);

  const callForLoader = () => {
    return (
      <Segment style={{ height: "100vh" }}>
        <Dimmer active inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>

        <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
      </Segment>
    );
  };


  const btnChecked = (e) => {
    if (e.target.checked) {
      set_ShowAllLocation(true);
    } else {
      set_ShowAllLocation(false);
    }
  };

  const btnChecked1 = (e) => {
    if (e.target.checked) {
      set_MultipleInvoice(1);
    } else {
      set_MultipleInvoice(0);
    }
  };



  return (
    <>
      {Loading ? (
        callForLoader()
      ) : (
        <Container fluid>
          <Row>
            <TopHeading HeadingName={"Invoice Generation"} />
          </Row>
          <Container fluid style={desgin}>
            <Row style={{ marginBottom: "15px", padding: "10px" }}>
              <Col sm={3}>
                <MDBInput
                  label="Show All Location"
                  type="checkbox"
                  onClick={(e) => {
                    btnChecked(e);
                  }} />
              </Col>

              <Col sm={3}>
                <MDBInput
                  label="Multiple Invoice"
                  type="checkbox"
                  onClick={(e) => {
                    btnChecked1(e);
                  }}
                />
              </Col>
              <Col sm={3}>
                <MDBInput
                  label="RT Invoice"
                  type="checkbox"
                  checked={RTInvoice}
                  onClick={(e) => {
                    if (e.target.checked) {
                      set_RTInvoice(true);
                    } else {
                      set_RTInvoice(false);
                    }
                  }}
                />
              </Col>

            </Row>
            <Row>
              <Col sm={4}>
                <Form.Label>Select Billing Location : </Form.Label>

                <BillingDropDown
                  passChildData={set_billingLocationId}
                  scid={SCQ}
                  ShowAllLocation={ShowAllLocation}
                  RTInvoice={RTInvoice}
                  billingLocationId={billingLocationId}
                  
                  onChange={(OnCityDropDownChange(billingLocationId))} 

                // onChange={(e, { billingLocationId }) => {
                //   debugger;
                //   OnBillingDropDownChange(billingLocationId);
                // }}

                />

              </Col>
              <Col sm={4}>
                <Form.Label>Select Bank : </Form.Label>
                <br></br>
                <BankDropdown
                  passChildData={set_bankId}
                  scid={SCQ}
                  ShowAllLocation={ShowAllLocation}
                  billingLocationId={billingLocationId}
                />
              </Col>
              <Col sm={4}>
                <Form.Label>Billing For : </Form.Label>
                <div
                  className="divSearchBarDesktop"
                  style={{ left: "-6px", width: "300px !important" }}
                >
                  <TextareaAutosize
                    aria-label="minimum height"
                    rowsMin={3}
                    onChange={(t) => {
                      search(t.target.value);
                      set_buyerName(t.target.value);
                    }}
                    value={buyerName}
                    className="inputSearchDesktop"
                    placeholder="Select Buyer Name"
                  />
                  <span className="SearchBoxItemDesktop">
                    {searchResults.map((item) => {
                      return (
                        <span
                          key={item.id}
                          onClick={() => {
                            setbuyerId(item.id);
                            set_buyerAddress(item.Address)
                            set_buyerName(item.CorporateName)
                            set_show_list("none");
                            setSearchResults([]);
                          }}
                          className="listSearchDesktop"
                        >
                          {item.text}
                        </span>
                      );
                    })}
                  </span>
                </div>
              </Col>
              <Col sm={4}>
                <Form.Label style={{ width: "45%", float: "left", textAlign: "right" }} className="labelFrm">Buyer Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: </Form.Label>
                <TextareaAutosize
                  aria-label="minimum height"
                  rowsMin={3}
                  onChange={(e) => { set_buyerAddress(e.target.value) }}
                  value={buyerAddress}
                  className="form-control dropdownState"
                  placeholder="Select Buyer Address"
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Select City : </Form.Label>
                <Dropdown
                  placeholder="Select City"
                  fluid
                  search
                  selection
                  options={City}
                  onChange={(e, { value }) => {
                    OnStateDropDownChange(value);
                  }}
                  clearable
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Select State : </Form.Label>
                <Dropdown
                  placeholder="Select State "
                  fluid
                  search
                  options={State}
                  selection
                  clearable
                  onChange={(e, { value }) => set_StateId(value)}
                />
              </Col>
              <Col sm={2}>
                <Form.Label>Select Currency : </Form.Label>
                <CurrencyDropdown passChildData={set_Currency} />
              </Col>
            </Row>
            <Row>
              <Col xs={1} md={4}></Col>
              <Col xs={4} md={4}>
                <Button
                  content="Next"
                  color="teal"
                  icon="angle double right"
                  style={{ marginTop: "20px" }}
                  onClick={() => {
                    btnGenrateInvoice();
                  }}
                />
              </Col>
              <Col xs={1} md={4}></Col>
            </Row>
          </Container>
        </Container>

      )}
    </>
  );
};

export default InvoiceFirstStep;
