import React, { useState, useEffect } from 'react';
import CSDataTable from '../../../Freelancer/Reusable/CSDataTable';
import SVGLoader from '../SVGLoader';

import { Dimmer, Loader, Image, Segment } from "semantic-ui-react";
import ReactDOM from 'react-dom';

var moment = require('moment');
//const readXlsxFile1 = require('read-excel-file/node')
// const [columns, setColumns] = useState([]);
// const [data, setData] = useState([]);

const axios = require("axios");
const InterimFB = (props) => {

    // const [Rows, setRows] = useState([]);
    const [loading, set_loading] = React.useState(true)
    const [Link, set_Link] = useState(0);

    useEffect(() => {
        // load event no function call required
        GetLink(props.Y_AID);
    }, [])


    const GetLink = async (Y_AID) => {
        set_loading(true);
        let SIDEncryptedValue = await GetEncryptedValue(Y_AID);
        let link = "https://rms.koenig-solutions.com/Student/StdFeedback/InterimFeedbackNew.aspx?id=" + SIDEncryptedValue;
        // alert(link);
        console.log(link);
        set_Link(link);

        //document.getElementById('RT_Line1').innerHTML = link;
        //setHooks(link);
    }

    const GetEncryptedValue = async (Value) => {
        let V = "";
        await axios.get("Nishant/GETStudedentEncryptedValue?type=0&TextValue=" + Value).then(function (response) {
            V = response.data[0].EValue;
        }).catch(function (error) {
            alert(error);
        });
        return V;
    }

    function CopydivContent(Content) {
        var copyText = Content
        document.addEventListener('copy', function (e) {
            e.clipboardData.setData('text/plain', copyText);
            e.preventDefault();
        }, true);
        document.execCommand('copy');
        alert('Copied');
        return true;
    }


    return (
        <div className="pl-2 pr-2">
            <span>
                <label> Link :</label> {Link}
                &nbsp;&nbsp;&nbsp; <span data-tip="Copy Link" className="badge btn btn-outline-primary mb-2" onClick={(e) => CopydivContent(Link)}>Copy link</span>
            </span>
        </div>
    )
}

export default InterimFB
