import React from 'react';
import HeaderMenu from '../Nishant/Freelancer/Reusable/HeaderMenu';
import AccordionItem from './AccordionItem'; // Import the AccordionItem component
import './UpcomingEvent.css';
import {
    Icon,
    Loader,
    Button,
    Modal,
    Table,
    Header,
    Input,
    Label,
    Segment,
} from "semantic-ui-react";
const axios = require("axios");

export default function MyComponent() {
    const [TechcallHtml, set_TechcallHtml] = React.useState("");
    const [loading, set_loading] = React.useState(true);

    let d = localStorage.getItem("emp_data");
    async function get_data() {
        set_loading(true);

        let d = localStorage.getItem("emp_data");
        console.log(data.d);
        let { data } = await axios.get(
            "yusuf/get_TechCallDetails?FlId=" + JSON.parse(d).trainer_id + ""
        );
        console.log(data)
        if (data.length > 0) {
            set_loading(false);
        } else {
            // alert("NO DATA")
            set_loading(false);
        }
    }

    return (
        <div>
            <HeaderMenu HeaderText="Upcoming Events" />

            {/* Add the AccordionItem */}
            <AccordionItem title="Tech Call Details">
                {/* Content for the accordion item */}
                {TechcallHtml}
            </AccordionItem>
        </div>
    );
}
