import React from "react"
import TextareaAutosize from "@material-ui/core/TextareaAutosize";

const BuyerAddressDetails = (props)=>{

    return(
        <table width="100%" border="0" cellSpacing="5" cellPadding="5" style={{borderBottom: "0 none", borderLeft: "0 none"}}>
           <tbody>
                <tr>
                    <td bgcolor="#fff">
                        <label style={{verticalAlign: "top"}}>Buyer</label><br/>                                                          
                        <span style={{fontWeight:"bold"}}>{props.BuyerName}</span><br/>
                        {/* {
                            props.BuyerAddress!=null?( <span style={{fontWeight:"bold"}} dangerouslySetInnerHTML={{ __html:props.BuyerAddress+"<br>"}}></span>) :""
                        }       */}
                        {
                            props.BuyerAddress!=null?( 
                                  <TextareaAutosize
                                aria-label="minimum height"
                                rowsMin={3}                                
                                value={props.BuyerAddress}
                                disabled={true}
                                className="form-control dropdownState"
                                style={{border: "transparent",background: "white"}}
                              />) :""
                        }                                      
                        {
                          props.NoClientCountryRequired==false?(<span>Client Country - {props.ClientCountry}</span>):""
                        }      
                                  
                    </td>
                </tr>                
            </tbody>
         </table>
    )
}

export default BuyerAddressDetails