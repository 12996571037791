import React, { Component } from 'react'
import { Input, Button, Label } from "semantic-ui-react";
import NotificationManager from 'react-notifications/lib/NotificationManager';
const axios = require('axios');

const ChangePassword = props => {
    const [loading, set_loading] = React.useState(false)
    const [NewPassword, setNewPassword] = React.useState('')
    const [ConfirmNewPassword, setConfirmNewPassword] = React.useState('')

    function ChangePWd(){
        if(verifyPWD()){
            set_loading(true);
            axios.get('Nishant/FGTAPI/ChangePAssword?Email=' + props.Email +'&PWD=' + NewPassword).then(function (response) {
                set_loading(false)
                if (!response.data.Ok) NotificationManager.error('Invalid Email')
                else {
                    NotificationManager.success('Password SuccessFully Changed. Please Login again');
                    setTimeout(() => {
                        window.location.href = window.location.origin + '/login';    
                    }, 2000);
                }
            }).catch(function (error) {
                    set_loading(false)
                    alert(error);
                })
        }
    }

    function verifyPWD() {
        if (NewPassword != '' && NewPassword != null) {
            if (NewPassword == ConfirmNewPassword) return true
            else { NotificationManager.error('Password did not matched'); return false; }
        }
        else { NotificationManager.error('Please Enter Password'); return false; }
    }

    return (
        <>
            <Input
                onChange={(e) => {
                    setNewPassword(e.target.value)
                }}
                size='large' type="password" placeholder='Enter New Password' />
            <br></br>
            <Input
                onChange={(e) => {
                    setConfirmNewPassword(e.target.value)
                }}
                size='large' type="password" placeholder='Confirm New Password' />
                <br></br>
            <div style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                <Button
                    style={{ width: 340, fontSize: 16 }}
                    onClick={() => {
                        ChangePWd()
                    }} positive>Change Password</Button>
            </div>
        </>
    )
}

export default ChangePassword
