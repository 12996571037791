//import { useState } from "react";
import React,{Component} from 'react'
import {Input,Button,TextArea,Container,Image} from 'semantic-ui-react'

import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import NotificationManager from 'react-notifications/lib/NotificationManager';

const axios =require('axios');
const queryString = require("query-string");

const FeedBack =(props) =>{
const queryURL = queryString.parseUrl(window.location.href);

const [emailid, setemailid] = React.useState(queryURL.query.Email);
  
const [loading,set_loading] = React.useState(false)
const [UserName,feedbackUserName] = React.useState('')
const [UserMessage,feedbackMessage] = React.useState('')

async function submitFeedback(){
    if(verifyInput()){
         set_loading(true);
        //  console.log(" Username :"+UserName)
        //  console.log("Email ID :"+emailid)
        //  console.log("Message  :"+UserMessage)
    
         if(emailid==undefined)
         {
            NotificationManager.error('Invalid Url !!!!');
            setTimeout(() => {
                window.location.href = window.location.origin + '/login';    
            }, 2000);
         }
         else
         {
            axios.get('Avishek/Save_Student_Feedback?Type=1&emailid=' + emailid +'&username=' + UserName+'&usermessage=' + UserMessage).then(function (response) {            
            console.log("API CALLED");
        
            set_loading(false)
            if (!response.data.Ok) 
            NotificationManager.success('Feedback submitted Successfully')
            else {
                NotificationManager.error('Something Went Wrong Try Again!!!!');
                setTimeout(() => {
                    window.location.href = window.location.origin + '/login';    
                }, 2000);
            }
            
        }).catch(function (error) {
            console.log("Error :")        
                set_loading(false)
                alert(error);
            })
        }
    }
}

function verifyInput() {
   
    if (UserMessage != '') {            
    return true;    
    }
    else 
    { 
        NotificationManager.error('Please Enter Message'); 
        return false;
     }
}

return(   
<Container style={{ width: 600, backgroundColor: '#fff' }}>
<div style={{
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    width: 600,
    paddingLeft: 130, paddingRight: 130,
    height: '100vh'
}}>
    <br></br>
    <br></br>

    <Image
        //size='tiny'
        style={{ alignSelf: 'center', height: 100, width: 100 }}
        src={require('../../reusable/images/ko.png')}
        //src={require('../../../reusable/images/ko.png')}
         />
    <br></br>
    <br></br>
    <br></br>
    <br></br>

    <br></br>
    <div style={{display: "grid"}} id="Content">
    <Input onChange={(e)=>{
             feedbackUserName(e.target.value)
        }}
        size='large' type="text" placeholder='Name' />

        <br></br><br></br>
        <TextArea   onChange={(e) => {
                feedbackMessage(e.target.value)
             }}
            placeholder='Your Feedback ' style={{ minHeight: 100 }} />
  <br></br>
<div style={{ alignSelf: 'center', display: loading ? 'none' : 'flex' }}>
                
                <Button
                    style={{ width: 340, fontSize: 16 }}
                    onClick={() => {
                        submitFeedback()
                    }} positive>Submit</Button>

         

        </div>
    </div>
   
   
</div>
<NotificationContainer></NotificationContainer>
</Container>







      )
}

export default FeedBack