import React, { useEffect } from 'react';
import VendonDropDown from '../../reusable/VendonDropDown'
import ContinentDropDown from '../../reusable/ContinentDropDown'
import CountrtDropDown from "../../reusable/CountryDropDown"
import RegCorpDropDown from "../../reusable/RegCorpDropDown"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Input } from "semantic-ui-react";
import { Loader, Radio, Button, Icon, Checkbox, Modal, Table, Dropdown } from 'semantic-ui-react'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './Bucket.css';
import 'bootstrap/dist/css/bootstrap.css';
import SCTypeDropDown from '../../reusable/SCTypeDropDown';

const axios = require('axios');

export default function Bucket() {

    const [loading, set_loading] = React.useState(false)
    const [country_name, set_country_name] = React.useState("");
    const [geo, set_geo] = React.useState("");
    const [vendor_id, set_vendor_id] = React.useState("");
    const [bucket, set_bucket] = React.useState("");
    const [sc_type, set_sc_type] = React.useState("");
    const [selfpaid_corp, set_selfpaid_corp] = React.useState("");
    const [regcorp, set_regcorp] = React.useState([]);
    const [regcorp_id, set_RegCorp_Id] = React.useState(0);
    const [regcorp_name, set_RegCorp_Name] = React.useState("");
    const [cell_data, cell_cell_data] = React.useState([])
    const [open, setOpen] = React.useState(false)

    async function Submit_click() {
        set_loading(true)
        if (bucket == '') {
            alert("Please enter bucket name!!")
        }
        else {
            let { data } = await axios.get('yusuf/buckets?Buckets=' + bucket + '&VendorId=' + vendor_id + '&CountryId=' + country_name + '&ContinentId=' + geo + '&SC_Type=' + sc_type + '&RegCorp_Id=' + regcorp_id + '&SelfPaidorCorporate=' + selfpaid_corp + '')
            if (data.length > 0) {
                alert("Bucket added successfully...")
            }
            else {
                alert("NO DATA")
            }
        }
        set_loading(false)
    }

    function cell_onclick(u, m) {
        debugger
        if (m == 'total') { return }
        set_loading(true)

        axios.get('yusuf/get_BucketNR?country=' + country_name + '&vendor=' + vendor_id + '&regcorp=' + regcorp_id + '')
            .then(function (response) {
                set_loading(false)
                if (response.data.length > 0) {
                    cell_cell_data(response.data)
                    setOpen(true)
                    set_loading(false)
                }
                else {
                    alert("NO DATA")
                }
                set_loading(false)



            })
            .catch(function (error) {
                alert(error);
            })


    }

    return (

        <div style={{ display: 'flex', flexDirection: 'row', width: '100vw', height: '100vh' }}>

            {/* DATA SECTION */}
            <div style={{ width: '90vw', marginLeft: '5vw', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff' }}>
                <div style={{
                    padding: '1.5%',
                    height: '8vh', backgroundColor: '#fafafb', width: '',
                    WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                    MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                    boxShadow: "0 8px 6px -6px #d5d4d6"
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100%' }}>
                            <div style={{ width: '100%', marginTop: '-0.5%', marginLeft: '0px', marginRight: '0px' }}>
                                <Row>
                                    <Col sm={2} style={{ paddingTop: '0.5%' }}>
                                        {/* <center>
                                            <span style={{ fontSize: 'larger', fontWeight: 'bold', fontFamily: 'sans-serif', width: '10%' }}>Bucket NR</span>
                                        </center> */}
                                    </Col>
                                    <Col sm={2}>
                                        {/* <VendonDropDown passChildData={set_vendor_id} /> */}
                                    </Col>
                                    <Col sm={2}>
                                        {/* <CountrtDropDown passChildData={set_country_name} /> */}
                                    </Col>
                                    <Col sm={4}>
                                        {/* <RegCorpDropDown
                                            scrolling
                                            passChildData={(regcorp_name, regcorp_id) => {
                                                set_RegCorp_Id(regcorp_id);
                                                set_RegCorp_Name(regcorp_name);
                                            }}
                                        /> */}
                                    </Col>
                                    <Col sm={2}>
                                        <button className='btn btn-primary' style={{ cursor: 'pointer' }} onClick={() => {
                                            cell_onclick()
                                            //alert(data_list[ii].cce + '--'+header[i])
                                            //alert(header[i])
                                        }}>Display NR of all Buckets</button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/* <div style={{ width: '20%', marginTop: '-0.5%' }} >
                            <button className="btn btn-danger" onClick={event => window.location.href = 'https://rms.koenig-solutions.com/Cce/frm_CceReport.aspx?Parameter=Old'}>
                                Old Cce Conversion
                            </button>
                        </div>
                        <div style={{ width: '20%', marginTop: '-0.5%' }} >
                            <ReactHTMLTableToExcel
                                className="btn btn-info"
                                table="conversion"
                                filename="Conversion"
                                sheet="Sheet"
                                buttonText="Export to Excel"
                                style={{ padding: '5px', borderRadius: "5px", fontSize: "14px" }} />
                        </div> */}
                    </div>


                </div>
                <br></br>
                <div style={{
                    padding: '1.5%',
                    height: '8vh', backgroundColor: '#fafafb', width: '',
                    WebkitBoxShadow: "0 8px 6px -6px #d5d4d6",
                    MozBoxShadow: "0 8px 6px -6px #d5d4d6",
                    boxShadow: "0 8px 6px -6px #d5d4d6"
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100%' }}>
                            <div style={{ width: '100%', marginTop: '-0.5%', marginLeft: '0px', marginRight: '0px' }}>
                                <Row>
                                    <Col sm={2} style={{ paddingTop: '0.5%' }}>
                                        <center>
                                            <span style={{ fontSize: 'larger', fontWeight: 'bold', fontFamily: 'sans-serif', width: '10%' }}>Add Buckets</span>
                                        </center>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        {/* <div style={{ width: '20%', marginTop: '-0.5%' }} >
                            <button className="btn btn-danger" onClick={event => window.location.href = 'https://rms.koenig-solutions.com/Cce/frm_CceReport.aspx?Parameter=Old'}>
                                Old Cce Conversion
                            </button>
                        </div>
                        <div style={{ width: '20%', marginTop: '-0.5%' }} >
                            <ReactHTMLTableToExcel
                                className="btn btn-info"
                                table="conversion"
                                filename="Conversion"
                                sheet="Sheet"
                                buttonText="Export to Excel"
                                style={{ padding: '5px', borderRadius: "5px", fontSize: "14px" }} />
                        </div> */}
                    </div>


                </div>

                <div style={{
                    display: loading ? 'none' : 'flex', padding: '1%', overflow: 'hidden', height: '85vh', marginTop: '0%',
                    backgroundColor: 'rgb(255, 255, 255)', boxShadow: 'rgb(213 212 214) 10px 10px 10px 10px'
                }} className="divBucket">

                    {/* <DynamicDataTable style={{border:2}} rows={data_list} /> */}
                    <Container>
                        <Row>
                            <hr></hr>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ textAlign: 'right', paddingTop: '0.7%' }}>
                                <h5>Bucket </h5>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'center', paddingTop: '0.7%' }}>
                                <h5>: </h5>
                            </Col>
                            <Col sm={6}>
                                <Input
                                    placeholder="Bucket Name"
                                    value={bucket}
                                    onChange={(e) => set_bucket(e.target.value)}
                                    style={{ width: '20vw', fontSize: '110%', padding: '0%' }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ textAlign: 'right', paddingTop: '0.7%' }}>
                                <h5>Vendor</h5>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'center', paddingTop: '0.7%' }}>
                                <h5>:</h5>
                            </Col>
                            <Col sm={6}>
                                <VendonDropDown passChildData={set_vendor_id} />
                            </Col>
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ textAlign: 'right', paddingTop: '0.7%' }}>
                                <h5>Country</h5>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'center', paddingTop: '0.7%' }}>
                                <h5>:</h5>
                            </Col>
                            <Col sm={6}>
                                <CountrtDropDown passChildData={set_country_name} />
                            </Col>
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ textAlign: 'right', paddingTop: '0.7%' }}>
                                <h5>Continent</h5>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'center', paddingTop: '0.7%' }}>
                                <h5>:</h5>
                            </Col>
                            <Col sm={6}>
                                <ContinentDropDown passChildData={set_geo} />
                            </Col>
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ textAlign: 'right', paddingTop: '0.7%' }}>
                                <h5>SC Type </h5>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'center', paddingTop: '0.7%' }}>
                                <h5>: </h5>
                            </Col>
                            <Col sm={6}>
                                <SCTypeDropDown
                                    scrolling
                                    passChildData={(regcorp_name, regcorp_id) => {
                                        set_sc_type(regcorp_id);
                                    }}
                                />
                                {/* <Input
                                    placeholder="Enter SC Type"
                                    value={sc_type}
                                    onChange={(e) => set_sc_type(e.target.value)}
                                    style={{ width: '20vw', fontSize: '110%', padding: '0%' }}
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ textAlign: 'right', paddingTop: '0.7%' }}>
                                <h5>Regular Corporate </h5>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'center', paddingTop: '0.7%' }}>
                                <h5>: </h5>
                            </Col>
                            <Col sm={6}>
                                <RegCorpDropDown
                                    scrolling
                                    passChildData={(regcorp_name, regcorp_id) => {
                                        set_RegCorp_Id(regcorp_id);
                                        set_RegCorp_Name(regcorp_name);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                        <Row>
                            <Col sm={5} style={{ textAlign: 'right', paddingTop: '0.7%' }}>
                                <h5>Self-paid / Corporate </h5>
                            </Col>
                            <Col sm={1} style={{ textAlign: 'center', paddingTop: '0.7%' }}>
                                <h5>: </h5>
                            </Col>
                            <Col sm={6} style={{ textAlign: '', paddingTop: '0.7%' }}>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Radio checked={(selfpaid_corp == 'Self') ? true : false} onChange={() => { set_selfpaid_corp('Self') }} label='Self Paid' />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <Radio checked={(selfpaid_corp == 'Corp') ? true : false} onChange={() => { set_selfpaid_corp('Corp') }} label='Corporate' />
                            </Col>
                        </Row>
                        <Row>
                            <hr></hr>
                        </Row>
                        <br></br>
                        <Row>
                            <Col sm={4}>
                            </Col>
                            <Col sm={4}>
                                <Button className="btn btn-info" style={{ width: '100%' }}
                                    onClick={() =>
                                        Submit_click()
                                    }>Submit</Button>
                            </Col>
                            <Col sm={4}>
                            </Col>
                        </Row>
                    </Container>
                </div>

                {/* LOADER */}
                <div style={{
                    display: loading ? 'flex' : 'none',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80vw',
                    flexDirection: 'column', height: '90vh'
                }}>
                    <Loader active inline size='massive'>getting data...</Loader>


                </div>

                <div>
                    <Modal
                        open={open}
                        onClose={() => setOpen(false)}
                        onOpen={() => setOpen(true)}
                        style={{ marginTop: '0px', marginLeft: '10%', width: '80%' }}
                    >

                        <Modal.Header style={{ height: '8vh' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ position: 'absolute', right: 10 }}>
                                    <Button onClick={() => setOpen(false)} primary>
                                        Close <Icon style={{ marginLeft: 10 }} name='close' />
                                    </Button>
                                </div>
                            </div>

                        </Modal.Header>

                        <Modal.Content scrolling>

                            <Modal.Description>
                                <Table className="" celled fixed singleLine>
                                    <Table.Header>
                                        <Table.Row>
                                            {/* <Table.HeaderCell>Name</Table.HeaderCell> */}
                                            <Table.HeaderCell>Employee</Table.HeaderCell>
                                            <Table.HeaderCell>MonYear</Table.HeaderCell>
                                            <Table.HeaderCell>Assignment</Table.HeaderCell>
                                            <Table.HeaderCell>NR</Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>

                                    <Table.Body>

                                        {cell_data.map((d) => {
                                            return (
                                                <Table.Row>
                                                    {/* <Table.Cell>{d.Name}</Table.Cell> */}
                                                    <Table.Cell><center>{d.Name}</center></Table.Cell>
                                                    <Table.Cell><center>{d.month}</center></Table.Cell>
                                                    <Table.Cell><center>{d.assignment_id}</center></Table.Cell>
                                                    <Table.Cell><center>{d.total_nr}</center></Table.Cell>
                                                </Table.Row>
                                            )
                                        })}



                                    </Table.Body>
                                </Table>


                            </Modal.Description>
                        </Modal.Content>

                    </Modal>
                </div>

            </div>




        </div>


    );
}
